<template>
  <transition
    enter-active-class="ease-out duration-300"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-from-class="opacity-100"
    leave-active-class="ease-out duration-300"
    leave-to-class="opacity-0"
  >
    <div
      v-if="isOpen"
      class="fixed w-screen h-screen z-80 inset-0 bg-grey-900/40 backdrop-blur"
    ></div>
  </transition>
  <focus-loop :isVisible="true">
    <transition
      enter-class="sm:opacity-0"
      enter-from-class="translate-y-full sm:translate-y-0"
      enter-active-class="duration-700 sm:duration-200 ease-out"
      enter-to-class="translate-y-0 sm:opacity-100"
      leave-class="sm:opacity-100"
      leave-from-class="translate-y-0"
      leave-active-class="duration-700 sm:duration-200 ease-out"
      leave-to-class="translate-y-full sm:translate-y-0 sm:opacity-0"
    >
      <div
        v-if="isOpen"
        class="fixed px-5 z-100 bg-white rounded-t-2xl sm:rounded-t-lg mx-auto my-auto translate-y-0 inset-0 items-center transition-all rounded-lg shadow-xl sm:px-12 flex flex-col justify-between overflow-y-hidden h-screen w-screen sm:max-w-3xl"
        :class="extraClasses"
        role="dialog"
        aria-modal="true"
      >
        <div class="flex flex-col w-full py-7 overflow-auto">
          <slot />
        </div>
      </div>
    </transition>
  </focus-loop>
</template>

<script lang="ts">
import { FocusLoop } from "@vue-a11y/focus-loop";

export default {
  name: "BaseModal",
  components: {
    FocusLoop,
  },
  props: {
    isOpen: { type: Boolean, default: false },
    isSmall: { type: Boolean, default: false },
    shouldAdjustToContent: { type: Boolean, default: false },
  },
  computed: {
    extraClasses(): string {
      if (this.isSmall) {
        return "sm:h-96 top-1/2 sm:top-0 sm:max-h-4xl";
      }
      if (this.shouldAdjustToContent) {
        return "sm:min-h-1/2";
      }
      return "sm:min-h-1/2 sm:max-h-4xl";
    },
  },
  beforeUnmount() {
    document.body.classList.toggle("overflow-hidden", false);
  },
  watch: {
    isOpen() {
      document.body.classList.toggle("overflow-hidden", this.isOpen);
    },
  },
};
</script>
