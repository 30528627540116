<template>
  <div class="copy-emphasis mb-8">Sign in details</div>
  <div class="">
    Your email address is
    <span class="copy-bold">{{ individual.email }}</span>
  </div>
  <div class="mt-3">
    To edit your email address or password, click
    <a :href="accountUrl" class="text-cta-tertiary">HERE</a>
  </div>
</template>

<script lang="ts">
import useIndividual from "@/composables/useIndividual";

export default {
  setup() {
    const { individual } = useIndividual();

    return {
      individual,
      accountUrl: process.env.VITE_ACCOUNT_DOMAIN + "/my-account",
    };
  },
};
</script>
