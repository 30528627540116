<template>
  <div>
    <div v-if="requiredSkills.length">
      <role-apply-skill-warning
        data-cy="required-skill-error"
        v-if="hasRequiredSkillError"
        message="You are required to answer all of the required skill(s)"
        ref="requiredSkillError"
        class="mb-6 scrollable-error"
        :isError="true"
      />
      <role-apply-skill-form
        v-for="skill in requiredSkills"
        :ref="skill.key"
        :key="skill.skill_id"
        :skill="skill"
        :rolesHeld="rolesHeld"
      />
    </div>

    <div v-if="desiredSkills.length" ref="desiredSkills">
      <h3 class="text-grey-700 copy-emphasis mb-6 mt-12">
        {{
          oneDesiredIsRequired
            ? "You should answer at least one of the following skills"
            : "Desired for this role"
        }}
      </h3>
      <role-apply-skill-warning
        data-cy="desired-skill-error"
        v-if="hasOneDesiredError"
        message="You are required to answer one of the following skills"
        ref="desiredSkillError"
        class="mb-6 scrollable-error"
        :isError="true"
      />
      <role-apply-skill-warning
        data-cy="desired-skill-nudge"
        v-if="!hasOneDesiredError && hasDesiredSkillsNudge"
        :message="desiredSkillsNudgeMessage"
        ref="desiredSkillNudge"
        class="mb-6"
      />
      <role-apply-skill-form
        v-for="skill in desiredSkills"
        :ref="skill.key"
        :key="skill.key"
        :skill="skill"
        :rolesHeld="rolesHeld"
        :canSkip="canSkip(skill)"
        :hasOneDesiredError="hasOneDesiredError"
        :hasNudgeError="shouldHaveNudgeBorder(skill)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import useVuelidate from "@vuelidate/core";
import { requiredIf } from "@vuelidate/validators";
import useRoleApplication from "@/composables/useRoleApplication";
import useRole from "@/composables/useRole";
import useIndividual from "@/composables/useIndividual";
import { RoleApplicationSkill } from "@/models/RoleApplication";
import RoleApplySkillWarning from "@/components/roleApplication/RoleApplySkillWarning.vue";
import RoleApplySkillForm from "./RoleApplySkillForm.vue";
import { Role } from "@/models/Role";

function mustHaveResponse(skills: any) {
  return skills.every(skill => {
    const hasResponses = !!skill.response.relevant_roles_notes;
    const hasRolesOrSkippedRoles =
      !!skill.response.relevant_roles?.length ||
      skill.response.no_role_selected;
    return hasRolesOrSkippedRoles && hasResponses;
  });
}

function mustHaveOneDesired(skills: RoleApplicationSkill[]) {
  if (!this.oneDesiredIsRequired) return true;

  return skills.some(function (skill) {
    if (!skill.response) return false;

    const hasBeenSkipped = skill.response.has_been_skipped;
    const hasSelectedOrSkippedRoles =
      !!skill.response.relevant_roles?.length ||
      skill.response.no_role_selected;

    const hasGivenResponse = !!skill.response.relevant_roles_notes;

    return !hasBeenSkipped && hasSelectedOrSkippedRoles && hasGivenResponse;
  });
}

export default {
  name: "RoleApplySkills",
  components: {
    RoleApplySkillForm,
    RoleApplySkillWarning,
  },
  props: {
    shouldNudgeDesiredSkills: {
      type: Boolean,
    },
  },
  setup() {
    const { roleApplication, updateRoleApplication } = useRoleApplication();
    const { role } = useRole();
    const { individual } = useIndividual();

    return {
      v$: useVuelidate(),
      roleApplication,
      updateRoleApplication,
      individual,
      role,
    };
  },
  validations: () => ({
    requiredSkills: {
      required: requiredIf(function () {
        return this.requiredSkills.length;
      }),
      mustHaveResponse,
    },
    desiredSkills: {
      required: requiredIf(function () {
        return this.desiredSkills.length && this.oneDesiredIsRequired;
      }),
      mustHaveOneDesired,
    },
  }),
  computed: {
    desiredSkillsNudgeMessage() {
      const shouldPluralise =
        this.desiredSkills.filter(skill => this.isSkillMissing(skill)).length >
        1;
      return `Do you want to skip the desired skill${
        shouldPluralise ? "s" : ""
      }?`;
    },
    hasDesiredSkillsNudge() {
      return this.shouldNudgeDesiredSkills && this.hasMissingDesiredSkills;
    },
    hasRequiredSkillError() {
      return this.v$.requiredSkills.$error && this.v$.requiredSkills.$dirty;
    },
    hasOneDesiredError() {
      return this.v$.desiredSkills.$error && this.v$.desiredSkills.$dirty;
    },
    oneDesiredIsRequired() {
      return this.role?.one_desired_required;
    },
    rolesHeld(): Role[] {
      const rolesHeld =
        this.roleApplication?.selected_roles_held ||
        this.individual?.roles_held.filter(
          role_held => !role_held.archived_date
        );

      return (rolesHeld || []).map(
        ({ id, organisation, position, from, to }) => ({
          id,
          organisation,
          position,
          from,
          to,
        })
      );
    },
    requiredSkills(): RoleApplicationSkill[] {
      const skills = this.roleApplication.skills || [];
      return skills.filter(skill => skill.required == "true");
    },
    desiredSkills(): RoleApplicationSkill[] {
      const skills = this.roleApplication.skills || [];
      return skills.filter(skill => skill.required != "true");
    },
    hasMissingDesiredSkills() {
      return this.desiredSkills.some(skill => this.isSkillMissing(skill));
    },
  },
  methods: {
    shouldHaveNudgeBorder(skill) {
      return this.hasDesiredSkillsNudge && this.isSkillMissing(skill);
    },
    isSkillMissing(skill) {
      if (!skill.response) return true;

      const hasBeenSkipped = skill.response.has_been_skipped;
      const hasSelectedOrSkippedRoles =
        !!skill.response.relevant_roles?.length ||
        skill.response.no_role_selected;
      const hasGivenResponse = !!skill.response.relevant_roles_notes;

      return !hasBeenSkipped && !hasSelectedOrSkippedRoles && !hasGivenResponse;
    },
    canSkip(skill) {
      const requiresDesiredSkill = this.role.one_desired_required;
      const isSkillSkipped = skill.response?.has_been_skipped;
      const allButOneSkipped =
        this.desiredSkills.filter(skill => !skill.response?.has_been_skipped)
          .length === 1;

      return !requiresDesiredSkill || (!isSkillSkipped && !allButOneSkipped);
    },
  },
};
</script>
