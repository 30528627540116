<template>
  <div
    class="bg-white max-w-screen-xl mx-auto w-full p-4 md:px-12 md:pt-6 lg:px-28 lg:pt-10 pb-24"
  >
    <div class="h-8"></div>
    <personal-details />
    <hr class="section-separator-lg" />
    <location-and-contact />
    <hr class="section-separator-lg" />
    <ethnic-background />
    <hr class="section-separator-lg" />
    <third-party-preferences />
    <hr class="section-separator-lg" />
    <snooze-notifications />
    <hr class="section-separator-lg" />
    <sign-in-details />
    <hr class="section-separator-lg" />
    <a href="/unsubscribe" class="mt-16 text-cta-tertiary"
      >I'm thinking of closing my account ...</a
    >
  </div>
</template>

<script lang="ts">
import PersonalDetails from "@/components/account/PersonalDetails.vue";
import LocationAndContact from "@/components/account/LocationAndContact.vue";
import EthnicBackground from "@/components/account/EthnicBackground.vue";
import ThirdPartyPreferences from "@/components/account/ThirdPartyPreferences.vue";
import SnoozeNotifications from "@/components/account/SnoozeNotifications.vue";
import SignInDetails from "@/components/account/SignInDetails.vue";
import useIndividual from "@/composables/useIndividual";
import { useToast } from "vue-toastification";

export default {
  components: {
    PersonalDetails,
    LocationAndContact,
    EthnicBackground,
    ThirdPartyPreferences,
    SnoozeNotifications,
    SignInDetails,
  },
  setup() {
    const { changesMade } = useIndividual();

    return {
      changesMade,
      toast: useToast(),
    };
  },
  watch: {
    changesMade() {
      this.toast.success("Changes saved successfully", { timeout: 3000 });
    },
  },
};
</script>
