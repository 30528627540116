<template>
  <button
    id="rolesTableAddButton"
    data-cy="add-a-role-button"
    class="cursor-pointer bg-sage-50 hover:bg-grey-100 rounded-full flex items-center justify-start sm:justify-center w-full border-none outline-none focus:ring focus:ring-orange-500"
    type="button"
  >
    <div
      class="rounded-full bg-sage-600 hover:bg-sage-700 active:bg-sage-800 flex items-center justify-center h-11 w-11 mx-4 my-1.5 sm:my-4 text-white"
    >
      <span class="material-icons-round"> add </span>
    </div>
    <span class="text-cta-primary text-grey-900">Add a role</span>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "AddRoleButton",
});
</script>
