<template>
  <div
    class="bg-white max-w-screen-xl mx-auto w-full p-4 md:px-12 md:pt-6 lg:px-28 lg:pt-10 pb-24"
  >
    <h3 class="copy-emphasis my-8">Your applications</h3>
    <div v-if="!isLoaded">
      <div
        v-for="i in [1, 2, 3]"
        :key="i"
        class="bg-grey-50 animate pulse mb-6 w-full rounded h-60"
      ></div>
    </div>
    <template v-else>
      <my-application
        v-for="application in visibleApplications"
        :key="application.id"
        :application="application"
      />
      <div
        class="w-full p-12 md:py-18 rounded bg-grey-50 text-text-grey-700 text-center"
        v-if="zeroVisibleApplications"
      >
        <h4 class="copy-emphasis">You have no recent applications.</h4>
        <p class="mt-3">
          You haven't yet applied for any roles - once you do so, your
          applications will be listed here for future reference.
        </p>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import MyApplication from "@/components/account/MyApplication.vue";
import { onMounted, computed, ref } from "vue";
import { apiAxios } from "@/lib/axios";
import { differenceInCalendarDays, isFuture } from "date-fns";

const applications = ref(null);
const isLoaded = ref(false);

onMounted(async () => {
  const response = await apiAxios.get("/applications");
  if (response.status == 200) {
    applications.value = response.data;
    isLoaded.value = true;
  }
});

const visibleApplications = computed(() => {
  return applications.value.filter(application => {
    const submitted = application.submitted == true;
    const deadlineInFuture = isFuture(new Date(application.deadline));
    const watchlistThreshold = 10;
    const significantlyFilledIn =
      application.amount_completed_cache > watchlistThreshold;
    const recentlyExpired =
      differenceInCalendarDays(new Date(), new Date(application.deadline)) < 60;

    return (
      submitted ||
      (significantlyFilledIn && (deadlineInFuture || recentlyExpired))
    );
  });
});

const zeroVisibleApplications = computed(() => {
  return visibleApplications.value.length === 0;
});
</script>
