<template>
  <div>
    <role-apply-professional-experience-role
      v-for="(role, index) in visibleRoles"
      :key="role.id"
      :index="index"
      :role="role"
    />
  </div>
</template>

<script lang="ts">
import RoleApplyProfessionalExperienceRole from "./RoleApplyProfessionalExperienceRole.vue";
import useIndividual from "@/composables/useIndividual";
import { RoleHeld } from "@/composables/useMiniCv";
import useRoleApplication from "@/composables/useRoleApplication";
import { sortRolesHeld } from "@/helpers/miniCv";

export default {
  name: "RoleApplyExperienceCollapsed",
  setup() {
    const { roleApplication, updateRoleApplication } = useRoleApplication();
    const { individual, getIndividual } = useIndividual();

    return {
      roleApplication,
      individual,
      getIndividual,
      updateRoleApplication,
    };
  },
  components: {
    RoleApplyProfessionalExperienceRole,
  },

  created() {
    this.updateRoles();
  },

  computed: {
    selectedRolesHeld(): RoleHeld[] {
      return this.roleApplication.selected_roles_held;
    },

    visibleRoles() {
      return sortRolesHeld(this.selectedRolesHeld).slice(0, 3);
    },

    missingDates() {
      return this.selectedRolesHeld.some(role => !role.from);
    },
  },

  methods: {
    storeReturnToApplicationId() {
      localStorage.setItem(
        "return-to-application-id",
        `${this.$route.params.roleId}#professional-experience`
      );
    },

    async updateRoles() {
      await this.getIndividual(); // ensure individual roles held is up-to-date before saving
      await this.updateRoleApplication({
        selected_roles_held: this.selectedRolesHeld,
      });
    },
  },
};
</script>
