<template>
  <div
    class="border border-solid border-ivory-400 rounded-full flex items-center px-6 focus-within:ring focus-within:ring-orange-500"
  >
    <img src="@/assets/images/linkedin-logo-grey.svg" alt="" />
    <input
      type="text"
      id="linkedin"
      placeholder="linkedin.com/in/username"
      class="flex-grow py-4 border-none outline-none text-inputs-content text-grey-900 placeholder-grey-400 ml-2"
      v-model="value"
      data-cy="linkedin-field"
      @keyup="$emit('value-changed', linkedinUrl)"
    />
    <a
      v-if="showLinkButton"
      :href="linkedinUrl"
      target="_blank"
      rel="noreferrer noopener"
      class="material-icons-round"
    >
      launch
    </a>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    initialValue: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      value: "",
    };
  },

  emits: ["value-changed"],

  created() {
    this.value = this.initialValue;
  },

  computed: {
    showLinkButton() {
      return this.value?.includes("linkedin.com/in/");
    },

    linkedinUrl() {
      if (this.value.startsWith("www" || "linkedin.com")) {
        return `https://${this.value}`;
      }
      return this.value;
    },
  },
};
</script>
