export default {
  commercial_role_types: {
    commercial_role_audit_committee: "Audit committee chair",
    commercial_role_chair: "Chair",
    commercial_role_non_executive_director: "Non-executive director (NED)",
    commercial_role_remuneration_committee: "Remuneration committee chair",
    commercial_role_advisory: "Senior advisory",
    commercial_role_commercial: "Commercial / CCO",
    commercial_role_communications: "Communications",
    commercial_role_company_secretary: "Company secretary",
    commercial_role_technology: "Digital and technology",
    commercial_role_finance: "Finance / CFO",
    commercial_role_management: "General management / CEO",
    commercial_role_hr: "Human resources / HRD",
    commercial_role_investment: "Investment / CIO",
    commercial_role_legal: "Legal / GC",
    commercial_role_operations: "Operations / COO",
    commercial_role_risk: "Risk and compliance",
    commercial_role_marketing: "Sales and marketing",
    commercial_role_science: "Science and medical",
    commercial_role_sustainability: "Sustainability",
  },
  commercial_non_executive_role_types: {
    commercial_role_audit_committee: "Audit committee chair",
    commercial_role_chair: "Chair",
    commercial_role_non_executive_director: "Non-executive director (NED)",
    commercial_role_remuneration_committee: "Remuneration committee chair",
    commercial_role_advisory: "Senior advisory",
  },
  commercial_executive_role_types: {
    commercial_role_commercial: "Commercial / CCO",
    commercial_role_communications: "Communications",
    commercial_role_company_secretary: "Company secretary",
    commercial_role_technology: "Digital and technology",
    commercial_role_finance: "Finance / CFO",
    commercial_role_management: "General management / CEO",
    commercial_role_hr: "Human resources / HRD",
    commercial_role_investment: "Investment / CIO",
    commercial_role_legal: "Legal / GC",
    commercial_role_operations: "Operations / COO",
    commercial_role_risk: "Risk and compliance",
    commercial_role_marketing: "Sales and marketing",
    commercial_role_science: "Science and medical",
    commercial_role_sustainability: "Sustainability",
  },
  nfp_role_types: {
    nfp_role_chair: "Chair",
    nfp_role_audit: "Treasurer / audit chair",
    nfp_role_trustee: "Trustee / board member",
    nfp_role_development: "Development",
  },
  commercial_org_sizes: {
    commercial_org_size_large:
      "Large-cap corporation / international non-profit",
    commercial_org_size_medium: "Mid-cap corporation / national non-profit",
    commercial_org_size_small: "Small-cap corporation / regional non-profit",
    commercial_org_size_extra_small: "Early-stage corporation",
  },
  compensated_commercial_org_sizes: {
    org_size_large: "Large-cap",
    org_size_medium: "Mid-cap",
    org_size_small: "Small-cap",
    org_size_extra_small: "Early-stage",
  },
  non_profit_org_sizes: {
    org_size_large: "International non-profit",
    org_size_medium: "National non-profit",
    org_size_small: "Regional non-profit",
    org_size_extra_small: "Early-stage corporation",
  },
  nfp_org_sizes: {
    nfp_org_size_large: "International non-profit",
    nfp_org_size_medium: "National non-profit",
    nfp_org_size_small: "Regional non-profit",
    nfp_org_size_extra_small: "Early-stage corporation",
  },
  commercial_org_sizes_full: {
    org_size_large: "Large-cap",
    org_size_medium_large: "Mid / large-cap",
    org_size_medium: "Mid-cap",
    org_size_small_medium: "Small / mid-cap",
    org_size_small: "Small-cap",
    org_size_extra_small_small: "Early-stage / small-cap",
    org_size_extra_small: "Early-stage",
  },
  nfp_org_sizes_full: {
    org_size_large: "International",
    org_size_medium_large: "National / international",
    org_size_medium: "National",
    org_size_small_medium: "Regional / national",
    org_size_small: "Regional",
  },
  years_experience: [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
    40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
  ],
  level_experience: {
    experience_level_board: "Main board member",
    experience_level_executive_committee: "Executive committee member",
    experience_level_owner: "Owner / founder",
    experience_level_division: "Responsible for division / function",
    experience_level_mid: "Mid management",
    experience_level_junior: "Junior management",
    experience_level_self_employed: "Self employed",
    experience_level_unspecified: "Unspecified",
  },
  role_experiences: {
    experience_role_chair: "Chair",
    experience_role_chaired_startup: "Chaired a founder led business",
    experience_role_ceo: "CEO / general management",
    experience_role_cfo: "CFO / finance",
    experience_role_operations: "COO / operations",
    experience_role_company_secretary: "Company secretary",
    experience_role_non_exec: "Non-executive / trustee",
    experience_role_audit_committee_chair: "Audit committee chair",
    experience_role_nominations_committee_chair: "Nominations committee chair",
    experience_role_remuneration_committee_chair:
      "Remuneration committee chair",
    experience_role_csr: "Corporate social responsibility (CSR)",
    experience_role_entrepreneur: "Entrepreneur / founder",
    experience_role_change_management: "Change management",
    experience_role_corporate_governance: "Corporate governance",
    experience_role_digital: "Digital / technology",
    experience_role_fundraising: "Fundraising",
    experience_role_growth_management: "Growth management",
    experience_role_hr: "HR",
    experience_role_investment: "Investment",
    experience_role_investor_relations:
      "Investor relations / markets knowledge",
    experience_role_ipo: "IPO / flotation",
    experience_role_legal: "Legal / compliance",
    experience_role_media: "Media / government relations",
    experience_role_m_and_a: "M&A advisory",
    experience_role_multisite: "Multi-site",
    experience_role_property: "Real estate / property",
    experience_role_research: "Research",
    experience_role_restructuring: "Restructuring",
    experience_role_risk: "Risk",
    experience_role_marketing: "Sales / marketing",
    experience_role_strategic: "Strategic",
    experience_role_finance: "Structured / project finance",
    experience_role_succession_planning: "Succession planning",
    experience_role_turnaround: "Turnaround",
  },
  organisation_experiences: {
    experience_organisation_large_cap: "Listed - large cap",
    experience_organisation_mid_cap: "Listed - mid cap",
    experience_organisation_small_cap: "Listed - small cap",
    experience_organisation_private: "Private / family owned",
    experience_organisation_private_equity: "Private equity backed",
    experience_organisation_academia: "Academia",
    experience_organisation_military: "Armed forces",
    experience_organisation_government: "Government",
    experience_organisation_health: "Health services",
  },
  geographic_experiences: {
    experience_geographic_africa: {
      label: "Africa",
      subItems: {
        experience_geographic_africa_east: "East Africa",
        experience_geographic_africa_north: "North Africa",
        experience_geographic_africa_south: "Southern Africa",
        experience_geographic_africa_other: "West Africa",
      },
    },
    experience_geographic_asia: {
      label: "Asia",
      subItems: {
        experience_geographic_asia_china: "China",
        experience_geographic_asia_indonesia: "Indonesia",
        experience_geographic_asia_hong_kong: "Hong Kong",
        experience_geographic_asia_india: "India",
        experience_geographic_asia_japan: "Japan",
        experience_geographic_asia_malaysia: "Malaysia",
        experience_geographic_asia_singapore: "Singapore",
        experience_geographic_asia_south_korea: "South Korea",
        experience_geographic_asia_other: "Other",
      },
    },
    experience_geographic_australasia: {
      label: "Australasia",
      subItems: {
        experience_geographic_australasia_australia: "Australia",
        experience_geographic_australasia_other: "Other",
      },
    },
    experience_geographic_europe: {
      label: "Continental Europe",
      subItems: {
        experience_geographic_europe_austria: "Austria",
        experience_geographic_europe_belgium: "Belgium",
        experience_geographic_europe_czech_republic: "Czech Republic",
        experience_geographic_europe_denmark: "Denmark",
        experience_geographic_europe_finland: "Finland",
        experience_geographic_europe_france: "France",
        experience_geographic_europe_germany: "Germany",
        experience_geographic_europe_greece: "Greece",
        experience_geographic_europe_hungary: "Hungary",
        experience_geographic_europe_italy: "Italy",
        experience_geographic_europe_luxembourg: "Luxembourg",
        experience_geographic_europe_netherlands: "Netherlands",
        experience_geographic_europe_norway: "Norway",
        experience_geographic_europe_poland: "Poland",
        experience_geographic_europe_portugal: "Portugal",
        experience_geographic_europe_ireland: "Republic of Ireland",
        experience_geographic_europe_russia: "Russia",
        experience_geographic_europe_spain: "Spain",
        experience_geographic_europe_sweden: "Sweden",
        experience_geographic_europe_switzerland: "Switzerland",
        experience_geographic_europe_turkey: "Turkey",
        experience_geographic_europe_united_kingdom: "United Kingdom",
        experience_geographic_europe_other: "Other",
      },
    },
    experience_geographic_middle_east: {
      label: "Middle East",
      subItems: {
        experience_geographic_middle_east_israel: "Israel",
        experience_geographic_middle_east_saudi_arabia: "Saudi Arabia",
        experience_geographic_middle_east_other: "Other",
      },
    },
    experience_geographic_north_america: {
      label: "North America",
      subItems: {
        experience_geographic_north_america_bermuda: "Bermuda",
        experience_geographic_north_america_canada: "Canada",
        experience_geographic_north_america_caribbean: "Caribbean",
        experience_geographic_north_america_usa: "USA",
        experience_geographic_north_america_mexico: "Mexico",
        experience_geographic_north_america_other: "Other",
      },
    },
    experience_geographic_south_america: {
      label: "South America",
      subItems: {
        experience_geographic_south_america_argentina: "Argentina",
        experience_geographic_south_america_brazil: "Brazil",
        experience_geographic_south_america_chile: "Chile",
        experience_geographic_south_america_colombia: "Colombia",
        experience_geographic_south_america_peru: "Peru",
        experience_geographic_south_america_uruguay: "Uruguay",
        experience_geographic_south_america_venezuela: "Venezuela",
        experience_geographic_south_america_other: "Other",
      },
    },
  },
  languages: {
    language_arabic: "Arabic",
    language_cantonese: "Cantonese",
    language_danish: "Danish",
    language_dutch: "Dutch",
    language_finnish: "Finnish",
    language_french: "French",
    language_german: "German",
    language_greek: "Greek",
    language_hindi: "Hindi",
    language_italian: "Italian",
    language_japanese: "Japanese",
    language_mandarin: "Mandarin",
    language_norwegian: "Norwegian",
    language_portuguese: "Portuguese",
    language_russian: "Russian",
    language_spanish: "Spanish",
    language_swedish: "Swedish",
    language_taiwanese: "Taiwanese",
    language_welsh: "Welsh",
  },
  role_location_areas: {
    location_africa: {
      label: "Africa",
      subItems: {
        location_africa_east: "East Africa",
        location_africa_north: "North Africa",
        location_africa_south: "Southern Africa",
        location_africa_other: "West Africa",
      },
    },
    location_asia: {
      label: "Asia",
      subItems: {
        location_asia_china: "China",
        location_asia_indonesia: "Indonesia",
        location_asia_hong_kong: "Hong Kong",
        location_asia_india: "India",
        location_asia_japan: "Japan",
        location_asia_malaysia: "Malaysia",
        location_asia_singapore: "Singapore",
        location_asia_south_korea: "South Korea",
        location_asia_other: "Other",
      },
    },
    location_australasia: {
      label: "Australasia",
      subItems: {
        location_australasia_australia: "Australia",
        location_australasia_other: "Other",
      },
    },
    location_europe: {
      label: "Continental Europe",
      subItems: {
        location_europe_austria: "Austria",
        location_europe_belgium: "Belgium",
        location_europe_czech_republic: "Czech Republic",
        location_europe_denmark: "Denmark",
        location_europe_finland: "Finland",
        location_europe_france: "France",
        location_europe_germany: "Germany",
        location_europe_greece: "Greece",
        location_europe_hungary: "Hungary",
        location_europe_italy: "Italy",
        location_europe_luxembourg: "Luxembourg",
        location_europe_netherlands: "Netherlands",
        location_europe_norway: "Norway",
        location_europe_poland: "Poland",
        location_europe_portugal: "Portugal",
        location_europe_ireland: "Republic of Ireland",
        location_europe_russia: "Russia",
        location_europe_spain: "Spain",
        location_europe_sweden: "Sweden",
        location_europe_switzerland: "Switzerland",
        location_europe_turkey: "Turkey",
        location_europe_other: "Other",
      },
    },
    location_middle_east: {
      label: "Middle East",
      subItems: {
        location_middle_east_israel: "Israel",
        location_middle_east_saudi_arabia: "Saudi Arabia",
        location_middle_east_other: "Other",
      },
    },
    location_north_america: {
      label: "North America",
      subItems: {
        location_north_america_bermuda: "Bermuda",
        location_north_america_canada: "Canada",
        location_north_america_caribbean: "Caribbean",
        location_north_america_usa: "USA",
        location_north_america_mexico: "Mexico",
        location_north_america_other: "Other",
      },
    },
    location_south_america: {
      label: "South America",
      subItems: {
        location_south_america_argentina: "Argentina",
        location_south_america_brazil: "Brazil",
        location_south_america_chile: "Chile",
        location_south_america_colombia: "Colombia",
        location_south_america_peru: "Peru",
        location_south_america_uruguay: "Uruguay",
        location_south_america_venezuela: "Venezuela",
        location_south_america_other: "Other",
      },
    },
    location_uk: {
      label: "UK",
      subItems: {
        location_uk_channel_islands: "Channel Islands",
        location_uk_london: "London",
        location_uk_midlands: "Midlands",
        location_uk_north_england: "North England",
        location_uk_northern_ireland: "Northern Ireland",
        location_uk_scotland: "Scotland",
        location_uk_south_east: "South East",
        location_uk_south_west: "South West",
        location_uk_wales: "Wales",
      },
    },
  },
  commercial_interests: {
    commercial_sector_consumer: {
      label: "Consumer",
      subItems: {
        commercial_sector_consumer_automobiles: "Automobiles & parts",
        commercial_sector_consumer_food: "Food & beverage",
        commercial_sector_consumer_gambling: "Gambling",
        commercial_sector_consumer_hospitality: "Hospitality & leisure",
        commercial_sector_consumer_household: "Household goods",
        commercial_sector_consumer_luxury: "Luxury",
        commercial_sector_consumer_personal: "Personal goods",
        commercial_sector_consumer_retail: "Retail & wholesale",
        commercial_sector_consumer_sports: "Sports",
        commercial_sector_consumer_tobacco: "Tobacco",
        commercial_sector_consumer_travel: "Travel",
      },
    },
    commercial_sector_education: {
      label: "Education",
    },
    commercial_sector_energy: {
      label: "Energy & natural resources",
      subItems: {
        commercial_sector_energy_forestry: "Forestry & paper",
        commercial_sector_energy_mining: "Metals & mining",
        commercial_sector_energy_nuclear: "Nuclear",
        commercial_sector_energy_fossil: "Oil & gas",
        commercial_sector_energy_renewable: "Renewable",
      },
    },
    commercial_sector_financial: {
      label: "Financial",
      subItems: {
        commercial_sector_finance_assets: "Asset management",
        commercial_sector_finance_family_office: "Family office",
        commercial_sector_finance_fintech: "FinTech (financial technology)",
        commercial_sector_finance_hedge_funds: "Hedge funds",
        commercial_sector_finance_insurance: "Insurance",
        commercial_sector_finance_banking: "Investment & corporate banking",
        commercial_sector_finance_investment_trusts: "Investment trusts",
        commercial_sector_finance_private_equity: "Private equity",
        commercial_sector_finance_retail_services: "Retail financial services",
        commercial_sector_finance_risk: "Risk management",
        commercial_sector_finance_sovereign_wealth: "Sovereign wealth funds",
        commercial_sector_finance_specialty: "Speciality finance",
        commercial_sector_finance_wealth: "Wealth management",
      },
    },
    commercial_sector_regulatory: {
      label: "Government & regulatory",
    },
    commercial_sector_healthcare: {
      label: "Healthcare",
      subItems: {
        commercial_sector_healthcare_biotechnology: "Biotechnology",
        commercial_sector_healthcare_services:
          "Healthcare equipment & services",
        commercial_sector_healthcare_pharmaceuticals: "Pharmaceuticals",
      },
    },
    commercial_sector_industrial: {
      label: "Industrial",
      subItems: {
        commercial_sector_industrial_aerospace_defence: "Aerospace & defence",
        commercial_sector_industrial_agriculture: "Agriculture",
        commercial_sector_industrial_automotive: "Automotive",
        commercial_sector_industrial_chemicals: "Chemicals",
        commercial_sector_industrial_construction:
          "Construction & infrastructure",
        commercial_sector_industrial_electrical:
          "Electrical & electronic products",
        commercial_sector_industrial_services: "Industrial & business services",
        commercial_sector_industrial_machinery: "Machinery & equipment",
        commercial_sector_industrial_manufacturing:
          "Manufacturing & processing",
        commercial_sector_industrial_packaging: "Paper & packaging",
        commercial_sector_industrial_logistics: "Transportation & logistics",
        commercial_sector_industrial_utilities: "Utilities",
        commercial_sector_industrial_waste: "Waste management",
      },
    },
    commercial_sector_media: {
      label: "Media",
    },
    commercial_sector_not_for_profit: {
      label: "Not-for-profit",
    },
    commercial_sector_professional_services: {
      label: "Professional services",
      subItems: {
        commercial_sector_professional_services_accountancy: "Accountancy",
        commercial_sector_professional_services_bis:
          "Business information services",
        commercial_sector_professional_services_bpo:
          "Business process outsourcing",
        commercial_sector_professional_services_human: "Human capital",
        commercial_sector_professional_services_it: "IT services",
        commercial_sector_professional_services_legal: "Legal",
        commercial_sector_professional_services_consultancy: "Consulting",
        commercial_sector_professional_services_pr: "Public relations",
      },
    },
    commercial_sector_real_estate: {
      label: "Real estate",
    },
    commercial_sector_information_technology: {
      label: "Technology",
    },
    commercial_sector_telecoms: {
      label: "Telecommunications",
    },
  },
  nfp_interests: {
    nfp_sector_arts: {
      label: "Arts",
      subItems: {
        nfp_sector_arts_dance: "Dance",
        nfp_sector_arts_music: "Music",
        nfp_sector_arts_theatre: "Theatre",
        nfp_sector_arts_visual: "Visual",
      },
    },
    nfp_sector_charity_services: {
      label: "Charity services",
    },
    nfp_sector_education: {
      label: "Education",
      subItems: {
        nfp_sector_education_primary: "Primary",
        nfp_sector_education_secondary: "Secondary",
        nfp_sector_education_further: "Higher / further",
      },
    },
    nfp_sector_emergencies: {
      label: "Emergencies & development",
      subItems: {
        nfp_sector_emergencies_conflict_resolution: "Conflict resolution",
        nfp_sector_emergencies_emergency_relief: "Emergency relief",
        nfp_sector_emergencies_famine_relief: "Famine relief",
        nfp_sector_emergencies_poverty_reduction: "Poverty reduction",
        nfp_sector_emergencies_water_sanitation: "Water & sanitation",
      },
    },
    nfp_sector_environmental: {
      label: "Environment & conservation",
      subItems: {
        nfp_sector_environmental_climate: "Climate change",
        nfp_sector_environmental_pollution: "Pollution & agriculture",
        nfp_sector_environmental_countryside: "Countryside",
        nfp_sector_environmental_marine: "Seas & oceans",
        nfp_sector_environmental_rivers_lakes: "Rivers & lakes",
        nfp_sector_environmental_plants: "Trees & plants",
        nfp_sector_environmental_animals: "Animals",
      },
    },
    nfp_sector_government: {
      label: "Government & regulatory",
    },
    nfp_sector_health: {
      label: "Health & disability",
      subItems: {
        nfp_sector_health_disability: "Disability",
        nfp_sector_health_disease: "Disease",
        nfp_sector_health_hospices: "Hospices",
        nfp_sector_health_hospitals: "Hospitals",
        nfp_sector_health_research: "Medical research",
        nfp_sector_health_mental: "Mental health",
        nfp_sector_health_maternity: "Maternity",
        nfp_sector_health_preventative: "Preventative measures",
      },
    },
    nfp_sector_heritage: {
      label: "Heritage",
      subItems: {
        nfp_sector_heritage_architecture: "Architecture",
        nfp_sector_heritage_archaeology: "Archaeology",
        nfp_sector_heritage_libraries: "Libraries",
        nfp_sector_heritage_museums: "Museums",
      },
    },
    nfp_sector_military: {
      label: "Military",
    },
    nfp_sector_social: {
      label: "Society",
      subItems: {
        nfp_sector_social_children: "Children",
        nfp_sector_social_community: "Community",
        nfp_sector_social_elderly: "Elderly",
        nfp_sector_social_employment: "Employment",
        nfp_sector_social_family: "Family",
        nfp_sector_social_housing: "Housing / accommodation",
        nfp_sector_social_politics: "Politics",
        nfp_sector_social_religion: "Religion",
        nfp_sector_social_sport: "Sport",
        nfp_sector_social_trade_associations: "Trade associations",
      },
    },
    nfp_sector_welfare: {
      label: "Welfare & rights",
      subItems: {
        nfp_sector_welfare_domestic_violence: "Domestic violence",
        nfp_sector_welfare_human_rights: "Human rights",
        nfp_sector_welfare_lgbt: "LGBT",
        nfp_sector_welfare_refugees: "Refugees & asylum seekers",
        nfp_sector_welfare_sexual_abuse: "Sexual abuse",
      },
    },
  },
  titles: [
    "Mr",
    "Mrs",
    "Miss",
    "Ms",
    "Dr.",
    "Sir",
    "Prof.",
    "Lord",
    "Lady",
    "Baroness",
    "Dame",
    "Hon",
    "Baron",
    "Admiral",
    "Admiral Lord",
    "Admiral Sir",
    "Air Chief Marshal",
    "Air Chief Marshal Sir",
    "Air Commodore",
    "Air Marshal",
    "Air Vice Marshal",
    "Ambassador",
    "Archbishop",
    "Archdeacon",
    "Associate Chief Justice",
    "Associate Professor",
    "Associate Professor Emerita",
    "Associate Professor Emeritus",
    "Baronet",
    "Bishop",
    "Brigadier",
    "Brigadier General",
    "Captain",
    "Cardinal",
    "Chair Emerita",
    "Chair Emeritus",
    "Chancellor",
    "Chief Judge",
    "Chief Justice",
    "Colonel",
    "Commander",
    "Commodore",
    "Commodore First Class",
    "Commodore Second Class",
    "Count",
    "Countess",
    "Dato'",
    "Deacon",
    "Dean",
    "Doctor",
    "Doctor Mrs.",
    "Duchess",
    "Duke",
    "Earl",
    "Father",
    "Field Marshal",
    "Fleet Admiral",
    "Freifrau",
    "Freiherr",
    "General",
    "General Sir",
    "Governor",
    "H. Excellency",
    "H.R.H The Prince",
    "Her Highness",
    "Her Highness Sheikha",
    "His Highness",
    "Lt. Gen.",
    "Madam",
    "Major",
    "Major General",
    "Marquess",
    "Marquessa",
    "Monsignor",
    "Prince",
    "Professor",
    "Professor Sir",
    "Professor Dr",
    "Rear Admiral",
    "Reverend",
    "Senator",
    "Sheikh",
    "Sister",
    "The Hon.",
    "The Hon. Doctor",
    "The Hon. Lord",
    "The Hon. Mrs",
    "The Hon. Sir",
    "The Rt. Hon.",
    "The Rt. Hon. Baroness",
    "The Rt. Hon. Dr",
    "The Rt. Hon. Earl",
    "The Rt. Hon. Lord",
    "The Rt. Hon. Sir",
    "The Rt. Hon. Viscount",
    "The Venerable",
    "Vice Admiral",
    "Viscount",
  ],
  nationalities: [
    "British",
    "American",
    "Afghan",
    "Ålandic",
    "Albanian",
    "Algerian",
    "American",
    "Andorran",
    "Angolan",
    "Anguillan",
    "Antarctic",
    "Antiguan",
    "Argentine",
    "Armenian",
    "Arubian",
    "Australian",
    "Austrian",
    "Azerbaijani",
    "Bahameese",
    "Bahrainian",
    "Bangladeshi",
    "Barbadian",
    "Barthélemois",
    "Belarusian",
    "Belgian",
    "Belizean",
    "Beninese",
    "Bermudan",
    "Bhutanese",
    "Bolivian",
    "Bosnian",
    "Brazilian",
    "British",
    "Bruneian",
    "Bulgarian",
    "Burkinabe",
    "Burundian",
    "Cambodian",
    "Cameroonian",
    "Canadian",
    "Cape Verdean",
    "Caymanian",
    "Central African",
    "Chadian",
    "Chilean",
    "Chinese",
    "Christmas Islander",
    "Cocossian",
    "Colombian",
    "Comoran",
    "Congolese",
    "Congolese",
    "Cook Islander",
    "Costa Rican",
    "Croatian",
    "Cuban",
    "Curaçaoan",
    "Cypriot",
    "Czech",
    "Danish",
    "Djiboutian",
    "Dominican",
    "Dominican",
    "Dutch",
    "Ecuadorean",
    "Egyptian",
    "Emirian",
    "Equatorial Guinean",
    "Eritrean",
    "Estonian",
    "Ethiopian",
    "Falkland Islander",
    "Faroese",
    "Fijian",
    "Filipino",
    "Finnish",
    "French",
    "French Guianese",
    "French Polynesian",
    "Gabonese",
    "Gambian",
    "Georgian",
    "German",
    "Ghanaian",
    "Gibraltarian",
    "Greek",
    "Greenlander",
    "Grenadian",
    "Guadeloupean",
    "Guamanian",
    "Guatemalan",
    "Guinean",
    "Guinean",
    "Guyanese",
    "Haitian",
    "Honduran",
    "Hong Konger",
    "Hungarian",
    "I-Kiribati",
    "Icelander",
    "Indian",
    "Indonesian",
    "Iranian",
    "Iraqi",
    "Irish",
    "Israeli",
    "Italian",
    "Ivorian",
    "Jamaican",
    "Japanese",
    "Jordanian",
    "Kazakhstani",
    "Kenyan",
    "Kittian",
    "Kuwaiti",
    "Kyrgyzstani",
    "Laotian",
    "Latvian",
    "Lebanese",
    "Liberian",
    "Libyan",
    "Liechtensteiner",
    "Lithuanian",
    "Luxembourger",
    "Macanese",
    "Macedonian",
    "Mahoran",
    "Malagasy",
    "Malawian",
    "Malaysian",
    "Maldivan",
    "Malian",
    "Maltese",
    "Manx",
    "Marshallese",
    "Martinican",
    "Mauritanian",
    "Mauritian",
    "Mexican",
    "Micronesian",
    "Moldovan",
    "Monacan",
    "Mongolian",
    "Montenegrin",
    "Montserratian",
    "Moroccan",
    "Mosotho",
    "Motswana",
    "Mozambican",
    "Myanmarese",
    "Namibian",
    "Nauruan",
    "Nepalese",
    "New Caledonian",
    "New Zealander",
    "Ni-Vanuatu",
    "Nicaraguan",
    "Nigerian",
    "Nigerien",
    "Niuean",
    "Norfolk Islander",
    "North Korean",
    "Northern Mariana Islander",
    "Norwegian",
    "Omani",
    "Pakistani",
    "Palauan",
    "Palestinian",
    "Panamanian",
    "Papua New Guinean",
    "Paraguayan",
    "Peruvian",
    "Pitcairn Islander",
    "Polish",
    "Portuguese",
    "Puerto Rican",
    "Qatari",
    "Romanian",
    "Russian",
    "Rwandan",
    "Saint Helenian",
    "Saint Kitts and Nevis",
    "Saint Lucian",
    "Saint Vincentian",
    "Saint-Pierrais",
    "Salvadorean",
    "Samoan",
    "Samoan",
    "Sanmarinese",
    "São Tomean",
    "Saudi Arabian",
    "Senegalese",
    "Serbian",
    "Seychellois",
    "Sierra Leonean",
    "Singaporean",
    "Slovakian",
    "Slovenian",
    "Solomon Islander",
    "Somali",
    "South African",
    "South Korean",
    "Spanish",
    "Sri Lankan",
    "Sudanese",
    "Sudanese",
    "Surinamer",
    "Swazi",
    "Swedish",
    "Swiss",
    "Syrian",
    "Taiwanese",
    "Tajikistani",
    "Tanzanian",
    "Thai",
    "Timorese",
    "Togolese",
    "Tokelauan",
    "Tongan",
    "Trinidadian",
    "Tunisian",
    "Turkish",
    "Turkmen",
    "Turks and Caicos Islander",
    "Tuvaluan",
    "Ugandan",
    "Ukrainian",
    "Uruguayan",
    "Uzbekistani",
    "Venezuelan",
    "Vietnamese",
    "Virgin Islander",
    "Virgin Islander",
    "Wallisian",
    "Western Saharan",
    "Yemeni",
    "Zambian",
    "Zimbabwean",
  ],
  countries: [
    "United Kingdom",
    "United States of America",
    "Afghanistan",
    "Åland Islands",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "British Virgin Islands",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burma (Republic of the Union of Myanmar)",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Caribbean Netherlands",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo (Republic of)",
    "Cook Islands",
    "Costa Rica",
    "Côte d’Ivoire (Ivory Coast)",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czech Republic",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard and McDonald Islands",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macau",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "North Korea",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Réunion",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Saint Barthélemy",
    "Saint Helena",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin (France)",
    "Saint Martin (Netherlands)",
    "Saint Vincent and Grenadines",
    "Samoa",
    "San Marino",
    "São Tome and Príncipe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "St. Pierre and Miquelon",
    "Sudan",
    "Suriname",
    "Svalbard and Jan Mayen Islands",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States Minor Outlying Islands",
    "United States of America",
    "United States Virgin Islands",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican",
    "Venezuela",
    "Vietnam",
    "Wallis and Futuna Islands",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ],
};
