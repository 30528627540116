<template>
  <div
    v-if="skill.response?.has_been_skipped"
    class="bg-ivory-200 flex justify-between items-center py-5 px-8 rounded-lg"
  >
    <span
      class="text-title-h2 text-ivory-800"
      data-cy="preview-skipped-skill-title"
      >{{ skill.title }}</span
    >
    <button
      class="bg-white text-ivory-700 border-ivory-200 rounded-full py-3 px-6 border border-solid text-cta-primary"
    >
      Skipped
    </button>
  </div>
  <div
    v-else
    class="flex flex-col border border-solid rounded-lg p-4 py-8 sm:p-8 border-grey-200 my-5"
  >
    <span class="text-nudging text-ivory-800 mb-2">
      {{ skill.required === "true" ? "Required" : "Desired" }}
    </span>
    <span class="text-title-h2 text-sage-600">
      {{ skill.title }}
    </span>
    <p class="copy-emphasis text-grey-800 mb-0">
      {{ skill.substituted_description }}
    </p>
    <div class="mt-2">
      <span class="text-grey-800">
        Please pick up to 3 roles that best demonstrate your fit for this skill
      </span>
      <div
        class="flex p-3 mt-4 text-grey-800 rounded-lg"
        :class="{
          'bg-grey-50': hasRelevantRoles,
          'bg-orange-50': !hasRelevantRoles,
        }"
      >
        <div v-if="hasRelevantRoles">
          <div
            v-for="role in skill.response?.relevant_roles"
            :key="role.id"
            class="copy-bold px-3"
          >
            {{ role.organisation }}
          </div>
        </div>
        <div v-else class="copy-bold px-3">No roles selected</div>
      </div>
    </div>
    <div class="flex flex-col sm:flex-row my-5">
      <div
        class="py-3.5 px-3 sm:px-5 bg-blue-50 rounded-lg flex-none flex items-center justify-center sm:mr-4 md:py-5 md:justify-start md:w-48"
      >
        <icon-document
          color="text-blue-700"
          class="h-7 w-7 sm:h-10 sm:w-10 mr-3"
        />
        <span class="text-grey-900 text-nudging">What we need from you</span>
      </div>
      <span class="text-grey-800 mt-4 sm:mt-0">
        {{ skill.structured_description }}
      </span>
    </div>
    <div
      class="bg-grey-50 text-grey-80 pt-5 pb-1 px-5 rounded-lg role-application-skill-preview"
    >
      <p v-html="skill.response?.relevant_roles_notes"></p>
    </div>
  </div>
</template>

<script lang="ts">
import { RoleApplicationSkill } from "@/models/RoleApplication";
import IconDocument from "@/components/icons/IconDocument.vue";

import { PropType } from "vue";
export default {
  components: {
    IconDocument,
  },
  props: {
    skill: {
      type: Object as PropType<RoleApplicationSkill>,
    },
  },
  computed: {
    hasRelevantRoles() {
      return this.skill.response?.relevant_roles?.length > 0;
    },
  },
};
</script>

<style>
.role-application-skill-preview ol,
.role-application-skill-preview ul,
.role-application-skill-preview li {
  all: revert;
}
</style>
