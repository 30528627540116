<template>
  <div class="bg-neutral py-4 px-6 rounded-lg flex flex-col gap-3">
    <div class="flex gap-1 items-center">
      <p class="flex-grow text-divider text-secondary">
        {{ title }} {{ examplesIndex + 1 }}/{{ examples.length }}
      </p>
      <button
        class="bg-white rounded-full p-1 disabled:bg-transparent group"
        @click="previousSlide()"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="fill-current text-grey-900 group-disabled:text-grey-600"
        >
          <path
            d="M14.9995 6.70461C14.6095 6.31461 13.9795 6.31461 13.5895 6.70461L8.99953 11.2946C8.60953 11.6846 8.60953 12.3146 8.99953 12.7046L13.5895 17.2946C13.9795 17.6846 14.6095 17.6846 14.9995 17.2946C15.3895 16.9046 15.3895 16.2746 14.9995 15.8846L11.1195 11.9946L14.9995 8.11461C15.3895 7.72461 15.3795 7.08461 14.9995 6.70461Z"
          />
        </svg>
      </button>
      <button
        class="bg-white rounded-full p-1 disabled:bg-transparent group"
        @click="nextSlide()"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="fill-current text-grey-900 group-disabled:text-grey-600"
        >
          <path
            d="M8.99953 15.8746L12.8795 11.9946L8.99953 8.11461C8.60953 7.72461 8.60953 7.09461 8.99953 6.70461C9.38953 6.31461 10.0195 6.31461 10.4095 6.70461L14.9995 11.2946C15.3895 11.6846 15.3895 12.3146 14.9995 12.7046L10.4095 17.2946C10.0195 17.6846 9.38953 17.6846 8.99953 17.2946C8.61953 16.9046 8.60953 16.2646 8.99953 15.8746Z"
          />
        </svg>
      </button>
    </div>
    <p>{{ examples[examplesIndex] }}</p>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";

const examplesIndex = ref(0);

const props = defineProps<{
  examples: string[];
  title: string;
}>();

const nextSlide = () => {
  examplesIndex.value++;
  if (examplesIndex.value >= props.examples.length) {
    examplesIndex.value = 0;
  }
};

const previousSlide = () => {
  examplesIndex.value--;
  if (examplesIndex.value < 0) {
    examplesIndex.value = props.examples.length - 1;
  }
};
</script>
