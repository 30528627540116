<template>
  <div class="lg:mt-0 mt-4"></div>
  <div
    class="text-grey-600 mr-4 w-full md:w-min mb-2 lg:mb-0 whitespace-nowrap md:flex mb-3"
  >
    Filter by
  </div>
  <div class="md:flex w-full md:w-auto">
    <filter-button
      label="Charities"
      :count="filterByCounts.pro_bono"
      @click="toggleFilter('pro_bono')"
      :secondary="!filterBy.pro_bono"
      data-cy="search-probono-button"
      class="md:mr-3 w-full md:w-auto"
      :data-active="filterBy.pro_bono"
    />
    <filter-button
      label="Education"
      :count="filterByCounts.education"
      @click="toggleFilter('education')"
      :secondary="!filterBy.education"
      data-cy="search-edu-button"
      class="md:mr-3 mt-3 md:mt-0 w-full md:w-auto"
      :data-active="filterBy.education"
    />
    <filter-button
      label="Compensated"
      :count="filterByCounts.non_executive"
      @click="toggleFilter('non_executive')"
      :secondary="!filterBy.non_executive"
      data-cy="search-nonexec-button"
      class="mt-3 md:mt-0 w-full md:w-auto"
      :data-active="filterBy.non_executive"
    />
  </div>
</template>

<script>
import FilterButton from "@/components/shared/FilterButton.vue";
import useRoleFilters from "@/composables/useRoleFilters";

export default {
  components: {
    FilterButton,
  },
  name: "FilterRolesBy",
  props: ["filterByCounts"],
  setup() {
    const { filterBy, toggleFilter } = useRoleFilters();

    return {
      toggleFilter,
      filterBy,
    };
  },
};
</script>
