<template>
  <div
    class="flex flex-col justify-center items-center sm:p-10 gap-6 bg-blue-50 rounded-lg flex-none order-1 grow-0 no-roles-wrap"
  >
    <div
      class="flex flex-col align items-start p-0 gap-2 flex-none order-none self-stretch grow-0"
    >
      <div
        class="flex flex-row content-center items-center p-0 gap-2 flex-none order-none grow-0"
      >
        <div
          class="copy-emphasis text-grey-900 flex-none order-1 grow-0 w-full"
        >
          Based on your profile, we couldn't find any roles we thought would be
          interesting and relevant
        </div>
      </div>
      <p class="text-grey-900 flex-none order-1 self-stretch grow-0 mb-0">
        With over 700 roles posted per year there is sure to be something
        suitable soon. In the meantime:
      </p>
    </div>
    <carousel
      :items-to-show="determineNumberOfSlides()"
      :snap-align="'center'"
      class="flex flex-col items-stretch p-0 flex-none order-1 self-stretch grow-0"
    >
      <slide v-for="option in no_recommended_roles_card_options" :key="option">
        <no-recommended-roles-card
          :heading="option.heading"
          :icon="option.icon"
          :message="option.message"
          :cta-label="option.ctaLabel"
          :cta-link="option.ctaLink"
          :cta-external-link="option.ctaExternalLink"
          class="flex flex-col content-end items-stretch p-4 sm:p-6 gap-4 bg-white shadow-none hover:shadow-ivory rounded order-none grow-3 card-max-width cursor-pointer"
        />
      </slide>
      <template #addons>
        <pagination v-if="mobile()" />
      </template>
    </carousel>
  </div>
</template>

<style lang="scss">
.no-roles-wrap {
  @media (max-width: 640px) {
    padding: 24px 16px !important;
  }

  @media (min-width: 1350px) {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.card-max-width {
  @media (min-width: 640px) {
    flex: 3 !important;
  }

  @media (max-width: 640px) {
    max-width: 100%;
  }
}

.carousel {
  text-align: left;
}

.carousel__track {
  @media (min-width: 640px) {
    margin-bottom: 0 !important;
  }
}

.carousel__track > li:first-of-type {
  padding-left: 0;
}

.carousel__track > li:last-of-type {
  padding-right: 0;
}

.carousel__slide {
  padding: 0 8px;
  align-items: stretch;
}

.carousel__pagination {
  margin-top: 0 !important;
  padding-left: 0 !important;
}

.carousel__pagination-button::after {
  width: 24px !important;
  height: 8px !important;
  border-radius: 24px !important;
  background-color: #cdd7e1 !important;
}

.carousel__pagination-button--active::after {
  background-color: #002d6e !important;
}

.carousel__viewport {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: -20px;
  margin-bottom: -20px;
}
</style>

<script lang="ts">
import NoRecommendedRolesCard from "@/components/roles/NoRecommendedRolesCard.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination } from "vue3-carousel/dist/carousel.es.js";

export default {
  name: "NoRecommendedRolesBanner",
  components: {
    NoRecommendedRolesCard,
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      screenWidth: window.innerWidth,
      no_recommended_roles_card_options: [
        {
          heading: "Keep your professional experience up to date",
          icon: "account_circle",
          message:
            "Keeping your professional experience recent and relevant helps us to know which roles to notify you about.",
          ctaLabel: "Update Experience",
          ctaLink: "/account/profile",
          ctaExternalLink: false,
        },
        {
          heading: "Ensure your role preferences are up to date",
          icon: "interests",
          message:
            "Check we haven’t missed any charity or education roles of interest for you by updating your preferences",
          ctaLabel: "Update Preferences",
          ctaLink: "/reconfigure",
          ctaExternalLink: false,
        },
        {
          heading: "Listen and learn from other NEDs",
          icon: "headphones",
          message:
            "Nurole podcasts are 40-minute interviews with NEDs operating at the cutting edge.",
          ctaLabel: "Listen",
          ctaLink: "https://www.nurole.com/nurole-podcast-enter-the-boardroom",
          ctaExternalLink: true,
        },
      ],
    };
  },
  mounted() {
    this.handleResize();
  },
  methods: {
    handleResize() {
      window.addEventListener("resize", () => {
        this.screenWidth = window.innerWidth;
      });
    },
    mobile() {
      return this.screenWidth < 640;
    },
    determineNumberOfSlides() {
      return this.mobile() ? 1.3 : 3;
    },
  },
};
</script>
