<template>
  <div
    class="text-grey-600 mr-4 w-full md:w-min lg:mb-0 whitespace-nowrap md:flex mb-3"
  >
    Sort by
  </div>
  <div
    class="rounded-full items-center border border-solid border-grey-200 flex"
  >
    <button
      :class="{
        'bg-sage-700 text-white text-labelling-active  hover:bg-sage-600':
          this.sortBy == 'Relevant to you',
        ' hover:bg-grey-50 bg-white text-labelling-inactive':
          this.sortBy !== 'Relevant to you',
      }"
      class="flex-1 md:flex-auto rounded-l-full cursor-pointer py-2 focus:ring focus:ring-orange-500 focus:z-10 px-4 border-grey-700 active:border-grey-900"
      @click="this.updateSortBy('Relevant to you')"
      data-cy="search-sort-relevance"
    >
      Relevant to you
    </button>

    <button
      :class="{
        'bg-sage-700 text-white text-labelling-active  hover:bg-sage-600':
          this.sortBy == 'Newest listed',
        ' hover:bg-grey-50 bg-white text-labelling-inactive':
          this.sortBy !== 'Newest listed',
      }"
      class="flex-1 md:flex-auto rounded-r-full cursor-pointer py-2 focus:ring focus:ring-orange-500 focus:z-10 px-4 border-grey-700 active:border-grey-900"
      @click="this.updateSortBy('Newest listed')"
      data-cy="search-sort-newest"
    >
      Newest listed
    </button>
  </div>
</template>

<script lang="ts">
import useRoleFilters from "@/composables/useRoleFilters";

export default {
  name: "SortRolesBy",
  setup() {
    const { sortBy, updateSortBy } = useRoleFilters();
    return { sortBy, updateSortBy };
  },
};
</script>
