<template>
  <div
    class="border rounded-lg bg-white overflow-hidden"
    :class="{
      'border-grey-200': guidanceVariant === 'info',
      'border-sage-600': guidanceVariant === 'success',
      'border-orange-700': guidanceVariant === 'warning',
    }"
  >
    <editor
      class="outline-none mx-6 py-4"
      v-model="context.text"
      @init="handleInit"
      :placeholder="placeholder"
      :api-key="API_KEY"
      :init="{
        selector: 'div.tinymce',
        plugins: ['wordcount', 'lists', 'autoresize'],
        mobile: {
          plugins: ['wordcount', 'lists'],
        },
        autoresize_bottom_margin: 10,
        browser_spellcheck: true,
        min_height: 160,
        menubar: false,
        statusbar: false,
        inline_boundaries: false,
        toolbar:
          'bold italic underline | bullist numlist outdent indent | cut copy paste undo redo removeformat',
        paste_retain_style_properties: 'ol,ul,li',
        paste_filter_drop: false,
        paste_as_text: true,
        format: {
          removeformat: [
            {
              selector: '*',
              remove: 'all',
              split: true,
              expand: false,
              block_expand: true,
              deep: true,
            },
          ],
        },
      }"
    />
    <div class="px-6 py-2 border-t font-semibold text-grey-700">
      {{ context.wordCount || 0 }}
      {{ context.wordCount === 1 ? "word" : "words" }}
    </div>
    <guidance-indicator
      v-if="guidanceMessage"
      :variant="guidanceVariant"
      :message="guidanceMessage"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { Editor as TinyMCEEditor } from "tinymce";
import Editor from "@tinymce/tinymce-vue";
import type {
  RichTextEditorContext,
  RichTextEditorProps,
} from "@/helpers/richTextEditor";
import GuidanceIndicator from "@/components-v2/GuidanceIndicator.vue";

// todo: use `import.meta.`
const API_KEY = process.env.VITE_TINYMCE_API_KEY;

withDefaults(defineProps<RichTextEditorProps>(), {
  placeholder: "Your text here",
  guidanceVariant: "info",
});

const context = defineModel<RichTextEditorContext>({ required: true });
const editorInstance = ref<TinyMCEEditor>();

const handleInit = (_: Event, editor: TinyMCEEditor) => {
  editorInstance.value = editor;
  updateWordCount();
  if (context.value.text) context.value.warnIfBelowMinimum = true;
  editor.on("blur", () => {
    if (context.value.text) context.value.warnIfBelowMinimum = true;
  });
  editor.on("focus", () =>
    editor.editorContainer.scrollIntoView({
      block: "center",
      behavior: "smooth",
    })
  );
};

watch(
  () => context.value.text,
  () => updateWordCount()
);

const updateWordCount = () => {
  context.value.wordCount =
    editorInstance.value?.plugins.wordcount.body.getWordCount() || 0;
  if (!context.value.text) context.value.warnIfBelowMinimum = false;
};
</script>

<style>
.tox:not([dir="rtl"]) {
  outline: 0 !important;
  border: none;

  &,
  .tox-edit-area::before {
    border-radius: 0;
  }
}
.tox:not(.tox-tinymce-inline) {
  .tox-editor-header.tox-editor-header {
    box-shadow: none;
  }
  .tox-edit-area {
    border-top: 1px solid #e8e8e8;
  }
}
</style>
