<template>
  <form
    enctype="multipart/form-data"
    novalidate
    class="relative justify-center items-center pt-3 px-3 w-full cursor-pointer border-solid border-ivory-100 rounded-full h-fit bg-ivory-100 hover:border-ivory-200 hover:bg-ivory-200"
  >
    <div class="w-full flex justify-center h-full sm:h-auto">
      <input
        data-cy="upload-cv-input"
        type="file"
        accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/rtf,application/x-rtf,text/rtf,text/richtext,text/plain,application/pdf"
        class="opacity-0 top-0 left-0 w-full h-full absolute cursor-pointer"
        @change="onFileSelect"
        @cancel.stop
      />
      <button
        class="flex justify-center items-center p-3 px-4 w-full text-cta-primary button-primary sm:px-7 sm:w-auto"
      >
        <span class="material-icons-round mr-2 text-xl text-white">
          attach_file
        </span>
        <span class="whitespace-nowrap"> Upload your CV </span>
      </button>
    </div>
    <div
      class="w-full flex justify-center h-full sm:h-auto text-center mt-1 mb-4"
    >
      Accepted file types: Word, PDF, Rich Text Format and Plain Text
    </div>
  </form>
</template>

<script setup lang="ts">
import axios from "axios";
import { PDFDocument } from "pdf-lib";
import { apiAxios } from "@/lib/axios";
import useIndividual from "@/composables/useIndividual";
import { computed } from "vue";

const { individual } = useIndividual();
const isSignedIn = computed(() => {
  return !!individual.value?.first_name;
});

const emit = defineEmits(["cv-file-uploaded", "failed"]);

const supportedFileTypes = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "text/plain",
  "application/rtf",
  "application/vnd.oasis.opendocument.text",
  "application/x-rtf",
  "text/rtf",
  "text/richtext",
];

async function onFileSelect(event: Event) {
  const target = event.target as HTMLInputElement;
  const file = target?.files?.[0];

  const fileType = file?.type || "";

  if (!supportedFileTypes.includes(fileType)) {
    emit("failed", { type: "unsupported-file-type" });
    return;
  }

  if (file?.type === "application/pdf") {
    const numPages = await pageCount(file);
    if (numPages > 10) {
      emit("failed", { type: "pdf-too-large" });
      return;
    }
  }

  const s3UploadData = await uploadFileToS3(file);
  emit("cv-file-uploaded", file?.name, s3UploadData);
}

async function uploadFileToS3(file) {
  try {
    const s3UploadData = await getS3UploadData(file.name);
    await axios.put(s3UploadData.signedUrl, file, {
      headers: {
        "Content-Type": file.type,
      },
    });
    return s3UploadData;
  } catch (error) {
    emit("failed", error);
    throw error;
  }
}

async function getS3UploadData(filename) {
  const url = isSignedIn.value
    ? "/individual/cvs/url-to-upload"
    : "/cvs/url-to-upload";
  const { data } = await apiAxios.get(url, {
    params: {
      filename: filename,
    },
  });
  return {
    s3ObjectKey: data.s3_object_key,
    signedUrl: data.signed_url,
  };
}

async function pageCount(file: File) {
  const arrayBuffer = await readFile(file);
  const pdf = await PDFDocument.load(arrayBuffer as ArrayBuffer);
  return pdf.getPageCount();
}

const readFile = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
    reader.readAsArrayBuffer(file);
  });
};
</script>
