<template>
  <div
    class="bg-grey-50 border-solid border border-t-0 border-l-0 border-r-0 border-b-0.5 border-grey-200"
  >
    <div
      class="pt-6 lg:pt-10 w-full items-center max-w-screen-xl md:mx-auto z-10"
    >
      <search-roles />
      <filters-and-sorting>
        <div class="lg:flex items-center mt-6 px-4 md:px-6">
          <sort-roles-by />
          <div class="flex-1"></div>
          <filter-roles-by :filterByCounts="filterByCounts" />
        </div>
      </filters-and-sorting>
    </div>
  </div>

  <content-loading-skeleton v-if="rolesLoading" />

  <no-roles-found v-if="filteredAndSortedRoles.length == 0" />
  <div v-else class="md:container max-w-screen-xl md:mx-auto">
    <roles-by-organisation
      :roles="filteredAndSortedRoles"
      :showSections="showSections"
    />
  </div>
  <router-view />
</template>

<script lang="ts">
import useRoles from "@/composables/useRoles";
import RolesByOrganisation from "@/components/RolesByOrganisation.vue";
import SearchRoles from "@/components/SearchRoles.vue";
import SortRolesBy from "@/components/SortRolesBy.vue";
import FilterRolesBy from "@/components/FilterRolesBy.vue";
import textMatches from "@/lib/textMatches";
import useIndividual from "@/composables/useIndividual";
import ContentLoadingSkeleton from "@/components/ContentLoadingSkeleton.vue";
import FiltersAndSorting from "@/components/roles/FiltersAndSorting.vue";
import NoRolesFound from "@/components/roles/NoRolesFound.vue";
import useRoleFilters from "@/composables/useRoleFilters";

export default {
  components: {
    FiltersAndSorting,
    ContentLoadingSkeleton,
    RolesByOrganisation,
    SearchRoles,
    SortRolesBy,
    FilterRolesBy,
    NoRolesFound,
  },
  setup() {
    const { individual, isMember } = useIndividual();
    const { roles, rolesLoading, algorithm_service_available, refreshRoles } =
      useRoles();
    const { searchQuery, sortBy, filterBy } = useRoleFilters();

    return {
      roles,
      rolesLoading,
      algorithm_service_available,
      individual,
      refreshRoles,
      searchQuery,
      sortBy,
      filterBy,
      isMember,
    };
  },
  async created() {
    const { success, status } = await this.refreshRoles();
    if (!success && status == 403) {
      this.$router.push("/no-member-found");
    }
  },
  mounted() {
    if (this.hasCompletedOnboarding) {
      this.filterBy = {
        ...this.filterBy,
        pro_bono: this.individual.interested_in_pro_bono_roles,
        education: this.individual.interested_in_education_roles,
        non_executive: this.individual.interested_in_commercial_roles,
      };
    }
  },
  computed: {
    hasCompletedOnboarding() {
      return this.isMember && this.individual.completed_personal_details;
    },
    filteredAndSortedRoles() {
      const roles: any[] = [];
      const proBonoAndEducationRoles: any[] = [];

      if (this.filterBy.pro_bono)
        proBonoAndEducationRoles.push(...this.proBonoRoles);
      if (this.filterBy.education)
        proBonoAndEducationRoles.push(...this.educationRoles);

      if (proBonoAndEducationRoles)
        proBonoAndEducationRoles.sort(
          (a, b) => (b.score || 0) - (a.score || 0)
        );

      //non exec roles are now already sorted by score once we get here
      if (this.filterBy.non_executive) roles.push(...this.nonExecutiveRoles);
      roles.push(...proBonoAndEducationRoles);

      if (this.sortBy == "Newest listed") {
        return roles.sort((a, b) => {
          return (
            new Date(b.publish_date || "").getTime() -
            new Date(a.publish_date || "").getTime()
          );
        });
      }
      return roles;
    },
    showSections() {
      return (
        this.sortBy == "Relevant to you" &&
        this.algorithm_service_available &&
        this.searchQuery.length == 0
      );
    },
    proBonoRoles() {
      return this.roles
        .filter(({ category }) => category == "pro_bono")
        .filter(role => textMatches(role, this.searchQuery));
    },
    educationRoles() {
      return this.roles
        .filter(({ category }) => category == "education")
        .filter(role => textMatches(role, this.searchQuery));
    },
    nonExecutiveRoles() {
      const roles = this.roles
        .filter(({ category }) => category == "non_executive")
        .filter(role => textMatches(role, this.searchQuery));

      return roles.sort((a, b) => (b.score || 0) - (a.score || 0));
    },
    filterByCounts() {
      return {
        pro_bono: this.proBonoRoles.length,
        education: this.educationRoles.length,
        non_executive: this.nonExecutiveRoles.length,
      };
    },
  },
};
</script>
