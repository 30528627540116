<template>
  <rich-text-editor
    v-model="editorContext"
    :guidanceMessage="guidance.message"
    :guidanceVariant="guidance.variant"
    placeholder="Your reason for application"
  />
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import RichTextEditor from "@/components-v2/ui-kit/RichTextEditor.vue";
import useRole from "@/composables/useRole";
import useRoleApplication from "@/composables/useRoleApplication";
import debounce from "@/utils/debounce";
import { parseNumber } from "@/utils/number";
import { getRFAGuidance } from "@/helpers/guidanceIndicator";
import type { RichTextEditorContext } from "@/helpers/richTextEditor";

const { role } = useRole();
const { roleApplication, updateRoleApplication } = useRoleApplication();
const editorContext = ref<RichTextEditorContext>({
  text: roleApplication.value?.cover_letter || "",
});

const updateCoverLetter = debounce(
  (value: string) => updateRoleApplication({ cover_letter: value }),
  2000
);

watch(() => editorContext.value.text, updateCoverLetter);

const guidance = computed(() =>
  getRFAGuidance(
    editorContext.value,
    parseNumber(role.value?.cover_letter_limit, 0)
  )
);
</script>
