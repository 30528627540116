<template>
  <div
    class="bg-sage-50 py-4 md:py-2 rounded-lg md:rounded-full flex items-center md:justify-center mt-3 px-6 flex-wrap md:flex-nowrap"
  >
    <div class="w-full md:w-auto">
      You just removed {{ deletedRole.position }}
    </div>
    <button
      class="button-tertiary text-cta-tertiary px-0 md:px-6"
      @click="undoDelete(deletedRole)"
    >
      UNDO
    </button>
  </div>
</template>

<script lang="ts">
import useMiniCv, { RoleHeld } from "@/composables/useMiniCv";
import { PropType } from "vue";

export default {
  name: "DeletedRoleHeld",
  props: {
    deletedRole: {
      type: Object as PropType<RoleHeld>,
      required: true,
    },
  },
  setup() {
    const { undoDelete } = useMiniCv();

    return {
      undoDelete,
    };
  },
};
</script>
