<template>
  <div
    class="bg-modal w-screen h-screen fixed left-0 right-0 top-0 bottom-0 z-100 grid place-items-center overflow-hidden"
  >
    <div
      class="w-full bg-white p-8 pb-24 md:pb-8 rounded-none md:rounded-lg h-screen md:h-auto max-h-screen overflow-y-auto"
      style="max-width: 800px"
    >
      <div class="flex">
        <h1 class="text-title-h2 m-0 flex-1">
          <slot name="title"></slot>
        </h1>
        <button class="px-3" @click="$emit('close-modal')">
          <span class="material-icons-round text-sage-700"> close </span>
        </button>
      </div>
      <hr class="my-4 border-grey-200 border-solid" />
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "CreateOrEditRole",
  emits: ["close-modal"],
  beforeMount() {
    document.body.classList.toggle("overflow-hidden", true);
  },
  beforeUnmount() {
    document.body.classList.toggle("overflow-hidden", false);
  },
};
</script>
