<template>
  <div
    id="role-questions"
    class="my-10 flex flex-col gap-4"
    v-if="role && individual"
  >
    <h2 class="text-title-h2 my-0">Questions &amp; feedback</h2>
    <p class="copy mb-8">
      Please reach out regarding any queries about the role. We'll get back to
      you within 2 working days, and you will receive the response via email.
    </p>

    <div v-if="role.cs_owner_detail" class="flex gap-4">
      <user-picture
        v-if="role.cs_owner_details.picture"
        :src="role.cs_owner_details.picture"
        :name="role.cs_owner_details.first_name"
      />

      <div>
        <h4 class="text-title-h4">
          {{ role.cs_owner_details.first_name }}
          {{ role.cs_owner_details.last_name }}
        </h4>
        <p class="copy my-0">Role runner</p>
      </div>
    </div>

    <textarea
      rows="4"
      :placeholder="placeholder"
      v-model="message"
      data-cy="ask-question-textarea"
      data-message
      class="textarea"
    ></textarea>

    <button
      @click="handleSubmit"
      class="button-secondary text-cta-secondary md:w-max"
      data-cy="submit-question-button"
    >
      Submit question
    </button>
  </div>
</template>

<script lang="ts">
import UserPicture from "@/components/UserPicture.vue";
import { apiAxios } from "@/lib/axios";
import useIndividual from "@/composables/useIndividual";
import { useToast } from "vue-toastification";

export default {
  name: "RoleQuestions",
  components: { UserPicture },
  props: {
    role: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { individual } = useIndividual();
    return {
      individual,
      toast: useToast(),
    };
  },
  data: () => ({
    message: "",
  }),
  computed: {
    placeholder() {
      const name = this.role.cs_owner_details?.first_name || "us";
      return `Ask ${name} and we'll get back to you within 2 working days.`;
    },
  },
  methods: {
    async handleSubmit() {
      if (/^\s*$/.test(this.message)) {
        return (this.error = "Please enter a message");
      }

      const params = {
        message: this.message,
        role_id: this.role.id,
        is_role_question: true,
      };

      try {
        await apiAxios.post("/enquiry", params);
        this.message = "";
        this.toast.success("Your question has been submitted");
      } catch (_error) {
        this.toast.error("Failed to send message");
      }
    },
  },
};
</script>
