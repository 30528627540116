<template>
  <div
    class="flex cursor-pointer group outline-none"
    @click="toggle"
    @keydown.enter="toggle"
    @keydown.space.prevent="toggle"
    data-cy="preview-accordion"
    tabindex="0"
  >
    <div class="copy-emphasis text-grey-800 flex-1">
      <slot name="title" />
    </div>
    <div class="hidden md:block mr-4"></div>
    <base-accordion-toggle
      :isOpen="isOpen"
      class="group-focus:ring group-focus:ring-orange-500"
    />
  </div>
  <slot v-if="isOpen" name="content" />
</template>

<script lang="ts">
import BaseAccordionToggle from "@/components/shared/BaseAccordionToggle.vue";

export default {
  components: {
    BaseAccordionToggle,
  },
  data() {
    return {
      isOpen: true,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
