<template>
  <div class="w-full flex flex-col gap-4 md:gap-5">
    <label class="copy-emphasis">Your LinkedIn profile</label>
    <form
      autocomplete="off"
      class="flex flex-col md:flex-row gap-4 justify-center items-center"
    >
      <input
        type="url"
        name="linkedin-uri"
        id="linkedin-uri"
        class="linkedin-input"
        placeholder="https://www.linkedin.com/in/user-name"
        v-model="linkedin"
        @input="saveChanges"
      />
    </form>
    <span v-if="incorrectFormat" class="text-red-500 text-nudging mb-2">
      Please use the format linkedin.com/in/username.
    </span>
  </div>
</template>

<script lang="ts" setup>
import { useToast } from "vue-toastification";
import useIndividual from "@/composables/useIndividual";
import { ref, watch } from "vue";
import debounce from "@/utils/debounce";
const { individual, updateIndividual } = useIndividual();
const toast = useToast();
const linkedin = ref(individual.value.linkedin || "");
const incorrectFormat = ref(false);

const saveChanges = debounce(async function () {
  const regex = new RegExp(/.*linkedin\.com\/in\/.+/);
  if (linkedin.value.length && !regex.test(linkedin.value)) {
    incorrectFormat.value = true;
    return;
  } else {
    incorrectFormat.value = false;
  }

  if (linkedin.value.length > 3 && !linkedin.value.includes("http")) {
    linkedin.value = `https://${linkedin.value.trim()}`;
  }

  await updateIndividual(
    {
      linkedin: linkedin.value,
    },
    "linkedin"
  );
  toast.success("Changes saved successfully", { timeout: 2000 });
}, 1000);

watch(individual, () => {
  linkedin.value = individual.value.linkedin || "";
});
</script>
