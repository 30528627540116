<template>
  <div
    class="fixed w-screen h-screen z-100 inset-0 bg-grey-900/40 backdrop-blur"
  >
    <div
      class="fixed px-5 z-100 bg-white rounded-t-2xl mx-auto sm:my-auto mb-0 mt-auto translate-y-0 inset-0 items-center transition-all sm:rounded-lg shadow-xl sm:px-12 flex flex-col justify-between overflow-y-hidden h-fit w-screen sm:max-w-3xl"
      aria-modal="true"
    >
      <div class="flex flex-col w-full py-7">
        <div v-if="!emailConfirmation" class="px-4 p-2">
          <base-modal-header
            @close="closeModal"
            :show-close-button="showCloseButton"
          >
            Verify your email address
          </base-modal-header>
          <span class="flex items-start self-stretch text-primary copy">
            To continue, please enter your email address.
          </span>
          <form class="flex flex-col items-start bg-white py-3">
            <label for="email" class="text-inputs-title">Email address</label>
            <input
              class="block text-input text-inputs-content w-full mt-3"
              type="email"
              name="email"
              placeholder="email@address.com"
              v-model="email"
            />
            <div
              v-if="showEmailWarning"
              class="flex items-center bg-red-50 rounded-lg mt-2 w-full"
            >
              <span
                class="material-icons-round text-red-700 py-2 px-2.5 rounded-l-lg"
              >
                error
              </span>
              <span class="text-copy-bold py-2 md:py-2 px-4">
                There was a error with your email please try again.
              </span>
            </div>
            <div class="flex sm:flex-row w-full flex-col mt-4">
              <button
                class="button-primary text-cta-primary whitespace-nowrap flex justify-center flex-wrap-reverse mb-3 sm:mb-0 mr-2 md:w-28"
                @click.prevent="submitEmail"
              >
                <span v-if="!submitting">Submit</span>
                <svg
                  v-else
                  class="animate-spin"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM3.6 12C3.6 16.6392 7.36081 20.4 12 20.4C16.6392 20.4 20.4 16.6392 20.4 12C20.4 7.36081 16.6392 3.6 12 3.6C7.36081 3.6 3.6 7.36081 3.6 12Z"
                    fill="#769184"
                  />
                  <path
                    d="M22.3923 18C23.4455 16.1758 24 14.1064 24 12C24 9.89356 23.4455 7.82423 22.3923 6C21.3391 4.17577 19.8242 2.66091 18 1.60769C16.1758 0.554475 14.1064 -9.20754e-08 12 0L12 3.6C13.4745 3.6 14.923 3.98813 16.2 4.72539C17.477 5.46264 18.5374 6.52304 19.2746 7.8C20.0119 9.07696 20.4 10.5255 20.4 12C20.4 13.4745 20.0119 14.923 19.2746 16.2L22.3923 18Z"
                    fill="white"
                  />
                </svg>
              </button>
              <button
                @click.prevent="closeModal"
                v-if="showCloseButton"
                class="button-secondary text-cta-secondary whitespace-nowrap hover:bg-sage-50 flex justify-center active:bg-sage-100 mb-3 sm:mb-0"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
        <div v-if="emailConfirmation">
          <magic-link-confirmation
            @close-magic-link-confirmation="closeModal"
            :guestEmail="email"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from "vue-router";
import { ref } from "vue";
import MagicLinkConfirmation from "./MagicLinkConfirmation.vue";
import BaseModalHeader from "./shared/BaseModalHeader.vue";
import { authAxios } from "@/lib/axios";

const emit = defineEmits(["close-magic-link-modal"]);

const props = defineProps({
  redirectLink: {
    type: String,
    required: false,
  },
  showCloseButton: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const email = ref("");
const emailConfirmation = ref(false);
const showEmailWarning = ref(false);
const currentRoute = useRoute();
const submitting = ref(false);
const magicLinkRedirect =
  props?.redirectLink ||
  `https://${window.location.host}${currentRoute.fullPath}`;

async function submitEmail() {
  if (email.value.length < 1) {
    showEmailWarning.value = true;
    return;
  }

  if (submitting.value) return;
  showEmailWarning.value = false;
  submitting.value = true;

  const data = await authAxios.post("/generate-sign-in-link", {
    email: email.value,
    redirect_uri: magicLinkRedirect,
  });
  if (data.status == 200) {
    showEmailWarning.value = false;
    emailConfirmation.value = true;
  } else {
    showEmailWarning.value = true;
  }
}

function closeModal() {
  emailConfirmation.value = false;
  email.value = "";
  emit("close-magic-link-modal");
}
</script>
