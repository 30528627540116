<template>
  <div>
    <div
      id="myEducation"
      class="hidden md:flex flex-col items-start sm:flex-row w-full justify-between sm:items-center gap-4"
    >
      <h4 class="text-title-h2 flex-1">Education</h4>
    </div>
    <validation-message
      v-if="!educationCourses.length"
      validationMessage="Optional: Please add at least 1 educational institution"
    />
    <div class="mt-5 sm:mt-7">
      <div
        v-if="educationCourses.length === 0"
        class="text-secondary text-gray-700 bg-gray-75 p-4 rounded-lg text-center"
      >
        Add your education history here
      </div>
      <ul v-for="(course, index) in educationCourses">
        <li :key="index">
          <div class="relative transition-all">
            <div
              class="p-4 md:p-6 hover:shadow-md rounded-lg my-3 border border-1 border-solid border-t-0 border-r-0 border-l-0 border-grey-100 flex items-end justify-between"
            >
              <div
                class="cursor-pointer group"
                @click="openEducation(course)"
                :disabled="isSaving"
              >
                <div
                  class="copy-emphasis group-hover:underline underline-offset-4"
                >
                  {{ course.institution }}
                </div>
                <div class="mt-3 group-hover:underline underline-offset-2">
                  {{ getDisplayDegree(course) }}
                </div>
                <div class="mt-1 group-hover:underline underline-offset-2">
                  {{ getDisplayDates(course) }}
                </div>
              </div>
              <div class="md:flex mt-2">
                <div
                  class="flex space-x-2 justify-between hidden xs:justify-start md:flex md:flex-row w-full sm:w-auto mt-6 md:mt-0"
                >
                  <button
                    class="text-cta-tertiary text-red-600 flex items-center mr-4"
                    @click="onDeleteEducation(course)"
                    :disabled="isSaving"
                  >
                    <span class="material-icons-round mr-2 text-base">
                      delete
                    </span>
                    Delete
                  </button>
                  <button
                    class="text-cta-tertiary text-sage-700 flex items-center"
                    @click="openEducation(course)"
                    :disabled="isSaving"
                  >
                    <span class="material-icons-round mr-2 text-base">
                      edit
                    </span>
                    Edit
                  </button>
                </div>
              </div>
            </div>
            <button
              class="absolute top-4 right-4 inline-block md:hidden"
              @click="isMobileActionsOpen = true"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                  fill="#F7F7F7"
                />
                <path
                  d="M12 8C13.1 8 14 7.1 14 6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6C10 7.1 10.9 8 12 8ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM12 16C10.9 16 10 16.9 10 18C10 19.1 10.9 20 12 20C13.1 20 14 19.1 14 18C14 16.9 13.1 16 12 16Z"
                  fill="#565958"
                />
              </svg>
            </button>
          </div>
          <the-modal
            v-if="isMobileActionsOpen"
            :easyToClose="true"
            @close-modal="isMobileActionsOpen = false"
          >
            <template #header>
              <h2 class="copy-emphasis sm:text-title-h2">
                {{ course.institution }}
              </h2>
            </template>

            <div class="flex flex-col gap-6">
              <button
                class="button-primary text-cta-primary flex justify-center items-center gap-2 w-full md:w-auto"
                @click="openEducation(course)"
                :disabled="isSaving"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M1.99902 11.6409V13.6676C1.99902 13.8543 2.14569 14.0009 2.33236 14.0009H4.35902C4.44569 14.0009 4.53236 13.9676 4.59236 13.9009L11.8724 6.6276L9.37236 4.1276L2.09902 11.4009C2.03236 11.4676 1.99902 11.5476 1.99902 11.6409ZM13.8057 4.69427C14.0657 4.43427 14.0657 4.01427 13.8057 3.75427L12.2457 2.19427C11.9857 1.93427 11.5657 1.93427 11.3057 2.19427L10.0857 3.41427L12.5857 5.91427L13.8057 4.69427Z"
                    fill="white"
                  />
                </svg>
                Edit
              </button>

              <button
                class="text-cta-secondary button-secondary text-red-600 flex items-center justify-center gap-2 w-full md:w-auto border-red-600 hover:bg-red-50"
                :disabled="isSaving"
                @click="onDeleteEducation(course)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M4.00016 12.6667C4.00016 13.4 4.60016 14 5.3335 14H10.6668C11.4002 14 12.0002 13.4 12.0002 12.6667V6C12.0002 5.26667 11.4002 4.66667 10.6668 4.66667H5.3335C4.60016 4.66667 4.00016 5.26667 4.00016 6V12.6667ZM12.0002 2.66667H10.3335L9.86016 2.19333C9.74016 2.07333 9.56683 2 9.3935 2H6.60683C6.4335 2 6.26016 2.07333 6.14016 2.19333L5.66683 2.66667H4.00016C3.6335 2.66667 3.3335 2.96667 3.3335 3.33333C3.3335 3.7 3.6335 4 4.00016 4H12.0002C12.3668 4 12.6668 3.7 12.6668 3.33333C12.6668 2.96667 12.3668 2.66667 12.0002 2.66667Z"
                    fill="#DF2211"
                  />
                </svg>
                Delete
              </button>
            </div>
          </the-modal>
        </li>
      </ul>
      <button
        data-cy="add-education-button"
        class="mt-6 cursor-pointer bg-sage-50 hover:bg-grey-100 rounded-full flex items-center justify-start sm:justify-center w-full border-none outline-none focus:ring focus:ring-orange-500"
        type="button"
        @click="openEducation(null)"
      >
        <div
          class="rounded-full bg-sage-600 hover:bg-sage-700 active:bg-sage-800 flex items-center justify-center h-11 w-11 mx-4 my-1.5 sm:my-4 text-white"
        >
          <span class="material-icons-round"> add </span>
        </div>
        <span class="text-cta-primary text-grey-900">Add</span>
      </button>
    </div>
  </div>

  <the-modal
    v-if="modalOpen && educationOpen"
    :easyToClose="false"
    @close-modal="modalOpen = false"
  >
    <template #header>
      <h2 class="copy-emphasis sm:text-title-h2">Add education</h2>
    </template>
    <education-form
      :education="educationOpen"
      @save-education="onSaveEducation"
      @delete-education="onDeleteEducation"
    />
  </the-modal>
</template>
<script lang="ts" setup>
import useEducation from "@/composables/useEducation";
import TheModal from "../TheModal.vue";
import { Ref, ref } from "vue";
import { Education } from "@/types/Education";
import EducationForm from "./EducationForm.vue";
import ValidationMessage from "../ValidationMessage.vue";

const modalOpen = ref(false);
const educationOpen: Ref<Education | null> = ref(null);
const isMobileActionsOpen = ref(false);
const isSaving = ref(false);

const {
  getEducation,
  updateEducation,
  deleteEducation,
  createEducation,
  educationCourses,
  isLoaded,
  getDisplayDates,
  getDisplayDegree,
} = useEducation();

if (!isLoaded.value) {
  getEducation();
}

const openEducation = (education: Education | null) => {
  if (!education) {
    education = {
      institution: "",
      degree: "",
      field_of_study: "",
      from: "",
      to: "",
    };
  }

  educationOpen.value = education;
  modalOpen.value = true;
};

const onSaveEducation = async (education: Education) => {
  isSaving.value = true;
  educationOpen.value = null;
  modalOpen.value = false;
  if (education.id) {
    await updateEducation(education);
  } else {
    await createEducation(education);
  }
  isSaving.value = false;
};

const onDeleteEducation = async (education: Education) => {
  if (confirm("Are you sure you want to delete this education?")) {
    isSaving.value = true;
    educationOpen.value = null;
    modalOpen.value = false;
    await deleteEducation(education);
    isSaving.value = false;
  }
};
</script>
