<template>
  <base-modal :isOpen="true" :is-small="true">
    <base-modal-header @close="close">
      {{ anyExistingRoles ? "Replace" : "Add" }} roles from CV
    </base-modal-header>
    <div class="overflow-y-auto py-2">
      <cv-parsing-progress @parsed="onCvParse" />
    </div>
  </base-modal>
</template>

<script setup lang="ts">
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseModalHeader from "@/components/shared/BaseModalHeader.vue";
import CvParsingProgress from "@/components/cv/CvParsingProgress.vue";
import useCvParsing from "@/composables/useCvParsing";

const emits = defineEmits(["parsed"]);

defineProps({
  anyExistingRoles: {
    type: Boolean,
    required: false,
    default: false,
  },
});

function onCvParse() {
  emits("parsed");
}

function close() {
  useCvParsing().setCv(null);
}
</script>
