export const sectors = [
  {
    display: "Agriculture",
    searchValues: ["Agriculture"],
  },
  {
    display: "Aquaculture",
    searchValues: ["Aquaculture"],
  },
  {
    display: "Farming",
    searchValues: ["Farming"],
  },
  {
    display: "Forestry Development/Harvesting",
    searchValues: ["Forestry Development/Harvesting", "forest development"],
  },
  {
    display: "Forestry Processing",
    searchValues: ["Forestry Processing"],
  },
  {
    display: "Horticulture",
    searchValues: ["Horticulture"],
  },
  {
    display: "Art Conservation & Restoration",
    searchValues: ["Art Conservation & Restoration", "Art Restoration"],
  },
  {
    display: "Arts",
    searchValues: ["Arts"],
  },
  {
    display: "Arts Education",
    searchValues: ["Arts Education"],
  },
  {
    display: "Community Development",
    searchValues: ["Community Development", "Community"],
  },
  {
    display: "Culture",
    searchValues: ["Culture"],
  },
  {
    display: "Heritage",
    searchValues: ["Heritage"],
  },
  {
    display: "Museums & Galleries",
    searchValues: ["Museums & Galleries", "Museums", "Galleries"],
  },
  {
    display: "Accounting, Audit & Tax Services",
    searchValues: [
      "Accounting, Audit & Tax Services",
      "accounting",
      "tax",
      "Other Financial Services",
      "Financial Services",
    ],
  },
  {
    display: "Business/Commercial Services (General/Other)",
    searchValues: [
      "Business/Commercial Services (General/Other)",
      "business services",
      "commercial services",
    ],
  },
  {
    display: "Consulting Services",
    searchValues: ["Consulting Services", "consulting"],
  },
  {
    display: "Decision/Risk Analysis",
    searchValues: ["Decision/Risk Analysis", "risk", "analysis"],
  },
  {
    display: "Professional Services (General/Other)",
    searchValues: [
      "Professional Services (General/Other)",
      "consulting",
      "business consulting",
    ],
  },
  {
    display: "Agricultural Chemicals",
    searchValues: ["Agricultural Chemicals", "chemicals"],
  },
  {
    display: "Commodity Chemicals",
    searchValues: ["Commodity Chemicals", "chemicals"],
  },
  {
    display: "Industrial Chemicals",
    searchValues: ["Industrial Chemicals", "chemicals"],
  },
  {
    display: "Materials (General/Other)",
    searchValues: ["Materials (General/Other)", "chemicals"],
  },
  {
    display: "Other Chemicals & Gases",
    searchValues: [
      "Other Chemicals & Gases",
      "Other Chemicals and Gases",
      "chemicals",
    ],
  },
  {
    display: "Semiconductors",
    searchValues: ["Semiconductors"],
  },
  {
    display: "Architecture & Design",
    searchValues: ["Architecture & Design", "architecture", "design"],
  },
  {
    display: "Building Materials & Supplies",
    searchValues: [
      "Building Materials & Supplies",
      "building materials",
      "building supplies",
    ],
  },
  {
    display: "Construction & Engineering (Construction)",
    searchValues: [
      "Construction & Engineering (Construction)",
      "engineering",
      "construction",
    ],
  },
  {
    display: "Construction & Engineering (Engineering)",
    searchValues: [
      "Construction & Engineering (Engineering)",
      "engineering",
      "construction",
    ],
  },
  {
    display: "Construction & Engineering (General)",
    searchValues: [
      "Construction & Engineering (General)",
      "engineering",
      "construction",
    ],
  },
  {
    display: "Infrastructure",
    searchValues: ["Infrastructure"],
  },
  {
    display: "Commercial Products",
    searchValues: ["Commercial Products", "commercial goods"],
  },
  {
    display: "Consumer Goods (Consumer Durables)",
    searchValues: [
      "Consumer Goods (Consumer Durables)",
      "Consumer Durables",
      "Durables",
    ],
  },
  {
    display: "Consumer Goods (Consumer Non-Durables)",
    searchValues: [
      "Consumer Goods (Consumer Non-Durables)",
      "Consumer Non-Durables",
      "Non-Durables",
    ],
  },
  {
    display: "Consumer Goods (FMCG)",
    searchValues: ["Consumer Goods (FMCG)", "FMCG"],
  },
  {
    display: "Consumer Goods (General/Other)",
    searchValues: ["Consumer Goods (General/Other)"],
  },
  {
    display: "Consumer Services",
    searchValues: ["Consumer Services"],
  },
  {
    display: "Distributors & Wholesale",
    searchValues: ["Distributors & Wholesale"],
  },
  {
    display: "Retail (Apparel & Accessories)",
    searchValues: [
      "Retail (Apparel & Accessories)",
      "Apparel and Accessories",
      "Consumer Non-Durables",
      "Textiles",
      "Retail",
      "Fashion",
    ],
  },
  {
    display: "Retail (Clothing)",
    searchValues: ["Retail (Clothing)"],
  },
  {
    display: "Retail (Department Stores)",
    searchValues: ["Retail (Department Stores)"],
  },
  {
    display: "Retail (Department/General Stores)",
    searchValues: ["Retail (Department/General Stores)"],
  },
  {
    display: "Retail (Electronics)",
    searchValues: ["Retail (Electronics)"],
  },
  {
    display: "Retail (Footwear)",
    searchValues: ["Retail (Footwear)"],
  },
  {
    display: "Retail (General/Other)",
    searchValues: ["Retail (General/Other)"],
  },
  {
    display: "Retail (Grocery)",
    searchValues: ["Retail (Grocery)"],
  },
  {
    display: "Retail (Household Goods)",
    searchValues: ["Retail (Household Goods)"],
  },
  {
    display: "Retail (Internet)",
    searchValues: ["Retail (Internet)"],
  },
  {
    display: "Retail (Luxury)",
    searchValues: ["Retail (Luxury)"],
  },
  {
    display: "Retail (Office Supplies)",
    searchValues: ["Retail (Office Supplies)"],
  },
  {
    display: "Retail (Personal Care)",
    searchValues: ["Retail (Personal Care)"],
  },
  {
    display: "Business Education",
    searchValues: ["Business Education"],
  },
  {
    display: "Education (General/Other)",
    searchValues: ["Education (General/Other)"],
  },
  {
    display: "Educational & Training Services",
    searchValues: ["Educational & Training Services"],
  },
  {
    display: "Primary & Secondary Education",
    searchValues: ["Primary & Secondary Education"],
  },
  {
    display: "Tertiary/Higher Education",
    searchValues: ["Tertiary/Higher Education"],
  },
  {
    display: "Energy (Equipment)",
    searchValues: ["Energy (Equipment)"],
  },
  {
    display: "Energy (Exploration)",
    searchValues: ["Energy (Exploration)"],
  },
  {
    display: "Energy (Fossil Fuels)",
    searchValues: ["Energy (Fossil Fuels)"],
  },
  {
    display: "Energy (General/Other)",
    searchValues: ["Energy (General/Other)"],
  },
  {
    display: "Energy (Infrastructure)",
    searchValues: ["Energy (Infrastructure)"],
  },
  {
    display: "Energy (Production)",
    searchValues: ["Energy (Production)"],
  },
  {
    display: "Energy (Renewables)",
    searchValues: [
      "Energy (Renewables)",
      "green energy",
      "clean energy",
      "solar",
      "wind",
      "geothermal",
      "hydroelectricity",
    ],
  },
  {
    display: "Energy (Storage)",
    searchValues: ["Energy (Storage)"],
  },
  {
    display: "Utilities (Electric)",
    searchValues: ["Utilities (Electric)"],
  },
  {
    display: "Utilities (Gas)",
    searchValues: ["Utilities (Gas)"],
  },
  {
    display: "Utilities (General/Other)",
    searchValues: ["Utilities (General/Other)"],
  },
  {
    display: "Utilities (Water)",
    searchValues: ["Utilities (Water)"],
  },
  {
    display: "Energy (General/Other) / Technology (General/Other)",
    searchValues: ["Energy (General/Other) / Technology (General/Other)"],
  },
  {
    display: "Conservation",
    searchValues: ["Conservation"],
  },
  {
    display: "Environmental & Sustainability (General/Other)",
    searchValues: ["Environmental & Sustainability (General/Other)"],
  },
  {
    display: "Environmental Services (B2B)",
    searchValues: ["Environmental Services (B2B)"],
  },
  {
    display: "Asset Management",
    searchValues: [
      "Asset Management",
      "Financial Services",
      "Other Financial Services",
      "Investment Companies",
    ],
  },
  {
    display: "Asset Management (Real Estate)",
    searchValues: ["Asset Management (Real Estate)"],
  },
  {
    display: "Asset Management / Insurance (General/Other)",
    searchValues: ["Asset Management / Insurance (General/Other)"],
  },
  {
    display: "Asset Management / Investment (General/Other)",
    searchValues: ["Asset Management / Investment (General/Other)"],
  },
  {
    display: "Banking (Commercial)",
    searchValues: ["Banking (Commercial)"],
  },
  {
    display: "Banking (Retail)",
    searchValues: ["Banking (Retail)"],
  },
  {
    display: "Capital Markets/Institutions",
    searchValues: [
      "Capital Markets/Institutions",
      "Financial Services",
      "Other Financial Services",
      "Investment Companies",
    ],
  },
  {
    display: "Credit & Lending",
    searchValues: ["Credit & Lending"],
  },
  {
    display: "Financial Advisory",
    searchValues: ["Financial Advisory"],
  },
  {
    display: "Financial Services (General/Other)",
    searchValues: ["Financial Services (General/Other)"],
  },
  {
    display: "Insurance (Automotive)",
    searchValues: ["Insurance (Automotive)"],
  },
  {
    display: "Insurance (General/Other)",
    searchValues: ["Insurance (General/Other)"],
  },
  {
    display: "Insurance (Health & Life)",
    searchValues: ["Insurance (Health & Life)"],
  },
  {
    display: "Insurance (Re-insurance)",
    searchValues: ["Insurance (Re-insurance)"],
  },
  {
    display: "Insurance (Real Estate & Property)",
    searchValues: ["Insurance (Real Estate & Property)"],
  },
  {
    display: "Investment (Angel)",
    searchValues: ["Investment (Angel)"],
  },
  {
    display: "Investment (General/Other)",
    searchValues: ["Investment (General/Other)"],
  },
  {
    display: "Investment (General/Other) / Financial Advisory",
    searchValues: ["Investment (General/Other) / Financial Advisory"],
  },
  {
    display: "Investment (Impact Investing)",
    searchValues: ["Investment (Impact Investing)"],
  },
  {
    display: "Investment (Real Estate)",
    searchValues: ["Investment (Real Estate)"],
  },
  {
    display: "Investment Banking",
    searchValues: ["Investment Banking"],
  },
  {
    display: "Pensions",
    searchValues: ["Pensions"],
  },
  {
    display: "Private Equity",
    searchValues: ["Private Equity"],
  },
  {
    display: "Private Equity / Credit & Lending",
    searchValues: ["Private Equity / Credit & Lending"],
  },
  {
    display: "Specialised Finance",
    searchValues: ["Specialised Finance"],
  },
  {
    display: "Venture Capital",
    searchValues: ["Venture Capital"],
  },
  {
    display: "Wealth Management",
    searchValues: ["Wealth Management"],
  },
  {
    display: "Beverages (Alcoholic)",
    searchValues: ["Beverages (Alcoholic)"],
  },
  {
    display: "Beverages (General)",
    searchValues: ["Beverages (General)"],
  },
  {
    display: "Food (Food & Food Products)",
    searchValues: ["Food (Food & Food Products)"],
  },
  {
    display: "Food & Beverages (General/Other)",
    searchValues: ["Food & Beverages (General/Other)"],
  },
  {
    display: "Advocacy Groups",
    searchValues: ["Advocacy Groups"],
  },
  {
    display: "Armed Forces",
    searchValues: ["Armed Forces"],
  },
  {
    display: "Chambers of Commerce",
    searchValues: ["Chambers of Commerce"],
  },
  {
    display: "Diplomatic",
    searchValues: ["Diplomatic"],
  },
  {
    display: "Government",
    searchValues: ["Government"],
  },
  {
    display: "Government & Public Sector (General/Other)",
    searchValues: ["Government & Public Sector (General/Other)"],
  },
  {
    display: "International Affairs",
    searchValues: ["International Affairs"],
  },
  {
    display: "Law Enforcement",
    searchValues: ["Law Enforcement"],
  },
  {
    display: "Local Government",
    searchValues: ["Local Government"],
  },
  {
    display: "Politics",
    searchValues: ["Politics"],
  },
  {
    display: "Public Infrastructure",
    searchValues: ["Public Infrastructure"],
  },
  {
    display: "Public Sector",
    searchValues: ["Public Sector"],
  },
  {
    display: "Regulators",
    searchValues: ["Regulators"],
  },
  {
    display: "Elder & Disabled Care",
    searchValues: ["Elder & Disabled Care"],
  },
  {
    display: "Genetic Medicine",
    searchValues: ["Genetic Medicine"],
  },
  {
    display: "Healthcare (General/Other)",
    searchValues: ["Healthcare (General/Other)"],
  },
  {
    display: "Healthcare Analytics",
    searchValues: ["Healthcare Analytics"],
  },
  {
    display: "Healthcare Consulting",
    searchValues: ["Healthcare Consulting"],
  },
  {
    display: "Healthcare Devices & Supplies",
    searchValues: ["Healthcare Devices & Supplies"],
  },
  {
    display: "Hospitals & Clinics",
    searchValues: ["Hospitals & Clinics"],
  },
  {
    display: "Life Sciences Services Biopharmaceutical Industry",
    searchValues: ["Life Sciences Services Biopharmaceutical Industry"],
  },
  {
    display: "Medical Devices & Supplies",
    searchValues: ["Medical Devices & Supplies"],
  },
  {
    display: "Medical Records Systems",
    searchValues: ["Medical Records Systems"],
  },
  {
    display: "Medical Research",
    searchValues: ["Medical Research"],
  },
  {
    display: "Medical Technology (Medtech)",
    searchValues: ["Medical Technology (Medtech)"],
  },
  {
    display: "Personal Health Care",
    searchValues: ["Personal Health Care"],
  },
  {
    display: "Public Health/NHS",
    searchValues: ["Public Health/NHS"],
  },
  {
    display: "Vetinary Services",
    searchValues: ["Vetinary Services"],
  },
  {
    display: "Casinos & Betting",
    searchValues: ["Casinos & Betting"],
  },
  {
    display: "Cruise Lines",
    searchValues: ["Cruise Lines"],
  },
  {
    display: "Hospitality & Leisure (General/Other)",
    searchValues: ["Hospitality & Leisure (General/Other)"],
  },
  {
    display: "Hotels & Resorts",
    searchValues: ["Hotels & Resorts"],
  },
  {
    display: "Hotels & Resorts / Restaurants & Bars",
    searchValues: ["Hotels & Resorts / Restaurants & Bars"],
  },
  {
    display: "Leisure Facilities",
    searchValues: ["Leisure Facilities"],
  },
  {
    display: "Restaurants & Bars",
    searchValues: ["Restaurants & Bars"],
  },
  {
    display: "Tourism",
    searchValues: ["Tourism"],
  },
  {
    display: "Career Development",
    searchValues: ["Career Development"],
  },
  {
    display: "Human Capital Services",
    searchValues: ["Human Capital Services"],
  },
  {
    display: "Recruitment (Executive Search)",
    searchValues: ["Recruitment (Executive Search)"],
  },
  {
    display: "Law Firms",
    searchValues: ["Law Firms"],
  },
  {
    display: "Legal (General/Other)",
    searchValues: ["Legal (General/Other)"],
  },
  {
    display: "Legal Services",
    searchValues: ["Legal Services"],
  },
  {
    display: "Aerospace & Defence",
    searchValues: ["Aerospace & Defence"],
  },
  {
    display: "Automotive",
    searchValues: ["Automotive"],
  },
  {
    display: "Containers & Packaging",
    searchValues: ["Containers & Packaging"],
  },
  {
    display: "Electronic Components",
    searchValues: ["Electronic Components"],
  },
  {
    display: "Electronics Manufacturing",
    searchValues: ["Electronics Manufacturing"],
  },
  {
    display: "General Purpose Semiconductors",
    searchValues: ["General Purpose Semiconductors"],
  },
  {
    display: "Industrial Supplies & Parts",
    searchValues: ["Industrial Supplies & Parts"],
  },
  {
    display: "Machinery",
    searchValues: ["Machinery"],
  },
  {
    display: "Manufacturing (General/Other)",
    searchValues: ["Manufacturing (General/Other)"],
  },
  {
    display: "Textile & Apparel Manufacturing",
    searchValues: ["Textile & Apparel Manufacturing", "Fashion Manufacturing"],
  },
  {
    display: "Advertising",
    searchValues: ["Advertising"],
  },
  {
    display: "Communications & PR",
    searchValues: ["Communications & PR"],
  },
  {
    display: "Marketing",
    searchValues: ["Marketing"],
  },
  {
    display: "Broadcasting",
    searchValues: ["Broadcasting"],
  },
  {
    display: "Content Production & Distribution",
    searchValues: ["Content Production & Distribution"],
  },
  {
    display: "Digital RIghts Licensing",
    searchValues: ["Digital RIghts Licensing"],
  },
  {
    display: "Events",
    searchValues: ["Events"],
  },
  {
    display: "Entertainment",
    searchValues: ["Entertainment"],
  },
  {
    display: "Entertainment Software",
    searchValues: ["Entertainment Software"],
  },
  {
    display: "Film & Television",
    searchValues: ["Film & Television"],
  },
  {
    display: "Gaming",
    searchValues: ["Gaming"],
  },
  {
    display: "Media & Entertainment (General/Other)",
    searchValues: ["Media & Entertainment (General/Other)"],
  },
  {
    display: "Music",
    searchValues: ["Music"],
  },
  {
    display: "Publishing",
    searchValues: ["Publishing"],
  },
  {
    display: "Social Media",
    searchValues: ["Social Media"],
  },
  {
    display: "Minerals & Mining (General/Other)",
    searchValues: ["Minerals & Mining (General/Other)"],
  },
  {
    display: "Mining (Aluminium)",
    searchValues: ["Mining (Aluminium)"],
  },
  {
    display: "Mining (Coal)",
    searchValues: ["Mining (Coal)"],
  },
  {
    display: "Mining (Gold)",
    searchValues: ["Mining (Gold)"],
  },
  {
    display: "Mining (Iron & Steel)",
    searchValues: ["Mining (Iron & Steel)"],
  },
  {
    display: "Mining (Multi)",
    searchValues: ["Mining (Multi)"],
  },
  {
    display: "Mining (Precious Metals & Minerals)",
    searchValues: ["Mining (Precious Metals & Minerals)"],
  },
  {
    display: "Charities",
    searchValues: ["Charities"],
  },
  {
    display: "International Development",
    searchValues: ["International Development"],
  },
  {
    display: "Membership Organisations",
    searchValues: ["Membership Organisations"],
  },
  {
    display: "Other non-profit groups",
    searchValues: ["Other non-profit groups"],
  },
  {
    display: "Religious Institutions",
    searchValues: ["Religious Institutions"],
  },
  {
    display: "Social Enterprise",
    searchValues: ["Social Enterprise"],
  },
  {
    display: "Think Tank",
    searchValues: ["Think Tank", "Services (Non-Financial)"],
  },
  {
    display: "Trade Association",
    searchValues: ["Trade Association"],
  },
  {
    display: "Bioinformatics",
    searchValues: ["Bioinformatics"],
  },
  {
    display: "Biotechnology",
    searchValues: ["Biotechnology", "biotech", "bio-tech", "bio tech"],
  },
  {
    display: "Drug Delivery",
    searchValues: ["Drug Delivery", "medical research", "pharma"],
  },
  {
    display: "Drug Discovery",
    searchValues: ["Drug Discovery", "medical research", "pharma"],
  },
  {
    display: "Pharmaceuticals",
    searchValues: ["Pharmaceuticals", "pharma"],
  },
  {
    display: "Pharmaceuticals & Biotechnology (General/Other)",
    searchValues: [
      "Pharmaceuticals & Biotechnology (General/Other)",
      "pharma",
      "biotech",
      "bio-tech",
      "bio tech",
    ],
  },
  {
    display: "Research & Development",
    searchValues: ["Research & Development", "r&d", "r & d"],
  },
  {
    display: "Buildings & Property",
    searchValues: ["Buildings & Property", "property"],
  },
  {
    display: "Real Estate",
    searchValues: ["Real Estate", "property"],
  },
  {
    display: "Social Housing",
    searchValues: ["Social Housing", "community housing", "accommodation"],
  },
  {
    display: "Sport",
    searchValues: ["Sport"],
  },
  {
    display: "Computer Hardware",
    searchValues: [
      "Computer Hardware",
      "Information Technology",
      "Other Information Technology",
    ],
  },
  {
    display: "Cybersecurity",
    searchValues: ["Cybersecurity"],
  },
  {
    display: "Data",
    searchValues: ["Data", "analysis"],
  },
  {
    display: "Education Technology (Edtech)",
    searchValues: [
      "Education Technology (Edtech)",
      "edtech",
      "ed-tech",
      "ed tech",
    ],
  },
  {
    display: "Financial Technology (Fintech)",
    searchValues: [
      "Financial Technology (Fintech)",
      "fintech",
      "fin-tech",
      "fin tech",
    ],
  },
  {
    display: "IT Services",
    searchValues: ["IT Services", "Information Technology", "IT"],
  },
  {
    display: "Software (Application)",
    searchValues: ["Software (Application)", "apps", "application", "tech"],
  },
  {
    display: "Software (Business/Productivity)",
    searchValues: [
      "Software (Business/Productivity)",
      "tech",
      "business software",
    ],
  },
  {
    display: "Software (Communications)",
    searchValues: [
      "Software (Communications)",
      "comms",
      "communications",
      "tech",
    ],
  },
  {
    display: "Software (Database Software)",
    searchValues: ["Software (Database Software)", "data", "database", "tech"],
  },
  {
    display: "Software (Educational)",
    searchValues: ["Software (Educational)", "edtech", "ed-tech", "ed tech"],
  },
  {
    display: "Software (Financial)",
    searchValues: ["Software (Financial)", "fintech", "fin-tech", "fin tech"],
  },
  {
    display: "Software (General/Other)",
    searchValues: [
      "Software (General/Other)",
      "IT",
      "Information Technology",
      "SaaS",
      "software as a service",
      "tech",
    ],
  },
  {
    display: "Software (Internet Software)",
    searchValues: ["Software (Internet Software)", "tech", "internet"],
  },
  {
    display: "Technology (General)",
    searchValues: ["Technology (General)", "technology", "IT"],
  },
  {
    display: "Technology (General/Other)",
    searchValues: ["Technology (General/Other)", "technology", "IT"],
  },
  {
    display: "Travel Technology",
    searchValues: ["Travel Technology", "technology", "IT", "travel"],
  },
  {
    display: "Technology (General) / Regulatory",
    searchValues: [
      "Technology (General) / Regulatory",
      "technology",
      "IT",
      "regulation",
    ],
  },
  {
    display: "Internet Service Providers",
    searchValues: ["Internet Service Providers", "internet", "ISP", "wifi"],
  },
  {
    display: "Telecommunication Services",
    searchValues: [
      "Telecommunication Services",
      "telco",
      "telecoms",
      "telephone service provider",
      "telecommunications operator",
    ],
  },
  {
    display: "Telecommunications (General/Other)",
    searchValues: ["Telecommunications (General/Other)", "telecoms", "telco"],
  },
  {
    display: "Wireless Communications Equipment",
    searchValues: [
      "Wireless Communications Equipment",
      "wifi",
      "wireless comms",
    ],
  },
  {
    display: "Telecommunication Service Providers / Broadcasting",
    searchValues: [
      "Telecommunication Service Providers / Broadcasting",
      "telecoms",
      "telco",
    ],
  },
  {
    display: "Automotive (Aftermarket)",
    searchValues: ["Automotive (Aftermarket)", "cars"],
  },
  {
    display: "Automotive (General/Other)",
    searchValues: ["Automotive (General/Other)", "cars"],
  },
  {
    display: "Aviation",
    searchValues: [
      "Aviation",
      "airlines",
      "aircraft",
      "air travel",
      "aeroplanes",
      "planes",
    ],
  },
  {
    display: "Energy Transportation",
    searchValues: ["Energy Transportation", "transport energy", "transport"],
  },
  {
    display: "Logistics",
    searchValues: ["Logistics"],
  },
  {
    display: "Outsourcing",
    searchValues: ["Outsourcing"],
  },
  {
    display: "Rail Transportation",
    searchValues: [
      "Rail Transportation",
      "trains",
      "train travel",
      "transport",
    ],
  },
  {
    display: "Road Transportation",
    searchValues: ["Road Transportation", "transport"],
  },
  {
    display: "Shipping & Maritime",
    searchValues: ["Shipping & Maritime", "maritime"],
  },
  {
    display: "Supply Chain Management",
    searchValues: ["Supply Chain Management"],
  },
  {
    display: "Transportation (General/Other)",
    searchValues: ["Transportation (General/Other)", "transport"],
  },
].sort((a, b) => (a.display > b.display ? 1 : b.display > a.display ? -1 : 0));

export const currencies = [
  {
    code: "GBP",
    symbol: "£",
  },
  {
    code: "USD",
    symbol: "$",
  },
  {
    code: "EUR",
    symbol: "€",
  },
];

export const ownerships = [
  { display: "Armed Forces", searchValues: ["Armed Forces"] },
  { display: "Charity", searchValues: ["Charity"] },
  { display: "Education", searchValues: ["Education"] },
  { display: "Government", searchValues: ["Government"] },
  { display: "In IPO Registration", searchValues: ["In IPO Registration"] },
  { display: "Medical", searchValues: ["Medical"] },
  { display: "Out of Business", searchValues: ["Out of Business"] },
  { display: "Private partnership", searchValues: ["Private partnership"] },
  { display: "Privately owned", searchValues: ["Privately owned"] },
  { display: "Publicly owned", searchValues: ["Publicly owned"] },
];
