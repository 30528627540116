import {
  differenceInMonths,
  differenceInYears,
  differenceInWeeks,
  differenceInDays,
} from "date-fns";

export function humaniseTimeSince(dateOne: Date, dateTwo: Date) {
  const yearsSinceLastUpdate = Math.abs(differenceInYears(dateOne, dateTwo));
  const monthsSinceLastUpdate = Math.abs(differenceInMonths(dateOne, dateTwo));
  const weeksSinceLastUpdate = Math.abs(differenceInWeeks(dateOne, dateTwo));
  const daysSinceLastUpdate = Math.abs(differenceInDays(dateOne, dateTwo));

  if (yearsSinceLastUpdate == 1) {
    return `a year ago`;
  }

  if (yearsSinceLastUpdate > 1) {
    return `${yearsSinceLastUpdate} years ago`;
  }

  if (monthsSinceLastUpdate > 1) {
    return `${monthsSinceLastUpdate} months ago`;
  }

  if (weeksSinceLastUpdate > 1) {
    return `${weeksSinceLastUpdate} weeks ago`;
  }

  if (daysSinceLastUpdate > 1) {
    return `${daysSinceLastUpdate} days ago`;
  }

  return "today";
}
