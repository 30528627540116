<template>
  <div class="border rounded-lg px-10 pb-10 mb-10">
    <required-badge v-if="!canSkip" class="mb-4" />
    <segment
      variant="h4"
      hide-divider
      :title="skill.title"
      :description="skill.substituted_description"
    />
    <div>
      <label class="block font-semibold text-gray-900">
        Please pick up to 3 roles that best demonstrate your fit for this skill
      </label>
      <p
        class="_ bg-red-50 copy-emphasis px-6 py-4 rounded my-2"
        v-if="hasLikelyRemovedRoleFromProfile"
      >
        It looks like you've removed a relevant role to this skill that was
        previously listed. Please add another role from your account
      </p>
      <roles-dropdown
        :roles="rolesHeld"
        :selectedRoles="selectedRoles"
        @toggle-role="toggleRole"
        :openByDefault="hasLikelyRemovedRoleFromProfile"
      />
      <div
        v-if="!selectedRoles.length"
        class="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 mt-[-28px]"
      >
        <base-checkbox
          class="mb-6"
          @click="
            updateSkillResponse(skill.key, {
              no_role_selected: !skill.response?.no_role_selected,
            })
          "
          label="I don’t have specific roles for this skill"
          :checked="hasNoRoleSelected"
        />
      </div>
    </div>

    <warning-banner
      v-if="hasNoRoleSelected && !selectedRoles.length"
      class="mb-4"
      message="If you strongly feel that your other experience is still relevant, please explain why below."
      description="You may still submit an application; however, we strongly encourage you to only do this if you meet the required experience and competencies."
    />
    <rich-text-editor
      v-model="editorContext"
      :guidanceMessage="guidance.message"
      :guidanceVariant="guidance.variant"
      :placeholder="
        skill.structured_description ||
        'Please include your most relevant experience including role and key achievements.'
      "
    />
  </div>
</template>

<script setup lang="ts">
import RequiredBadge from "@/components-v2/RequiredBadge.vue";
import Segment from "@/components-v2/ui-kit/Segment.vue";
import RichTextEditor, {
  type RichTextEditorContext,
} from "@/components-v2/ui-kit/RichTextEditor.vue";
import RolesDropdown from "./RolesDropdown.vue";
import type { RoleApplicationSkill } from "@/models/RoleApplication";
import { Role } from "@/models/Role";
import useRoleApplication from "@/composables/useRoleApplication";
import debounce from "@/utils/debounce";
import { parseNumber } from "@/utils/number";
import BaseCheckbox from "../shared/BaseCheckbox.vue";
import WarningBanner from "@/components-v2/WarningBanner.vue";
import { computed, ref, watch } from "vue";
import { GuidanceIndicatorProps } from "@/components-v2/GuidanceIndicator.vue";

export type RoleApplySkillFormProps = {
  skill: RoleApplicationSkill;
  rolesHeld?: Role[];
  hasNudgeError?: boolean;
  hasOneDesiredError?: boolean;
  canSkip?: boolean;
};
const props = withDefaults(defineProps<RoleApplySkillFormProps>(), {
  canSkip: true,
});

const { roleApplication, updateRoleApplication } = useRoleApplication();
const editorContext = ref<RichTextEditorContext>({
  text: props.skill.response?.relevant_roles_notes || "",
});

const updateSkillResponse = (
  skillKey: RoleApplicationSkill["key"],
  response: Partial<RoleApplicationSkill["response"]>
) => {
  // todo: fix `useRoleApplication()` types
  const skills = roleApplication.value?.skills.map(
    (skill: RoleApplicationSkill) =>
      skill.key === skillKey
        ? { ...skill, response: { ...skill.response, ...response } }
        : skill
  );
  skills && updateRoleApplication({ skills });
};

const updateNotes = debounce(
  (note: string) =>
    updateSkillResponse(props.skill.key, { relevant_roles_notes: note }),
  2000
);

watch(() => editorContext.value.text, updateNotes);

const hasNoRoleSelected = computed(
  () => !!props.skill.response?.no_role_selected
);
const selectedRoles = computed(() => {
  const ids =
    props.skill.response?.relevant_roles?.map(({ id }) => id as string) || [];
  return props.rolesHeld?.filter(({ id }) => ids.includes(id)) || [];
});

const hasLikelyRemovedRoleFromProfile = computed(
  () =>
    props.skill.response &&
    (props.skill.response.relevant_roles?.length || 0) > 10 &&
    !selectedRoles.value.length
);

const guidance = computed((): GuidanceIndicatorProps => {
  const limit = parseNumber(props.skill.recommended_words, 500);
  const { wordCount, warnIfBelowMinimum } = editorContext.value;
  if (!wordCount) {
    return getGuidanceIndicator("default", limit);
  }
  if (wordCount < limit * 0.6) {
    return warnIfBelowMinimum
      ? getGuidanceIndicator("below")
      : getGuidanceIndicator("default", limit);
  }
  if (wordCount > limit * 2) {
    return getGuidanceIndicator("over");
  }
  return getGuidanceIndicator("within");
});

const getGuidanceIndicator = (
  state: "default" | "below" | "over" | "within",
  limit?: number
): GuidanceIndicatorProps => {
  switch (state) {
    case "below":
      return {
        message: `It looks like your response is fairly brief - a very short answer may reduce your chance of progressing.`,
        variant: "warning",
      };
    case "over":
      return {
        message: `You’ve exceeded our recommended response length. We’d recommend you keep your answer succinct – significantly longer responses may reduce your chances of progressing.`,
        variant: "warning",
      };
    case "within":
      return {
        message: `We find that this is a good response length. If you’d like to write more then feel free to do so.`,
        variant: "success",
      };
    case "default":
    default:
      return {
        message: `We’d advise you to write around ${limit} words.`,
        variant: "info",
      };
  }
};

const toggleRole = (role: Role) => {
  const relevantRoles = props.skill.response?.relevant_roles ?? [];
  const updatedRelevantRoles = relevantRoles.some(({ id }) => id === role.id)
    ? relevantRoles.filter(({ id }) => id !== role.id)
    : [...relevantRoles, role];

  updateSkillResponse(props.skill.key, {
    relevant_roles: updatedRelevantRoles,
  });
};
</script>
