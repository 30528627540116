<template>
  <div class="relative px-4 md:px-6 xl:px-0">
    <span
      class="material-icons-round text-grey-300 absolute inset-y-0 left-10 xl:left-4 top-5 pl-2"
    >
      search
    </span>
    <input
      data-cy="search-input"
      :value="searchQuery"
      @input="updateSearchQuery($event.target.value)"
      class="text-input text-inputs-content w-full copy-emphasis pl-16 placeholder-grey-600"
      placeholder="Search roles ..."
    />
  </div>
</template>

<script>
import useRoleFilters from "@/composables/useRoleFilters";
export default {
  name: "SearchRoles",
  setup() {
    const { searchQuery, updateSearchQuery } = useRoleFilters();
    return { searchQuery, updateSearchQuery };
  },
};
</script>
