<template>
  <div class="md:container max-w-screen-xl md:mx-auto py-12">
    <div
      class="bg-grey-50 animate-pulse rounded-lg"
      style="height: 100px"
    ></div>
    <div
      class="bg-grey-50 animate-pulse rounded-lg mt-4"
      style="height: 800px"
    ></div>
  </div>
</template>
