import * as Sentry from "@sentry/browser";
import flagsmith from "flagsmith";
import { apiAxios } from "@/lib/axios";
import { setIdentityResolved } from "@/composables/useFlagsmith";

export default async function identifyUser(): Promise<void> {
  try {
    const { data } = await apiAxios.get("/identity");
    const { login_id, email, name, individual_id, pink_tags } = data;

    // Mixpanel
    await window.analytics.identify(login_id);
    window.analytics.opt_in_tracking();

    window.analytics.alias(individual_id); // Correct case when individualId was used
    window.analytics.alias("email:" + email); // email:jane.doe@example.com is used to identify users whilst being invited, see here: https://github.com/Nurole/admin/blob/master/admin/app/controllers/invites_controller.rb#L65
    window.analytics.alias(email);
    window.analytics.people.set("$email", email);
    window.analytics.people.set("$name", name);
    window.analytics.people.set("pink_tags", pink_tags);

    // Google Analytics 4
    (window as any).gtag("config", process.env.VITE_GTAG_ID, {
      user_id: login_id,
      debug_mode: process.env.VITE_RACK_ENV === "development",
      user_properties: {
        nuroler: (email || "").trim().endsWith("@nurole.com"),
        pink_tags: pink_tags,
      },
    });

    // Sentry
    Sentry.setUser({ id: login_id, email: email });

    // Flagsmith
    await flagsmith.identify(login_id);
    await flagsmith.setTrait("email", email);
    await flagsmith.setTrait("pink_tags", pink_tags);

    // Hubspot
    const hubspotQueue = (window._hsq = window._hsq || []);
    hubspotQueue.push(["identify", { login_id, email, name, individual_id }]);
  } catch {
    const anonymousId = localStorage.getItem("ajs_anonymous_id");
    if (anonymousId) {
      await flagsmith.setTrait(
        "anonymousId",
        anonymousId.substring(1, anonymousId.length - 1)
      );
    }
  } finally {
    setIdentityResolved();
  }
}

identifyUser();
