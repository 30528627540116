<template>
  <base-modal :isOpen="isOpen">
    <base-modal-header @close="$emit('boardex-cancelled')" />
    <div
      class="flex items-center flex-col text-grey-700 sm:px-5 overflow-y-auto"
    >
      <div class="copy-emphasis text-center sm:px-34">
        We think we have found you
      </div>
      <div class="my-3">Is this you?</div>
      <div class="my-4 flex flex-col items-center w-full">
        <div class="text-lg bg-grey-50 py-2 w-full text-center font-semibold">
          {{ this.suggestedBoardexIndividual?.displayName }}
        </div>
        <div class="flex flex-col items-start my-3 w-full pb-24">
          <div
            v-for="role in suggestedBoardexIndividual.roles"
            :key="role.id"
            class="my-2 w-full flex-col xs:flex-row"
          >
            <div>
              <roles-table-date :role="role" />
              <div class="max-w-xl flex flex-col">
                <div class="font-semibold">{{ role.position }}</div>
                <span
                  data-cy="request-invite-tile-name"
                  class="text-left text-lg font-semibold"
                  >{{ currentOrganisationName(role.organisation.name) }}</span
                >
                <span
                  v-if="otherOrganisationName(role.organisation.name)"
                  data-cy="request-invite-tile-name"
                  class="text-left text-sm"
                  >{{ otherOrganisationName(role.organisation.name) }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <base-modal-footer>
      <div class="flex flex-col w-full xs:flex-row xs:justify-center">
        <button
          data-cy="roles-table-modal-primary-btn"
          class="mt-3 xs:mr-3 button-primary text-cta-primary"
          @click="confirm"
        >
          Yes, it's me
        </button>
        <button
          data-cy="modal-secondary-btn"
          class="mt-3 button-secondary text-cta-secondary"
          @click="reject"
        >
          No, it isn't me
        </button>
      </div>
      <span class="text-grey-700 font-light pt-5 text-center">
        Don't worry if there are a few mistakes - we can edit this later
      </span>
    </base-modal-footer>
  </base-modal>
</template>

<script lang="ts">
import RolesTableDate from "@/components/rolesTable/RolesTableDate.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseModalHeader from "@/components/shared/BaseModalHeader.vue";
import BaseModalFooter from "@/components/shared/BaseModalFooter.vue";

export default {
  name: "BoardexLookupModal",
  components: {
    BaseModal,
    BaseModalHeader,
    BaseModalFooter,
    RolesTableDate,
  },
  props: {
    suggestedBoardexIndividual: {
      type: Object,
      default: null,
    },
  },
  emits: ["boardex-confirmed", "boardex-rejected", "boardex-cancelled"],
  computed: {
    isOpen() {
      return !!this.suggestedBoardexIndividual;
    },
  },
  methods: {
    currentOrganisationName(name) {
      const everythingBeforeBrackets = name.match(/^[^(]+/g);
      if (!everythingBeforeBrackets) return "";
      return everythingBeforeBrackets[0];
    },
    otherOrganisationName(name) {
      const everythingInBrackets = name.match(/\(.*/g);
      if (!everythingInBrackets) return "";
      return everythingInBrackets[0];
    },
    reject() {
      this.$emit("boardex-rejected");
    },
    confirm() {
      this.$emit("boardex-confirmed");
    },
  },
};
</script>
