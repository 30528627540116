<template>
  <button
    @click="isOpen = !isOpen"
    class="lg:hidden flex items-center w-full border-solid border-l-0 border-r-0 border-t-0.5 border-b-0.5 border-grey-200 mt-4 px-4 md:px-6 focus:ring focus:ring-orange-500"
    style="height: 56px"
    :style="!isOpen && 'border-bottom-color: transparent !important;'"
  >
    <div class="flex-1 text-cta-tertiary text-sage-700 text-left">
      FILTER & SORT
    </div>
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="transition"
      :class="isOpen ? 'rotate-180' : 'rotate-0'"
    >
      <path
        d="M2.12021 1.29L6.00022 5.17L9.88021 1.29C10.2702 0.899998 10.9002 0.899998 11.2902 1.29C11.6802 1.68 11.6802 2.31 11.2902 2.7L6.70021 7.29C6.31021 7.68 5.68021 7.68 5.29022 7.29L0.700215 2.7C0.310215 2.31 0.310215 1.68 0.700215 1.29C1.09021 0.909998 1.73021 0.899998 2.12021 1.29Z"
        fill="#1E4B37"
      />
    </svg>
  </button>
  <template
    :class="isOpen ? 'block' : 'hidden'"
    class="lg:visible lg:block pb-8"
  >
    <slot></slot>
  </template>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },
};
</script>
