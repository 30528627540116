<template>
  <div class="text-grey-900">
    <request-invitation-success v-if="submitSuccess" ref="requestSuccess" />
    <div v-else>
      <div class="py-8 md:pt-10 px-3 sm:px-6 md:px-16 lg:px-24">
        <i class="las la-users la-4x pb-5" />
        <p class="">
          Thanks for your interest in becoming a Nurole member. Fill in the form
          below and we will process your request as soon as possible. You will
          need to have either sat on a board before, or have skills and
          experience that make you board-ready.
        </p>
        <p class="">
          Instead of filling in this form, you can also ask an existing member
          to recommend you. This is often a quicker way to become a member.
        </p>
      </div>
      <div
        class="pt-8 pb-6 border-t border-b-0 border-l-0 border-r-0 border-solid border-grey-100 px-3 sm:px-6 md:px-16 lg:px-24"
      >
        <h2 class="copy-emphasis mb-3">Your details</h2>
        <p class="mt-1 mb-8">We recommend you use a personal email address.</p>
        <div class="flex flex-col md:flex-row">
          <div
            class="flex flex-col md:w-1/2 md:mr-1 mb-6 md:mb-0"
            :class="{
              'request-error': v$.name.$invalid && v$.name.$error,
            }"
          >
            <h3 class="text-inputs-title m-0">Your full name</h3>
            <input
              type="text"
              class="my-3 text-input text-inputs-content"
              :class="{
                'border-grey-300': name,
                'border-red-700': v$.name.$invalid && v$.name.$error,
              }"
              placeholder="Type here"
              v-model="name"
              @blur="v$.name.$touch()"
              data-cy="full-name-input"
            />
            <span
              class="text-red-700 text-inputs-title mt-4 flex items-center"
              v-if="v$.name.$invalid && v$.name.$error"
              data-cy="full-name-error"
            >
              <span class="material-icons-round mr-2"> warning </span>
              Please enter your full name</span
            >
          </div>
          <div
            class="flex flex-col md:w-1/2 md:ml-1"
            :class="{
              'request-error': v$.email.$invalid && v$.email.$error,
            }"
          >
            <h3 class="text-inputs-title m-0">Your email address</h3>
            <input
              type="text"
              class="my-3 text-input text-inputs-content"
              :class="{
                'border-red-700': v$.email.$invalid && v$.email.$error,
                'border-grey-300': email && !v$.email.$error,
              }"
              placeholder="email@address.com"
              v-model="email"
              @blur="v$.email.$touch()"
              data-cy="email-input"
            />
            <span
              class="text-red-700 mt-4 flex items-center text-inputs-title"
              v-if="v$.email.$invalid && v$.email.$error"
              data-cy="email-error"
            >
              <span class="material-icons-round mr-2"> warning </span>
              Please enter a valid email address</span
            >
          </div>
        </div>
      </div>
      <div
        class="pt-8 border-t border-b-0 border-l-0 border-r-0 border-solid border-grey-100 px-3 sm:px-6 md:px-16 lg:px-24"
      >
        <h2 class="copy-emphasis mb-3" id="Add your CV">Add your CV</h2>
        <p class="">
          Please upload your CV here so we can automatically import your
          previous roles to help us assess your membership request.
        </p>
        <upload-cv :cv="cv" @cv-change="onCvChange" />
        <span
          v-if="hasExperienceError && !cv"
          class="request-error text-red-700 text-inputs-title inline-block"
          data-cy="request-invite-minimum-error"
          >Please add your CV or at least 3 roles</span
        >
        <or-divider />
        <div class="pt-5">
          <h2 class="copy-emphasis mb-3" id="yourExperience">
            Your experience
          </h2>
          <p class="">Please tell us about at least 3 of your latest roles.</p>
          <logged-out-mini-cv />
        </div>
      </div>

      <div
        class="mt-12 pt-8 pb-6 border-t border-b-0 border-l-0 border-r-0 border-solid border-grey-100 px-3 sm:px-6 md:px-16 lg:px-24"
      >
        <h2 class="copy-emphasis mb-3">How did you hear about Nurole?</h2>
        <p class="mb-4">
          This information is used for marketing purposes only. Your answer will
          not impact in any way upon your eligibility for membership.
        </p>
        <textarea
          class="textarea w-full text-inputs-content"
          placeholder="Your answer here"
          v-model="howDidYouHear"
          data-cy="request-invite-how-did-you-hear"
        ></textarea>
      </div>
      <div class="pt-6 pb-6 px-3 sm:px-6 md:px-16 lg:px-24">
        <h2 class="copy-emphasis mb-3">
          Is there anything else you would like to share?
        </h2>
        <textarea
          class="textarea w-full text-inputs-content"
          placeholder="Your answer here"
          v-model="anythingElse"
          data-cy="request-invite-anything-else"
        ></textarea>
        <div class="flex justify-center my-10">
          <button
            @click="submit"
            data-cy="request-invite-submit-btn"
            class="button-primary text-cta-primary"
          >
            Request invite
          </button>
        </div>
      </div>
    </div>
    <boardex-lookup-modal
      :suggestedBoardexIndividual="suggestedBoardexIndividual"
      @boardex-cancelled="suggestedBoardexIndividual = null"
      @boardex-confirmed="confirmBoardexLookup"
      @boardex-rejected="rejectBoardexLookup"
    />
  </div>
</template>

<script lang="ts">
import { required, email, minLength } from "@vuelidate/validators";
import RequestInvitationSuccess from "@/components/requestInvitation/RequestInvitationSuccess.vue";
import UploadCv from "@/components/cv/UploadCv.vue";
import useVuelidate from "@vuelidate/core";
import useRequestInvite from "@/composables/useRequestInvite";
import OrDivider from "@/components/OrDivider.vue";
import LoggedOutMiniCv from "@/components/LoggedOutMiniCv.vue";
import BoardexLookupModal from "./BoardexLookupModal.vue";

export default {
  name: "RequestInvitation",
  components: {
    RequestInvitationSuccess,
    UploadCv,
    OrDivider,
    LoggedOutMiniCv,
    BoardexLookupModal,
  },
  setup() {
    const {
      name,
      email,
      howDidYouHear,
      anythingElse,
      submitForm,
      roles,
      cv,
      updateRoles,
      suggestedBoardexIndividual,
      confirmBoardexLookup,
      rejectBoardexLookup,
      getDataFromLocalStorage,
    } = useRequestInvite();

    return {
      name,
      email,
      roles,
      cv,
      howDidYouHear,
      anythingElse,
      submitForm,
      updateRoles,
      suggestedBoardexIndividual,
      confirmBoardexLookup,
      rejectBoardexLookup,
      getDataFromLocalStorage,
      v$: useVuelidate(),
    };
  },

  validations() {
    return {
      name: {
        required,
        mustHaveFirstAndLastName: value => {
          const splitName = value.split(" ").filter((name: string) => !!name);
          return splitName.length > 1;
        },
      },
      email: {
        required,
        email,
      },
      roles: {
        required,
        minLength: minLength(3),
      },
    };
  },

  data() {
    return {
      submitSuccess: false,
      isLoading: false,
      hasExperienceError: false,
      showValidation: false,
    };
  },

  async created() {
    this.isLoading = true;
    await this.getDataFromLocalStorage();
    this.isLoading = false;
  },

  methods: {
    async submit() {
      if (await this.isFormValid()) {
        try {
          await this.submitForm();
          this.submitSuccess = true;
          await this.scrollToSuccess();
        } catch {
          this.submitSuccess = false;
        }
      } else {
        this.scrollToFirstError();
        this.submitSuccess = false;
      }
    },

    async isFormValid() {
      this.showValidation = true;
      this.v$.$touch();
      await this.$nextTick();
      this.validateExperience();

      const hasNameError = this.v$.name.$invalid;
      const hasEmailError = this.v$.email.$invalid;
      const hasMissingRoleInformation =
        document.getElementsByClassName("request-error").length > 0;

      return !(
        hasNameError ||
        hasEmailError ||
        hasMissingRoleInformation ||
        this.hasExperienceError
      );
    },

    scrollToFirstError() {
      const cvErrors = document.getElementsByClassName("error-message");
      const roleErrors = document.getElementsByClassName("request-error");
      const [firstErrorElement] = [...cvErrors, ...roleErrors];

      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    },

    async scrollToSuccess() {
      await this.$nextTick();
      const successMessageElement = this.$refs["requestSuccess"];

      successMessageElement?.$el.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    },

    validateExperience() {
      const hasRoleError =
        this.v$.roles.$invalid ||
        this.v$.roles.$error ||
        this.v$.roles.minLength.$invalid;

      const missingCv = !this.cv;

      this.hasExperienceError = missingCv && hasRoleError;
    },

    async onCvChange(updatedCv) {
      this.cv = updatedCv;
    },
  },
};
</script>
