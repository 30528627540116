<template>
  <div class="rounded-xl">
    <h3 class="pb-3 border-b border-gray-900"><slot></slot></h3>
    <div class="mt-2">
      <profile-role
        v-for="role in displayedRoles"
        :key="role.id"
        :role="role"
      ></profile-role>
    </div>
    <div class="flex justify-center">
      <button
        v-if="!showAll"
        @click="showAll = true"
        class="button-secondary text-cta-secondary flex gap-2 items-center mt-6"
      >
        <span>Show all roles</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
        >
          <path
            d="M5.44 5.53L8.5 8.58333L11.56 5.53L12.5 6.47L8.5 10.47L4.5 6.47L5.44 5.53Z"
            fill="#3C6955"
          />
        </svg>
      </button>
      <button
        v-if="showAll && roles.length > 4"
        @click="showAll = false"
        class="button-secondary text-cta-secondary flex gap-2 items-center mt-6"
      >
        <span>Show fewer roles</span>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.41669 10.0002L8.00336 7.41351L10.59 10.0002C10.85 10.2602 11.27 10.2602 11.53 10.0002C11.79 9.74018 11.79 9.32018 11.53 9.06018L8.47002 6.00018C8.21002 5.74018 7.79002 5.74018 7.53002 6.00018L4.47002 9.06018C4.21002 9.32018 4.21002 9.74018 4.47002 10.0002C4.73002 10.2535 5.15669 10.2602 5.41669 10.0002Z"
            fill="#3C6955"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import ProfileRole from "./ProfileRole.vue";
import { RoleHeld } from "@/composables/useMiniCv";

const props = defineProps<{
  roles: RoleHeld[];
}>();

const rolesDisplayCount = 4;

const showAll = ref(props.roles.length <= rolesDisplayCount);

const displayedRoles = computed(() =>
  showAll.value ? props.roles : props.roles.slice(0, rolesDisplayCount)
);
</script>
