<template>
  <base-modal :isOpen="true" :is-small="true">
    <base-modal-header @close="$emit('close-modal')">
      Upload CV
    </base-modal-header>
    <upload-cv @cv-change="onCvChange" />
  </base-modal>
</template>

<script setup lang="ts">
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseModalHeader from "@/components/shared/BaseModalHeader.vue";
import UploadCv from "@/components/cv/UploadCv.vue";
import useCvs from "@/composables/useCvs";

const { getIndividualCvs } = useCvs();
const emits = defineEmits(["close-modal"]);

const onCvChange = async () => {
  emits("close-modal");
  await getIndividualCvs();
};
</script>
