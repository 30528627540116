<template>
  <page-with-title-layout>
    <div class="px-4" data-cy="role-apply-success">
      <role-apply-feedback-form
        :feedback="feedback"
        @submit-form="submitForm"
        @save-changes="handleChanges"
        @leave-form="redirectToRoles"
      />
    </div>
  </page-with-title-layout>
</template>

<script lang="ts">
import PageWithTitleLayout from "@/components/PageWithTitleLayout.vue";
import RoleApplyFeedbackForm from "@/components/roleApplication/RoleApplyFeedbackForm.vue";
import * as Sentry from "@sentry/browser";
import { apiAxios } from "@/lib/axios";

interface Feedback {
  timeTaken: string | null;
  selfRepresentationScore: number | null;
  comments: string | null;
  smileValue: string | null;
  feedbackReceived?: number | null;
}

export default {
  name: "RoleApplicationFeedback",
  components: {
    PageWithTitleLayout,
    RoleApplyFeedbackForm,
  },
  props: {
    roleId: {
      type: String,
      required: true,
    },
    roleApplicationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      feedback: {
        timeTaken: null,
        selfRepresentationScore: null,
        comments: "",
        smileValue: null,
        feedbackReceived: null, // CSAT smiley face score from 0 to 4
      },
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    async handleChanges(feedback: Partial<Feedback>) {
      this.feedback = {
        ...this.feedback,
        ...feedback,
      };

      if (feedback.smileValue) {
        this.feedback.feedbackReceived = [
          "hate",
          "dislike",
          "neutral",
          "like",
          "love",
        ].indexOf(feedback.smileValue);
      }

      if (feedback.comments) return;

      await this.saveChanges();
    },

    async saveChanges() {
      window.analytics.track("Role application feedback", {
        roleId: this.roleId,
        feedback: this.feedback,
      });

      try {
        await apiAxios.post("/application_feedback", {
          location: "application_submitted",
          feedback_received: this.feedback.feedbackReceived, // scale from 0 to
          role_application_id: this.roleApplicationId,
          details: this.feedback.comments,
          time_taken: this.feedback.timeTaken,
          self_representation_score: this.feedback.selfRepresentationScore, // TODO: THIS IS NEW!!!!!! Make sure goes through api and rendered on admin
        });
      } catch (error) {
        Sentry.captureException(error);
      }
    },

    async submitForm() {
      await this.saveChanges();
    },
    redirectToRoles() {
      window.location.replace("/roles");
    },
  },
};
</script>
