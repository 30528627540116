<template>
  <div
    class="flex px-4 py-4 items-start gap-3"
    :class="{
      'bg-ivory-100': variant === 'warning',
      'bg-sage-50': variant === 'success',
      'bg-grey-75': variant === 'info',
    }"
  >
    <span
      class="material-icons-round rounded-full p-2 w-8 h-8"
      style="font-size: 16px"
      :class="{
        'bg-orange-700': variant === 'warning',
        'bg-sage-700': variant === 'success',
        'bg-grey-200': variant === 'info',
        'text-grey-900': variant === 'info',
        'text-ivory-100 ': variant !== 'info',
      }"
      >{{ icon }}</span
    >
    <div
      class="self-stretch text-grey-900 text-md font-semibold leading-[156%] tracking-[0.32px] mt-1"
    >
      {{ message }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { GuidanceIndicatorProps } from "@/helpers/guidanceIndicator";
import { computed } from "vue";

const props = defineProps<GuidanceIndicatorProps>();

const icon = computed(() => {
  return {
    info: "info",
    warning: "warning",
    success: "thumb_up_alt",
  }[props.variant];
});
</script>
