<script setup lang="ts">
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { ref, watch } from "vue";

const props = defineProps(["disabled", "dateValue", "minDate"]);
const emits = defineEmits(["update:dateValue"]);

const dateSelected = ref(props.dateValue);

if (
  (!dateSelected.value.year && dateSelected.value.month === undefined) ||
  (dateSelected.value.year === null && dateSelected.value.month === null)
) {
  dateSelected.value = null;
}

const textInputOptions = {
  enterSubmit: true,
  tabSubmit: true,
  openMenu: true,
  selectOnFocus: true,
  format: "MM/yyyy",
};

watch(
  () => props.disabled,
  newValue => {
    if (newValue) {
      dateSelected.value = null;
    }
  }
);

watch(
  () => dateSelected.value,
  newValue => {
    if (props.disabled) return;
    emits("update:dateValue", newValue);
  }
);
</script>

<template>
  <vue-date-picker
    v-model="dateSelected"
    month-picker
    placeholder="MM/YYYY"
    :text-input="textInputOptions"
    :format="'MM/yyyy'"
    :disabled="props.disabled"
    :clearable="true"
    :auto-apply="true"
    :hide-input-icon="true"
    :input-class-name="'text-input'"
    :calendar-cell-class-name="'calendar-selection'"
    :prevent-min-max-navigation="true"
    :min-date="props.minDate"
  >
    <template #calendar-icon>
      <p class="calendar-icon-text">Back to month view</p>
    </template>
  </vue-date-picker>
</template>

<style>
.calendar-icon-text {
  color: var(--sage-700, #1e4b37);
  text-align: center;
  font-family: "Helvetica Neue";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625rem; /* 185.714% */
}

.dp__theme_light {
  --dp-primary-color: var(--sage-600, #3c6955);
}

.dp__inner_nav {
  border-radius: 0.25rem;
}

.dp--year-mode-picker {
  gap: 0.5rem;
  padding: 0 0.25rem 0.25rem 0.25rem;
}

.dp--year-select {
  color: var(--sage-700, #1e4b37);
}

.dp--year-select:hover {
  color: var(--sage-700, #1e4b37);
}

.dp__selection_grid_header {
  border-bottom-width: 1px;
}

.dp__button_bottom {
  background-color: #f7f7f7 !important;
}

.dp__button_bottom:hover {
  background-color: var(--sage-50, #f5faf7) !important;
}

:root {
  --dp-month-year-row-button-size: 2.5rem;
  --dp-month-year-row-height: 2.5rem;
  --dp-cell-size: 2.5rem;
  --dp-button-height: 2.5rem;
  --dp-border-radius: 0.25rem;
}
</style>
