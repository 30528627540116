<template>
  <div v-if="isImpersonating" class="bg-red-50" data-impersonation-banner>
    <div class="flex items-center py-3 px-6 xl:px-0 max-w-screen-xl mx-auto">
      <div class="copy-bold text-red-700 flex-1">
        You are currently impersonating {{ name }}
      </div>
      <button
        class="text-cta-secondary button-error-secondary"
        @click="stopImpersonating"
        :disabled="isStoppingImpersonation"
      >
        Stop impersonating
      </button>
    </div>
  </div>
</template>

<script>
import { authAxios } from "@/lib/axios";
export default {
  name: "ImpersonationBanner",
  props: ["individual"],
  data() {
    return {
      isImpersonating: false,
      isStoppingImpersonation: false,
    };
  },
  async created() {
    if (!window.Cypress) {
      try {
        const { data } = await authAxios.get("/status");
        if (data.impersonating_login_id) {
          this.isImpersonating = true;
        }
      } catch (error) {
        this.isImpersonating = false;
      }
    }
  },
  methods: {
    async stopImpersonating() {
      this.isStoppingImpersonation = true;
      await authAxios.post("/stop-impersonating");
      this.isStoppingImpersonation = false;
      window.location.reload();
    },
  },
  computed: {
    name() {
      return this.individual
        ? `${this.individual.first_name} ${this.individual.last_name}`
        : "";
    },
  },
};
</script>
