<template>
  <h4 class="mt-4 md:mt-8 copy-emphasis block">Profile visibility</h4>
  <div v-if="isLoaded" class="mt-4">
    <div class="bg-blue-50 p-6 rounded-lg" v-if="menuOpen">
      <h3 class="copy-emphasis">Expand your profile visibility</h3>
      <p class="mt-1">
        The Nurole team can view your profile to assess your fit for roles.
        Would you like to also allow
      </p>
      <profile-privacy-options />
      <button
        class="button-primary text-cta-primary mt-4"
        @click="menuOpen = false"
      >
        Save
      </button>
    </div>
    <div
      class="bg-claret-700 p-8 rounded-lg"
      v-else-if="individual.profile_privacy == 'nurole'"
    >
      <div
        class="flex flex-col md:flex-row gap-4 items-start text-white w-full"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
        >
          <path
            d="M27.5003 18.3337C28.667 18.3337 29.7837 18.5003 30.8337 18.817V16.667C30.8337 14.8337 29.3337 13.3337 27.5003 13.3337H25.8337V10.0003C25.8337 5.40033 22.1003 1.66699 17.5003 1.66699C12.9003 1.66699 9.16699 5.40033 9.16699 10.0003V13.3337H7.50033C5.66699 13.3337 4.16699 14.8337 4.16699 16.667V33.3337C4.16699 35.167 5.66699 36.667 7.50033 36.667H17.9337C16.617 34.7837 15.8337 32.4837 15.8337 30.0003C15.8337 23.5503 21.0503 18.3337 27.5003 18.3337ZM12.3337 10.0003C12.3337 7.15032 14.6503 4.83366 17.5003 4.83366C20.3503 4.83366 22.667 7.15032 22.667 10.0003V13.3337H12.3337V10.0003Z"
            fill="#FFFAF0"
          />
          <path
            d="M27.5003 21.667C22.9003 21.667 19.167 25.4003 19.167 30.0003C19.167 34.6003 22.9003 38.3337 27.5003 38.3337C32.1003 38.3337 35.8337 34.6003 35.8337 30.0003C35.8337 25.4003 32.1003 21.667 27.5003 21.667ZM27.5003 25.0003C28.8837 25.0003 30.0003 26.117 30.0003 27.5003C30.0003 28.8837 28.8837 30.0003 27.5003 30.0003C26.117 30.0003 25.0003 28.8837 25.0003 27.5003C25.0003 26.117 26.117 25.0003 27.5003 25.0003ZM27.5003 35.0003C25.7837 35.0003 24.267 34.1337 23.367 32.8003C24.5837 32.1003 25.9837 31.667 27.5003 31.667C29.017 31.667 30.417 32.1003 31.6337 32.8003C30.7337 34.1337 29.217 35.0003 27.5003 35.0003Z"
            fill="#FFFAF0"
          />
        </svg>
        <div class="w-full">
          <h3 class="copy-emphasis">Hiring boards can't view your profile</h3>
          <p class="mt-1">
            They can only see your profile after you have applied for a role.
            This may limit your access to opportunities.
          </p>
          <button
            class="mt-4 text-cta-secondary button-secondary text-white border-white hover:bg-transparent w-full md:w-auto"
            @click="allowBoardsToFindMe"
          >
            Allow boards to find me
          </button>
        </div>
      </div>
    </div>
    <div
      class="bg-gray-75 flex flex-col md:flex-row px-4 py-4 md:px-4 md:py-2 rounded-lg justify-between gap-4 md:gap-2"
      v-else
    >
      <div class="flex flex-col md:flex-row gap-2 md:p-4">
        <div v-if="individual.profile_privacy == 'clients'">
          <span class="copy-bold"
            >Your profile is visible to hiring boards</span
          >
          <p class="copy my-1">
            Nurole can share your profile with relevant boards
          </p>
        </div>

        <div v-if="individual.profile_privacy == 'public'">
          <span class="copy-bold">Your profile is public</span>
          <p class="copy my-1">
            Anyone with the link can view your Nurole profile
          </p>
        </div>
      </div>
      <button
        class="text-cta-tertiary uppercase text-sage-600 flex items-center gap-2 md:m-4"
        @click="menuOpen = true"
      >
        Manage visibility
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M4.94 5.52979L8 8.58312L11.06 5.52979L12 6.46979L8 10.4698L4 6.46979L4.94 5.52979Z"
            fill="#3C6955"
          />
        </svg>
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import ProfilePrivacyOptions from "./ProfilePrivacyOptions.vue";
import {
  isLoaded,
  individual,
  updateIndividual,
} from "@/composables/useIndividual";
import { ref } from "vue";
const menuOpen = ref(false);

function allowBoardsToFindMe() {
  window.analytics.track("Allow boards to find me clicked");
  updateIndividual({ profile_privacy: "clients" }, "profile_privacy");
  menuOpen.value = true;
}
</script>
