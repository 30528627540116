<template>
  <div
    class="py-8 px-6 flex flex-col gap-6 text-center items-center bg-white md:py-16 md:px-28"
  >
    <img src="@/assets/empty-search.svg" class="md:w-auto w-28 md:mr-12" />
    <div class="flex flex-col gap-2">
      <p class="copy-emphasis">
        {{ title }}
      </p>
      <p class="">
        {{ message }}
      </p>
    </div>
    <div
      class="flex flex-col gap-6 w-full md:flex-row md:items-center md:justify-center"
    >
      <a
        v-if="showContactSupport"
        class="button-secondary text-cta-secondary"
        href="/contact"
        target="_blank"
        rel="noopener"
      >
        Contact support
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { useRoute } from "vue-router";

export default {
  setup() {
    const route = useRoute();
    if (route.query.preview) {
      window.analytics.track("PreviewUnavailable", {
        role_id: route.query.preview,
      });
      return {
        title: "Preview currently unavailable",
        message:
          "We're still getting this preview ready to share. If we shared this with you, please let us know you still need access.",
      };
    } else {
      return {
        title: "We're still trying to find an exact match for your search.",
        message: "Please could you try searching again or contact us",
      };
    }
  },
};
</script>
