<template>
  <div class="flex gap-x-3 w-full justify-center font-semibold">
    <div
      class="flex p-2 px-4 bg-ivory-300 rounded-full cursor-pointer hover:bg-ivory-400"
      @click="scrollToSection('experience')"
    >
      Professional experience
    </div>
    <div
      class="flex p-2 px-4 bg-ivory-300 rounded-full cursor-pointer hover:bg-ivory-400"
      @click="scrollToSection('highlights')"
    >
      Career highlights
    </div>
    <div
      class="flex p-2 px-4 bg-ivory-300 rounded-full cursor-pointer hover:bg-ivory-400"
      @click="scrollToSection('education')"
    >
      Education
    </div>
    <div
      class="flex p-2 px-4 bg-ivory-300 rounded-full cursor-pointer hover:bg-ivory-400"
      @click="scrollToSection('qualifications')"
    >
      Qualifications
    </div>
    <div
      class="flex p-2 px-4 bg-ivory-300 rounded-full cursor-pointer hover:bg-ivory-400"
      @click="scrollToSection('info')"
    >
      Additional information
    </div>
  </div>
</template>

<script setup>
const emit = defineEmits(["scroll-to-section"]);

const scrollToSection = section => {
  emit("scroll-to-section", section);
};
</script>
