<template>
  <div
    class="h-2 w-full rounded-full bg-neutral relative overflow-hidden mb-8 -mt-6"
  >
    <div
      ref="progressBar"
      class="bg-sage-600 absolute top-0 left-0 bottom-0 rounded-full transition duration-500"
      style="width: 0%"
    ></div>
  </div>
  <template v-if="currentStep == STEP_ONE">
    <quick-apply-profile-and-cv @next="next" :role="role" />
  </template>
  <template v-else-if="currentStep == STEP_TWO">
    <quick-apply-cover-letter @prev="prev" :role="role" />
  </template>
</template>

<script lang="ts" setup>
import QuickApplyProfileAndCv from "./QuickApplyProfileAndCv.vue";
import QuickApplyCoverLetter from "./QuickApplyCoverLetter.vue";
import { ref, onMounted, Ref, nextTick, onBeforeUnmount } from "vue";
import { clearQuickApplication, loadQuickApplication } from "@/lib/quickApply";

const props = defineProps(["role"]);
const emit = defineEmits(["navigate"]);

const STEP_ONE = "Profile and CV";
const STEP_TWO = "Cover letter";
const currentStep = ref(STEP_ONE);
const progressBar: Ref<HTMLElement | null> = ref(null);

onMounted(async () => {
  await nextTick();
  progressBar.value?.setAttribute("style", "width: 50%;");

  await loadQuickApplication(props.role.id);
});

onBeforeUnmount(() => {
  clearQuickApplication();
});

function next() {
  currentStep.value = STEP_TWO;
  progressBar.value?.setAttribute("style", "width: 100%;");
  emit("navigate", STEP_TWO);
}

function prev() {
  currentStep.value = STEP_ONE;
  progressBar.value?.setAttribute("style", "width: 50%;");
  emit("navigate", STEP_ONE);
}
</script>
