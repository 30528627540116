<template>
  <div
    class="flex flex-col sm:flex-row bg-blue-50 rounded-lg p-6 items-start grow-0"
  >
    <span
      class="material-icons-outlined text-blue-800 text-3xl pr-5 pb-2 pt-1 hidden sm:inline sm:pb-0"
    >
      {{ icon }}
    </span>
    <div>
      <p v-if="isTitleEmphasis" class="copy-emphasis m-0">
        {{ title }}
      </p>
      <p v-else class="copy-bold m-0">
        {{ title }}
      </p>
      <p class="m-0 mt-1">
        <slot> </slot>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "InformativeBanner",

  props: {
    icon: { default: "check_circle_outline" },

    title: {
      type: String,
      default: "NOTE",
    },

    isTitleEmphasis: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
