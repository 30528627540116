export default function plausibleBirthYears() {
  const years: string[] = [];

  let startYear = new Date().getFullYear() - 120;
  const endYear = new Date().getFullYear() - 18;

  for (let i = startYear; i <= endYear; i++) {
    years.push(startYear.toString());
    startYear++;
  }

  return years;
}
