import { RichTextEditorContext } from "./richTextEditor";

export type GuidanceIndicatorProps = {
  variant: "info" | "warning" | "success";
  message: string;
};

export const getRFAGuidance = (
  editorContext: RichTextEditorContext,
  textLength = 500
): GuidanceIndicatorProps => {
  const limit = textLength / 5;
  const { wordCount, warnIfBelowMinimum } = editorContext;
  if (!wordCount) {
    return getRFAGuidanceIndicator("default", limit);
  }
  if (wordCount < limit * 0.6) {
    return warnIfBelowMinimum
      ? getRFAGuidanceIndicator("below")
      : getRFAGuidanceIndicator("default", limit);
  }
  if (wordCount > limit * 2) {
    return getRFAGuidanceIndicator("over");
  }
  return getRFAGuidanceIndicator("within");
};

const getRFAGuidanceIndicator = (
  state: "default" | "below" | "over" | "within",
  limit?: number
): GuidanceIndicatorProps => {
  switch (state) {
    case "below":
      return {
        message: `It looks like your response is fairly brief - a very short answer may reduce your chance of progressing.`,
        variant: "warning",
      };
    case "over":
      return {
        message: `You’ve exceeded our recommended response length. We’d recommend you keep your answer succinct – significantly longer responses may reduce your chances of progressing.`,
        variant: "warning",
      };
    case "within":
      return {
        message: `We find that this is a good response length. If you’d like to write more then feel free to do so.`,
        variant: "success",
      };
    case "default":
    default:
      return {
        message: `We’d advise you to write around ${limit} words.`,
        variant: "info",
      };
  }
};
