// Useful links:
// https://github.com/microsoft/clarity/tree/master/packages/clarity-js
// https://learn.microsoft.com/en-us/clarity/setup-and-installation/clarity-api
import { clarity } from "clarity-js";
clarity.consent();
clarity.start({
  projectId: process.env.VITE_CLARITY_PROJECT_ID,
  upload: "https://m.clarity.ms/collect",
  track: true,
  content: true,
});
clarity.upgrade("Always");
