<template>
  <div class="flex w-full sm:w-auto">
    <div class="my-3 flex bg-blue-100 rounded-l-lg">
      <span
        class="material-icons-round my-auto text-xl text-blue-700 mx-2 justify-self-start"
      >
        info_outline
      </span>
    </div>
    <div
      class="sm:flex my-4 pl-4 rounded-r-lg bg-white w-full justify-between sm:pl-0"
    >
      <p
        class="mt-2 sm:my-auto text-lg font-normal mb-0 sm:pl-4"
        data-cy="preferences-nudge-banner-text"
      >
        Not interested in this role?
      </p>
      <button
        data-cy="preferences-nudge-banner-button"
        class="text-cta-tertiary text-sage-600 sm:px-6 pl-0 py-3 text-left sm:whitespace-nowrap hover:underline hover:bg-blue-50 active:bg-blue-100 flex sm:block rounded-lg sm:pl-4 sm:rounded-none"
        @click="goToPreferences()"
      >
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { RoleType } from "@/lib/RoleTyper";
import { PropType } from "vue";
import { RoleNudgingHelper } from "@/lib/RoleNudgingHelper";
export default {
  name: "RoleNudgingBanner",
  props: {
    roleTypes: {
      type: Array as PropType<RoleType[]>,
      required: true,
    },
    roleId: {
      type: String,
      required: true,
    },
  },
  computed: {
    buttonText() {
      const roleNudgingHelper = new RoleNudgingHelper();
      return roleNudgingHelper.determineNudgingButtonText(this.roleTypes);
    },
  },
  methods: {
    goToPreferences() {
      const roleNudgingHelper = new RoleNudgingHelper();
      roleNudgingHelper.setNudgingAnalytics(
        this.roleTypes,
        undefined,
        this.roleId
      );
      return roleNudgingHelper.navigateToPreferences(this.roleTypes);
    },
  },
};
</script>
