<template>
  <button
    class="flex items-center rounded-full cursor-pointer border-none bg-none focus:outline-none focus:ring focus:ring-orange-500 p-0"
    @click.stop="toggle"
  >
    <input
      tabindex="-1"
      type="checkbox"
      class="opacity-0 w-0"
      :checked="checked"
    />
    <span class="material-icons-round text-grey-700 text-icon" v-if="!checked">
      check_box_outline_blank
    </span>
    <span class="material-icons-round text-sage-600 text-icon" v-else>
      check_box
    </span>
    <span class="text-labelling-inactive text-grey-700 ml-3">
      {{ label }}
    </span>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseCheckbox",

  props: {
    label: { type: String },
    checked: { type: Boolean, default: false },
  },

  methods: {
    toggle() {
      this.$emit("value", !this.checked);
    },
  },
});
</script>
