export class DiversityTooltipRejectionMessages {
  public static readonly GENDER =
    "Our client welcomes applications from candidates with the requisite interests, skills and experience. As gender diversity is currently under-represented on the Board, applications from these individuals would be particularly encouraged.\n\n Since you have not self-identified as being female, we have not proactively notified you about this role.\n\n However, if you feel you are a strong match then please do submit an application.\n";
  public static readonly ETHNICITY =
    "Our client welcomes applications from candidates with the requisite interests, skills and experience. As ethnic diversity is currently under-represented on the Board, applications from these individuals would be particularly encouraged.\n\n Since you have not identified as coming from an ethnic minority background, we have not proactively notified you about this role.\n\n However, if you feel you are a strong match then please do submit an application.\n";
  public static readonly COMBINED =
    "Our client welcomes applications from candidates with the requisite interests, skills and experience. As gender and ethnic diversity are currently under-represented on the Board, applications from these individuals would be particularly encouraged.\n\n Since you have not self-identified as being female or not identified as coming from an ethnic minority background, we have not proactively notified you about this role.\n\n However, if you feel you are a strong match then please do submit an application.\n";
}

export class DiversityTooltipMoreInfoMessages {
  public static readonly GENDER =
    "Our client welcomes applications from candidates with the requisite interests, skills and experience. As gender diversity is currently under-represented on the Board, applications from these individuals would be particularly encouraged.\n\n Since you have not self-identified as being female, we have not proactively notified you about this role.\n\n However, if you feel you are a strong match then please do submit an application.\n\n Alternatively, if any of this information is incorrect, please update your account information.\n";
  public static readonly ETHNICITY =
    "Our client welcomes applications from candidates with the requisite interests, skills and experience. As ethnic diversity is currently under-represented on the Board, applications from these individuals would be particularly encouraged.\n\n Since you have not identified as coming from an ethnic minority background, we have not proactively notified you about this role.\n\n However, if you feel you are a strong match then please do submit an application.\n\n Alternatively, if any of this information is incorrect, please update your account information.\n";
  public static readonly COMBINED =
    "Our client welcomes applications from candidates with the requisite interests, skills and experience. As gender and ethnic diversity are currently under-represented on the Board, applications from these individuals would be particularly encouraged.\n\n Since you have not self-identified as being female or not identified as coming from an ethnic minority background, we have not proactively notified you about this role.\n\n However, if you feel you are a strong match then please do submit an application.\n\n Alternatively, if any of this information is incorrect, please update your account information.\n";
}

export class DiversityBannerText {
  public static readonly GENDER =
    "Applicants who bring gender diversity particularly encouraged";
  public static readonly ETHNICITY =
    "Applicants who bring ethnic diversity particularly encouraged";
  public static readonly COMBINED =
    "Applicants who bring gender and ethnic diversity particularly encouraged";
}
