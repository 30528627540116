import { ref } from "vue";

const _cvParsingStatus = ref<string | null | undefined>(undefined);
const _cv = ref<any>(undefined);

const getCv = () => {
  if (_cv.value === undefined) {
    const cvFromLocalStorage = localStorage.getItem("cvToParse");
    if (cvFromLocalStorage) {
      _cv.value = JSON.parse(cvFromLocalStorage);
    }
  }
  return _cv.value;
};

const setCv = updatedCv => {
  _cv.value = updatedCv;
  setCvParsingStatus(updatedCv == null ? "cancelled" : "in_progress");
  localStorage.setItem("cvToParse", JSON.stringify(updatedCv));
};

const setCvParsingStatus = (updatedCvParsingStatus: string) => {
  _cvParsingStatus.value = updatedCvParsingStatus;
  localStorage.setItem("cvParsingStatus", _cvParsingStatus.value);
};

const getCvParsingStatus = () => {
  if (_cvParsingStatus.value === undefined) {
    const cvParsingStatusFromLocalStorage =
      localStorage.getItem("cvParsingStatus");
    if (cvParsingStatusFromLocalStorage) {
      _cvParsingStatus.value = cvParsingStatusFromLocalStorage;
    }
  }
  return _cvParsingStatus.value;
};

export default function useCvParsing() {
  return {
    getCv,
    setCv,
    getCvParsingStatus,
    setCvParsingStatus,
  };
}
