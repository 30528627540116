import { apiAxios } from "@/lib/axios";
import { RequestQueue } from "@/utils/RequestQueue";
import { ref, readonly, watch } from "vue";
import useMiniCv from "@/composables/useMiniCv";
const { rolesHeld, isLoaded: rolesLoaded } = useMiniCv();

const pins = ref<Set<string>>(new Set());
const pinRequestQueue = new RequestQueue();

const refreshPins = () => {
  pins.value = new Set(
    rolesHeld.value?.filter((r: any) => r.pinned).map((r: any) => r.id)
  );
};

refreshPins();

watch(rolesLoaded, loaded => {
  if (loaded) {
    refreshPins();
  }
});

async function pinRole(id: string) {
  return new Promise<void>((resolve, reject) => {
    pinRequestQueue.enqueue({
      apiCall: () => {
        return apiAxios.post(`roles-held/${id}/pin`);
      },
      onSuccess: () => {
        pins.value.add(id);
        resolve();
      },
      onError: (error: any) => {
        reject(error);
      },
    });
  });
}

async function unpinRole(id: string) {
  return new Promise<void>((resolve, reject) => {
    pinRequestQueue.enqueue({
      apiCall: () => {
        return apiAxios.delete(`roles-held/${id}/pin`);
      },
      onSuccess: () => {
        pins.value.delete(id);
        resolve();
      },
      onError: (error: any) => {
        reject(error);
      },
    });
  });
}

export default function useRoleHeldPins() {
  return {
    pins: readonly(pins),
    pinRole,
    unpinRole,
  };
}
