<template>
  <div class="flex flex-col">
    <div class="bg-sage-600 w-full relative">
      <div class="w-full max-w-screen-xl mx-auto p-4 xl:px-0 md:p-9 xl:pb-24">
        <h1 class="text-title-h1 text-white m-0">{{ title }}</h1>
      </div>
      <div class="flex-1 bg-ivory-100 xl:pb-16">
        <div
          class="bg-white w-full relative lg:max-w-screen-xl xl:mx-auto xl:rounded-lg xl:-top-16"
        >
          <div
            class="w-full max-w-screen-xl mx-auto md:px-4 py-6 pb-14 md:pt-12 md:pb-20 xl:px-28"
          >
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "PageWithTitleLayout",
  props: ["title"],
};
</script>
