<template>
  <div class="wrap mx-5 sm:mx-12 lg:mx-28 my-4 md:my-10 flex flex-col gap-2">
    <h2 class="text-title-h2 my-0">
      {{ notifiable ? "Recommended for you" : "Other roles" }}
    </h2>
    <template v-if="notifiable">
      <p v-if="!zeroNotifiableRoles" class="text-grey-700 my-0">
        <span> Based on your </span>
        <a class="text-cta-tertiary" @click="openInNewTab('preferences')">
          preferences <span class="material-icons-round"> open_in_new </span>
        </a>
        <span> and </span>
        <a class="text-cta-tertiary" @click="openInNewTab('experience')">
          professional experience
          <span class="material-icons-round"> open_in_new </span>
        </a>
        <span
          >, we think the following roles will be interesting and appropriate
          for you.</span
        >
      </p>
    </template>
    <p v-else class="text-grey-700 my-0">
      We don’t see a strong correlation between these positions and your current
      experience and preferences, but please feel free to take a look.
    </p>
  </div>
</template>

<script lang="ts">
export default {
  name: "RolesListSectionHeader",
  props: {
    notifiable: {
      required: true,
      type: Boolean,
    },
    zeroNotifiableRoles: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openInNewTab: (source: string) => {
      if (source === "experience") {
        window.analytics.track(
          "Professional experience link clicked at top of recommended roles section"
        );
        window.open("/account/profile", "_blank");
      } else if (source === "preferences") {
        window.analytics.track(
          "Preferences link clicked at top of recommended roles section"
        );
        window.open("/reconfigure", "_blank");
      }
    },
  },
};
</script>

<style scoped lang="scss">
span {
  padding-right: 5px;
}

.wrap {
  @media (min-width: 1350px) {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  p {
    a {
      text-transform: uppercase;
      display: inline-flex;
      align-items: center;
      gap: 3px;
      padding-right: 5px;
      font-size: 16px !important;

      span {
        font-size: 16px;
        padding: 0px;
      }
    }
  }
}
</style>
