<template>
  <div :class="{ hidden: !isOnRoot }" class="md:block">
    <div class="p-4 bg-white md:hidden">
      <h3 class="text-title-h4">My Preferences</h3>
    </div>
  </div>
  <router-link
    to="/account/preferences"
    class="flex justify-between bg-white p-4 text-cta-secondary text-black border-b md:hidden sticky top-14 z-10"
    v-if="!isOnRoot"
  >
    <div class="flex gap-4">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M18.7915 11.0046H7.62148L12.5015 6.12461C12.8915 5.73461 12.8915 5.09461 12.5015 4.70461C12.1115 4.31461 11.4815 4.31461 11.0915 4.70461L4.50148 11.2946C4.11148 11.6846 4.11148 12.3146 4.50148 12.7046L11.0915 19.2946C11.4815 19.6846 12.1115 19.6846 12.5015 19.2946C12.8915 18.9046 12.8915 18.2746 12.5015 17.8846L7.62148 13.0046H18.7915C19.3415 13.0046 19.7915 12.5546 19.7915 12.0046C19.7915 11.4546 19.3415 11.0046 18.7915 11.0046Z"
          fill="#1E4B37"
        />
      </svg>
      <span>
        {{ sections[route.path]?.name }}
      </span>
    </div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18.3002 5.70973C17.9102 5.31973 17.2802 5.31973 16.8902 5.70973L12.0002 10.5897L7.11022 5.69973C6.72022 5.30973 6.09021 5.30973 5.70021 5.69973C5.31021 6.08973 5.31021 6.71973 5.70021 7.10973L10.5902 11.9997L5.70021 16.8897C5.31021 17.2797 5.31021 17.9097 5.70021 18.2997C6.09021 18.6897 6.72022 18.6897 7.11022 18.2997L12.0002 13.4097L16.8902 18.2997C17.2802 18.6897 17.9102 18.6897 18.3002 18.2997C18.6902 17.9097 18.6902 17.2797 18.3002 16.8897L13.4102 11.9997L18.3002 7.10973C18.6802 6.72973 18.6802 6.08973 18.3002 5.70973Z"
        fill="#1E4B37"
      />
    </svg>
  </router-link>
  <div
    class="md:mt-6 md:flex md:gap-6 lg:gap-10 bg-white md:bg-ivory-100 max-w-screen-2xl mx-auto md:mb-6"
  >
    <div
      :class="{ hidden: !isOnRoot }"
      class="md:block md:sticky md:top-[160px] md:h-full"
    >
      <nav>
        <ul v-for="(section, path) in sections" :key="path">
          <router-link
            :to="path"
            class="text-black"
            :class="{
              ' text-white': $route.path === path,
            }"
          >
            <li
              class="copy border-b p-4 flex justify-between rounded-lg"
              :class="{
                'bg-sage-700 text-cta-invert copy-bold': $route.path === path,
                'hover:bg-ivory-300': $route.path !== path,
              }"
            >
              <div class="flex gap-4">
                <span class="material-icons-round">
                  {{ section.icon }}
                </span>
                <span class="whitespace-nowrap">
                  {{ section.name }}
                </span>
              </div>
              <svg
                class="md:hidden"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M8.29492 16.59L12.8749 12L8.29492 7.41L9.70492 6L15.7049 12L9.70492 18L8.29492 16.59Z"
                  fill="#242726"
                />
              </svg>
            </li>
          </router-link>
        </ul>
      </nav>
    </div>
    <div
      class="bg-white md:rounded md:shadow md:rounded p-4 md:py-0 md:px-10 lg:px-20 max-w-screen-xl w-full"
      v-if="individualLoaded"
    >
      <router-view></router-view>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import router from "@/lib/theVueRouter";
import useIndividual from "@/composables/useIndividual";
import { useSideNavUtils } from "@/utils/useSideNav";

const { getIndividual, isLoaded } = useIndividual();
const individualLoaded = ref(false);

const initializeIndividual = async () => {
  await getIndividual();
  individualLoaded.value = true;
};

initializeIndividual();

const route = useRoute();
const isOnRoot = computed(() => route.path === "/account/preferences");

const sections = computed(() => ({
  "/account/preferences/compensated": {
    name: "Compensated",
    icon: "account_balance_wallet",
  },
  "/account/preferences/charities": {
    name: "Charities",
    icon: "volunteer_activism",
  },
  "/account/preferences/education": {
    name: "Education Sector",
    icon: "school",
  },
}));

watch(isLoaded, () => {
  if (isLoaded) {
    individualLoaded.value = true;
  }
});

const redirectTo = "/account/preferences/compensated";
useSideNavUtils(isOnRoot, router, redirectTo);
</script>
