<template>
  <div
    class="flex flex-col w-full border-solid border-r-0 border-l-0 border-t-0 pb-8"
  >
    <h2 class="copy-emphasis text-left">Choose your areas of interest</h2>
    <p class="mb-3">
      Selecting your preferences carefully will maximise our ability to send you
      great matches.
    </p>
    <div class="flex flex-col space-y-3">
      <div
        v-for="[category, label] in categories"
        :key="category"
        data-cy="area-of-interest"
      >
        <div
          v-if="isCategoryClosed(category)"
          class="border-solid rounded-lg p-5 flex flex-col cursor-pointer bg-white hover:bg-grey-50 hover:shadow-md"
          @click="toggleCategoryDropdown(category)"
          data-cy="toggle-category-dropdown"
        >
          <div class="flex justify-between items-center">
            <span class="copy-emphasis"> {{ label }}</span>
            <span class="material-icons-round text-sage-700">
              keyboard_arrow_down</span
            >
          </div>

          <div class="text-labelling-inactive mt-2">
            <div v-if="hasSelectedSomeTags(category)" class="text-grey-700">
              {{ fractionOfTagsSelected(category) }} tags selected
            </div>

            <div v-else class="text-orange-700 flex items-center">
              <span class="material-icons-round text-orange-700 text-base mr-2">
                error
              </span>
              No tags selected
            </div>
          </div>
        </div>

        <div
          v-else
          class="border-solid rounded-lg flex flex-col cursor-pointer hover:shadow-md"
        >
          <div
            class="flex flex-col bg-grey-50 p-5 items-start rounded-tl-lg rounded-tr-lg"
            @click="toggleCategoryDropdown(category)"
          >
            <div class="flex justify-between items-center w-full">
              <span class="copy-emphasis"> {{ label }}</span>
              <span class="material-icons-round text-sage-700">
                keyboard_arrow_up</span
              >
            </div>
            <base-checkbox
              data-cy="toggle-select-all"
              label="Select all"
              class="mt-2"
              :checked="allSelected(category)"
              @value="toggleSelectAll(category)"
            />
          </div>
          <div
            class="flex flex-col items-start xs:flex-row flex-wrap xs:items-center bg-white rounded-bl-lg rounded-br-lg px-5 pb-5"
          >
            <tag
              v-for="[tag, label] in tagsByCategory(category)"
              :key="tag"
              :tag="tag"
              :label="label"
              :is-selected="isSelected(tag)"
              @update-tag="updateTag"
              @track-analytics="trackTagUpdate"
            />
          </div>
        </div>
      </div>
      <div v-if="!hasAtLeast1AreaOfInterest && showValidation" class="pt-3">
        <error-message
          message="Please select at least 1 area of interest"
          class="mb-0"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from "vue";
import { PreferenceTag } from "@/types/PreferenceTag";
import preferenceTags from "@/assets/preference_tags.json";
import debounce from "@/utils/debounce";
import BaseCheckbox from "@/components/shared/BaseCheckbox.vue";
import Tag from "@/components/onboarding/Tag.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";

const CHARITY_TAGS = preferenceTags["charity"];

type Data = {
  closedCategories: string[];
  selectedTags: PreferenceTag;
  showValidation: boolean;
};

export default {
  name: "AreasOfInterest",

  components: { BaseCheckbox, Tag, ErrorMessage },

  emits: ["track-analytics", "update-tags"],

  data(): Data {
    return {
      closedCategories: [],
      selectedTags: this.individualPreferenceTags || {},
      showValidation: false,
    };
  },

  props: {
    individualPreferenceTags: {
      type: Object as PropType<PreferenceTag>,
      default: () => ({}),
    },
  },

  computed: {
    categories(): [string, string][] {
      return Object.keys(CHARITY_TAGS).map(category => [
        category,
        CHARITY_TAGS[category].label,
      ]);
    },

    hasAtLeast1AreaOfInterest(): boolean {
      return Object.entries(this.selectedTags).some(([tag, isSelected]) => {
        const charityTags = Object.values(CHARITY_TAGS).flatMap(category =>
          Object.keys(category.subItems)
        );
        const isCharityTag = charityTags.some(charityTag => tag == charityTag);
        return isSelected && isCharityTag;
      });
    },
  },

  methods: {
    isCategoryClosed(category: string) {
      return this.closedCategories.includes(category);
    },

    toggleCategoryDropdown(category: string): void {
      const removeClosedCategory = () =>
        (this.closedCategories = this.closedCategories.filter(
          item => item !== category
        ));

      if (this.closedCategories.includes(category)) {
        removeClosedCategory();
      } else {
        this.closedCategories.push(category);
      }
    },

    tagsByCategory(category: string): [string, string][] {
      return Object.entries(CHARITY_TAGS[category]["subItems"]);
    },

    hasSelectedSomeTags(category: string): boolean {
      return this.selectedNumberOfTags(category) > 0;
    },

    fractionOfTagsSelected(category: string) {
      const selected = this.selectedNumberOfTags(category);
      const total = this.totalNumberOfTags(category);

      return `${selected}/${total}`;
    },

    selectedNumberOfTags(category: string) {
      return Object.entries(this.selectedTags).filter(
        ([tag, isSelected]) =>
          isSelected && CHARITY_TAGS[category]["subItems"][tag]
      ).length;
    },

    totalNumberOfTags(category: string) {
      return Object.keys(CHARITY_TAGS[category]["subItems"]).length;
    },

    allSelected(category: string): boolean {
      return (
        this.selectedNumberOfTags(category) === this.totalNumberOfTags(category)
      );
    },

    isSelected(tag: string): boolean {
      return !!this.selectedTags[tag];
    },

    toggleSelectAll(category: string) {
      if (this.allSelected(category)) {
        this.selectOrDeselectAll(category, false);
      } else {
        this.selectOrDeselectAll(category, true);
      }
    },

    selectOrDeselectAll(category: string, value: boolean) {
      Object.keys(CHARITY_TAGS[category]["subItems"]).forEach(tag => {
        this.selectedTags[tag] = value;
      });
      this.emitTags();
    },

    updateTag(tag: string) {
      this.selectedTags[tag] = !this.selectedTags[tag];
      this.emitTags();
    },

    trackTagUpdate(data) {
      this.$emit("track-analytics", {
        type: "pro-bono",
        ...data,
      });
    },

    emitTags: debounce(function () {
      this.$emit("update-tags", this.selectedTags);
    }, 1200),

    async isValid() {
      this.showValidation = true;

      if (!this.hasAtLeast1AreaOfInterest) {
        await this.$nextTick();
        const errorElement =
          document.getElementsByClassName("error-message")[0];

        errorElement?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
