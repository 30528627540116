import {
  nextTick,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  Ref,
  watch,
} from "vue";

interface Options {
  highlightedIndex: Ref;
  defaultIndex?: number;
  options: any[];
  onEnter: Function;
  onTab: Function;
}

/**
 * useOptionsKeyPresses
 * @param config
 * Requires "option-[INDEX]" as ID for scrolling behaviour
 */
export default function useOptionsKeyPresses(config: Options) {
  function handleKeydown(e: KeyboardEvent) {
    switch (e.key) {
      case "ArrowUp":
        e.preventDefault(); // Prevent scrolling on parent
        if (config.highlightedIndex.value > 0) {
          config.highlightedIndex.value -= 1;
        }
        break;
      case "ArrowDown":
        if (config.highlightedIndex.value < config.options.length - 1) {
          config.highlightedIndex.value += 1;
        }
        break;
      case "Enter":
        e.preventDefault(); // Prevents re-focusing on button or input which would re-open options
        config.onEnter();
        break;
      case "Tab":
        config.onTab();
        break;
      default:
        break;
    }
  }

  onMounted(() => {
    config.highlightedIndex.value = config.defaultIndex || -1;
  });

  onBeforeMount(() => {
    window.addEventListener("keydown", handleKeydown);
  });

  onBeforeUnmount(() => {
    window.removeEventListener("keydown", handleKeydown);
  });

  watch(config.highlightedIndex, async newIndex => {
    await nextTick();
    document.getElementById(`option-${newIndex}`)?.scrollIntoView();
  });
}
