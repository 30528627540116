<template>
  <div
    v-for="i in [1, 2, 3]"
    :key="i"
    class="p-4 md:p-6 my-3 border border-solid border-t-0 border-r-0 border-l-0 border-grey-100"
  >
    <!-- Title -->
    <div class="h-8 bg-grey-100 animate-pulse w-1/3 rounded-full mt-4"></div>

    <!-- Organisation -->
    <div class="h-8 bg-grey-50 animate-pulse w-1/2 rounded-full mt-4"></div>

    <!-- Dates -->
    <div class="h-8 bg-grey-50 animate-pulse w-1/4 rounded-full mt-4"></div>

    <!-- Organisation sector, size, ownership -->
    <div class="w-full gap-2 mt-4 flex">
      <div class="h-10 bg-grey-50 animate-pulse w-1/5 rounded-lg"></div>
      <div class="h-10 bg-grey-50 animate-pulse w-1/4 rounded-lg"></div>
      <div class="h-10 bg-grey-50 animate-pulse w-1/4 rounded-lg"></div>
    </div>
  </div>
</template>
