<template>
  <div
    class="bg-white px-5 sm:px-12 lg:px-28 w-full py-5 md:py-12 my-5 md:my-0 md:border-b md:border-t-0 md:border-l-0 md:border-r-0 md:border-solid md:border-grey-100"
  >
    <div class="py-4" v-for="field in extraFields" :key="field.id">
      <preview-accordion>
        <template v-slot:title>
          <span class="copy-emphasis"> {{ field.heading }} </span>
        </template>
        <template v-slot:content>
          <p class="text-grey-800 mt-3 mb-5">
            {{ field.description }}
          </p>
          <div
            class="bg-grey-50 text-grey-80 pt-5 pb-1 px-5 mt-4 role-application-extra-fields-preview"
          >
            <p v-html="field.response"></p>
          </div>
        </template>
      </preview-accordion>
    </div>
  </div>
</template>

<script lang="ts">
import PreviewAccordion from "@/components/roleApplication/PreviewAccordion.vue";

import useRoleApplication from "@/composables/useRoleApplication";

export default {
  components: {
    PreviewAccordion,
  },
  setup() {
    const { roleApplication } = useRoleApplication();
    return {
      roleApplication,
    };
  },
  computed: {
    title() {
      return "Additional details";
    },
    extraFields() {
      return this.roleApplication.extra_fields;
    },
  },
};
</script>

<style>
.role-application-extra-fields-preview ol,
.role-application-extra-fields-preview ul,
.role-application-extra-fields-preview li {
  all: revert;
}
</style>
