<template>
  <base-modal :isOpen="true" :is-small="!anyUploadedCvs">
    <base-modal-header @close="emits('close-modal')">
      {{ anyExistingRoles ? "Replace" : "Add" }} roles from CV
    </base-modal-header>
    <upload-cv :is-cv-parse="true" @cv-change="chooseCvForParsing" />
    <div v-if="anyUploadedCvs">
      <or-divider />
      <h2 class="copy-emphasis mt-6 mb-3" id="Add your CV">Your CVs</h2>
      <div class="pt-1 pb-5 flex flex-col space-y-3">
        <cv-deselected
          v-for="cv in individualCvs"
          :cv="cv"
          :key="cv.id"
          @select="chooseCvForParsing(cv)"
        />
      </div>
    </div>
  </base-modal>
</template>

<script setup lang="ts">
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseModalHeader from "@/components/shared/BaseModalHeader.vue";
import useCvs from "@/composables/useCvs";
import UploadCv from "@/components/cv/UploadCv.vue";
import CvDeselected from "@/components/account/cv/CvDeselected.vue";
import { onMounted, ref } from "vue";
import OrDivider from "@/components/OrDivider.vue";
import useCvParsing from "@/composables/useCvParsing";

const individualCvs = ref([]);
const anyUploadedCvs = ref(false);
const emits = defineEmits(["close-modal"]);
defineProps({
  anyExistingRoles: {
    type: Boolean,
    required: false,
    default: false,
  },
});

onMounted(async () => {
  await useCvs().getIndividualCvs();
  individualCvs.value = useCvs().individualCvs.value;
  anyUploadedCvs.value = individualCvs.value?.length > 0;
});

async function chooseCvForParsing(cv) {
  await useCvParsing().setCv(cv);
  emits("close-modal");
}
</script>
