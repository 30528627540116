<script setup lang="ts">
import { ref, onBeforeMount, onBeforeUnmount } from "vue";
import type { RoleHeld } from "@/composables/useMiniCv";
import OrganisationSector from "@/components/miniCv/OrganisationSector.vue";
import OrganisationOwnership from "@/components/miniCv/OrganisationOwnership.vue";
import OrganisationSize from "@/components/miniCv/OrganisationSize.vue";
import OrganisationDataModelFormLine from "@/components/miniCv/OrganisationDataModelFormLine.vue";

const props = defineProps({
  roleHeld: {
    type: Object as () => RoleHeld,
    required: true,
  },
  selectedButton: {
    type: String,
  },
});

const emit = defineEmits(["close-modal", "save-role-held-org-data"]);

const organisationWebsiteData = ref(props.roleHeld?.organisation_website);
const organisationSectorData = ref(props.roleHeld?.organisation_sector);
const organisationOwnershipData = ref(props.roleHeld?.organisation_ownership);
const organisationSizeData = ref({
  label: props.roleHeld?.organisation_size?.label || "Revenue",
  value: props.roleHeld?.organisation_size?.value || 0,
  currency: props.roleHeld?.organisation_size?.currency || "GBP",
});

const saveInProgress = ref(false);

const modalRows = ref([
  {
    label: "Sector",
    for: "sector",
    focus: props.selectedButton === "sector",
    component: OrganisationSector,
    inputProps: {
      vModel: organisationSectorData,
    },
  },
  {
    label: "Ownership",
    for: "ownership",
    focus: props.selectedButton === "ownership",
    component: OrganisationOwnership,
    inputProps: {
      vModel: organisationOwnershipData,
    },
  },
  {
    label: null,
    for: "size",
    focus: props.selectedButton === "size",
    component: OrganisationSize,
    inputProps: {
      vModel: organisationSizeData,
    },
  },
  {
    label: "Organisation website (optional)",
    for: "website",
    focus: false,
    component: "input",
    inputProps: {
      vModel: organisationWebsiteData,
    },
  },
]);

function toggleOverflowHidden(value: boolean) {
  document.body.classList.toggle("overflow-hidden", value);
}

onBeforeMount(() => {
  toggleOverflowHidden(true);
});

onBeforeUnmount(() => {
  toggleOverflowHidden(false);
});

function organisationSizeToSubmit() {
  if (
    organisationSizeData.value.value === 0 &&
    organisationSizeData.value.label === "Revenue" &&
    organisationSizeData.value.currency === "GBP"
  ) {
    return undefined;
  } else {
    return organisationSizeData.value;
  }
}

function saveChanges() {
  if (saveInProgress.value) return;
  saveInProgress.value = true;
  const roleHeld = {
    id: props.roleHeld?.id,
    organisation: props.roleHeld?.organisation,
    organisation_id: props.roleHeld?.organisation_id,
    organisation_website: organisationWebsiteData.value || undefined,
    organisation_sector: organisationSectorData.value || undefined,
    organisation_size: organisationSizeToSubmit(),
    organisation_ownership: organisationOwnershipData.value || undefined,
    organisation_description: props.roleHeld?.organisation_description,
    position: props.roleHeld?.position,
    from: props.roleHeld?.from,
    to: props.roleHeld?.to,
    is_current: props.roleHeld?.is_current,
    end_date_unknown: props.roleHeld?.end_date_unknown,
    executive: props.roleHeld?.executive,
    compensated: props.roleHeld?.compensated,
    pinned: props.roleHeld?.pinned,
  };

  emit("save-role-held-org-data", roleHeld);
}
</script>

<template>
  <div
    class="bg-modal w-screen h-screen fixed left-0 right-0 top-0 bottom-0 z-100 grid place-items-center overflow-hidden"
  >
    <div
      class="w-full bg-white pt-8 px-8 pb-24 md:pb-8 rounded-none md:rounded-lg h-screen md:h-auto max-h-screen overflow-y-auto"
      style="max-width: 800px"
    >
      <div class="flex">
        <h1 class="text-title-h2 m-0 flex-1">Organisation Details</h1>
        <button class="px-3" @click="$emit('close-modal')">
          <span class="material-icons-round text-sage-700"> close </span>
        </button>
      </div>
      <hr class="my-4 border-grey-200 border-solid" />
      <div class="flex flex-col justify-center items-start gap-8 self-stretch">
        <form
          class="w-full"
          novalidate
          @submit.prevent.stop
          ref="organisationDataForm"
        >
          <div
            class="flex flex-col content-center items-start gap-4 self-stretch"
          >
            <div class="flex flex-col items-start self-stretch">
              <span class="self-stretch copy-emphasis text-[#242726]">
                {{ props.roleHeld?.organisation }}
              </span>
              <span class="self-stretch copy text-grey-700">
                This information helps our hiring organisations understand your
                experience.
              </span>
            </div>
            <div class="flex flex-col items-start gap-6 self-stretch">
              <template v-for="row in modalRows" :key="row.label">
                <organisation-data-model-form-line
                  :label="row.label"
                  :for="row.for"
                >
                  <component
                    v-if="row.component !== 'input'"
                    :is="row.component"
                    v-model="row.inputProps.vModel"
                    :focus="row.focus"
                  />
                  <input
                    v-else
                    type="url"
                    :id="row.for"
                    class="text-inputs-content text-input w-full py-[15px]"
                    placeholder="Organisation website"
                    v-model="row.inputProps.vModel"
                    data-website
                  />
                </organisation-data-model-form-line>
              </template>
            </div>
          </div>
        </form>
        <div class="flex items-end gap-4 self-stretch">
          <button
            class="button-primary text-cta-primary w-full md:w-auto"
            @click="saveChanges"
            :disabled="saveInProgress"
            data-save-organisation
          >
            Submit
          </button>
          <button
            class="button-secondary text-cta-secondary w-full md:w-auto"
            @click="$emit('close-modal')"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
