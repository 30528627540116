<template>
  <button
    class="text-cta-secondary button-secondary flex items-center justify-center gap-2 w-full md:w-auto"
    @click="toggleWatching"
    :disabled="isLoading"
  >
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1 6.02667L9.87338 5.74667L8.61338 2.78C8.38672 2.24 7.61338 2.24 7.38671 2.78L6.12671 5.75333L2.90672 6.02667C2.32005 6.07333 2.08005 6.80667 2.52672 7.19333L4.97338 9.31333L4.24005 12.46C4.10672 13.0333 4.72672 13.4867 5.23338 13.18L8.00005 11.5133L10.7667 13.1867C11.2734 13.4933 11.8934 13.04 11.76 12.4667L11.0267 9.31333L13.4734 7.19333C13.92 6.80667 13.6867 6.07333 13.1 6.02667ZM8.00005 10.2667L5.49338 11.78L6.16005 8.92667L3.94672 7.00667L6.86672 6.75333L8.00005 4.06667L9.14005 6.76L12.06 7.01333L9.84672 8.93333L10.5134 11.7867L8.00005 10.2667Z"
        :fill="isLoading ? '#C2BEB3' : '#1E4B37'"
      />
    </svg>
    {{ isWatching ? "Remove from watchlist" : "Add to watchlist" }}
  </button>
</template>

<script lang="ts">
import { apiAxios } from "@/lib/axios";
import useWatchlist from "@/composables/useWatchlist";

export default {
  props: ["roleId"],
  setup() {
    const { watchlist, refreshWatchlist } = useWatchlist();

    return {
      watchlist,
      refreshWatchlist,
    };
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async toggleWatching() {
      this.isLoading = true;
      if (this.isWatching) {
        await apiAxios.delete(`/watchlist`, { data: { role_id: this.roleId } });
        await this.refreshWatchlist();
        this.isLoading = false;
      } else {
        await apiAxios.post(`/watchlist`, { role_id: this.roleId });
        await this.refreshWatchlist();
        this.isLoading = false;
      }
    },
  },
  computed: {
    isWatching() {
      return !!this.watchlist?.find(id => id === this.roleId);
    },
  },
};
</script>
