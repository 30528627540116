import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import RoleApply from "@/views/roles/RoleApply.vue";
import ReviewApplicationBeforeSubmit from "@/views/ReviewApplicationBeforeSubmit.vue";
import RoleApplicationFeedback from "@/views/roles/RoleApplicationFeedback.vue";
import TheRolesList from "@/views/TheRolesList.vue";
import RequestInvitation from "@/views/RequestInvitation.vue";
import { onboarding as onboardingRoutes } from "@/lib/onboardingRouter";
import { PunchThrough } from "@/helpers/punchThrough";
import NoMemberFound from "@/views/NoMemberFound.vue";
import Role from "@/views/roles/Role.vue";
import LongFormRoleContent from "@/components/role/LongFormRoleContent.vue";
import RecommendForm from "@/components/role/RecommendForm.vue";
import ContactUs from "@/views/ContactUs.vue";
import UnsubscribeOrClose from "@/views/UnsubscribeOrClose.vue";
import SnoozeNotifications from "@/views/SnoozeNotifications.vue";
import Recommend from "@/views/Recommend.vue";
import AccountLayout from "@/components/AccountLayout.vue";
import AccountSettings from "@/views/account/AccountSettings.vue";
import MyProfile from "@/views/account/MyProfile.vue";
import MyWatchlist from "@/views/account/MyWatchlist.vue";
import MyApplications from "@/views/account/MyApplications.vue";
import MyRecommendations from "@/views/account/MyRecommendations.vue";
import MyProfilePreview from "@/views/MyProfilePreview.vue";
import { preventUnauthorizedAccess } from "@/composables/useAuth";
import MyProfileEducation from "@/components/profile/MyProfileEducation.vue";
import MyProfileExperience from "@/components/profile/MyProfileExperience.vue";
import MyProfileCareerHighlights from "@/components/profile/MyProfileCareerHighlights.vue";
import MyProfileSummary from "@/components/profile/MyProfileSummary.vue";
import MyProfileCVs from "@/components/profile/MyProfileCVs.vue";
import MyProfileAdditionalInformation from "@/components/profile/MyProfileAdditionalInformation.vue";
import MyProfileQualifications from "@/components/profile/MyProfileQualifications.vue";
import MyPreferences from "@/components/profile/MyPreferences.vue";
import MyPreferencesCompensated from "@/components/profile/MyPreferencesCompensated.vue";
import MyPreferencesCharities from "@/components/profile/MyPreferencesCharities.vue";
import MyPreferencesEducation from "@/components/profile/MyPreferencesEducation.vue";
import { apiAxios } from "@/lib/axios";

export const routes: RouteRecordRaw[] = [
  {
    path: "",
    redirect: "/roles", // Re-directing to home because it has the route guard logic on which page to show
  },
  {
    path: "/roles",
    name: "RolesList",
    component: TheRolesList,
    meta: {
      scrollTopWhenGoingTo: [
        "Onboarding",
        "Onboarding Personal Details",
        "Onboarding Your Experience",
        "Onboarding WhatYouBring",
        "Onboarding WhatYouWant",
        "Onboarding ProBono",
        "Onboarding Education",
        "Reconfigure",
        "Reconfigure WhatYouBring",
        "Reconfigure WhatYouWant",
        "Reconfigure ProBono",
        "Reconfigure Education",
        "RoleDetails",
        "RoleRecommend",
      ],
      mustBeLoggedIn: true,
    },
  },
  {
    path: "/roles/:roleId",
    component: Role,
    children: [
      {
        path: "",
        component: LongFormRoleContent,
        name: "RoleDetails",
        meta: {
          restoreLastScrollPositionWhenGoingTo: ["RolesList", "Home"],
          mustBeLoggedIn: true,
        },
      },
      {
        path: "recommend",
        component: RecommendForm,
        name: "RoleRecommend",
        meta: {
          restoreLastScrollPositionWhenGoingTo: ["RolesList", "Home"],
        },
      },
    ],
  },
  {
    path: "/roles/share/:token",
    name: "ShareableRole",
    props: true,
    component: () => import("@/views/ShareableRole.vue"), // lazy loading infrequently accessed route
  },
  {
    path: "/p/:profileId",
    name: "ShareableProfile",
    props: true,
    meta: {
      hideNav: true,
      mustBeLoggedIn: false,
      componentHandlesPageTitle: true,
    },
    component: () => import("@/views/ShareableProfile.vue"),
  },
  {
    path: "/roles/:roleId/apply",
    name: "RoleApply",
    props: true,
    component: RoleApply,
    meta: {
      componentHandlesPageTitle: true,
      hideNav: true,
      hideFooter: true,
      mustBeLoggedIn: true,
      denyPunchthroughAccess: true,
    },
  },
  {
    path: "/roles/:roleId/apply/preview",
    name: "ReviewApplicationBeforeSubmit",
    props: true,
    component: ReviewApplicationBeforeSubmit,
    meta: {
      title: "Nurole | Review your application",
      hideNav: true,
      hideFooter: true,
      mustBeLoggedIn: true,
      denyPunchthroughAccess: true,
      denyPreviewModeAccess: true,
    },
  },
  {
    path: "/roles/:roleId/success/:roleApplicationId",
    name: "RoleApplicationSubmitted",
    props: true,
    component: RoleApplicationFeedback,
    meta: {
      title: "Nurole | Role application process feedback",
      mustBeLoggedIn: true,
      denyPunchthroughAccess: true,
      denyPreviewModeAccess: true,
    },
  },
  {
    path: "/end-of-process",
    component: () => import("@/components/feedback/RejectionFeedbackPopup.vue"),
    meta: {
      MustBeLoggedIn: true,
    },
  },
  {
    path: "/account/applications/:id",
    redirect: to => "/applications/" + to.params.id,
  },
  {
    path: "/applications/:id",
    name: "SubmittedApllication",
    props: true,
    component: () => import("@/views/account/SubmittedApplication.vue"),
    meta: {
      title: "Nurole | Your completed application",
      mustBeLoggedIn: true,
      denyPunchthroughAccess: true,
      denyPreviewModeAccess: true,
    },
  },
  {
    path: "/guest/applications/:id",
    name: "GuestSubmittedApllication",
    props: true,
    component: () => import("@/views/account/SubmittedApplication.vue"),
    meta: {
      title: "Nurole | Your completed application",
      mustBeLoggedIn: true,
      allowToUseEmailVerification: true,
      denyPunchthroughAccess: true,
      denyPreviewModeAccess: true,
    },
  },
  {
    path: "/no-member-found",
    name: "NoMemberFound",
    component: NoMemberFound,
  },
  {
    path: "/invite/:inviteId/:position",
    redirect: to => "/onboarding/register/" + to.params.inviteId,
  },
  {
    path: "/invite/:inviteId",
    redirect: to => "/onboarding/register/" + to.params.inviteId,
  },
  {
    path: "/request",
    name: "RequestInvitation",
    component: RequestInvitation,
    meta: { title: "Nurole | Request an invitation" },
  },
  ...onboardingRoutes, // TODO: These routes are a mess! Maybe re-do the whole onboarding?
  {
    path: "/not-found",
    name: "PageNotFound",
    component: () => import("@/views/PageNotFound.vue"),
    meta: {
      title: "Nurole | Oops! We could not find the page you were looking for",
    },
  },
  {
    path: "/contact",
    name: "ContactUs",
    component: ContactUs,
    meta: {
      title: "Nurole | Contact us",
    },
  },
  {
    path: "/unsubscribe",
    name: "UnsubscribeOrClose",
    component: UnsubscribeOrClose,
    meta: {
      title: "Nurole | Unsubscribe or close your account",
      mustBeLoggedIn: true,
      denyPunchthroughAccess: true,
      denyPreviewModeAccess: true,
    },
  },
  {
    path: "/snooze",
    name: "Snooze",
    component: SnoozeNotifications,
    meta: {
      mustBeLoggedIn: true,
      denyPunchthroughAccess: true,
      denyPreviewModeAccess: true,
    },
  },
  {
    path: "/postpone",
    redirect: "/unsubscribe",
  },
  {
    path: "/account/close",
    redirect: "/unsubscribe",
  },
  {
    path: "/recommend",
    name: "Recommend",
    component: Recommend,
    meta: {
      title: "Nurole | Recommend a new member",
      mustBeLoggedIn: true,
    },
  },
  {
    path: "/account/personal-details",
    redirect: "/account/settings",
  },
  {
    path: "/account/professional-experience",
    redirect: "/account/profile",
  },
  {
    path: "/account/professional",
    redirect: "/account/profile",
  },
  {
    path: "/account/account-settings",
    redirect: "/account/settings",
  },
  {
    path: "/account",
    name: "Account",
    component: AccountLayout,
    redirect: "/account/profile",
    children: [
      {
        path: "profile",
        component: MyProfile,
        children: [
          {
            path: "preview",
            name: "MyProfilePreview",
            component: MyProfilePreview,
          },
          {
            path: "summary",
            name: "MyProfileSummary",
            component: MyProfileSummary,
          },
          {
            path: "experience",
            name: "MyProfileExperience",
            component: MyProfileExperience,
          },
          {
            path: "career-highlights",
            name: "MyProfileCareerHighlights",
            component: MyProfileCareerHighlights,
          },
          {
            path: "education",
            name: "MyProfileEducation",
            component: MyProfileEducation,
          },
          {
            path: "qualifications",
            name: "MyProfileQualifications",
            component: MyProfileQualifications,
          },
          {
            path: "additional_information",
            name: "MyProfileAdditionalInformation",
            component: MyProfileAdditionalInformation,
          },
          {
            path: "cvs",
            name: "MyProfileCVs",
            component: MyProfileCVs,
          },
        ],
      },
      {
        path: "preferences",
        component: MyPreferences,
        children: [
          {
            path: "compensated",
            name: "MyPreferencesCompensated",
            component: MyPreferencesCompensated,
          },
          {
            path: "charities",
            name: "MyPreferencesCharities",
            component: MyPreferencesCharities,
          },
          {
            path: "education",
            name: "MyPreferencesEducation",
            component: MyPreferencesEducation,
          },
        ],
      },
      { path: "watchlist", component: MyWatchlist },
      { path: "recommendations", component: MyRecommendations },
      {
        path: "applications",
        component: MyApplications,
      },
      { path: "settings", component: AccountSettings },
    ],
    meta: {
      mustBeLoggedIn: true,
      denyPunchthroughAccess: true,
      denyPreviewModeAccess: true,
      alwaysScrollTop: true,
      scrollTopWhenGoingTo: [
        "RolesList",
        "RoleDetails",
        "RoleRecommend",
        "Recommend",
        "UnsubscribeOrClose",
      ],
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: () => import("@/views/PageNotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Restore scroll position to top of page
    if (
      (Array.isArray(from.meta?.scrollTopWhenGoingTo) &&
        from.meta.scrollTopWhenGoingTo.includes(to.name)) ||
      to.meta?.alwaysScrollTop
    ) {
      return {
        top: 0,
      };
    }

    // Scroll to element id
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }

    // Restore scroll to saved position on browser back button
    if (
      Array.isArray(from.meta?.restoreLastScrollPositionWhenGoingTo) &&
      from.meta.restoreLastScrollPositionWhenGoingTo.includes(to.name) &&
      savedPosition
    ) {
      return savedPosition;
    }

    // Restore scroll to saved position on vue routing
    if (
      Array.isArray(from.meta?.restoreLastScrollPositionWhenGoingTo) &&
      from.meta.restoreLastScrollPositionWhenGoingTo.includes(to.name) &&
      to.name &&
      savedScrollPositions[to.name]
    ) {
      return {
        top: savedScrollPositions[to.name],
      };
    }
  },
});

const savedScrollPositions: { [routeName: string | symbol]: number } = {};

router.beforeEach(async (to, from) => {
  // Set meta.mustBeLoggedIn to false if the role is public
  if (
    to.path.startsWith(`/roles/${to.params.roleId}`) &&
    !(from.query.preview && from.query.signature)
  ) {
    const { data } = await apiAxios.get(`/roles/${to.params.roleId}/public`, {
      validateStatus: () => true,
    });
    const publicRole = data?.public;
    to.meta.isPublicRole = publicRole;
    to.meta.mustBeLoggedIn = !publicRole;
  }

  if (to.query.previewHideNavAndFooter) {
    to.meta.hideNav = true;
    to.meta.hideFooter = true;
  }

  if (to.query.hideInteractionElements) {
    to.meta.hideInteractionElements = true;
  }

  preventUnauthorizedAccess(to);

  if (from.name) {
    savedScrollPositions[from.name] =
      document.documentElement.scrollTop || document.body.scrollTop;
  }
});

router.afterEach((to, from) => {
  window.analytics.track("Page view", {
    path: to.path,
    from: from.name,
    name: to.name,
    role_id: to.params.roleId,
    punch_through_token_used: PunchThrough.getToken() !== undefined,
  });
  window.gtag("event", "page_view}", {
    path: to.path,
    from: from.name,
    name: to.name,
    role_id: to.params.roleId,
    punch_through_token_used: PunchThrough.getToken() !== undefined,
  });

  const hubspotQueue = (window._hsq = window._hsq || []);
  hubspotQueue.push(["trackPageView"]);
  hubspotQueue.push(["setPath", to.fullPath]);

  if (to.meta.title && typeof to.meta.title == "string") {
    document.title = to.meta.title;
  } else if (!to.meta?.title && !to.meta?.componentHandlesPageTitle) {
    document.title =
      "Nurole | The board director and senior executive job site";
  }

  document.body.classList.toggle("overflow-hidden", to.meta?.isModal == true);
});

export default router;
