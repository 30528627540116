<template>
  <div class="copy-emphasis">Please tell us your ethnicity</div>
  <div class="mt-2 mb-8">
    This is optional, but helps us monitor the diversity of our members and
    better support all candidates. This information will be anonymised and only
    ever used in a general statistical summary. It will never be shared with a
    hiring organisation.
  </div>
  <div class="md:flex items-center">
    <div class="flex-1">
      <div class="text-inputs-title mb-2">What is your ethnic group?</div>
      <select
        v-model="ethnicity_group"
        class="select-input w-full"
        data-cy="ethnicity-group-select"
        @input="saveChanges"
      >
        <option value="unspecified">Prefer not to say</option>
        <option
          v-for="option in ethnicities"
          :key="option.value"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
    <div class="flex-1 md:ml-3 md:mt-0 mt-6" v-if="backgroundOptions.length">
      <div class="text-inputs-title mb-2">
        Which one best describes your background?
      </div>
      <select
        v-model="ethnicity_background"
        class="select-input w-full"
        data-cy="ethnicity-background-select"
        @input="saveChanges"
      >
        <option
          v-for="option in backgroundOptions"
          :key="option.value"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
  </div>
</template>

<script lang="ts">
import useIndividual from "@/composables/useIndividual";
import ethnicities from "@/lib/ethnicities";
import debounce from "@/utils/debounce";

type EthnicityBackground = {
  label: string;
  value: string;
};

type EthnicityGroup = {
  label: string;
  value: string;
  backgrounds: EthnicityBackground[];
};

export default {
  setup() {
    const { isLoaded, individual, updateIndividual } = useIndividual();
    return {
      individual,
      ethnicities,
      updateIndividual,
      isLoaded,
    };
  },
  data() {
    return {
      ethnicity_group: "",
      ethnicity_background: "",
    };
  },
  computed: {
    selectedethnicity_group(): EthnicityGroup {
      return this.ethnicities.find(
        (group: EthnicityGroup) => group.value == this.ethnicity_group
      );
    },
    backgroundOptions(): EthnicityBackground[] {
      return this.selectedethnicity_group
        ? this.selectedethnicity_group.backgrounds
        : [];
    },
  },
  methods: {
    saveChanges: debounce(async function () {
      await this.updateIndividual(this.$data, "ethnicity_background");
    }, 500),
  },
  watch: {
    isLoaded: {
      immediate: true,
      handler(isLoaded) {
        if (isLoaded) {
          this.ethnicity_group = this.individual.ethnicity_group;
          this.ethnicity_background = this.individual.ethnicity_background;
        }
      },
    },
  },
};
</script>
