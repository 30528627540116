import { ref, readonly } from "vue";

type SortBy = "Relevant to you" | "Newest listed";
type RoleFilter = "pro_bono" | "education" | "non_executive";

const searchQuery = ref<string>("");
const sortBy = ref<SortBy>("Relevant to you");
const filterBy = ref<{}>({
  pro_bono: true,
  education: true,
  non_executive: true,
});

function updateSearchQuery(newSearchQuery: string) {
  searchQuery.value = newSearchQuery;
}

function updateSortBy(newSortBy: SortBy) {
  sortBy.value = newSortBy;
}

function toggleFilter(filter: RoleFilter) {
  filterBy.value[filter] = !filterBy.value[filter];
}

export default function useRoleFilters() {
  return {
    searchQuery: readonly(searchQuery),
    sortBy: readonly(sortBy),
    filterBy: readonly(filterBy),
    updateSearchQuery,
    updateSortBy,
    toggleFilter,
  };
}
