export function addAttributeTo(
  html: string,
  tag: string,
  attrs: Record<string, string>
) {
  const parser = new DOMParser();
  const document = parser.parseFromString(html, "text/html");
  const anchors = document.getElementsByTagName(tag);
  for (const aTag of anchors) {
    for (const attribute of Object.entries(attrs)) {
      aTag.setAttribute(...attribute);
    }
  }

  return document.body.innerHTML;
}
