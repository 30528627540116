/* eslint-disable @typescript-eslint/no-explicit-any */
export default function debounce<T extends Function>(callback: T, wait = 1000) {
  let timeout = 0;
  let lastThis = null;

  function callable(...args: any) {
    clearTimeout(timeout);
    lastThis = this; // eslint-disable-line @typescript-eslint/no-this-alias
    timeout = window.setTimeout(() => callback.apply(lastThis, args), wait);
  }
  return callable as any as T;
}
