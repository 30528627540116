<template>
  <img
    class="inline-block h-14 w-14 rounded-full"
    :src="src"
    :alt="name || 'profile picture'"
  />
</template>

<script lang="ts">
export default {
  name: "UserPicture",
  props: {
    src: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
    },
  },
};
</script>
