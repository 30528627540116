<template>
  <div
    class="bg-white shadow-ivory p-10 md:px-28 rounded-xl"
    v-if="educationCourses.length"
  >
    <div class="pb-3 border-b border-gray-900 flex">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 6.09375L19.4141 6.40625L2.53906 15.1562L0.390625 16.25L2.5 17.3047V27.8516C1.75293 28.2861 1.25 29.0771 1.25 30C1.25 31.3818 2.36816 32.5 3.75 32.5C5.13184 32.5 6.25 31.3818 6.25 30C6.25 29.0771 5.74707 28.2861 5 27.8516V18.5938L7.5 19.8828V26.25C7.5 26.8018 7.75391 27.2998 8.04688 27.6172C8.33984 27.9346 8.69629 28.125 9.0625 28.3203C9.7998 28.7061 10.6738 28.9795 11.7578 29.2188C13.9258 29.7021 16.8164 30 20 30C23.1836 30 26.0742 29.7021 28.2422 29.2188C29.3262 28.9795 30.2002 28.7061 30.9375 28.3203C31.3037 28.125 31.6602 27.9346 31.9531 27.6172C32.2461 27.2998 32.5 26.8018 32.5 26.25V19.8828L37.4609 17.3438L39.6094 16.25L37.4609 15.1562L20.5859 6.40625L20 6.09375ZM20 8.90625L34.1406 16.25L31.9141 17.3828C31.6309 17.085 31.2891 16.8652 30.9375 16.6797C30.2051 16.2939 29.3262 16.0205 28.2422 15.7812C26.0791 15.2979 23.1982 15 20 15C16.8018 15 13.9209 15.2979 11.7578 15.7812C10.6738 16.0205 9.79492 16.2939 9.0625 16.6797C8.71094 16.8652 8.36914 17.085 8.08594 17.3828L5.85938 16.25L20 8.90625ZM20 17.5C23.0518 17.5 25.7959 17.7783 27.6953 18.2031C28.6426 18.4131 29.4043 18.6572 29.8047 18.8672C29.9316 18.9355 29.9512 18.9844 30 19.0234V23.7891C29.4775 23.5986 28.9062 23.4277 28.2422 23.2812C26.0742 22.7979 23.1836 22.5 20 22.5C16.8164 22.5 13.9258 22.7979 11.7578 23.2812C11.0938 23.4277 10.5225 23.5986 10 23.7891V19.0234C10.0488 18.9844 10.0684 18.9355 10.1953 18.8672C10.5957 18.6572 11.3574 18.4131 12.3047 18.2031C14.2041 17.7783 16.9482 17.5 20 17.5ZM20 25C23.0322 25 25.791 25.2783 27.6953 25.7031C28.4863 25.8789 28.9844 26.0645 29.4141 26.25C28.9844 26.4355 28.4863 26.6211 27.6953 26.7969C25.791 27.2217 23.0322 27.5 20 27.5C16.9678 27.5 14.209 27.2217 12.3047 26.7969C11.5137 26.6211 11.0156 26.4355 10.5859 26.25C11.0156 26.0645 11.5137 25.8789 12.3047 25.7031C14.209 25.2783 16.9678 25 20 25Z"
          fill="#242726"
        />
      </svg>

      <h3 class="ml-2 mt-1">Education</h3>
    </div>

    <ul class="mt-6 flex flex-col gap-4">
      <li
        v-for="course in educationCourses"
        :key="course.id"
        class="pb-4 border-b border-grey-100 last:border-b-0"
      >
        <div class="flex justify-between">
          <p class="copy-bold">
            {{ course.institution }}
          </p>
          <div class="text-primary">
            {{ getDisplayDates(course) }}
          </div>
        </div>
        <div class="text-primary">
          {{ getDisplayDegree(course) }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { Profile } from "@/types/Profile";

import useEducation from "@/composables/useEducation";

const { getDisplayDates, getDisplayDegree } = useEducation();

defineProps<{
  educationCourses: Profile["education"];
}>();
</script>
