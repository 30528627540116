<template>
  <div class="flex md:flex-row flex-col md:space-x-2 space-y-2 md:space-y-0">
    <div
      class="flex flex-wrap w-full md:w-auto md:space-x-2 justify-between md:justify-start mb-1"
    >
      <button
        v-for="val in [1, 2, 3, 4, 5]"
        :key="val"
        @click="$emit('numberClicked', val)"
        class="flex-none border-solid border border-grey-300 h-12 w-12 rounded-full outline-none cursor-pointer md:h-14 md:w-14"
        :class="
          selectedValue === val
            ? 'bg-sage-700 text-white  hover:bg-sage-800 '
            : 'bg-white text-sage-800  hover:bg-ivory-50 active:bg-ivory-100'
        "
      >
        {{ val }}
      </button>
    </div>
    <div
      class="flex flex-wrap w-full md:w-auto md:space-x-2 justify-between md:justify-start mb-1"
    >
      <button
        v-for="val in [6, 7, 8, 9, 10]"
        :key="val"
        @click="$emit('numberClicked', val)"
        class="flex-none border-solid border border-grey-300 h-12 w-12 rounded-full outline-none cursor-pointer md:h-14 md:w-14"
        :class="
          selectedValue === val
            ? 'bg-sage-700 text-white  hover:bg-sage-800 '
            : 'bg-white text-sage-800  hover:bg-ivory-50 active:bg-ivory-100'
        "
        :data-cy="'self-rep-' + val"
      >
        {{ val }}
      </button>
    </div>
  </div>
</template>
<script lang="ts">
export default {
  name: "InformationBadges",
  props: {
    selectedValue: { type: Number, default: 0 },
  },
};
</script>
