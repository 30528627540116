import {
  sortRolesHeld,
  rolesHeldValid,
  validateRoleHeld,
} from "@/helpers/miniCv";
import { apiAxios } from "@/lib/axios";
import { Ref, computed, ref } from "vue";
import useIndividual from "@/composables/useIndividual";
import { trackProfileStats } from "@/lib/trackProfileStats";

export const rolesHeld: Ref<RoleHeld[]> = ref([]);
const deletedRoles: Ref<RoleHeld[]> = ref([]);
export const isLoaded: Ref<boolean> = ref(false);

export type RoleHeld = {
  id: string;
  position: string;
  organisation: string;
  organisation_id: string | null;
  from: string;
  to: string | null;
  end_date_unknown: boolean;
  executive: boolean;
  compensated: boolean;
  pinned: boolean;

  // Orgs library info overrides
  organisation_size?: any;
  organisation_sector?: string;
  organisation_ownership?: string;
  organisation_website?: string;
  organisation_description?: string;

  // Local use only
  isDeleted?: true;
};

export async function getRolesHeld() {
  const { data } = await apiAxios.get("/roles-held");
  isLoaded.value = true;
  rolesHeld.value = sortRolesHeld(data);
}

async function saveRoleHeld(params: Partial<RoleHeld>) {
  const { individual } = useIndividual();
  if (params.id) {
    const { id, ...updates } = params;
    await apiAxios.put("/roles-held/" + id, updates);
    window.analytics.track("MiniCv - Updated role held");
    trackProfileStats(individual.value, "update_role_held");
  } else {
    await apiAxios.post("/roles-held", params);
    window.analytics.track("MiniCv - Created role held");
    trackProfileStats(individual.value, "create_role_held");
  }

  await getRolesHeld();
}

async function deleteRoleHeld(id: string) {
  const { individual } = useIndividual();
  await apiAxios.delete("/roles-held/" + id);
  await getRolesHeld();
  window.analytics.track("MiniCv - Deleted role held");
  trackProfileStats(individual.value, "delete_role_held");
}

async function deleteWithUndo(roleHeld: RoleHeld) {
  await deleteRoleHeld(roleHeld.id);
  deletedRoles.value = [
    ...deletedRoles.value,
    { ...roleHeld, id: "deleted-" + roleHeld.id, isDeleted: true },
  ];

  setTimeout(() => {
    deletedRoles.value = deletedRoles.value.filter(
      role => role.id != "deleted-" + roleHeld.id
    );
  }, 4000);
}

async function undoDelete(deletedRole: RoleHeld) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, isDeleted, ...roleHeld } = deletedRole;
  await saveRoleHeld(roleHeld);

  deletedRoles.value = deletedRoles.value.filter(role => role.id != id);
  window.analytics.track("MiniCv - Undo delete role held");
}

function setRolesHeld(roles: RoleHeld[]) {
  rolesHeld.value = sortRolesHeld(roles);
}

const allRolesHeldValid = computed(() => rolesHeldValid(rolesHeld.value));

const invalidRolesCount = computed(() => {
  let count = 0;
  rolesHeld.value.forEach(roleHeld => {
    if (validateRoleHeld(roleHeld).isValid == false) count++;
  });

  return count;
});

export default function useMiniCv() {
  return {
    rolesHeld,
    isLoaded,
    getRolesHeld,
    saveRoleHeld,
    deleteRoleHeld,
    deleteWithUndo,
    deletedRoles,
    undoDelete,
    setRolesHeld,
    allRolesHeldValid,
    invalidRolesCount,
  };
}
