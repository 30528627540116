<template>
  <div class="bg-grey-50 md:bg-sage-700 flex justify-center min-h-screen">
    <div
      class="flex flex-col justify-between w-screen bg-grey-50 sm:bg-white rounded-lg md:w-full md:max-w-6xl md:m-12"
    >
      <preview-header :role="role" v-if="role" />
      <div v-if="showPreview">
        <preview-personal-details />
        <preview-professional-experience />
        <preview-skills v-if="hasSkills" />
        <preview-extra-fields v-if="hasExtraFields" />
        <preview-reason-for-application v-if="coverLetterRequired" />
        <preview-cv />
      </div>
      <div
        v-else
        class="px-5 sm:px-12 lg:px-28 w-full py-5 md:py-12 my-5 md:my-0 flex flex-col space-y-5"
      >
        <preview-skeleton v-for="i in [1, 2, 3, 4, 5]" :key="i" />
      </div>
      <div v-if="showPreview">
        <preview-privacy-consent
          :hasConsented="isPrivacyConsentValid"
          :submitAttempted="submitAttempted"
        />
        <preview-terms-and-conditions
          :hasAgreedTerms="hasAgreedTerms"
          :submitAttempted="submitAttempted"
          v-if="isGuest"
        />
        <div
          class="flex flex-col py-7 pb-32 px-5 gap-y-5 md:flex-row md:justify-between md:bg-white md:pt-0 md:py-10 md:px-12 lg:px-28"
        >
          <router-link
            :to="`/roles/${role.id}/apply`"
            class="button-secondary text-cta-secondary text-center relative md:flex md:items-center md:justify-center"
          >
            <span
              class="material-icons-round absolute left-8 md:static md:mr-3"
            >
              chevron_left
            </span>
            Back to application
          </router-link>
          <button
            data-cy="role-apply-submit"
            @click="submitApplication"
            :disabled="isSubmitting"
            class="button-primary text-cta-primary"
          >
            Submit application
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import useVuelidate from "@vuelidate/core";
import useRoleApplication from "@/composables/useRoleApplication";
import useRole from "@/composables/useRole";
import useCvs from "@/composables/useCvs";
import useIndividual from "@/composables/useIndividual";
import PreviewHeader from "@/components/roleApplication/PreviewHeader.vue";
import PreviewPersonalDetails from "@/components/roleApplication/PreviewPersonalDetails.vue";
import PreviewProfessionalExperience from "@/components/roleApplication/PreviewProfessionalExperience.vue";
import PreviewSkills from "@/components/roleApplication/PreviewSkills.vue";
import PreviewPrivacyConsent from "@/components/roleApplication/PreviewPrivacyConsent.vue";
import PreviewTermsAndConditions from "@/components/roleApplication/PreviewTermsAndConditions.vue";
import PreviewExtraFields from "@/components/roleApplication/PreviewExtraFields.vue";
import PreviewReasonForApplication from "@/components/roleApplication/PreviewReasonForApplication.vue";
import PreviewCv from "@/components/roleApplication/PreviewCv.vue";
import PreviewSkeleton from "@/components/roleApplication/PreviewSkeleton.vue";
import flagsmith from "flagsmith";

export default {
  components: {
    PreviewHeader,
    PreviewPersonalDetails,
    PreviewProfessionalExperience,
    PreviewSkills,
    PreviewExtraFields,
    PreviewReasonForApplication,
    PreviewCv,
    PreviewPrivacyConsent,
    PreviewTermsAndConditions,
    PreviewSkeleton,
  },
  props: {
    roleId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      showPreview: false,
      isSubmitting: false,
      submitAttempted: false,
    };
  },
  setup(props) {
    const { getRoleApplicationCv, getIndividualCvs } = useCvs();
    const { getIndividual, individual, isMember } = useIndividual();
    const {
      roleApplication,
      setRoleId,
      getLatestRoleApplication,
      updateRoleApplication,
    } = useRoleApplication();
    const { role, getRoleById } = useRole();

    setRoleId(props.roleId);

    return {
      role,
      roleApplication,
      getRoleById,
      getIndividual,
      getLatestRoleApplication,
      updateRoleApplication,
      getIndividualCvs,
      getRoleApplicationCv,
      individual,
      isMember,
      v$: useVuelidate(),
    };
  },

  async created() {
    await this.getIndividual();
    await this.getRoleById(this.roleId);
    await this.getLatestRoleApplication();
    await this.getIndividualCvs();
    await this.getRoleApplicationCv();

    this.showPreview = true;
  },
  computed: {
    coverLetterRequired() {
      return !(this.role.cover_letter_required === false);
    },
    hasExtraFields() {
      return this.role.extra_application_fields.length > 0;
    },
    hasSkills() {
      return this.role.skills.length > 0;
    },
    isDisabled() {
      return (
        !this.isPrivacyConsentValid ||
        (this.isGuest && !this.hasAgreedTerms) ||
        this.isSubmitting ||
        this.roleApplication.submitted
      );
    },
    isGuest() {
      return !this.isMember;
    },
    isPrivacyConsentValid() {
      return this.roleApplication.privacy_consent;
    },
    hasAgreedTerms() {
      return this.roleApplication.agreed_terms_and_conditions;
    },
  },
  methods: {
    flagEnabled(featureName: string, rolesListName: string) {
      return (
        flagsmith.hasFeature(featureName) &&
        flagsmith.getValue(featureName) == "visible" &&
        flagsmith.hasFeature(rolesListName) &&
        (flagsmith.getValue(rolesListName) || "")
          .toString()
          .indexOf(this.role.id) > -1
      );
    },
    async submitApplication() {
      if (this.isDisabled) {
        this.submitAttempted = true;
        return;
      }

      this.isSubmitting = true;

      await this.updateRoleApplication({ submitted: true });
      window.analytics.track("Role with Flag Applied", {
        roleId: this.role.id,
        individualId: this.individual.id,
      });

      this.$router.push(
        `/roles/${this.role.id}/success/${this.roleApplication.id}`
      );
    },
  },
};
</script>
