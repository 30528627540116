<template>
  <label
    class="text-inputs-title font-semibold block mt-6 mb-2"
    for="roleHeldPosition"
    >Your position*</label
  >
  <div class="relative" ref="elementRef">
    <input
      :value="modelValue"
      @input="
        $emit('update:modelValue', $event.target.value);
        showResults = true;
      "
      ref="position"
      class="text-input text-inputs-content w-full"
      placeholder="Add your role title"
      :class="{
        'rounded-b-none ring-0 ring-offset-0 rounded-t-lg shadow-md':
          showResults && search.length > 0,
      }"
      @focus="showResults = true"
      data-position
      id="roleHeldPosition"
      name="roleHeldPosition"
      data-cy="role-held-position-input"
    />
    <simple-option-selector
      v-if="showResults"
      :options="topTenResults"
      displayKey="name"
      @on-select="selectPosition"
      @on-close="handleClose"
    />
  </div>
  <div
    ref="acronymsSuggestions"
    v-if="suggestedPositions && !showResults"
    class="mt-2"
  >
    Could you provide us with a bit more clarity on your role? Do you mean:
    <div class="mt-2 flex flex-wrap">
      <a
        data-cy="role-held-position-option"
        class="mb-4 mr-4 sm:mb-3 text-sage-700 text-cta-secondary underline cursor-pointer"
        v-for="position in suggestedPositions"
        :key="position"
        @click="
          $emit('update:modelValue', position);
          this.showResults = false;
        "
      >
        {{ position }}
      </a>
    </div>
  </div>
</template>

<script>
import positions from "@/lib/positions";
import { useVueFuse } from "vue-fuse";
import SimpleOptionSelector from "../shared/SimpleOptionSelector.vue";
import { ref } from "vue-demi";
import useOutsidePress from "@/composables/useOutsidePress";
import positionExpansions from "@/lib/positions/positionExpansions";
export default {
  components: { SimpleOptionSelector },
  name: "RoleHeldPosition",
  props: ["modelValue"],
  setup() {
    const elementRef = ref(null);
    const showResults = ref(false);
    useOutsidePress(elementRef, () => (showResults.value = false));

    const { search, results } = useVueFuse(positions, {
      keys: ["name", "searchValues"],
      includeScore: true,
      shouldSort: true,
      threshold: 0.2,
    });

    return {
      search,
      results,
      showResults,
      elementRef,
    };
  },
  computed: {
    topTenResults() {
      const results = this.results.slice(0, 10) || [];
      const searchTermPresent = results.some(
        result => result.name === this.search
      );
      if (!searchTermPresent && this.search) {
        results.unshift({ name: `"${this.search}"` });
      }
      return results;
    },
    suggestedPositions() {
      return positionExpansions[this.modelValue?.toUpperCase()];
    },
  },
  methods: {
    handleClose() {
      if (positionExpansions[this.modelValue.toUpperCase()]?.length == 1) {
        this.$emit(
          "update:modelValue",
          positionExpansions[this.modelValue.toUpperCase()][0]
        );
      }
      this.showResults = false;
    },
    selectPosition(position) {
      this.$emit(
        "update:modelValue",
        position.name.replace(/^"/, "").replace(/"$/, "")
      );
      this.showResults = false;
    },
  },
  mounted() {
    this.$refs.position.focus();
  },
  watch: {
    modelValue(newValue) {
      this.search = newValue;
    },
  },
};
</script>
