<template>
  <div
    class="bg-sage-600 flex fixed top-4 sm:top-0 left-0 w-full h-full z-100 items-center justify-center"
    v-if="displayFeedbackComponent"
  >
    <div
      v-if="!feedbackSubmitted"
      class="bg-white sm:rounded rounded-lg overflow-scroll w-full md:w-7/12 sm:w-11/12 bottom-0 z-50 max-h-full"
      :class="!smileSelected ? 'h-full sm:h-auto mt-4/5 sm:mt-0' : ''"
    >
      <form method="post" @submit.prevent="submitFeedback" class="relative">
        <div
          class="application-modal-close sm:fixed sm:top-10 sm:right-10 sm:text-white absolute text-sage-600 right-2 top-7 z-10"
        >
          <span
            v-if="!isDirectVisit"
            @click="submitPartialFeedback"
            :data-cy="'application-feedback-close-button'"
            class="material-icons-round cursor-pointer rounded-full flex justify-center items-center sm:border-solid h-10 w-10 font-bold border-white text-center"
            >close</span
          >
        </div>
        <div id="feedback" class="px-5 sm:px-10 sm:pt-16 pt-2">
          <label
            class="text-sage-700 text-title-jumbo text-lg font-source sm:font-playfair sm:text-5xl"
          >
            How did we do?
          </label>
          <p>
            You should have received a call or an email on
            {{ finalised_date }} updating you on your application for the role
            of <strong>{{ role_title }}</strong> at
            <strong>{{ role_name }}</strong
            >. If you haven't and it's not in your spam folder, please get in
            touch.
          </p>
        </div>
        <smile-feedback-scale
          class="mt-14 pb-4 px-5 sm:px-10 pt-2"
          :selectedValue="smileSelectedValue"
          :textPrompt="'How was your overall experience?'"
          @smile-clicked="smileValue => saveChanges({ smileValue })"
        />
        <div
          v-if="smileSelected"
          class="px-4 sm:px-8 pt-4 bg-sage-50 sm:m-0 m-4 pb-8"
          id="further-feedback"
        >
          <legend class="thanks-for-feed py-2">
            <h2 class="text-sage-700 font-semibold text-title-h3">
              Thank you for your feedback
            </h2>
            <p class="grey-900">
              All feedback helps us to improve the Nurole platform and we will
              never share it with the hiring organisation.
            </p>
          </legend>
          <fieldset class="was-feedback-useful py-2" v-if="cameFromEmail">
            <h2 class="text-grey-900 copy-emphasis">
              On a scale of 1 to 10 how valuable did you find the content of the
              email that you received?
            </h2>
            <div class="flex flex-wrap content-start">
              <label
                class="mt-4 cursor-pointer border-1 border-solid border-grey-300 rounded-full py-1 px-2 text-center h-14 w-14 m-1 md:h-16 md:w-16 flex items-center justify-center"
                v-for="usefulness in 10"
                :key="usefulness"
                :class="
                  usefulness === usefulnessSelected
                    ? 'bg-sage-700 text-white text-labelling-active  hover:bg-sage-600'
                    : 'hover:bg-grey-50 bg-white text-labelling-inactive'
                "
                @click="saveChanges({ usefulness: usefulness })"
                :data-cy="'application-feedback-usefulness-' + usefulness"
                for="Usefulness"
              >
                <input type="radio" name="usefulness" class="hidden" />
                <label class="m-0">
                  {{ usefulness }}
                </label>
              </label>
            </div>
          </fieldset>
          <fieldset class="likely-to-recommend py-2">
            <h2 class="text-grey-900 copy-emphasis">
              How likely are you to apply again through Nurole?
            </h2>
            <div class="block xl:flex justify-between">
              <label
                v-for="(likely, index) in LIKELINESS"
                :key="likely"
                @click="saveChanges({ likely: likely })"
                :class="
                  likely === likelinessSelected
                    ? 'bg-sage-700 text-white text-labelling-active  hover:bg-sage-600'
                    : 'hover:bg-grey-50 bg-white text-labelling-inactive'
                "
                class="cursor-pointer sm:w-full px-2 py-3 border-1 border-solid border-grey-300 rounded-full m-1 flex items-center justify-center h-14 mt-4"
                :data-cy="'application-feedback-likeliness-' + index"
                for="Likeliness"
              >
                <input type="radio" name="likeliness" class="hidden" />
                <label class="m-0 flex">
                  {{ likely }}
                  <span
                    v-if="likely === likelinessSelected"
                    class="material-icons-round ml-1"
                  >
                    check
                  </span>
                </label>
              </label>
            </div>
          </fieldset>
          <fieldset class="anything-else py-2">
            <h2 class="copy-emphasis">
              Anything else you would like to share?
            </h2>
            <textarea
              v-model="details"
              class="textarea w-full mt-4"
              rows="4"
              data-cy="application-feedback-input-box"
            >
            </textarea>
          </fieldset>
          <button
            type="button"
            class="button-primary h-12 copy-bold flex items-center justify-center sm:w-28 w-full mt-4"
            @click="submitFullFeedback"
            data-cy="application-feedback-submit-button"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
    <div
      v-if="feedbackSubmitted"
      class="bg-white sm:rounded rounded-lg overflow-scroll w-full md:w-7/12 sm:w-11/12 bottom-0 z-50 max-h-full py-24"
    >
      <div>
        <div class="flex items-center justify-center flex-col">
          <img
            src="@/assets/images/stages-speech-bubble.svg"
            alt=""
            class="mb-3 w-16"
          />
          <div class="text-grey-900 text-title-h2">
            Thank you for your feedback
          </div>
          <button
            v-if="isMember"
            class="w-full md:w-auto my-6 button-primary text-cta-primary"
            @click="goToRolesPage"
          >
            Go to roles listing
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import SmileFeedbackScale from "../roleApplication/SmileFeedbackScale.vue";
import * as Sentry from "@sentry/browser";
import { apiAxios } from "@/lib/axios";
import useIndividual from "@/composables/useIndividual";

export default {
  components: { SmileFeedbackScale },

  data() {
    return {
      hasClosedPopup: false,
      selectedSmileValue: null,
      cameFromEmail: false,
      details: "",
      likelinessSelected: null,
      usefulnessSelected: null,
      feedbackSubmitted: false,
    };
  },
  setup() {
    const FACES = ["hate", "dislike", "neutral", "like", "love"];
    const LIKELINESS = [
      "Not at all likely",
      "Unlikely",
      "Somewhat likely",
      "Very likely",
    ];
    const { individual, getIndividual, isMember } = useIndividual();
    return {
      FACES,
      LIKELINESS,
      individual,
      getIndividual,
      isMember,
    };
  },

  mounted() {
    const fromEmail = location.search.match(/overall=(\d*)/);
    const score = fromEmail ? Number(fromEmail[1]) : null;
    if (score && score >= 1 && score <= 5) {
      this.selectedSmileValue = this.FACES[score - 1];
      this.cameFromEmail = true;
    }
  },

  computed: {
    finalised_date() {
      return this.pendingFeedbacksPresent
        ? this.pendingFeedbacks[0].finalised_date
        : null;
    },
    role_title() {
      return this.pendingFeedbacksPresent
        ? this.pendingFeedbacks[0].role_title
        : null;
    },
    role_name() {
      return this.pendingFeedbacksPresent
        ? this.pendingFeedbacks[0].role_name
        : null;
    },
    smileSelected() {
      return this.smileSelectedValue !== null;
    },
    smileSelectedValue() {
      return this.selectedSmileValue;
    },
    pendingFeedbacks() {
      return this.individual.feedbacks_needed || [];
    },
    pendingFeedbacksPresent() {
      return this.pendingFeedbacks.length > 0;
    },
    isDirectVisit() {
      return window.location.pathname.includes("end-of-process");
    },
    displayFeedbackComponent() {
      return (
        (this.pendingFeedbacksPresent && !this.hasClosedPopup) ||
        this.isDirectVisit
      );
    },
  },
  methods: {
    goToRolesPage() {
      this.$router.push("/roles");
    },
    saveChanges({ smileValue, likely, usefulness }) {
      if (smileValue) {
        this.selectedSmileValue = smileValue;
        window.analytics.track("Clicked Application Rejection Smiley", {
          smileValue: smileValue,
        });
      }
      if (usefulness) {
        this.usefulnessSelected = usefulness;
      }
      if (likely) {
        this.likelinessSelected = likely;
      }
    },
    submitFullFeedback() {
      window.analytics.track("Submitted Application Rejection Feedback", {
        role_application_id: this.pendingFeedbacks[0]._id,
      });
      this.submitAndCloseFeedback();
    },
    submitPartialFeedback() {
      window.analytics.track(
        "Closed Application Rejection Modal Without Finishing Feedback",
        {
          role_application_id: this.pendingFeedbacks[0]._id,
        }
      );
      this.submitAndCloseFeedback();
    },
    async submitAndCloseFeedback() {
      await this.submitFeedback();
      await this.getIndividual();
      this.hasClosedPopup = true;
      this.feedbackSubmitted = true;
    },
    async submitFeedback() {
      const feedback =
        this.selectedSmileValue !== null
          ? this.FACES.indexOf(this.selectedSmileValue) + 1
          : null;
      window.analytics.track("Application Rejection Feedback", {
        role_application_id: this.pendingFeedbacks[0]._id,
        feedback_received: feedback,
        likely: this.likelinessSelected,
        usefulness: this.usefulnessSelected,
      });
      try {
        await apiAxios.post("/application_rejection_feedback", {
          location: "end_of_process",
          feedback_received: feedback,
          likeliness: this.likelinessSelected,
          usefulness: this.usefulnessSelected,
          role_application_id: this.pendingFeedbacks[0]._id,
          details: this.details,
        });
      } catch (error) {
        Sentry.captureException(error);
      }
    },
  },
};
</script>
