<template>
  <div ref="container">
    <div
      class="scrollable-error rounded-lg p-6 flex items-center mb-4 bg-red-50"
      v-if="experienceError"
    >
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.0483 0L2.36243 0.647763L0.647763 2.36243L0 3.0483L0.495349 3.84848L3.0483 8.11609L3.39123 8.72575H6.97298L11.8503 13.5649C7.49215 17.9421 1.94805 23.5052 1.75277 23.7005C-0.157178 25.6105 -0.16194 28.7397 1.79088 30.5592C3.69606 32.4406 6.80152 32.4882 8.61144 30.5592C8.62573 30.5449 8.63526 30.5354 8.64955 30.5211L16.156 22.9765L23.7005 30.5592L23.8148 30.6354C25.7295 32.4406 28.7731 32.4644 30.5592 30.5592V30.5211H30.5973C32.4644 28.6111 32.4834 25.5057 30.5592 23.7005L30.5211 23.6624L24.1578 17.3372C28.4397 16.9371 31.7928 13.322 31.8547 8.95438H31.8928C31.8976 8.93056 31.8928 8.90198 31.8928 8.87817C31.8928 8.86388 31.8928 8.85435 31.8928 8.84007C32.0119 7.43023 31.7071 6.08231 30.9783 4.91538L30.1401 3.61985L24.4626 9.29731L22.7479 7.50643L28.5397 1.71467L26.8631 1.0288C25.882 0.60966 24.7484 0.30483 23.4719 0.30483C18.7851 0.30483 14.9367 4.15331 14.9367 8.84007C14.9367 9.3497 15.0462 9.79266 15.1272 10.2499C14.5937 10.7834 14.1936 11.2215 13.5649 11.8503L8.72575 7.01109V3.39123L8.1161 3.0483L3.84848 0.495348L3.0483 0ZM23.4719 2.74347C23.6434 2.74347 23.7624 2.84349 23.9291 2.85778L19.3186 7.46833L20.1569 8.34472L23.5862 11.8884L24.4245 12.7647L29.2637 7.92558C29.2875 8.20659 29.4875 8.4114 29.4542 8.72575V8.84007C29.4542 12.198 26.7155 14.9367 23.3576 14.9367C22.9099 14.9367 22.3669 14.8319 21.7572 14.708L21.1095 14.5937L20.6522 15.051L6.89678 28.8445H6.85867V28.8826C5.99181 29.8305 4.47719 29.8543 3.46744 28.8445V28.8064H3.42934C2.48151 27.9396 2.45769 26.4249 3.46744 25.4152C3.92945 24.9532 12.8791 15.9274 17.261 11.5454L17.7563 11.0501L17.5658 10.3642C17.4658 9.96889 17.3753 9.28778 17.3753 8.84007C17.3753 5.48217 20.114 2.74347 23.4719 2.74347ZM3.42934 3.0483L6.28712 4.80107V6.02039L6.02039 6.28712H4.80107L3.0483 3.42934L3.42934 3.0483ZM21.2619 17.8706L28.8445 25.4152V25.4533H28.8826C29.8305 26.3202 29.8543 27.8348 28.8445 28.8445H28.8064V28.8826C27.9396 29.8305 26.4249 29.8543 25.4152 28.8445L17.8707 21.2619L21.2619 17.8706Z"
          fill="#DF2211"
        />
      </svg>
      <div class="flex flex-col items-start justify-center ml-8">
        <span class="copy-emphasis text-grey-800">
          {{ experienceError }}
        </span>
      </div>
    </div>

    <div v-if="showMiniCv">
      <div class="state-alternate">
        Select roles to show on this application
      </div>
      <mini-cv :hide-delete="true" :show-org-description="false">
        <template v-slot="slotProps">
          <toggle-selected-role-slot
            :slotProps="{ role: slotProps, selectedRolesHeld }"
          />
        </template>
      </mini-cv>
    </div>
    <role-apply-experience-collapsed v-else />

    <div class="flex flex-col md:flex-row pt-6">
      <n-button
        v-if="showMiniCv"
        label="Save updated roles"
        :type="noRolesSelected ? 'disabled' : 'primary'"
        showLeftIcon
        leftIcon="save"
        @click="saveUpdatedRoles"
      />
      <n-button
        v-else
        label="Review my roles"
        type="secondary"
        showLeftIcon
        leftIcon="edit"
        @click="reviewMyRoles"
      />
    </div>
  </div>
</template>

<script lang="ts">
import NButton from "@/components-v2/ui-kit/Button.vue";
import RoleApplyExperienceCollapsed from "@/components/roleApplication/RoleApplyExperienceCollapsed.vue";
import ToggleSelectedRoleSlot from "@/components/roleApplication/ToggleSelectedRoleSlot.vue";
import MiniCv from "@/components/MiniCv.vue";
import useRoleApplication from "@/composables/useRoleApplication";
import useMiniCv from "@/composables/useMiniCv";
import useIndividual from "@/composables/useIndividual";

export default {
  name: "RoleApplyPersonalExperience",
  setup() {
    const { roleApplication, updateRoleApplication, getLatestRoleApplication } =
      useRoleApplication();
    const { individual, getIndividual } = useIndividual();
    const { rolesHeld, getRolesHeld, invalidRolesCount, allRolesHeldValid } =
      useMiniCv();

    return {
      roleApplication,
      individual,
      getIndividual,
      updateRoleApplication,
      rolesHeld,
      getRolesHeld,
      getLatestRoleApplication,
      invalidRolesCount,
      allRolesHeldValid,
    };
  },
  components: {
    NButton,
    RoleApplyExperienceCollapsed,
    ToggleSelectedRoleSlot,
    MiniCv,
  },
  data() {
    return {
      showMiniCv: false,
    };
  },
  async mounted() {
    if (
      !this.roleApplication.selected_roles_held ||
      this.roleApplication.selected_roles_held.length == 0
    ) {
      await this.getRolesHeld();
      await this.updateRoleApplication({ selected_roles_held: this.rolesHeld });
    }
  },
  methods: {
    async saveUpdatedRoles() {
      this.showMiniCv = false;
      await this.$nextTick();

      this.$refs.container.scrollIntoView({ behavior: "smooth" });
    },
    reviewMyRoles() {
      this.showMiniCv = true;

      this.$refs.container.scrollIntoView({ behavior: "smooth" });
    },
  },
  computed: {
    selectedRolesHeld() {
      return this.roleApplication.selected_roles_held;
    },
    experienceError() {
      if (!this.hasAtLeastThreeRoles) return "Please add at least 3 role";
      if (!this.allRolesHeldValid)
        return (
          "There is missing information on " +
          this.invalidRolesCount.toString() +
          " of your roles"
        );

      return null;
    },
    hasAtLeastThreeRoles() {
      return this.roleApplication.selected_roles_held?.length >= 3;
    },
    noRolesSelected() {
      return !this.roleApplication.selected_roles_held?.length;
    },
  },
  watch: {
    async rolesHeld() {
      await this.getLatestRoleApplication();
    },
  },
};
</script>
