<template>
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 0C9.42092 0 0 9.42092 0 21C0 32.5791 9.42092 42 21 42C32.5791 42 42 32.5791 42 21C42 9.42092 32.5791 0 21 0ZM21 3.23077C30.7973 3.23077 38.7692 11.2027 38.7692 21C38.7692 30.7973 30.7973 38.7692 21 38.7692C11.2027 38.7692 3.23077 30.7973 3.23077 21C3.23077 11.2027 11.2027 3.23077 21 3.23077ZM13.7308 14.5385C13.0881 14.5385 12.4718 14.7937 12.0174 15.2482C11.563 15.7026 11.3077 16.3189 11.3077 16.9615C11.3077 17.6042 11.563 18.2205 12.0174 18.6749C12.4718 19.1293 13.0881 19.3846 13.7308 19.3846C14.3734 19.3846 14.9897 19.1293 15.4441 18.6749C15.8986 18.2205 16.1538 17.6042 16.1538 16.9615C16.1538 16.3189 15.8986 15.7026 15.4441 15.2482C14.9897 14.7937 14.3734 14.5385 13.7308 14.5385ZM28.2692 14.5385C27.6266 14.5385 27.0103 14.7937 26.5559 15.2482C26.1014 15.7026 25.8462 16.3189 25.8462 16.9615C25.8462 17.6042 26.1014 18.2205 26.5559 18.6749C27.0103 19.1293 27.6266 19.3846 28.2692 19.3846C28.9119 19.3846 29.5282 19.1293 29.9826 18.6749C30.437 18.2205 30.6923 17.6042 30.6923 16.9615C30.6923 16.3189 30.437 15.7026 29.9826 15.2482C29.5282 14.7937 28.9119 14.5385 28.2692 14.5385Z"
      :fill="fill"
    />
    <rect x="14.7" y="26.25" width="12.6" height="2.625" :fill="fill" />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "IconSmileNeutral",

  props: {
    fill: {
      type: String,
      default: "#FFC05C",
    },
  },
});
</script>
