<template>
  <div
    class="w-full group flex flex-col rounded-lg border-solid border-grey-100"
  >
    <div
      class="flex flex-col items-start sm:items-center sm:flex-row py-3 px-5 w-full justify-between"
    >
      <div
        class="bg-grey-50 rounded-lg p-2 flex items-center justify-center mr-3 hidden md:inline"
      >
        <span class="material-icons-outlined flex items-center">
          description</span
        >
      </div>
      <div class="flex w-full items-start justify-between sm:mt-0">
        <div class="flex flex-col w-full items-start sm:mx-3">
          <span
            v-if="cv"
            data-cy="upload-cv-success-filename"
            class="copy-bold break-all text-grey-900"
          >
            {{ cv.filename }}
          </span>
          <span class="text-labelling-inactive text-grey-700">
            Added: {{ dateAdded }}
          </span>
        </div>
      </div>
      <div class="flex items-center space-x-3 mt-3 sm:mt-0">
        <span
          class="flex items-center border border-solid border-t-0 border-l-0 border-r-0 border-transparent hover:border-red-600"
        >
          <button
            @click="deleteCvAndRefresh"
            class="text-cta-tertiary button-tertiary flex items-center text-red-600 p-0 no-underline"
          >
            <span class="material-icons-round text-red-600 text-base mr-2">
              delete
            </span>
            DELETE
          </button>
        </span>

        <span
          class="flex items-center border border-solid border-t-0 border-l-0 border-r-0 border-transparent hover:border-sage-700"
        >
          <a
            :href="sanitizeUrl(cv.location)"
            class="text-cta-tertiary button-tertiary flex items-center text-sage-700 p-0 no-underline"
          >
            <span class="material-icons-outlined text-sage-700 text-base mr-2">
              file_download
            </span>
            DOWNLOAD
          </a>
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { format } from "date-fns";
import { sanitizeUrl } from "@braintree/sanitize-url";
import useCvs from "@/composables/useCvs";

export default {
  props: {
    cv: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const { getIndividualCvs, deleteCv } = useCvs();

    return {
      getIndividualCvs,
      deleteCv,
      sanitizeUrl,
    };
  },

  computed: {
    dateAdded() {
      return format(new Date(this.cv.created_at), "MMMM d, yyyy, h:mma");
    },
  },

  methods: {
    async deleteCvAndRefresh() {
      this.deleteCv(this.cv.id);
      await this.getIndividualCvs();
    },
  },
};
</script>
