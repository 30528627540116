<template>
  <div class="icon-wrapper">
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      class="fill-current relative"
    >
      <path
        d="M9.1665 5.83342H10.8332V7.50008H9.1665V5.83342ZM9.1665 9.16675H10.8332V14.1667H9.1665V9.16675ZM9.99984 1.66675C5.39984 1.66675 1.6665 5.40008 1.6665 10.0001C1.6665 14.6001 5.39984 18.3334 9.99984 18.3334C14.5998 18.3334 18.3332 14.6001 18.3332 10.0001C18.3332 5.40008 14.5998 1.66675 9.99984 1.66675ZM9.99984 16.6667C6.32484 16.6667 3.33317 13.6751 3.33317 10.0001C3.33317 6.32508 6.32484 3.33341 9.99984 3.33341C13.6748 3.33341 16.6665 6.32508 16.6665 10.0001C16.6665 13.6751 13.6748 16.6667 9.99984 16.6667Z"
      />
    </svg>
  </div>
</template>

<style scoped>
.icon-wrapper {
  @apply p-2 m-0 flex flex-col justify-center items-center w-9 !important;
  height: inherit !important;
}
</style>
