<template>
  <div class="flex flex-col">
    <div class="text-grey-900 copy-bold">
      {{ textPrompt }}
    </div>
    <div class="flex mt-2 gap-2">
      <smile-feedback-single
        v-for="value in ['hate', 'dislike', 'neutral', 'like', 'love']"
        :key="value"
        :value="value"
        :selected="selectedValue == value"
        @click="$emit('smile-clicked', value)"
        :data-cy="'smile-score-' + value"
      >
        <component
          :is="`icon-smile-${value}`"
          class="h-10 w-10 md:h-10 md:w-10 flex-none"
          :fill="selectedValue == value ? '#FFFAF0' : undefined"
        />
      </smile-feedback-single>
    </div>
  </div>
</template>
<script lang="ts">
import IconSmileHate from "@/components/icons/IconSmileHate.vue";
import IconSmileDislike from "@/components/icons/IconSmileDislike.vue";
import IconSmileNeutral from "@/components/icons/IconSmileNeutral.vue";
import IconSmileLike from "@/components/icons/IconSmileLike.vue";
import IconSmileLove from "@/components/icons/IconSmileLove.vue";

import SmileFeedbackSingle from "@/components/roleApplication/SmileFeedbackSingle.vue";

export default {
  name: "SmileFeedbackScale",
  components: {
    SmileFeedbackSingle,
    IconSmileHate,
    IconSmileDislike,
    IconSmileNeutral,
    IconSmileLike,
    IconSmileLove,
  },
  props: {
    selectedValue: { type: String, default: null },
    textPrompt: {
      type: String,
      default: "How did you find the Nurole process?",
    },
  },
};
</script>
