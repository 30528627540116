<template>
  <div>
    <div
      class="hidden md:flex flex-col items-start sm:flex-row w-full justify-between sm:items-center gap-4"
    >
      <h4 class="text-title-h2 flex-1">Additional Information</h4>
    </div>
    <validation-message
      v-if="!individual?.open_profile_additional_information"
      validationMessage="Optional : Please share any relevant information not included in other sections of your profile."
    />
    <div class="mt-6 border border-grey-200 border-solid rounded-lg p-2">
      <rich-text-editor
        v-if="isLoaded"
        :isLoaded="false"
        :initialText="individual.open_profile_additional_information"
        editorPlaceholder="Add your text here"
        @text-updated="updateIndvidualPreferences"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import debounce from "@/utils/debounce";
import useIndividual from "@/composables/useIndividual";
import RichTextEditor from "../shared/RichTextEditor.vue";
import ValidationMessage from "../ValidationMessage.vue";

const { individual, updateIndividual, isLoaded } = useIndividual();

const updateIndvidualPreferences = debounce(async event => {
  await updateIndividual(
    { open_profile_additional_information: event },
    "additional_information"
  );
});
</script>
