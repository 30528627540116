<template>
  <div class="flex flex-col items-start" data-cy="role-details" v-if="role">
    <!-- This a tag is broken, I think due to theVueRouter -->
    <a href="/roles" class="w-40">
      <nurole-text
        :width="160"
        class="mr-24 hidden lg:block cursor-pointer"
        color="#3A4349"
      />
    </a>
    <div class="mb-4 flex items-center mt-8">
      <div
        class="hidden lg:flex border border-solid bg-white border-grey-200 rounded-lg w-20 h-20 justify-center items-center"
      >
        <img
          v-if="role.logo_thumbnail_public"
          class="object-contain w-20 h-20 p-2"
          :src="role.logo_thumbnail_public"
        />
        <span
          v-else
          class="hidden lg:block material-icons-round text-36/36 text-grey-400 flex-none"
        >
          business
        </span>
      </div>
    </div>
    <span class="text-title-h2 inline lg:hidden">
      {{ role.title }}
    </span>
    <span class="text-title-h3 hidden lg:inline" data-cy="role-title">
      {{ role.title }}
    </span>
    <span class="text-grey-800 mt-2">
      {{ role.name }}
    </span>
    <role-type-badge
      :variant="role.sector === 'nfp' ? 'probono' : 'compensated'"
      class="my-4"
    />
    <div class="pb-2 mr-4 text-grey-800 text-nudging flex items-center">
      <span class="material-icons-round mr-2 mt-1 text-16/16">
        calendar_today
      </span>
      {{ commitment }}
    </div>
    <div class="py-2 mr-4 text-grey-800 text-nudging flex items-center">
      <span class="material-icons-round mr-2 text-16/16"> flight </span>
      {{ formatLocation(role) }}
    </div>
    <router-link
      :to="`/roles/${$route.params.roleId}`"
      class="mt-2 button-secondary text-cta-secondary text-center w-full sm:w-auto flex bg-sage-50 hover:bg-sage-100 my-auto align-middle text-sage-700 hover:text-sage-800"
    >
      <span class="material-icons-round text-18/26 mr-2"> arrow_back </span>
      Role details
    </router-link>
  </div>
</template>

<script lang="ts">
import NuroleText from "@/components/shared/NuroleText.vue";
import RoleTypeBadge from "@/components-v2/RoleTypeBadge.vue";
import { formatDeadline, formatLocation } from "@/helpers/roleHelpers";
import useRole from "@/composables/useRole";

export default {
  components: {
    NuroleText,
    RoleTypeBadge,
  },

  setup() {
    const { role } = useRole();

    return { role, formatDeadline, formatLocation };
  },

  computed: {
    commitment() {
      return this.role.part_time ? this.role.commitment : "Full time";
    },
  },
};
</script>
