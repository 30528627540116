<template>
  <!-- Roles held (including recently deleted) -->
  <mini-cv-role
    v-for="role in sortedRolesHeld"
    :key="role.id"
    :role="role"
    :allow-delete="false"
    :show-org-description="false"
    @open-role-held="openRoleHeld"
    @open-org-data-modal="openOrgDataModal"
  >
  </mini-cv-role>

  <!-- Add or edit role held -->
  <role-held-modal v-if="openedRoleHeld" @close-modal="openedRoleHeld = null">
    <template #title>
      {{ openedRoleHeld.id ? "Edit experience" : "Add experience" }}
    </template>
    <role-held-form
      :role-held="openedRoleHeld"
      :show-org-description="false"
      @close-form="openedRoleHeld = null"
      @save-role-held="saveRoleHeld"
      @delete-role-held="deleteRoleHeld"
    />
  </role-held-modal>

  <organisation-data-modal
    v-if="openedOrgDataModal"
    @close-modal="openedOrgDataModal = null"
    :role-held="openedOrgDataModal"
    :selected-button="selectedOrgDataButton"
    @save-role-held-org-data="saveRoleHeld"
  />

  <add-role-button @click="addRoleHeld" class="mt-5 sm:mt-7" />
</template>

<script lang="ts">
import { v4 as uuidv4 } from "uuid";
import MiniCvRole from "@/components/MiniCvRole.vue";
import RoleHeldModal from "@/components/miniCv/RoleHeldModal.vue";
import { sortRolesHeld } from "@/helpers/miniCv";
import AddRoleButton from "@/components/miniCv/AddRoleButton.vue";
import { RoleHeld } from "@/composables/useMiniCv";
import useRequestInvite from "@/composables/useRequestInvite";
import RoleHeldForm from "./miniCv/RoleHeldForm.vue";
import OrganisationDataModal from "@/components/miniCv/OrganisationDataModal.vue";

export default {
  name: "LoggedOutMiniCv",
  components: {
    OrganisationDataModal,
    MiniCvRole,
    RoleHeldModal,
    AddRoleButton,
    RoleHeldForm,
  },
  setup() {
    const { roles, updateRoles } = useRequestInvite();

    return {
      rolesHeld: roles,
      updateRoles,
    };
  },
  data() {
    return {
      openedRoleHeld: null as RoleHeld | null,
      openedOrgDataModal: null as RoleHeld | null,
      selectedOrgDataButton: "",
    };
  },
  methods: {
    openRoleHeld(roleHeld: Partial<RoleHeld> = {}) {
      this.openedRoleHeld = roleHeld;
    },
    openOrgDataModal(roleHeld: Partial<RoleHeld> = {}, button: string) {
      this.openedOrgDataModal = roleHeld;
      this.selectedOrgDataButton = button;
    },
    saveRoleHeld(roleHeld: Partial<RoleHeld>) {
      if (roleHeld.id) {
        this.updateRoleHeld(roleHeld);
      } else {
        this.addRoleHeld(roleHeld);
      }

      this.openedRoleHeld = null;
      this.openedOrgDataModal = null;
      this.selectedOrgDataButton = "";
    },
    deleteRoleHeld(id: string) {
      this.updateRoles(this.rolesHeld.filter(roleHeld => roleHeld.id != id));
      this.openedRoleHeld = null;
    },
    addRoleHeld() {
      const newRoleHeld = { id: uuidv4() };
      this.updateRoles([...this.rolesHeld, newRoleHeld]);
      this.openedRoleHeld = newRoleHeld;
    },
    updateRoleHeld(updatedRoleHeld: RoleHeld) {
      this.updateRoles(
        this.rolesHeld.map(existingRoleHeld =>
          existingRoleHeld.id == updatedRoleHeld.id
            ? updatedRoleHeld
            : existingRoleHeld
        )
      );
    },
  },
  computed: {
    sortedRolesHeld() {
      return sortRolesHeld(this.rolesHeld);
    },
  },
};
</script>
