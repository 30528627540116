<template>
  <div class="w-80 flex flex-col shadow-lg bg-white py-2">
    <div
      class="flex flex-col border-t-0 border-l-0 border-r-0 border-b border-solid border-grey-200 pb-2"
    >
      <router-link
        v-for="(link, index) in links"
        :key="index"
        :to="link.href"
        @click="handleLinkClick(link.label)"
        class="text-cta-secondary m-0 text-grey-900 hover:bg-sage-50 py-4 px-4 flex gap-2 items-center"
        >{{ link.label
        }}<mark v-if="link.highlightAsNew">NEW</mark></router-link
      >

      <a
        href="https://help.nurole.com"
        target="_blank"
        rel="noopener"
        class="text-cta-secondary m-0 text-grey-900 hover:bg-sage-50 py-4 px-4 flex items-center"
      >
        Help Centre<svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="ml-3"
        >
          <path
            d="M1.33341 0.666626V1.99996H7.06008L0.666748 8.39329L1.60675 9.33329L8.00008 2.93996V8.66663H9.33341V0.666626H1.33341Z"
            fill="#C8C8C8"
          />
        </svg>
      </a>
    </div>
    <div class="flex flex-col space-y-4 items-start px-4 my-2 py-2 pt-4">
      <a
        v-for="(cta, index) in ctas"
        :key="index"
        :href="cta.href"
        :data-cy="cta.dataCy"
        class="border border-solid py-3 px-4"
        :class="
          cta.primary
            ? 'text-white text-cta-primary bg-sage-600  hover:bg-sage-700 active:bg-sage-800'
            : 'text-cta-secondary border-sage-600 text-sage-600  hover:border-sage-700  hover:text-sage-700 bg-white  hover:bg-sage-50 active:bg-sage-100'
        "
        @click="$emit('close-menu')"
      >
        {{ cta.label }}
      </a>
      <button
        @click="logout"
        class="border border-solid py-3 px-4 text-cta-secondary border-sage-600 text-sage-600 hover:border-sage-700 hover:text-sage-700 bg-white hover:bg-sage-50 active:bg-sage-100"
      >
        Sign out
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import logout from "@/lib/logout";

export default {
  name: "DesktopComposite",
  props: {
    individual: {
      type: Object,
    },
  },
  setup() {
    return {
      communityURL: process.env.VITE_COMMUNITY_DOMAIN,
      adminSiteURL: process.env.VITE_ADMIN_URL,
      logout,
    };
  },
  computed: {
    isAdmin() {
      return this.individual?.is_admin_site_user;
    },
    links() {
      return [
        {
          label: "My Profile",
          href: "/account/profile",
        },
        {
          label: "Watchlist",
          href: "/account/watchlist",
        },
        {
          label: "Applications",
          href: "/account/applications",
        },
        {
          label: "Recommendations",
          href: "/account/recommendations",
        },
        {
          label: "Settings",
          href: "/account/settings",
        },
        {
          label: "Reconfigure my preferences",
          href: "/account/preferences",
        },
      ];
    },
    ctas() {
      return [
        {
          label: "Recommend someone",
          href: "/recommend",
          primary: true,
          dataCy: null,
        },
        ...(this.isAdmin
          ? [
              {
                label: "Go to admin site",
                href: this.adminSiteURL,
                primary: false,
                dataCy: "go-to-admin-site-button",
              },
            ]
          : []),
      ];
    },
  },
  methods: {
    handleLinkClick(linkLabel: string) {
      window.analytics.track(linkLabel + " clicked");
      this.$emit("close-menu");
    },
  },
};
</script>
<style scoped>
.slide-left-enter-active {
  transition: transform 0.15s ease-out;
}
.slide-left-enter,
.slide-left-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.slide-right-enter-active {
  transition: transform 0.15s ease-out;
}
.slide-right-enter,
.slide-right-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}
</style>
