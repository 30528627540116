<template>
  <segment
    hide-divider
    variant="h4"
    :title="field.heading"
    :description="field.description"
  />
  <role-apply-rich-text-editor
    :uniqueId="field.id"
    :initialText="field.response"
    :recommendedWordCount="recommendedWordCount"
    @text-updated="textUpdated"
    dataCy="role-apply-rich-text"
    class="mb-8 lg:mb-0"
  />
</template>

<script>
// todo: replace RoleApplyRichTextEditor with @/components-v2/ui-kit/RichTextEditor
import RoleApplyRichTextEditor from "@/components/roleApplication/RoleApplyRichTextEditor.vue";
import Segment from "@/components-v2/ui-kit/Segment.vue";

export default {
  name: "RoleApplyExtraFieldRichText",

  components: {
    RoleApplyRichTextEditor,
    Segment,
  },

  props: {
    field: {
      type: Object,
      required: true,
    },
  },

  methods: {
    textUpdated(text) {
      this.$emit("text-updated", { text, id: this.field.id });
    },
  },

  computed: {
    recommendedWordCount() {
      return Number(this.field.recommended_word_count);
    },
  },
};
</script>
