<template>
  <div class="w-full py-6 border-b border-grey-100 last:border-b-0">
    <div class="text-divider flex items-center mb-2" v-if="role.pinned">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.6666 8.57998C12.6666 8.26665 12.4399 8.01331 12.1333 7.92665C11.2866 7.69331 10.6666 6.91998 10.6666 5.99998V2.66665H11.3333C11.6999 2.66665 11.9999 2.36665 11.9999 1.99998C11.9999 1.63331 11.6999 1.33331 11.3333 1.33331H4.66659C4.29992 1.33331 3.99992 1.63331 3.99992 1.99998C3.99992 2.36665 4.29992 2.66665 4.66659 2.66665H5.33325V5.99998C5.33325 6.91998 4.71325 7.69331 3.86659 7.92665C3.55992 8.01331 3.33325 8.26665 3.33325 8.57998V8.66665C3.33325 9.03331 3.63325 9.33331 3.99992 9.33331H7.31992L7.33325 14C7.33325 14.3666 7.63325 14.6666 7.99992 14.6666C8.36659 14.6666 8.66659 14.3666 8.66659 14L8.65325 9.33331H11.9999C12.3666 9.33331 12.6666 9.03331 12.6666 8.66665V8.57998Z"
          fill="#565958"
        />
      </svg>
      <div class="ml-2">FEATURED</div>
    </div>
    <div
      class="text-divider flex items-center mb-2"
      v-if="!role.pinned && isCurrent"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M10.6667 7.33334H5.33333C4.96667 7.33334 4.66667 7.63334 4.66667 8.00001C4.66667 8.36668 4.96667 8.66668 5.33333 8.66668H10.6667C11.0333 8.66668 11.3333 8.36668 11.3333 8.00001C11.3333 7.63334 11.0333 7.33334 10.6667 7.33334ZM12.6667 2.66668H12V2.00001C12 1.63334 11.7 1.33334 11.3333 1.33334C10.9667 1.33334 10.6667 1.63334 10.6667 2.00001V2.66668H5.33333V2.00001C5.33333 1.63334 5.03333 1.33334 4.66667 1.33334C4.3 1.33334 4 1.63334 4 2.00001V2.66668H3.33333C2.59333 2.66668 2 3.26668 2 4.00001V13.3333C2 14.0667 2.59333 14.6667 3.33333 14.6667H12.6667C13.4 14.6667 14 14.0667 14 13.3333V4.00001C14 3.26668 13.4 2.66668 12.6667 2.66668ZM12 13.3333H4C3.63333 13.3333 3.33333 13.0333 3.33333 12.6667V6.00001H12.6667V12.6667C12.6667 13.0333 12.3667 13.3333 12 13.3333ZM8.66667 10H5.33333C4.96667 10 4.66667 10.3 4.66667 10.6667C4.66667 11.0333 4.96667 11.3333 5.33333 11.3333H8.66667C9.03333 11.3333 9.33333 11.0333 9.33333 10.6667C9.33333 10.3 9.03333 10 8.66667 10Z"
          fill="#565958"
        />
      </svg>
      <div class="ml-2">CURRENT ROLE</div>
    </div>
    <div class="flex justify-between gap-2">
      <div class="copy-bold" data-cy="role-held-position">
        {{ role.position }}
      </div>
      <div class="text-labelling-inactive" data-cy="role-held-dates">
        {{ getDisplayDates(role) }}
      </div>
    </div>
    <div class="mt-1 text-labelling-inactive">
      {{ role.organisation }}
    </div>
    <clamped-text
      :text="role.organisation_description"
      classes="text-labelling-inactive"
    ></clamped-text>
    <ul class="mt-3 md:flex-1 flex flex-wrap items-center" v-if="badges.length">
      <li
        v-for="badge in badges"
        :key="badge"
        class="whitespace-nowrap text-nudging rounded px-3 py-1 mr-2 mt-2 bg-grey-75"
        data-organisation-badge
      >
        {{ badge }}
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { getDisplayDates, isRoleHeldCurrent } from "@/helpers/miniCv";
import { computed } from "vue";
import { RoleHeld } from "@/composables/useMiniCv";
import getOrganisationSize from "@/helpers/getOrganisationSize";
import ClampedText from "../shared/ClampedText.vue";

const props = defineProps<{
  role: RoleHeld;
}>();

const isCurrent = computed(() => isRoleHeldCurrent(props.role));

const badges = computed(() =>
  [
    props.role.organisation_sector,
    props.role.organisation_ownership,
    getOrganisationSize({ size: props.role.organisation_size }),
  ].filter(badge => badge && badge.length)
);
</script>
