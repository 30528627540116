<template>
  <div
    class="bg-white max-w-screen-xl mx-auto w-full p-4 md:px-12 md:pt-6 lg:px-28 lg:pt-10 pb-24"
  >
    <h3 class="copy-emphasis mt-8">
      Your watchlist ({{ watchedRoles.length }})
    </h3>
    <p class="mt-3 mb-8">
      We will send you a reminder email before a watched role closes and will
      let you know if a watched role is updated.
    </p>
    <div
      class="w-full py-6 px-4 md:py-18 rounded-lg bg-grey-50 text-text-grey-700 text-center"
      v-if="watchedRoles.length == 0"
    >
      <h4 class="copy-emphasis text-grey-700">
        You are not currently watching any roles.
      </h4>
      <p class="mt-3 text-grey-700">
        To add a role to your watchlist, go to the roles list, find a relevant
        role and click the "add to watchlist" link.
      </p>
    </div>
    <watched-role
      v-for="role in watchedRoles"
      :key="role.id"
      :role="role"
      class="mb-3"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, computed } from "vue";
import useRoles from "@/composables/useRoles";
import useWatchlist from "@/composables/useWatchlist";
import WatchedRole from "@/components/account/WatchedRole.vue";
import { Role } from "@/models/Role";

const { watchlist, refreshWatchlist } = useWatchlist();
const { roles } = useRoles();

const watchedRoles = computed(() => {
  return roles.value.filter((role: Role) => watchlist.value.includes(role.id));
});

onMounted(async () => {
  await refreshWatchlist();
});
</script>
