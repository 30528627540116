<template>
  <div
    class="flex justify-center items-center bg-grey-50 text-grey-600 w-full py-9 px-7"
  >
    <i class="las la-file-alt la-2x mr-2" />
    You don’t have any existing CVs
  </div>
</template>

<script lang="ts">
export default {
  name: "CvNone",
};
</script>
