<template>
  <the-modal v-if="modalOpen" :easyToClose="true" @close-modal="closeModal">
    <template #header>
      <h2 class="hidden sm:block">Introducing the Nurole Profile</h2>
      <p class="block sm:hidden">
        <em class="copy-emphasis">Introducing the Nurole Profile</em>
      </p>
    </template>
    <h3 class="copy-emphasis">Expand your profile visibility</h3>
    <p class="mt-1">
      The Nurole team can view your profile to assess your fit for roles. Would
      you like to also allow
    </p>
    <profile-privacy-options></profile-privacy-options>
    <div class="mt-6 flex gap-2">
      <button
        @click="saveHandler"
        class="button-primary text-cta-primary w-full md:w-auto"
      >
        Update
      </button>
    </div>
  </the-modal>
</template>

<script setup lang="ts">
import TheModal from "@/components/TheModal.vue";
import { individual, isLoaded } from "@/composables/useIndividual";
import useFlagsmith from "@/composables/useFlagsmith";
import { ref, watch } from "vue";
import ProfilePrivacyOptions from "@/components/profile/ProfilePrivacyOptions.vue";

const modalOpen = ref(false);

const { flagsmith, identified } = useFlagsmith();

const previouslyShown =
  document.cookie.replace(
    /(?:(?:^|.*;\s*)privacyModalShown\s*=\s*([^;]*).*$)|^.*$/,
    "$1"
  ) === "true";

watch(
  [identified, isLoaded],
  () => {
    if (
      window.location.pathname.includes("/onboarding") ||
      window.location.search.includes("preview=")
    ) {
      return;
    }
    if (identified.value && isLoaded.value) {
      const featureEnabled = flagsmith.value.hasFeature(
        "open_profile_privacy_modal"
      );

      const shouldUpdatePrivacy =
        !individual.value.profile_privacy ||
        individual.value.profile_privacy == "nurole";

      if (
        featureEnabled &&
        !previouslyShown &&
        shouldUpdatePrivacy &&
        !individual.value.is_guest
      ) {
        window.analytics.track("Nurole profile introduction modal shown");
        modalOpen.value = true;
      }
    }
  },
  { immediate: true }
);

const saveHandler = () => {
  modalOpen.value = false;
  document.cookie =
    "privacyModalShown=true; path=/; max-age=630720000; domain=nurole.com;";
};

defineExpose({
  modalOpen,
});

function closeModal() {
  window.analytics.track("Nurole profile introduction modal closed", {
    profilePrivacy: individual.value.profile_privacy,
  });
  modalOpen.value = false;
}
</script>
