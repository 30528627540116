<template>
  <template v-if="isReadyToEdit">
    <div class="flex flex-col gap-3">
      <button
        v-for="cv in latestTwoCvs"
        :key="cv.id"
        @click="$emit('select-cv', cv)"
        class="rounded-lg border py-4 px-6 flex align-start gap-5 items-center"
        :class="{
          'ring-1 border-sage-200 ring-sage-200':
            quickApplication.cv_id == cv.id,
        }"
      >
        <svg
          v-if="quickApplication.cv_id == cv.id"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 2.5C6.48 2.5 2 6.98 2 12.5C2 18.02 6.48 22.5 12 22.5C17.52 22.5 22 18.02 22 12.5C22 6.98 17.52 2.5 12 2.5ZM12 20.5C7.58 20.5 4 16.92 4 12.5C4 8.08 7.58 4.5 12 4.5C16.42 4.5 20 8.08 20 12.5C20 16.92 16.42 20.5 12 20.5Z"
            fill="#3C6955"
          />
          <path
            d="M12 17.5C14.7614 17.5 17 15.2614 17 12.5C17 9.73858 14.7614 7.5 12 7.5C9.23858 7.5 7 9.73858 7 12.5C7 15.2614 9.23858 17.5 12 17.5Z"
            fill="#3C6955"
          />
        </svg>
        <svg
          v-else
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 2.5C6.48 2.5 2 6.98 2 12.5C2 18.02 6.48 22.5 12 22.5C17.52 22.5 22 18.02 22 12.5C22 6.98 17.52 2.5 12 2.5ZM12 20.5C7.58 20.5 4 16.92 4 12.5C4 8.08 7.58 4.5 12 4.5C16.42 4.5 20 8.08 20 12.5C20 16.92 16.42 20.5 12 20.5Z"
            fill="#3C6955"
          />
        </svg>

        <div class="flex-1 text-left">
          <div class="copy-bold">{{ cv.filename }}</div>
          <div>Added: {{ format(new Date(cv.created_at), "MMM d, yyyy") }}</div>
        </div>
      </button>

      <div
        class="rounded-lg py-4 px-6 flex flex-col sm:flex-row gap-4 justify-center bg-error"
        v-if="cvUploadState == 'failed'"
      >
        Failed to upload CV
      </div>
      <upload-cv @cv-change="onCvSelect" />
    </div>
  </template>
  <template v-else>
    <div class="bg-neutral animate-pulse rounded-lg h-[86px]"></div>
  </template>
</template>

<script setup lang="ts">
import {
  quickApplication,
  cvs,
  isReadyToEdit,
  refreshCvs,
} from "@/lib/quickApply";
import { format } from "date-fns";
import UploadCv from "@/components/cv/UploadCv.vue";
import { Ref, computed, ref } from "vue";

const emit = defineEmits(["select-cv"]);

const cvUploadState: Ref<"not-started" | "uploading" | "failed" | "success"> =
  ref("not-started");

const latestTwoCvs = computed(() => {
  return cvs.value.slice(0, 2);
});

function onCvSelect(updatedCv) {
  refreshCvs();
  emit("select-cv", updatedCv);
}
</script>
