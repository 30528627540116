import { apiAxios } from "@/lib/axios";
import { RequestQueue } from "@/utils/RequestQueue";
import { ref, readonly } from "vue";

const watchlist = ref<any[]>([]);
const watchlistRequestQueue = new RequestQueue();

async function refreshWatchlist() {
  return new Promise((resolve, reject) => {
    watchlistRequestQueue.enqueue({
      apiCall: () => {
        return apiAxios.get("watchlist");
      },
      onSuccess: (response: any) => {
        watchlist.value = response.data;
        resolve(response.data);
      },
      onError: (error: any) => {
        watchlist.value = [];
        reject(error);
      },
    });
  });
}

async function addToWatchlist(id: string) {
  return new Promise((resolve, reject) => {
    watchlistRequestQueue.enqueue({
      apiCall: () => {
        return apiAxios.post("watchlist", { role_id: id });
      },
      onSuccess: (response: { data: any[] }) => {
        watchlist.value = response.data;
        resolve(response.data);
      },
      onError: (error: any) => {
        reject(error);
      },
    });
  });
}

async function removeFromWatchlist(id: string) {
  return new Promise((resolve, reject) => {
    watchlistRequestQueue.enqueue({
      apiCall: () => {
        return apiAxios.delete("watchlist", {
          data: { role_id: id },
        });
      },
      onSuccess: (response: { data: any[] }) => {
        watchlist.value = response.data;
        resolve(response.data);
      },
      onError: (error: any) => {
        reject(error);
      },
    });
  });
}

export default function useWatchlist() {
  return {
    watchlist: readonly(watchlist),
    refreshWatchlist,
    addToWatchlist,
    removeFromWatchlist,
  };
}
