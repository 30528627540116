export const parseNumber = (value: unknown, defaultNumber = 0) => {
  if (value === 0) return 0;
  if (!value) return defaultNumber;
  if (Number.isNaN(value)) {
    //* Regexp to remove all non-numeric characters (eg.: >> "\"150\"" <<)
    const parsedValue = Number(`${value}`.replace(/\D/g, ""));
    return Number.isNaN(parsedValue) ? defaultNumber : parsedValue;
  }
  return Number(value);
};
