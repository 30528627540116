<template>
  <div
    class="flex flex-col items-center justify-center w-full bg-white my-0 md:my-6"
  >
    <div
      class="border-solid border-r-0 border-l-0 border-t-0 w-full md:pt-5 px-5 md:px-24"
    >
      <h2 class="text-title-h2 mb-8 text-left">
        {{ title }}
      </h2>
    </div>
    <div class="w-full px-5 md:px-24">
      <h2 class="copy-emphasis mb-6">
        We notify you about compensated roles based on your experience
      </h2>
      <informative-banner
        title="Professional experience"
        icon="business_center"
      >
        <div>
          <p class="">
            To receive the most relevant compensated roles, please review your
            roles regularly to ensure your professional experience is up to date
            and relevant.
          </p>
          <router-link
            v-if="isReconfigurePage"
            to="/account/profile/experience"
            class="text-cta-tertiary text-sage-700 pb-6"
          >
            Update professional experience
          </router-link>
        </div>
      </informative-banner>
      <div>
        <h2
          class="copy-emphasis pt-10 border-solid border-r-0 border-l-0 border-b-0"
        >
          What's your professional 'DNA'?
        </h2>
        <p class="">
          Please provide a very top level overview of yourself which captures
          the core elements of your professional experience.
        </p>
        <textarea
          v-model="preferencesText"
          @input="onTextUpdated"
          placeholder="Example: I am a growth expert with a general compensated focus, specialising in leading companies from start-up to c.£50m revenue."
          class="textarea w-full mt-6 mb-16"
          rows="3"
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import debounce from "@/utils/debounce";
import { Individual } from "@/models/Individual";
import { PropType } from "vue";
import InformativeBanner from "@/components/InformativeBanner.vue";

export default {
  name: "Compensated",

  emits: ["update-individual-and-put", "set-on-finish-step-callback"],

  components: {
    InformativeBanner,
  },

  props: {
    title: {
      type: String,
    },
    individual: {
      type: Object as PropType<Individual>,
    },
  },

  data() {
    return {
      preferencesText: "",
    };
  },

  created() {
    this.preferencesText = this.individual.commercial_preferences_free_text;
    this.$emit("set-on-finish-step-callback", this.hasTrackedFreeText);
  },

  computed: {
    isReconfigurePage() {
      return this.$route.name === "MyPreferencesCompensated";
    },
  },

  methods: {
    onTextUpdated: debounce(function () {
      this.$emit("update-individual-and-put", {
        commercial_preferences_free_text: this.preferencesText,
      });
    }, 1000),

    hasTrackedFreeText() {
      const trackingData = {
        userId: this.individual.id,
        commercialTextbox: this.preferencesText,
      };

      window.analytics.track("Commercial free text", trackingData);

      return true;
    },
  },
};
</script>
