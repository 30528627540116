<template>
  <div
    class="bg-white px-5 sm:px-12 lg:px-28 w-full py-5 md:py-12 my-5 md:my-0 md:border-b md:border-t-0 md:border-l-0 md:border-r-0 md:border-solid md:border-grey-100"
  >
    <preview-accordion>
      <template v-slot:title>
        <span class="text-title-h2 text-grey-800">
          Professional experience
        </span>
      </template>
      <template v-slot:content>
        <div class="flex justify-between flex-col">
          <current-or-previous-role
            v-for="role in selectedRolesHeld"
            :key="role.id"
            :role="role"
            :isChoosingRoles="false"
            class="border-t-0 border-l-0 border-r-0 border-b border-solid border-grey-100 last:border-b-0"
          />
        </div>
      </template>
    </preview-accordion>
  </div>
</template>

<script lang="ts">
import PreviewAccordion from "@/components/roleApplication/PreviewAccordion.vue";
import CurrentOrPreviousRole from "@/components/roleApplication/CurrentOrPreviousRole.vue";
import useRoleApplication from "@/composables/useRoleApplication";
import useIndividual from "@/composables/useIndividual";
import { sortRolesHeld } from "@/helpers/miniCv";

export default {
  components: {
    PreviewAccordion,
    CurrentOrPreviousRole,
  },
  setup() {
    const { individual } = useIndividual();
    const { roleApplication } = useRoleApplication();

    return {
      individual,
      roleApplication,
    };
  },
  computed: {
    selectedRolesHeld() {
      const experience =
        this.roleApplication.selected_roles_held ||
        this.individual.roles_held.filter(roleHeld => !roleHeld.archived_date);

      return sortRolesHeld(experience);
    },
  },
};
</script>
