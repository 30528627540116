<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    :width="`${calcWidth}px`"
    :height="`${calcHeight}px`"
    viewBox="0 0 3332 386"
    preserveAspectRatio="xMidYMid meet"
  >
    <title>Nurole logo</title>
    <g
      transform="translate(0,386) scale(0.10,-0.10)"
      :fill="color"
      stroke="none"
    >
      <path
        d="M335 3584 c-51 -12 -92 -38 -115 -71 l-25 -37 -3 -1578 c-2 -1553 -2
-1579 17 -1617 71 -139 250 -146 329 -13 l27 47 5 1205 5 1206 767 -1246 c601
-974 775 -1250 802 -1268 90 -61 248 -48 303 25 l28 36 3 1581 c1 870 0 1591
-3 1603 -7 29 -61 90 -94 107 -71 36 -147 27 -207 -26 -75 -66 -68 44 -74
-1299 l-5 -1206 -755 1225 c-415 674 -766 1238 -779 1254 -13 16 -40 37 -60
47 -37 19 -131 33 -166 25z"
      />
      <path
        d="M6700 3578 c-50 -14 -97 -53 -123 -101 l-22 -42 0 -1155 0 -1155 22
-100 c53 -239 123 -378 265 -530 175 -188 406 -289 723 -316 346 -30 677 55
895 230 146 117 272 306 324 485 57 196 57 200 54 1428 l-3 1126 -30 44 c-43
60 -105 93 -175 92 -60 -1 -90 -13 -133 -53 -66 -61 -61 32 -67 -1241 -6
-1151 -6 -1155 -28 -1238 -67 -251 -223 -416 -457 -484 -69 -20 -103 -23 -250
-23 -147 0 -181 3 -248 23 -161 46 -276 129 -362 262 -52 79 -70 121 -98 225
-21 79 -21 94 -27 1235 -6 1286 1 1182 -74 1248 -54 47 -115 60 -186 40z"
      />
      <path
        d="M19979 3579 c-250 -20 -468 -108 -623 -251 -188 -171 -305 -410 -351
-713 -22 -146 -22 -1324 0 -1470 45 -298 154 -527 332 -695 173 -165 374 -247
663 -271 230 -19 491 17 658 92 288 130 489 391 568 739 41 182 46 300 41 955
-5 648 -5 645 -57 849 -67 257 -239 495 -447 617 -149 88 -296 132 -499 149
-132 11 -147 11 -285 -1z m382 -398 c284 -73 442 -277 489 -634 8 -55 10 -296
7 -722 l-3 -640 -27 -107 c-86 -354 -316 -528 -697 -528 -427 0 -662 215 -720
660 -7 57 -10 294 -8 720 3 625 4 637 27 735 74 318 258 492 563 535 94 13
281 4 369 -19z"
      />
      <path
        d="M25515 3585 c-5 -2 -22 -6 -37 -9 -37 -8 -95 -61 -119 -107 -19 -37
-19 -74 -19 -1577 l0 -1540 33 -48 c18 -27 49 -56 72 -68 40 -21 50 -21 875
-21 459 0 847 3 862 7 70 20 128 105 128 188 0 94 -59 168 -150 189 -29 7
-295 11 -727 11 l-683 0 -2 1418 -3 1418 -28 42 c-15 23 -44 53 -65 66 -34 23
-113 40 -137 31z"
      />
      <path
        d="M13070 3541 c-88 -18 -154 -93 -165 -184 -3 -29 -5 -722 -3 -1542 l3
-1490 28 -47 c15 -26 40 -56 57 -68 44 -31 125 -45 178 -31 56 16 123 82 140
137 9 31 12 199 12 658 l0 616 308 0 307 0 39 -62 c252 -402 420 -691 604
-1041 84 -160 135 -247 158 -267 78 -68 192 -61 277 19 42 38 57 73 57 131 0
46 -12 75 -108 263 -115 225 -218 407 -443 784 -77 127 -139 234 -139 236 0 3
33 15 73 27 279 85 482 290 559 565 20 73 22 102 23 335 0 236 -2 261 -23 340
-89 327 -332 528 -727 601 -100 19 -158 21 -645 24 -294 2 -551 0 -570 -4z
m1172 -409 c108 -30 183 -69 248 -129 109 -100 140 -195 140 -428 0 -238 -30
-326 -150 -438 -74 -70 -145 -108 -258 -139 -72 -20 -106 -22 -489 -26 l-413
-4 0 597 0 596 423 -3 c394 -4 427 -6 499 -26z"
      />
      <path
        d="M31267 3540 c-48 -9 -84 -35 -122 -89 l-30 -43 -3 -1511 c-3 -1689
-8 -1562 68 -1631 22 -20 52 -39 67 -43 36 -10 1679 -10 1715 0 66 18 137 114
138 185 0 66 -49 144 -115 180 -27 15 -101 17 -748 20 l-717 3 2 537 3 537
630 5 c453 4 636 8 650 17 26 15 69 62 85 92 7 14 13 48 14 77 1 69 -41 138
-103 171 l-44 23 -619 0 -618 0 0 540 0 539 683 3 c679 3 682 3 723 25 110 59
135 204 51 299 -64 74 -3 69 -882 71 -434 0 -807 -2 -828 -7z"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
export default defineComponent({
  name: "NuroleText",
  props: {
    width: { type: Number as PropType<number>, default: 0 },
    height: { type: Number as PropType<number>, default: 0 },
    color: { default: "#000000", type: String },
  },
  data: () => ({
    ratioHeight: 0.11625,
    ratioWidth: 8.602150537634408,
  }),
  computed: {
    calcWidth(): number {
      if (this.width) return this.width;
      return this.height * this.ratioWidth;
    },

    calcHeight(): number {
      if (this.height) return this.height;
      return this.width * this.ratioHeight;
    },
  },
});
</script>
