<template>
  <footer class="bg-sage-800 text-white p-8">
    <div class="mx-auto max-w-screen-xl">
      <div class="flex items-center">
        <div class="mb-0 flex-grow">
          <svg
            width="40"
            height="23"
            viewBox="0 0 139 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="w-10"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M67.2837 10.0436C67.8253 5.18319 70.1888 3.47804 76.0333 3.45604V0H50.0054V3.45604C57.7815 3.88813 60.1566 5.5077 60.698 10.2597C60.733 10.7677 60.7766 11.3269 60.825 11.9482C61.0752 15.1581 61.4546 20.0274 61.4546 28.0796V49.8988H61.1301L18.4687 0H0V3.45604C4.75208 3.88813 7.12626 4.7511 9.28727 7.45072C11.2322 9.93708 11.4473 11.3388 11.4473 18.7924V41.6887C11.4473 51.4089 10.9077 56.2705 10.4757 59.8349C10.0437 64.6936 7.45169 66.3142 1.40364 66.7471V70.2021H27.4335V66.7471C19.7638 66.3142 17.6038 64.587 17.0634 59.5105C16.7408 56.2705 16.3088 51.4089 16.3088 41.6887V16.4153H16.5238L61.8846 71.2822H66.4226V28.0796C66.4226 18.3604 66.8556 13.3923 67.2837 10.0436ZM114.6 51.3819C119.154 57.5445 128.34 69.9758 130.839 72.2672C133.605 74.9005 135.718 76.1667 138.485 76.903V80C138.052 80 137.434 79.9921 136.754 79.9833C135.922 79.9726 134.997 79.9607 134.205 79.9607C121.224 79.4327 116.535 76.6908 111.365 69.9487C107.88 65.309 96.6621 48.6862 93.1773 43.3111C91.1326 40.2545 89.0905 38.8845 84.2829 38.8845H84.0801V34.9859H85.6018C91.0124 34.9859 94.9774 34.2487 97.8641 32.1404C102.072 29.0856 103.877 24.8712 103.877 19.3895C103.877 8.00751 95.9708 3.45604 88.3984 3.45604H84.0801V0H90.0506C100.03 0 106.039 0.947496 110.727 3.794C115.538 6.63858 118.783 11.2755 118.783 17.8092C118.783 26.9764 112.049 32.2461 103.634 35.3027C105.559 38.5687 110.248 45.5231 113.853 50.3711C114.085 50.6846 114.335 51.0226 114.6 51.3819Z"
              fill="#FFFAF0"
            />
          </svg>
        </div>
        <div class="text-ivory-700 flex gap-8 justify-end">
          <div>© 2014-{{ new Date().getFullYear() }} Nurole Ltd</div>
        </div>
      </div>
      <div>
        <ul class="md:flex md:gap-8">
          <li v-for="link in privacyLinks" :key="link.label" class="my-2">
            <a class="text-ivory-700" :href="link.href">{{ link.label }}</a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
type Link = {
  label: string;
  href?: string;
  to?: string;
};

const privacyLinks: Link[] = [
  {
    label: "Privacy policy",
    href: "https://www.nurole.com/privacy",
  },
  {
    label: "Terms & conditions",
    href: "https://www.nurole.com/terms-and-conditions",
  },
  {
    label: "Website terms of use",
    href: "https://www.nurole.com/website-terms",
  },
  {
    label: "GDPR",
    href: "https://www.nurole.com/gdpr",
  },
  {
    label: "Cookies",
    href: "https://www.nurole.com/privacy#cookies",
  },
];
</script>
