<template>
  <div class="copy-emphasis mb-8">Personal details</div>
  <div class="md:flex items-center">
    <profile-picture />
    <div class="md:ml-8">
      <div class="text-inputs-title mb-2 mt-6 md:mt-0">Title</div>
      <select
        @input="saveChanges"
        v-model="title"
        class="select-input"
        data-cy="title-select"
      >
        <option v-for="option in titles" :key="option" :value="option">
          {{ option }}
        </option>
      </select>
    </div>
    <div class="flex-1 md:ml-3 md:mt-0 mt-6">
      <div class="text-inputs-title mb-2">First name</div>
      <input
        @input="saveChanges"
        type="text"
        class="text-input w-full"
        v-model="first_name"
        data-cy="first-name-input"
        name="firstName"
        autocomplete="given-name"
      />
    </div>
    <div class="flex-1 md:ml-3 md:mt-0 mt-6">
      <div class="text-inputs-title mb-2">Last name</div>
      <input
        @input="saveChanges"
        type="text"
        class="text-input w-full"
        v-model="last_name"
        data-cy="last-name-input"
        name="lastName"
        autocomplete="family-name"
      />
    </div>
  </div>
  <div class="md:flex items-center md:mt-8 md:mt-0 mt-6">
    <div class="flex-1">
      <div class="text-inputs-title mb-2">Birth year</div>
      <select
        @input="saveChanges"
        v-model="birth_year"
        class="select-input w-full"
        data-cy="year-select"
      >
        <option value="unspecified">Prefer not to say</option>
        <option
          v-for="option in plausibleBirthYears"
          :key="option"
          :value="option"
        >
          {{ option }}
        </option>
      </select>
    </div>
    <div class="flex-1 md:ml-3 md:mt-0 mt-6">
      <div class="text-inputs-title mb-2">Gender</div>
      <select
        @input="saveChanges"
        v-model="gender"
        class="select-input w-full"
        data-cy="gender-select"
      >
        <option value="unspecified">Prefer not to say</option>
        <option value="male">Male</option>
        <option value="female">Female</option>
      </select>
    </div>
  </div>
  <div class="text-inputs-title mb-2 mt-8">Nationality</div>
  <select
    @input="saveChanges"
    v-model="nationality"
    class="select-input w-full"
    data-cy="nationality-select"
  >
    <option v-for="option in nationalities" :key="option" :value="option">
      {{ option }}
    </option>
  </select>
</template>

<script lang="ts">
import useIndividual from "@/composables/useIndividual";
import ProfilePicture from "@/components/shared/ProfilePicture.vue";
import titles from "@/lib/titles";
import nationalities from "@/lib/nationalities";
import plausibleBirthYears from "@/lib/plausibleBirthYears";
import debounce from "@/utils/debounce";

export default {
  setup() {
    const { isLoaded, individual, updateIndividual } = useIndividual();

    return {
      titles,
      nationalities,
      plausibleBirthYears: plausibleBirthYears(),
      individual,
      updateIndividual,
      isLoaded,
    };
  },
  data() {
    return {
      title: "",
      first_name: "",
      last_name: "",
      gender: "",
      birth_year: "",
      nationality: "",
    };
  },
  methods: {
    saveChanges: debounce(async function () {
      await this.updateIndividual(this.$data, "personal_details");
    }, 500),
  },
  watch: {
    isLoaded: {
      immediate: true,
      handler(isLoaded) {
        if (isLoaded) {
          this.title = this.individual.title;
          this.first_name = this.individual.first_name;
          this.last_name = this.individual.last_name;
          this.gender = this.individual.gender;
          this.birth_year = this.individual.birth_year;
          this.nationality = this.individual.nationality;
        }
      },
    },
  },
  components: { ProfilePicture },
};
</script>
