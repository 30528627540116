<template>
  <div
    class="text-nudging whitespace-nowrap py-1"
    data-cy="role-table-tile-date"
  >
    <span>
      {{ formattedFullDate }}
    </span>
    <span v-if="timeAtRole"> ({{ timeAtRole }}) </span>
  </div>
</template>

<script lang="ts">
import { PropType } from "vue";
import { RolesTableRole } from "@/types/RolesTableRole";
import { formatDuration, intervalToDuration, isPast } from "date-fns";
import { format } from "date-fns/format";

export default {
  name: "RolesTableDate",
  props: {
    role: { type: Object as PropType<RolesTableRole> },
  },
  computed: {
    isCurrent() {
      return (
        this.role.isCurrent ||
        (isPast(new Date(this.role.from)) && this.role.endDateUnknown)
      );
    },
    formattedFullDate(): string {
      if (!this.role || !this.role.from) return "Unknown";

      return `${this.formattedFrom} - ${this.formattedTo}`;
    },
    formattedFrom() {
      return this.formatSingleDate(this.role.from);
    },
    formattedTo() {
      if (!this.role.to && this.isCurrent) return "Present";
      if (!this.role.to) return "Unknown";

      return this.formatSingleDate(this.role.to);
    },
    timeAtRole(): string {
      if (!this.role || !this.role.from || (!this.role.to && !this.isCurrent))
        return "";

      const duration = intervalToDuration({
        start: new Date(this.role.from),
        end: this.isCurrent ? new Date() : new Date(this.role.to),
      });

      return formatDuration(duration, { format: ["years", "months"] });
    },
  },
  methods: {
    formatSingleDate(date: string): string {
      const isMissingMonth = date.length === 4;

      if (isMissingMonth) return date;
      return format(new Date(date), "MMM yyyy");
    },
  },
};
</script>
