<template>
  <div class="md:grid md:grid-cols-2 md:gap-4">
    <div>
      <label class="text-inputs-title font-semibold block mb-2">Degree</label>
      <input
        v-model="degree"
        class="text-input text-inputs-content w-full"
        placeholder="E.g. Masters"
        data-cy="degree-input"
      />
    </div>

    <div>
      <label class="text-inputs-title font-semibold block mb-2 mt-6 md:mt-0"
        >Field of study</label
      >
      <input
        v-model="fieldOfStudy"
        class="text-input text-inputs-content w-full"
        placeholder="E.g. Economics"
        data-cy="degree-input"
      />
    </div>
  </div>

  <label class="mt-6 text-inputs-title font-semibold block mb-2"
    >Institution name*</label
  >
  <div class="w-full relative">
    <input
      :value="institution"
      autocomplete="organization"
      @input="
        updateInstitution($event.target.value),
          onSearchUniversities($event.target.value)
      "
      class="text-input text-inputs-content w-full"
      placeholder="Search"
      :class="{
        'rounded-b-none ring-0 ring-offset-0 rounded-t-lg shadow-md':
          (showResults && searchResults.length) || showLoadingSkeleton(),
      }"
      id="educationInstitution"
      name="educationInstitution"
      data-cy="education-institution-name-input"
    />
    <organisation-selector
      v-if="showResults && searchResults.length"
      :organisations="searchResults"
      @on-select="selectUniversity"
      @on-none-of-these="selectNone"
    />
    <organisation-selector-skeleton v-if="showLoadingSkeleton()" />
  </div>

  <label class="mt-6 text-inputs-title font-semibold block mb-2"
    >Start date</label
  >
  <div class="w-full grid grid-cols-2 gap-3">
    <role-held-month-selector
      v-model="from.month"
      data-cy="start-month-selector"
    />
    <role-held-year-selector
      v-model="from.year"
      data-cy="start-year-selector"
    />
  </div>

  <label class="mt-6 text-inputs-title font-semibold block mb-2"
    >End date</label
  >
  <div class="w-full grid grid-cols-2 gap-3">
    <role-held-month-selector
      v-model="to.month"
      data-cy="start-month-selector"
    />
    <role-held-year-selector v-model="to.year" data-cy="start-year-selector" />
  </div>

  <div class="md:flex items-center mt-8 mb-8">
    <button
      @click="saveChanges()"
      class="button-primary text-cta-primary w-full md:w-auto mb-3 md:mb-0"
      :disabled="!institution"
    >
      Save
    </button>
    <button
      v-if="education.id"
      class="button-error text-cta-primary md:ml-3 flex items-center w-full md:w-auto"
      @click="$emit('delete-education', education)"
    >
      <span class="material-icons-round mr-2"> delete </span>
      <span class="text-center md:text-left flex-1">Delete</span>
    </button>
  </div>
</template>
<script lang="ts" setup>
import RoleHeldYearSelector from "../miniCv/RoleHeldYearSelector.vue";
import RoleHeldMonthSelector from "../miniCv/RoleHeldMonthSelector.vue";
import { Education } from "@/types/Education";
import { reactive, ref } from "vue";
import debounce from "@/utils/debounce";

import useEducation from "@/composables/useEducation";
import OrganisationSelectorSkeleton from "../miniCv/OrganisationSelectorSkeleton.vue";
import OrganisationSelector from "../miniCv/OrganisationSelector.vue";

const { searchUniversities } = useEducation();

const props = defineProps<{
  education: Education;
}>();

const emit = defineEmits(["save-education", "delete-education"]);

const from = reactive({
  month: props.education.from?.length
    ? props.education.from.split("-")[1]
    : undefined,
  year: props.education.from?.length
    ? parseInt(props.education.from.split("-")[0])
    : undefined,
});

const to = reactive({
  month: props.education.to?.length
    ? props.education.to.split("-")[1]
    : undefined,
  year: props.education.to?.length
    ? parseInt(props.education.to.split("-")[0])
    : undefined,
});

const fieldOfStudy = ref(props.education.field_of_study);
const degree = ref(props.education.degree);
const institution = ref(props.education.institution);

const saveChanges = () => {
  if (from.year && !from.month) {
    from.month = "01";
  }
  if (to.year && !to.month) {
    to.month = "12";
  }

  const education = {
    ...props.education,
    from: from.year ? `${from.year}-${from.month}` : undefined,
    to: to.year ? `${to.year}-${to.month}` : undefined,
    field_of_study: fieldOfStudy.value,
    degree: degree.value,
    institution: institution.value,
  };
  emit("save-education", education);
};

const searchResults = ref([]);
const isSearching = ref(false);
const showResults = ref(false);

const updateInstitution = (name: string) => {
  institution.value = name;
};
const onSearchUniversities = debounce(async function (name: string) {
  isSearching.value = true;
  searchResults.value = await searchUniversities(name);
  isSearching.value = false;
  showResults.value = true;
}, 500);

const showLoadingSkeleton = () => {
  return (
    isSearching.value &&
    searchResults.value.length == 0 &&
    institution.value.length > 0
  );
};

const selectNone = () => {
  showResults.value = false;
};

const selectUniversity = uni => {
  institution.value = uni.name;
  showResults.value = false;
};
</script>
