<template>
  <div
    @keydown.esc="tentativeClose"
    @click="tentativeClose"
    class="fixed z-90 top-0 left-0 bg-sage-700/90 w-screen h-screen"
    ref="modal"
    tabindex="0"
  >
    <button
      class="flex-col gap-2 hidden absolute top-8 right-8 cursor-pointer xl:flex"
      @click="close"
    >
      <svg
        width="42"
        height="43"
        viewBox="0 0 42 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28 15.91L26.59 14.5L21 20.09L15.41 14.5L14 15.91L19.59 21.5L14 27.09L15.41 28.5L21 22.91L26.59 28.5L28 27.09L22.41 21.5L28 15.91Z"
          fill="#FFFAF0"
        />
        <rect
          x="1"
          y="1.5"
          width="40"
          height="40"
          rx="20"
          stroke="#FFFAF0"
          stroke-width="2"
        />
      </svg>
      <p v-if="easyToClose" class="text-ivory-100 mx-auto">esc</p>
    </button>
  </div>
  <div
    class="fixed z-100 top-0 left-0 flex flex-col justify-end w-screen h-screen pointer-events-none sm:justify-center"
  >
    <div
      class="bg-white w-full mx-auto rounded-t-2xl pointer-events-auto overflow-y-scroll px-4 pb-16 max-h-[95vh] sm:max-w-[90vw] sm:rounded-lg sm:pb-6 md:pb-8 md:px-8 xl:max-w-[800px]"
    >
      <div id="sticky-header-top"></div>
      <div
        id="sticky-header"
        class="flex gap-2 pb-4 border-b-[1px] border-grey-200 mb-6 sticky top-0 bg-white pt-6 md:pt-8 z-10 -ml-3 -mr-3 px-6"
      >
        <div class="flex-grow">
          <slot name="header"></slot>
        </div>
        <button @click="close" class="xl:hidden">
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="justify-self-end fill-current text-sage-600 sm:hidden"
          >
            <path
              d="M19 6.91L17.59 5.5L12 11.09L6.41 5.5L5 6.91L10.59 12.5L5 18.09L6.41 19.5L12 13.91L17.59 19.5L19 18.09L13.41 12.5L19 6.91Z"
            />
          </svg>
          <svg
            width="42"
            height="42"
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="hidden sm:block"
          >
            <path
              d="M28 15.41L26.59 14L21 19.59L15.41 14L14 15.41L19.59 21L14 26.59L15.41 28L21 22.41L26.59 28L28 26.59L22.41 21L28 15.41Z"
              fill="#3C6955"
            />
            <rect
              x="1"
              y="1"
              width="40"
              height="40"
              rx="20"
              stroke="#3C6955"
              stroke-width="2"
            />
          </svg>
        </button>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref, Ref } from "vue";
const modal: Ref<HTMLDivElement | null> = ref(null);
const props = defineProps({
  easyToClose: {
    type: Boolean,
    default: false,
  },
});
onMounted(() => {
  document.body.classList.toggle("overflow-hidden", true);
  document.body.classList.toggle("hide-chat", true);

  if (modal.value) modal.value.focus();

  if (document.querySelector("#sticky-header-top")) {
    observer.observe(document.querySelector("#sticky-header-top"));
  }
});
onBeforeUnmount(() => {
  document.body.classList.toggle("hide-chat", false);
  document.body.classList.toggle("overflow-hidden", false);
});
const emit = defineEmits(["close-modal"]);
function tentativeClose() {
  if (props.easyToClose) emit("close-modal");
}
function close() {
  emit("close-modal");
}

//to check when element get's position sticky
const observer = new IntersectionObserver(
  function (entries) {
    // no intersection
    if (entries[0].intersectionRatio === 0)
      document.querySelector("#sticky-header")?.classList?.add("custom-shadow");
    // fully intersects
    else if (entries[0].intersectionRatio === 1)
      document
        .querySelector("#sticky-header")
        ?.classList?.remove("custom-shadow");
  },
  {
    threshold: [0, 1],
  }
);
</script>

<style scoped>
.custom-shadow {
  box-shadow: 0 6px 3px -2px var(--grey-200, #e8e8e8);
}
</style>
