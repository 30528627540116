<template>
  <loading-overlay v-if="showLoadingOverlay" />
  <template v-if="showNav">
    <signed-in-header v-if="isMember" />
    <signed-out-header v-if="!isMember && !isPublicRole" />
    <public-role-header v-if="!isMember && isPublicRole" />
  </template>
  <div class="relative flex-grow flex flex-col">
    <router-view />
  </div>
  <template v-if="showFooter">
    <logged-out-footer v-if="!isMember && isPublicRole" />
    <member-footer v-else />
  </template>
  <cookies-popup />
  <template v-if="allowRejectionPopup">
    <rejection-feedback-popup />
  </template>

  <my-profile-introduction-modal />
</template>

<script lang="ts">
import MemberFooter from "@/components/MemberFooter.vue";
import SignedInHeader from "@/components/SignedInHeader.vue";
import SignedOutHeader from "@/components/SignedOutHeader.vue";
import PublicRoleHeader from "@/components/PublicRoleHeader.vue";
import CookiesPopup from "@/components/CookiesPopup.vue";
import RejectionFeedbackPopup from "@/components/feedback/RejectionFeedbackPopup.vue";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import useIndividual from "@/composables/useIndividual";
import useWatchlist from "@/composables/useWatchlist";
import identifyUser from "./lib/identify";
import useAuth from "./composables/useAuth";
import MyProfileIntroductionModal from "./components/profile/MyProfileIntroductionModal.vue";
import LoggedOutFooter from "@/components/LoggedOutFooter.vue";
import "sharer.js";
import { PunchThrough } from "@/helpers/punchThrough";

export default {
  components: {
    LoggedOutFooter,
    LoadingOverlay,
    SignedInHeader,
    SignedOutHeader,
    PublicRoleHeader,
    MemberFooter,
    CookiesPopup,
    RejectionFeedbackPopup,
    MyProfileIntroductionModal,
  },
  setup() {
    const { getIndividual, isMember } = useIndividual();
    const { refreshWatchlist } = useWatchlist();
    const { getAuthLevel, authLevel } = useAuth();
    return {
      getIndividual,
      refreshWatchlist,
      getAuthLevel,
      authLevel,
      isMember,
    };
  },
  data() {
    return {
      showLoadingOverlay: true,
    };
  },
  async created() {
    const punchthroughToken = PunchThrough.getToken();
    const authLevelPromise = this.getAuthLevel();
    const individualPromise = this.getIndividual(punchthroughToken);
    const identifyUserPromise = identifyUser();
    await Promise.all([
      authLevelPromise,
      individualPromise,
      identifyUserPromise,
    ]);

    await this.$nextTick();
    this.showLoadingOverlay = false;
    await this.refreshWatchlist();
  },
  async mounted() {
    await this.$nextTick();
    // Have to manually check for query params here as the router meta and query
    // properties don't seem to have them available in the mounted hook
    const urlParams = new URLSearchParams(window.location.search);
    if (!urlParams.has("hideInteractionElements")) {
      setTimeout(() => {
        import("@/lib/livechat");
        import("@/lib/hubspot");
      }, 3000); // Waiting 3 seconds before importing slow things
    }
  },
  computed: {
    showFooter() {
      return !this.$route.meta.hideFooter;
    },
    showNav() {
      return !this.$route.meta.hideNav;
    },
    isPublicRole() {
      return this.$route.meta.isPublicRole;
    },
    allowRejectionPopup() {
      return !window.location.pathname.includes("end-of-process");
    },
  },
};
</script>
