<template>
  <div
    class="absolute w-full left-0 right-0 shadow-md rounded-b-lg z-90 bg-white"
  >
    <div class="max-h-56 overflow-y-scroll">
      <organisation-option
        @on-select="$emit('on-select', organisation)"
        v-for="(organisation, index) in organisations"
        :key="organisation.id"
        :organisation="organisation"
        :id="`option-${index}`"
        :isHighlighted="highlightedIndex == index"
        data-cy="organisation-option"
      />
    </div>
    <div class="p-2" v-if="organisations.length">
      <button
        @click="$emit('on-none-of-these')"
        class="button-secondary text-cta-secondary w-full"
      >
        None of these
      </button>
    </div>
  </div>
</template>

<script>
import useOptionsKeyPresses from "@/composables/useOptionsKeyPresses";
import OrganisationOption from "./OrganisationOption.vue";
import { ref } from "vue-demi";
export default {
  components: { OrganisationOption },
  name: "OrganisationSelector",
  props: {
    organisations: {
      type: Array,
      required: true,
    },
    displayKey: {
      type: String,
      required: false,
    },
  },
  emits: ["on-select", "on-none-of-these"],
  setup(props, context) {
    const highlightedIndex = ref(-1);
    useOptionsKeyPresses({
      highlightedIndex,
      options: props.organisations,
      onEnter: () => {
        if (props.organisations[highlightedIndex.value]) {
          context.emit(
            "on-select",
            props.organisations[highlightedIndex.value]
          );
        } else {
          context.emit("on-none-of-these");
        }
      },
      onTab: () => context.emit("on-none-of-these"),
    });

    return {
      highlightedIndex,
    };
  },
};
</script>
