<template>
  <div class="px-4 md:px-28 pt-6 md:pt-10 pb-12" data-cy="already-member-view">
    <i class="las la-users la-4x pb-5" />
    <p class="text-16/24 m-0 mb-1">
      Congratulations {{ individual.first_name }} - you are already a member of
      Nurole.
    </p>
    <p class="text-16/24 m-0 mb-10">
      Not {{ fullName }}? <button @click="logout">PLEASE CLICK HERE</button>
    </p>
    <div class="grid lg:grid-cols-3 gap-6">
      <div
        class="bg-grey-50 rounded-lg py-6 px-4 text-sage-700 flex flex-col sm:items-start"
      >
        <p>To browse roles, view details or apply for a role</p>
        <router-link
          to="/roles"
          class="button-primary text-cta-primary text-center"
        >
          View roles
        </router-link>
      </div>
      <div
        class="rounded-lg py-6 px-4 text-sage-700 flex flex-col sm:items-start"
      >
        <p>To recommend a contact for invitation to Nurole</p>
        <router-link
          to="/recommend"
          class="button-primary text-cta-primary text-center"
        >
          Recommend
        </router-link>
      </div>
      <div
        class="bg-grey-50 rounded-lg py-6 px-4 text-sage-700 flex flex-col sm:items-start"
      >
        <p>If you have a query or would like to post a role</p>
        <router-link
          to="/contact"
          class="button-primary text-cta-primary text-center"
        >
          Contact Nurole
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import logout from "@/lib/logout";
export default {
  name: "AlreadyMember",
  setup() {
    return {
      logout,
    };
  },
  props: {
    individual: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fullName() {
      return `${this.individual.first_name} ${this.individual.last_name}`;
    },
  },
};
</script>
