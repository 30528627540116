import { computed } from "vue";
import { individual } from "@/composables/useIndividual";
import { rolesHeld } from "@/composables/useMiniCv";
import useEducation from "@/composables/useEducation";

export default function profileCompleteness() {
  const { educationCourses } = useEducation();
  const rolesDone = computed(() => rolesHeld?.value?.length >= 3);

  const roleDescriptionsDone = computed(() => {
    return (
      rolesHeld?.value?.filter(
        roleHeld => (roleHeld?.organisation_description?.length || 0) > 5
      ).length >= 1
    );
  });

  const careerSummaryDone = computed(
    () => individual.value.executive_summary?.length
  );

  const careerHighlightDone = computed(() =>
    individual.value.career_highlights?.some((h: string) => h.length)
  );

  const educationCoursesDone = computed(
    () => educationCourses.value.length > 0
  );

  return {
    rolesDone,
    roleDescriptionsDone,
    careerSummaryDone,
    careerHighlightDone,
    educationCoursesDone,
  };
}
