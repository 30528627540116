<template>
  <div class="w-full flex-col justify-between">
    <div class="flex flex-col items-start gap-4 self-stretch">
      <span
        v-if="!isMember"
        class="flex text-primary self-stretch text-inputs-title"
      >
        Profile picture (Optional)
      </span>
      <profile-picture :individual="individual" />
    </div>
    <div v-if="isMember">
      <div v-if="!editDetails">
        <div class="flex flex-col mt-4 md:mt-2 mb-4 gap-4 lg:gap-0">
          <div class="flex flex-grow flex-col gap-1 md:gap-4 lg:gap-1">
            <span class="text-grey-900 copy-emphasis mb-1 md:mb-0">
              {{ name }}
            </span>
            <span
              :v-if="individual.country || showLocationError"
              :class="individual.country ? 'text-grey-900' : 'text-grey-600'"
            >
              {{ location }}
            </span>
            <span
              v-if="individual.telephone || showPhoneError"
              :class="individual.telephone ? 'text-grey-900' : 'text-grey-600'"
            >
              {{ telephone }}
            </span>
            <div class="flex items-center gap-2">
              <img
                src="@/assets/images/linkedin-logo.svg"
                alt=""
                class="w-6 h-6 sm:w-auto sm:h-auto"
              />
              <a
                data-cy="linkedin"
                :href="sanitezedLinkedin"
                v-if="individual.linkedin"
                target="_blank"
                rel="noreferrer noopener"
                class="copy-bold md:text-cta-secondary text-sage-700 flex items-center justify-between gap-2 w-full sm:w-auto"
              >
                {{ linkedin }}
                <span class="material-icons-round text-md sm:text-base">
                  launch
                </span>
              </a>
              <span v-else data-cy="linkedin" class="text-grey-600">
                {{ linkedin }}
              </span>
            </div>
          </div>
        </div>
        <div class="flex flex-col md:flex-row gap-4">
          <slot></slot>
          <n-button
            v-if="showEditButton"
            @click="updateDetails"
            type="secondary"
            label="Edit details"
            show-left-icon
            left-icon="edit"
          />
        </div>
      </div>

      <edit-personal-details
        v-if="showEditButton && editDetails"
        :individual="individual"
        @close-edit-details="editDetails = false"
        @details-updated="detailsUpdated"
      />
    </div>

    <edit-guest-details
      v-if="!isMember"
      :individual="individual"
      @close-edit-details="editDetails = false"
      @details-updated="detailsUpdated"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import NButton from "@/components-v2/ui-kit/Button.vue";
import ProfilePicture from "./ProfilePicture.vue";
import EditPersonalDetails from "./EditPersonalDetails.vue";
import EditGuestDetails from "./EditGuestDetails.vue";
import useIndividual from "@/composables/useIndividual";
import { useToast } from "vue-toastification";
import DOMPurify from "dompurify";

const { individual, isMember } = useIndividual();
const toast = useToast();

defineProps({
  showEditButton: {
    type: Boolean,
    default: true,
  },
  showPhoneError: {
    type: Boolean,
    default: true,
  },
  showLocationError: {
    type: Boolean,
    default: true,
  },
});

const editDetails = ref(false);

const updateDetails = () => {
  editDetails.value = true;
};

const detailsUpdated = () => {
  editDetails.value = false;
  toast.success("Changes saved, your profile was also updated", {
    timeout: 3000,
  });
};

const sanitezedLinkedin = computed(() => {
  return DOMPurify.sanitize(individual.value.linkedin);
});

const name = computed(
  () => `${individual.value.first_name} ${individual.value.last_name}`
);
const telephone = computed(() => {
  if (individual.value.telephone) return individual.value.telephone;
  return "No phone number added";
});
const linkedin = computed(() => {
  const { linkedin } = individual.value;
  if (!linkedin) return "No Linkedin link added";
  return linkedin.includes("in/") ? linkedin.split("in/")[1] : linkedin;
});
const location = computed(() => {
  if (individual.value.country) return individual.value.country;
  return "No location added";
});
</script>
