<template>
  <div v-if="role">
    <transition
      enter-active-class="ease-out duration-300"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-from-class="opacity-100"
      leave-active-class="ease-out duration-300"
      leave-to-class="opacity-0"
    >
      <div
        v-if="isOpen"
        class="fixed w-screen h-screen z-50 top-0 bottom-0 bg-grey-900/20 backdrop-blur"
      ></div>
    </transition>
    <div class="w-screen flex bg-white p-5 justify-between items-center">
      <div class="flex flex-col">
        <span class="text-divider text-grey-400"> APPLY FOR ROLE </span>
        <span class="text-grey-800 text-nudging"> {{ role.title }} </span>
      </div>
      <span
        class="material-icons-round text-20/20 text-sage-600"
        @click="toggleNavigation"
      >
        more_vert
      </span>
    </div>
    <transition
      enter-active-class="ease-out duration-700"
      enter-from-class="translate-y-full"
      enter-to-class="translate-y-0"
      leave-from-class="translate-y-0"
      leave-active-class="ease-out duration-700"
      leave-to-class="translate-y-full"
    >
      <div
        v-if="isOpen"
        class="px-5 pt-5 pb-20 fixed w-full h-full z-50 bg-white rounded-t-2xl -translate-y-7 transition-all overflow-scroll"
      >
        <div class="flex justify-between">
          <span class="copy-emphasis"> Details and actions </span>
          <span
            class="material-icons-round text-sage-600"
            @click="toggleNavigation"
          >
            close
          </span>
        </div>
        <hr class="w-full border-solid border-grey-100" />
        <role-apply-navigation-content />
      </div>
    </transition>
  </div>
</template>

<script>
import RoleApplyNavigationContent from "@/components/roleApplication/RoleApplyNavigationContent.vue";
import useRole from "@/composables/useRole";

export default {
  components: {
    RoleApplyNavigationContent,
  },
  setup() {
    const { role } = useRole();

    return { role };
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleNavigation() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
