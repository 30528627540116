<template>
  <div
    class="bg-red-50 rounded-lg px-6 py-6 flex flex-col items-start md:space-x-3 md:py-4 md:items-center md:flex-row"
  >
    <img
      src="@/assets/images/documents.svg"
      alt="document icon"
      class="w-8 h-8 md:w-16 md:h-16 mb-3 md:mb-0"
    />
    <div class="flex flex-col items-start justify-center">
      <span class="copy-emphasis max-w-lg text-grey-900 mb-4 md:mb-0">
        You haven't completed this section, your chance of progressing will be
        reduced
      </span>
      <button
        data-cy="update-answer-btn"
        v-if="showUpdateButton"
        @click="showApplyPage"
        class="w-full mt-3 button-error-primary md:w-auto text-cta-primary"
      >
        Update my answer
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import useRoleApplication from "@/composables/useRoleApplication";

export default {
  setup() {
    const { roleApplication } = useRoleApplication();

    return { roleApplication };
  },
  props: {
    showUpdateButton: {
      default: true,
      type: Boolean,
    },
  },
  methods: {
    showApplyPage() {
      this.$router.push(`/roles/${this.roleApplication.role_id}/apply`);
    },
  },
};
</script>
