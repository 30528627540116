<template>
  <div class="flex items-center h-14 md:h-20 bg-sage-700 sticky top-0 z-50">
    <div
      v-if="showMenu"
      class="fixed inset-0 w-full z-10"
      @click="closeAll"
    ></div>
    <div
      class="w-full flex items-center max-w-screen-xl md:mx-auto z-10 px-4 md:px-6 xl:px-0"
    >
      <a :href="`${marketingAppURL}?noredirect=true`">
        <img src="@/assets/nurole.svg" class="md:w-auto w-28 md:mr-12" />
      </a>
      <div
        class="hidden md:flex items-center text-cta-secondary md:space-x-2 lg:space-x-6"
      >
        <a
          v-for="(link, index) in externalLinks"
          :target="link.openInNewTab ? '_blank' : ''"
          :rel="link.openInNewTab ? 'noopener' : ''"
          :key="index"
          :href="link.url"
          class="text-ivory-50 hover:text-ivory-300 active:text-ivory-400 border-t-0 border-r-0 border-l-0 border-b-2 pt-2 pb-1 border-solid cursor-pointer border-transparent flex gap-2 items-center"
          >{{ link.label }}<mark v-if="link.highlightAsNew">NEW</mark></a
        >
      </div>

      <div class="flex-grow"></div>
      <a
        :href="`${marketingAppURL}/post-a-role`"
        class="mx-3 hidden md:block text-cta-primary bg-ivory-50 text-grey-900 py-3 px-6 hover:bg-ivory-300 active:bg-ivory-400"
      >
        Discuss a hire
      </a>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "NavBar",
  setup() {
    return {
      marketingAppURL: process.env.VITE_MARKETING_BASE_URL,
    };
  },
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    toggleMenu() {
      this.showProfile = false;
      this.showMenu = !this.showMenu;
    },

    closeAll() {
      this.showMenu = false;
      this.showProfile = false;
    },
  },
  computed: {
    externalLinks() {
      return [
        {
          label: "Pricing",
          url: `${this.marketingAppURL}/pricing-guide`,
          shouldBeSignedIn: false,
        },
        {
          label: "For organisations",
          url: `${this.marketingAppURL}/for-organisations`,
          shouldBeSignedIn: false,
        },
        {
          label: "For candidates",
          url: `${this.marketingAppURL}/candidates`,
          shouldBeSignedIn: false,
        },
      ];
    },
  },
};
</script>

<style scoped>
.router-link-active {
  @apply border-ivory-50  hover:border-ivory-300 active:border-ivory-400 !important;
}
</style>
