export default [
  "LSDC (Licentiate of the Society of Dyers and Colourists)",
  "LRAD (Licentiate of the Royal Academy of Dance)",
  "RTRAD (Registered Teacher of the Royal Academy of Dance)",
  "EngTech (Engineering technician)",
  "ICTTech (ICT Technician)",
  "ACIM (Associate Member of The Chartered Institute of Marketing)",
  "RMet (Registered Meteorologist)",
  "ARICS (Associate Member of Royal Institution of Chartered Surveyors)",
  "LTI (Licentiate of the Textile Institute)",
  "MCIEH/FCIEH (Environmental Health Practitioner)",
  "CTA (Chartered Tax Advisor)",
  "ACMA/FCMA (Chartered Management Accountant)",
  "CIMA (Chartered Management Accountant)",
  "ACCA/FCCA (Chartered Certified Accountant)",
  "ACA (Chartered Accountant)",
  "CA (Chartered Accountant)",
  "MCIBS (Member of the Chartered Institute of Bankers in Scotland (Chartered Banker))",
  "Aifs (Member of the Institute of Financial Services)",
  "CBiol (Chartered Biologist)",
  "ACIB (Associate of the Chartered Institute of Building)",
  "MCIOB (Chartered Builder)",
  "MRICS/FRICS (Chartered Building Surveyor)",
  "ACIS (Chartered Secretary)",
  "ASDC (Associateship of Society of Dyers and Colourists)",
  "CCol (Chartered Colourist)",
  "MIET (Member of the Institution of Engineering and Technology)",
  "IEng (Incorporated Engineer)",
  "MCIWM (Chartered Wastes Manager)",
  "MCIEH/FCIEH (Environmental Health Officer)",
  "MICFor/FICFor (Chartered Forester)",
  "CGeog (Chartered Geographer)",
  "CMIOSH/CFIOSH (Chartered Safety and Health Practitioner)",
  "CIHCM (CIH Chartered Member)",
  "CITP (Chartered IT Professional)",
  "ACII (Chartered Insurance Broker)",
  "ACII (Chartered Insurance Practitioner)",
  "AFPS (Chartered Financial Planner)",
  "ACII (Chartered Insurer (insurance underwriting))",
  "MRICS/FRICS (Chartered Land Surveyor)",
  "FCILEx (Chartered Legal Executive)",
  "CILIP (Chartered Institute of Library & Information Professionals)",
  "ACLA (Chartered Loss Adjuster)",
  "CMgr (Chartered Manager)",
  "CMktr (Chartered Marketer)",
  "MCIM (Member of The Chartered Institute of Marketing)",
  "CMath (Chartered Mathematician)",
  "CStat (Chartered Statistician)",
  "MInstMC (Chartered Measurement and Control Technologist)",
  "C.Eng, MIMechE (Chartered Mechanical Engineer -Member of the Institution of Mechanical Engineers)",
  "CMet (Chartered Meteorologist)",
  "MRICS/FRICS (Chartered Minerals Surveyor)",
  "MIMMM (Member of the Institute of Materials Minerals and Mining)",
  "CPFA (Chartered Public finance accountant)",
  "MRICS/FRICS (Chartered Quantity Surveyor)",
  "FICS (Chartered Shipbroker)",
  "MRICS/FRICS (Chartered Surveyor)",
  "CText (Chartered Textile Technologist)",
  "MRTPI (Chartered Town Planner)",
  "MRICS/FRICS (Chartered Valuation Surveyor)",
  "MCIWEM (Chartered Water and Environment Manager)",
  "MRAeS (Member of Royal Aeronautical Society)",
  "MCIAT (Chartered Architectural Technologist)",
  "MCIBSE (Chartered Building Services Engineer)",
  "MIChemE (Chartered Chemical Engineer)",
  "CChem (Chartered Chemist)",
  "MICE (Chartered Civil Engineer, Member of ICE)",
  "MIStructE (Chartered Structural Engineer)",
  "CSci (Chartered Scientist)",
  "MEI/FEI (Chartered Energy Engineer)",
  "CEng (Chartered Engineer)",
  "Ch EHO (Chartered Environmental Health Officer)",
  "EHO (Environmental Health Officer)",
  "MIGEM (Chartered Gas Engineer)",
  "CGeol (Chartered Geologist)",
  "CMLI (Member of the Landscape Institute)",
  "CMarEng (Chartered Marine Engineer)",
  "MRINA (Member of the Royal Institution of Naval Architects)",
  "MEI/FEI (Chartered Petroleum Engineer)",
  "CPhys (Chartered Physicist)",
  "ACIArb (Associate of the Chartered Institute of Arbitrators)",
  "MCIArb (Member of the Chartered Institute of Arbitrators)",
  "FCIArb (Chartered Arbitrator)",
  "MICFor (Chartered Arboriculturalist)",
  "RIBA (Chartered Architect)",
  "MCSD & FCSD (Chartered Designer)",
  "CDir (Chartered Director)",
  "CEnv (Chartered Environmentalist)",
  "CErgHF (Chartered Ergonomist and Human Factors Specialist)",
  "CHort (Chartered Horticulturist)",
  "CMIIA/CFIIA (Chartered Internal Auditor)",
  "CL (Chartered Linguist)",
  "CMathTeach (Chartered Architect)",
  "Chartered MCIPD/Chartered FCIPD (Chartered Designer)",
  "Chartered MCISI/Chartered FCISI (Chartered Director)",
  "CMILT/FCILT (Chartered Environmentalist)",
  "CPA (Chartered Ergonomist and Human Factors Specialist)",
  "MCSP (Chartered Horticulturist)",
  "MCIPS/FCIPS (Chartered Internal Auditor)",
  "CPsychol (Chartered Linguist)",
  "Chart.PR (Chartered Public Relations Practitioner)",
  "CQP (Chartered Quality Professionals)",
  "CRadP (Chartered Radiation Protection Professional)",
  "CSciTeach (Chartered Science Teacher)",
  "CSyP (Chartered Security Professional)",
  "CTE (Chartered Teacher of English)",
  "Chartered MCISI/Chartered FCISI (Chartered Wealth Manager)",
];
