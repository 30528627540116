export default [
  {
    label: "White",
    value: "1_ethnicity_white",
    backgrounds: [
      {
        label: "English, Welsh, Scottish, Northern Irish or British",
        value: "1_english_welsh_scottish_northern_irish_or_british",
      },
      {
        label: "Irish",
        value: "2_irish",
      },
      {
        label: "Gypsy or Irish Traveller",
        value: "3_gypsy_or_irish_traveller",
      },
      {
        label: "Any other White background",
        value: "4_any_other_white_background",
      },
    ],
  },
  {
    label: "Mixed or Multiple ethnic groups",
    value: "2_ethnicity_mixed",
    backgrounds: [
      {
        label: "White and Black Caribbean",
        value: "1_white_and_black_caribbean",
      },
      {
        label: "White and Black African",
        value: "2_white_and_black_african",
      },
      {
        label: "White and Asian",
        value: "3_white_and_asian",
      },
      {
        label: "Any other Mixed or Multiple ethnic background",
        value: "4_any_other_mixed_or_multiple_ethnic_background",
      },
    ],
  },
  {
    label: "Asian or Asian British",
    value: "3_ethnicity_asian",
    backgrounds: [
      {
        label: "Indian",
        value: "1_indian",
      },
      {
        label: "Pakistani",
        value: "2_pakistani",
      },
      {
        label: "Bangladeshi",
        value: "3_bangladeshi",
      },
      {
        label: "Chinese",
        value: "4_chinese",
      },
      {
        label: "Any other Asian background",
        value: "5_any_other_asian_background",
      },
    ],
  },
  {
    label: "Black",
    value: "4_ethnicity_black",
    backgrounds: [
      {
        label: "African",
        value: "1_african",
      },
      {
        label: "Caribbean",
        value: "2_caribbean",
      },
      {
        label: "Black British",
        value: "3_black_british",
      },
      {
        label: "Any other Black, African or Caribbean background",
        value: "4_any_other_black_african_or_caribbean_background",
      },
    ],
  },
  {
    label: "Arab",
    value: "5_arab",
    backgrounds: [],
  },
  {
    label: "Other ethnic group",
    value: "6_ethnicity_other",
    backgrounds: [],
  },
];
