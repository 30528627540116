<template>
  <a
    :href="roleApplicationCv.location"
    class="flex flex-col group sm:flex-row items-start w-full justify-between sm:items-center outline-none focus-visible:ring focus-visible:ring-orange-500 active:bg-sage-200 ring-offset-4 bg-sage-50 p-3 px-4 hover:bg-sage-100 rounded-lg"
  >
    <div class="flex flex-row justify-between w-full items-center">
      <div class="flex items-center">
        <span
          class="flex justify-center items-center h-11 w-11 shrink-0 rounded-full bg-white"
        >
          <span class="material-icons-round text-3xl text-sage-600">
            attach_file
          </span>
        </span>
        <div class="flex flex-col w-full items-start mx-3">
          <span
            data-cy="upload-cv-success-filename"
            class="text-sage-600 break-all copy-bold group- hover:text-grey-900"
          >
            {{ roleApplicationCv.filename }}
          </span>
          <span
            class="text-grey-700 text-labelling-inactive group- hover:text-grey-900"
          >
            Added {{ dateAdded }}
          </span>
        </div>
      </div>
    </div>
    <button
      class="flex justify-center items-center text-cta-secondary text-sage-700 bg-transparent button-primary whitespace-nowrap w-full mt-3 sm:mt-0 sm:w-auto"
      @click.stop.prevent="showManageCvModal"
      type="button"
    >
      Change CV
    </button>
  </a>
</template>

<script>
import useCvs from "@/composables/useCvs";
import { format } from "date-fns";

export default {
  setup() {
    const { roleApplicationCv, showManageCvModal } = useCvs();

    return {
      roleApplicationCv,
      showManageCvModal,
    };
  },
  computed: {
    dateAdded() {
      return format(
        new Date(this.roleApplicationCv.created_at),
        "MMMM d, yyyy, h:mma"
      );
    },
  },
};
</script>
