import { apiAxios } from "@/lib/axios";
import { ref } from "vue";

const roles = ref<RolesResponse["roles"]>([]);
const rolesLoading = ref(false);
const rolesLoaded = ref(false);
const algorithm_service_available = ref(false);

export async function refreshRoles(): Promise<{
  success: boolean;
  status?: number;
}> {
  try {
    rolesLoading.value = true;
    const { data } = await apiAxios.get<RolesResponse>("roles");
    if (data && data.roles && Array.isArray(data.roles)) {
      roles.value = data.roles.filter(
        (role: any) =>
          Date.parse(role.deadline) > Date.now() ||
          role.current_user_has_applied === true
      );
      algorithm_service_available.value = data.algorithm_service_available;
      rolesLoading.value = false;
      rolesLoaded.value = true;
    }
    return { success: true };
  } catch (error: any) {
    rolesLoading.value = false;
    roles.value = [];
    algorithm_service_available.value = false;
    return { success: false, status: error.response?.status };
  }
}

export default function useRoles() {
  return {
    rolesLoading: rolesLoading,
    rolesLoaded: rolesLoaded,
    roles: roles,
    algorithm_service_available: algorithm_service_available,
    refreshRoles,
  };
  //* todo: do we need to return readonly values?
  // return {
  //   rolesLoading: readonly(rolesLoading),
  //   rolesLoaded: readonly(rolesLoaded),
  //   roles: readonly(roles),
  //   algorithm_service_available: readonly(algorithm_service_available),
  //   refreshRoles,
  // };
}

export type RolesResponse = {
  roles: Role[];
  algorithm_service_available: boolean;
};

// todo: use import { Role } from "@/models/Role"; ?
export type Role = {
  additional_1_text?: string;
  additional_1_title?: string;
  additional_2_text?: string;
  additional_2_title?: string;
  additional_3_text?: string;
  additional_3_title?: string;
  additional_4_text?: string;
  additional_4_title?: string;
  additional_5_text?: string;
  additional_5_title?: string;
  board_composition?: string;
  commitment: string;
  compensation_details?: string;
  cover_letter_intro: string;
  cover_letter_limit: number;
  deadline: string;
  decision_date: string;
  diversity: string;
  extra_application_fields: Array<{
    id: string;
    heading: string;
    description: string;
    limit: number;
    use_rich_text_form: boolean;
    recommended_word_count: any;
  }>;
  location_region?: string;
  organisation_summary_override: string;
  part_time: boolean;
  person_specification: string;
  preference_tags: {
    educational_charities?: boolean;
    secondary_education?: boolean;
    further_education?: boolean;
    mat?: boolean;
    trees_and_plants?: boolean;
    architecture?: boolean;
    museums?: boolean;
    primary_education?: boolean;
    employment?: boolean;
    religion?: boolean;
    children_and_family?: boolean;
    domestic_violence?: boolean;
    sexual_abuse?: boolean;
    government_and_regulatory?: boolean;
    climate_change?: boolean;
    rivers_and_lakes?: boolean;
    disability?: boolean;
    elderly?: boolean;
    housing_accommodation?: boolean;
    early_years?: boolean;
  };
  public: boolean;
  publish_date: string;
  role_specification: string;
  role_summary: string;
  sector: string;
  skills: Array<{
    skill_id: string;
    key: string;
    title: string;
    substituted_title: string;
    required: string;
    skill_type: string;
    description: string;
    substituted_description: string;
    character_limit?: number;
    recommended_words?: number;
    structured_criteria: string;
    structured_description: string;
    exclude_from_notifications: boolean;
    variables: Array<{
      _id: string;
      name: string;
      variable_type: string;
      value: string;
      units: string;
    }>;
  }>;
  timeline?: string;
  title: string;
  token: string;
  type: string;
  name: string;
  logo_thumbnail_public: string;
  website: string;
  score?: number;
  match_strength?: string;
  notifiable: boolean;
  calculations?: Array<{
    name: string;
    applied: number;
  }>;
  summary: string;
  culture: string;
  id: string;
  nfp_sectors: string;
  commercial_sectors: string;
  locations: string;
  locations_formatted: string;
  forms: Array<any>;
  current_user_has_applied: boolean;
  current_user_has_partially_completed: boolean;
  current_user_notifiable: boolean;
  size: string;
  org_type: string;
  first_contact_date: string;
  one_desired_required: any;
  cover_letter_required: any;
  organisation_summary: string;
  cs_owner_details: {
    first_name: string;
    last_name: string;
    picture: string;
  };
  is_education_role: boolean;
  category: string;
  request_additional_user_info: boolean;
  is_extended: boolean;
  is_quick_apply: boolean;
};
