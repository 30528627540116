<template>
  <div v-if="!isLoaded" class="py-4 px-6 bg-neutral animate-pulse h-[90px]" />
  <div
    v-else
    class="py-4 px-6 rounded-lg"
    :class="{
      'bg-error': state.style == 'error',
      'bg-nudging': state.style == 'nudge',
      border: state.style == 'normal',
    }"
  >
    <div class="sm:gap-4 flex sm:items-center flex-col sm:flex-row">
      <div :innerHTML="state.icon"></div>
      <div class="flex-1">
        <div class="copy-bold" data-profile-status-title>{{ state.title }}</div>
        <div>{{ state.subTitle }}</div>
      </div>
      <router-link
        to="/account/profile/experience"
        class="button-secondary text-cta-secondary flex items-center gap-3 justify-center"
        target="_blank"
        v-if="state.showUpdateButton"
      >
        Update
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.6667 10.6667H1.33333V1.33333H6V0H1.33333C0.593333 0 0 0.6 0 1.33333V10.6667C0 11.4 0.593333 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667V6H10.6667V10.6667ZM7.33333 0V1.33333H9.72667L3.17333 7.88667L4.11333 8.82667L10.6667 2.27333V4.66667H12V0H7.33333Z"
            fill="#3C6955"
          />
        </svg>
      </router-link>
    </div>
    <button
      data-ignore-nudge
      v-if="state.style == 'nudge'"
      class="text-cta-tertiary text-sage-700 mt-4"
      @click="nudgeIgnored = true"
    >
      No thanks, It is UP TO DATE
    </button>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { individual } from "@/composables/useIndividual";
import { humaniseTimeSince } from "@/helpers/humaniseTimeSince";
import { rolesHeld, isLoaded } from "@/composables/useMiniCv";

const nudgeIgnored = ref(false);

const state = computed(() => {
  if (rolesHeld.value.length < 3)
    return {
      style: "error",
      icon: errorIcon,
      title: "Please add at least 3 roles",
      subTitle: `${rolesHeld.value.length} roles added`,
      showUpdateButton: true,
    };

  if (timeSinceLastUpdated.value.includes("year") && !nudgeIgnored.value)
    return {
      style: "nudge",
      icon: nudgeIcon,
      title: "You haven’t updated your experience in over a year",
      subTitle: `Last updated ${timeSinceLastUpdated.value}`,
      showUpdateButton: true,
    };

  return {
    style: "normal",
    icon: tickIcon,
    title: "Your profile looks up-to-date",
    subTitle: `Last updated ${timeSinceLastUpdated.value}`,
    showUpdateButton: false,
  };
});

const timeSinceLastUpdated = computed(() => {
  const updatedAt = new Date(individual.value.updated_at);
  const currentDate = new Date();

  return humaniseTimeSince(updatedAt, currentDate);
});

const errorIcon = `
<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" class="hidden sm:block">
  <rect width="56" height="56" rx="28" fill="#FFDDD3" />
  <path
  d="M28 18C22.48 18 18 22.48 18 28C18 33.52 22.48 38 28 38C33.52 38 38 33.52 38 28C38 22.48 33.52 18 28 18ZM28 29C27.45 29 27 28.55 27 28V24C27 23.45 27.45 23 28 23C28.55 23 29 23.45 29 24V28C29 28.55 28.55 29 28 29ZM29 33H27V31H29V33Z"
  fill="#F04132" />
</svg>`;

const nudgeIcon = `
<svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg"
  class="hidden sm:block">
  <rect y="0.5" width="56" height="56" rx="28" fill="#FFEBD2" />
  <path
    d="M20.47 37.0038H35.53C37.07 37.0038 38.03 35.3338 37.26 34.0038L29.73 20.9938C28.96 19.6638 27.04 19.6638 26.27 20.9938L18.74 34.0038C17.97 35.3338 18.93 37.0038 20.47 37.0038ZM28 30.0038C27.45 30.0038 27 29.5538 27 29.0038V27.0038C27 26.4538 27.45 26.0038 28 26.0038C28.55 26.0038 29 26.4538 29 27.0038V29.0038C29 29.5538 28.55 30.0038 28 30.0038ZM29 34.0038H27V32.0038H29V34.0038Z"
    fill="#E69105" />
</svg>
`;

const tickIcon = `
<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg"
  class="hidden sm:block">
  <rect width="56" height="56" rx="28" fill="#EDF2F1" />
  <path
    d="M28 18C22.48 18 18 22.48 18 28C18 33.52 22.48 38 28 38C33.52 38 38 33.52 38 28C38 22.48 33.52 18 28 18ZM25.29 32.29L21.7 28.7C21.31 28.31 21.31 27.68 21.7 27.29C22.09 26.9 22.72 26.9 23.11 27.29L26 30.17L32.88 23.29C33.27 22.9 33.9 22.9 34.29 23.29C34.68 23.68 34.68 24.31 34.29 24.7L26.7 32.29C26.32 32.68 25.68 32.68 25.29 32.29Z"
    fill="#3C6955" />
</svg>
`;
</script>
