import { Ref, ref, readonly } from "vue";
import { apiAxios } from "@/lib/axios";
import { Profile } from "@/types/Profile";
import * as Sentry from "@sentry/browser";
import { HttpErrorCode } from "@/types/Errors";

export const myProfile: Ref<Profile | null> = ref(null);
export const myProfileLoaded: Ref<boolean> = ref(false);

export const profile: Ref<Profile | null> = ref(null);
export const profileError: Ref<HttpErrorCode | null> = ref(null);

async function getMyProfile(): Promise<void> {
  try {
    const { data } = await apiAxios.get("/my-profile");

    myProfile.value = data;
    myProfileLoaded.value = true;
  } catch (error: any) {
    Sentry.captureException({
      message: "Failed to fetch my profile",
      error,
    });
  }
}

async function getProfile(urlSegment: string, key?: string): Promise<void> {
  try {
    const token = urlSegment.split("-").pop();
    if (!token) throw new Error("Invalid profile link");

    const { data } = await apiAxios.get(`/profile/${token}`, {
      params: { key },
    });

    profile.value = data;
  } catch (error: any) {
    profileError.value = error.response?.status;
    if (profileError.value != 401) {
      Sentry.captureException({
        message: `Failed to fetch profile ${urlSegment}`,
        error,
      });
    }
  }
}

export default function useProfile() {
  return {
    myProfile,
    myProfileLoaded: readonly(myProfileLoaded),
    getMyProfile,
    profile,
    getProfile,
    profileError: readonly(profileError),
  };
}
