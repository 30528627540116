<template>
  <div class="flex w-full sm:w-auto">
    <div class="flex bg-blue-100 rounded-l-lg my-4">
      <span
        class="material-icons-outlined my-auto text-2xl text-blue-700 mx-2 justify-self-start"
      >
        info
      </span>
    </div>
    <div
      class="sm:flex my-4 pl-4 rounded-r-lg bg-white w-full justify-between sm:pl-0"
    >
      <p
        class="mt-2 sm:my-auto text-lg text-grey-900 font-normal mb-0 sm:pl-4"
        data-cy="role-notification-reason-banner-text"
      >
        {{ this.bannerText }}
      </p>
      <router-link
        v-if="!this.shouldOnHoverDetailsBeRendered"
        data-cy="role-notification-reason-banner-link"
        class="text-cta-tertiary text-sage-600 sm:px-6 pl-0 py-3 text-left sm:whitespace-nowrap hover:underline hover:bg-blue-50 active:bg-blue-100 flex sm:block rounded-lg sm:pl-4 sm:rounded-none"
        :to="this.linkAddress"
      >
        {{ this.linkText }}
      </router-link>
      <div v-else class="relative">
        <role-nudging-info-tooltip
          v-if="this.renderInformationOverlay"
          :information-message="this.learnMoreInformationMessage"
          :render-link="this.renderLearnMoreLink"
          :link-address="this.learnMoreLinkAddress"
          :link-label="this.learnMoreLinkLabel"
          :role-id="roleId"
          :nudging-type="this.nudgingType"
        ></role-nudging-info-tooltip>
        <div
          data-cy="role-notification-learn-more-message"
          class="text-cta-tertiary text-sage-600 sm:px-6 pl-0 py-3 text-left sm:whitespace-nowrap hover:underline hover:bg-blue-50 active:bg-blue-100 flex sm:block rounded-lg sm:pl-4 sm:rounded-none cursor-pointer"
          @click="showInformationOverlay()"
        >
          Learn More
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import RoleNudgingInfoTooltip from "@/components/roles/RoleNudgingInfoTooltip.vue";
import useIndividual from "@/composables/useIndividual";
import {
  DiversityTooltipRejectionMessages,
  DiversityTooltipMoreInfoMessages,
  DiversityBannerText,
} from "@/constants/DiversityRoleNotificationReasonConstants";
import { PropType } from "vue";
import { RoleType } from "@/lib/RoleTyper";
import { RoleNudgingHelper } from "@/lib/RoleNudgingHelper";

export default {
  name: "RoleNotificationReasonBanner",
  components: {
    RoleNudgingInfoTooltip,
  },
  props: {
    reason: {
      type: String,
      required: true,
    },
    roleId: {
      type: String,
      required: true,
    },
    roleRequestAdditionalUserInfo: {
      type: Boolean,
    },
    roleDiversityRequirements: {
      type: String,
    },
    roleTypes: {
      type: Array as PropType<RoleType[]>,
      required: true,
    },
  },
  data() {
    return {
      bannerText: "",
      linkText: "",
      linkAddress: "",
      trackingEventName: "",
      shouldOnHoverDetailsBeRendered: false,
      renderInformationOverlay: false,
      learnMoreInformationMessage: "",
      renderLearnMoreLink: false,
      learnMoreLinkLabel: "",
      learnMoreLinkAddress: "",
      nudgingType: "",
    };
  },
  setup() {
    const { individual } = useIndividual();
    return {
      individual,
    };
  },
  beforeMount() {
    this.setBannerValues(this.reason);
  },
  computed: {
    genderFieldCompleted(): boolean {
      return !!(this.individual && this.individual.gender);
    },
    ethnicityGroupFieldCompleted(): boolean {
      return !!(this.individual && this.individual.ethnicity_group);
    },
  },
  methods: {
    determinePreferencesLinks() {
      const roleNudgingHelper = new RoleNudgingHelper();
      return roleNudgingHelper.getPreferencesUrl(this.roleTypes);
    },
    setBannerValues(reason: string) {
      switch (reason) {
        case "diversity":
          this.nudgingType = "diversity";
          if (this.roleRequestAdditionalUserInfo) {
            this.bannerText =
              "We need more information from you to consider recommending this role";
            this.linkText = "Update details";
            this.linkAddress = "/account/settings";
            this.trackingEventName =
              "Navigated to account details from diversity nudging banner";
            this.shouldOnHoverDetailsBeRendered = true;
            this.learnMoreInformationMessage =
              this.determineDiversityTooltipMoreInfoMessage();
            this.renderLearnMoreLink = true;
            this.learnMoreLinkLabel = "Update details";
            this.learnMoreLinkAddress = "/account/settings";
          } else {
            this.bannerText = this.determineDiversityBannerText();
            this.trackingEventName =
              "Navigated to account details from diversity nudging banner";
            this.shouldOnHoverDetailsBeRendered = true;
            this.learnMoreInformationMessage =
              this.determineDiversityTooltipRejectionMessage();
            this.renderLearnMoreLink = false;
          }
          break;
        case "experience":
          this.setExperienceBanner();
          break;
        case "location":
          this.setLocationBanner();
          break;
        case "preferences":
          this.bannerText =
            "Update your preferences to match with roles like this";
          this.linkText = "Update preferences";
          this.linkAddress = this.determinePreferencesLinks();
          this.trackingEventName =
            "Navigated to role preferences from non-notified role preferences nudging banner";
          this.shouldOnHoverDetailsBeRendered = false;
          break;
        default:
          break;
      }
    },
    setExperienceBanner() {
      this.bannerText =
        "Tell us more about your experience if you think this role is a match";
      this.linkText = "Update profile";
      this.linkAddress = "/account/profile?nudging";
      this.trackingEventName =
        "Navigated to mini-cv from professional experience nudging banner";
      this.shouldOnHoverDetailsBeRendered = false;
    },
    setLocationBanner() {
      this.nudgingType = "geographic";
      this.bannerText =
        "We thought you'd be interested in roles that are closer to you";
      this.linkText = "Update post code";
      this.linkAddress = "/account/settings";
      this.trackingEventName =
        "Navigated to account details from geographical nudging banner";
      this.shouldOnHoverDetailsBeRendered = true;
      this.learnMoreInformationMessage =
        "For some of our smaller organisations, we are only notifying individuals who live within easy travelling distance of the role as the role is more likely to be of interest. You are still very welcome to apply if you are interested in this role.";
      this.renderLearnMoreLink = true;
      this.learnMoreLinkLabel = "Update post code";
      this.learnMoreLinkAddress = "/account/settings";
    },
    navigateToLink() {
      this.trackEvent();
      this.$router.push({ path: this.linkAddress });
    },
    trackEvent() {
      return window.analytics.track(this.trackingEventName, {
        roleId: this.roleId,
      });
    },
    showInformationOverlay() {
      const eventType = this.renderInformationOverlay ? "Closed" : "Rendered";

      window.analytics.track(
        `${eventType} the Learn more information for ${this.nudgingType} nudging banner`,
        {
          roleId: this.roleId,
        }
      );
      this.renderInformationOverlay = !this.renderInformationOverlay;
    },
    hideInformationOverlay() {
      window.analytics.track(
        `Closed the Learn more information for ${this.nudgingType} nudging banner`,
        {
          roleId: this.roleId,
        }
      );
      this.renderInformationOverlay = false;
    },
    determineDiversityBannerText() {
      switch (this.roleDiversityRequirements) {
        case "gender":
          return DiversityBannerText.GENDER;
        case "ethnicity":
          return DiversityBannerText.ETHNICITY;
        case "gender_AND_ethnicity":
        case "gender_OR_ethnicity":
          return DiversityBannerText.COMBINED;
        default:
          break;
      }
    },
    determineDiversityTooltipRejectionMessage() {
      switch (this.roleDiversityRequirements) {
        case "gender":
          return DiversityTooltipRejectionMessages.GENDER;
        case "ethnicity":
          return DiversityTooltipRejectionMessages.ETHNICITY;
        case "gender_AND_ethnicity":
        case "gender_OR_ethnicity":
          return DiversityTooltipRejectionMessages.COMBINED;
        default:
          break;
      }
    },
    determineDiversityTooltipMoreInfoMessage() {
      switch (this.roleDiversityRequirements) {
        case "gender":
          return DiversityTooltipMoreInfoMessages.GENDER;
        case "ethnicity":
          return DiversityTooltipMoreInfoMessages.ETHNICITY;
        case "gender_AND_ethnicity":
        case "gender_OR_ethnicity":
          if (
            !this.genderFieldCompleted &&
            !this.ethnicityGroupFieldCompleted
          ) {
            return DiversityTooltipMoreInfoMessages.COMBINED;
          } else if (!this.genderFieldCompleted) {
            return DiversityTooltipMoreInfoMessages.GENDER;
          } else if (!this.ethnicityGroupFieldCompleted) {
            return DiversityTooltipMoreInfoMessages.ETHNICITY;
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>
