<template>
  <div class="mb-4">
    As part of your Nurole membership we are able to connect you with selected,
    independent third parties. Please check the relevant boxes of the services
    that would be interesting to you:
  </div>

  <input
    class="checkbox-input"
    type="checkbox"
    id="coaching_interest"
    v-model="coaching_interest"
    @input="saveChanges"
  />
  <label for="coaching_interest" class="checkbox-label"
    >Coaching / CV / interview preparation</label
  >
  <br />

  <input
    class="checkbox-input"
    type="checkbox"
    id="networking_interest"
    v-model="networking_interest"
    @input="saveChanges"
  />
  <label for="networking_interest" class="checkbox-label">Networking</label>
  <br />

  <input
    class="checkbox-input"
    type="checkbox"
    id="education_interest"
    v-model="education_interest"
    @input="saveChanges"
  />
  <label for="education_interest" class="checkbox-label"
    >Education / thought leadership</label
  >
</template>

<script lang="ts">
import useIndividual from "@/composables/useIndividual";
import debounce from "@/utils/debounce";

export default {
  setup() {
    const { isLoaded, individual, updateIndividual } = useIndividual();
    return {
      individual,
      updateIndividual,
      isLoaded,
    };
  },
  data() {
    return {
      coaching_interest: false,
      networking_interest: false,
      education_interest: false,
    };
  },
  methods: {
    saveChanges: debounce(async function () {
      await this.updateIndividual(this.$data, "third_party_preferences");
    }, 500),
  },
  watch: {
    isLoaded: {
      immediate: true,
      handler(isLoaded) {
        if (isLoaded) {
          this.coaching_interest = this.individual.coaching_interest;
          this.networking_interest = this.individual.networking_interest;
          this.education_interest = this.individual.education_interest;
        }
      },
    },
  },
};
</script>
