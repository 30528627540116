<template>
  <div
    class="relative bg-white md:shadow rounded-lg p-4 mb-4 mx-4 border-2 md:mx-0 md:border-0"
    v-if="layout == 'card' && nextStepToComplete"
  >
    <router-link
      class="no-underline text-lg text-black hover:text-sage-700 hover:underline"
      :to="nextStepToComplete.path"
      v-on:click="scrollToItem"
    >
      <div class="mb-2">
        <div>
          <div class="font-semibold">{{ nextStepToComplete.title }}</div>
          <span class="inline-block py-1 rounded-full text-black">{{
            nextStepToComplete.description
          }}</span>
        </div>
        <div>
          <span class="copy-bold text-black inline-block whitespace-nowrap"
            >{{ stepsCompleted }} / {{ stepsToComplete }} steps completed</span
          >
        </div>
      </div>
      <div class="h-2 mb-4 flex w-full">
        <div class="bg-blue-100 w-full rounded"></div>
        <div
          :class="{
            'w-1/5': stepsCompleted === 1,
            'w-2/5': stepsCompleted === 2,
            'w-3/5': stepsCompleted === 3,
            'w-4/5': stepsCompleted === 4,
            'w-full': stepsCompleted === 5,
          }"
          class="bg-green-900 absolute h-2 rounded"
        ></div>
      </div>
    </router-link>
  </div>

  <div v-if="layout == 'banner' && nextStepToComplete" class="bg-white">
    <div class="relative bg-white p-4 mb-4 md:px-0 md:max-w-6xl md:mx-auto">
      <div class="copy-emphasis mb-2">
        Recommended steps: {{ stepsCompleted }}/{{ stepsToComplete }} completed
      </div>
      <div class="h-2 mb-4 flex w-full">
        <div class="bg-blue-100 w-full rounded"></div>
        <div
          :class="{
            'w-1/5': stepsCompleted === 1,
            'w-2/5': stepsCompleted === 2,
            'w-3/5': stepsCompleted === 3,
            'w-4/5': stepsCompleted === 4,
            'w-full': stepsCompleted === 5,
          }"
          class="bg-green-900 absolute h-2 rounded"
        ></div>
      </div>

      <div class="border rounded-lg p-4">
        <div class="mb-2">
          <div>
            <div class="copy-emphasis">{{ nextStepToComplete.title }}</div>
            <div class="flex flex-col md:flex-row">
              <span
                class="inline-block py-1 rounded-full text-black flex-grow mb-4 copy"
                >{{ nextStepToComplete.description }}</span
              >

              <router-link
                class="order-1 button-primary text-cta-primary whitespace-nowrap text-center items-center justify-center gap-2 h-[49px]"
                :to="nextStepToComplete.path"
                v-on:click="scrollToItem"
              >
                {{ nextStepToComplete.title }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="text-gray-600 mt-1 divider flex items-center">
        <span class="material-icons-outlined text-sage-700 text-base mr-2">
          visibility
        </span>
        <span> These suggestions are visible to you only </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import profileCompleteness from "@/composables/profileCompleteness";
defineProps({
  layout: String,
});

const {
  rolesDone,
  roleDescriptionsDone,
  careerSummaryDone,
  careerHighlightDone,
  educationCoursesDone,
} = profileCompleteness();

const stepsCompleted = computed(() => {
  return steps.value.filter(step => step.completed).length;
});

const stepsToComplete = computed(() => {
  return steps.value.length;
});

const steps = computed(() => {
  return [
    {
      title: "Add summary",
      description:
        "Craft a compelling career summary for a strong first impression with hiring boards.",
      path: "/account/profile/summary",
      completed: careerSummaryDone.value,
      scrollTo: "career_summary",
      setFocusTo: "textarea",
    },
    {
      title: "Add Professional Experience",
      description:
        "Boost your profile – members with a detailed career history have a 70% higher chance of being featured to clients.",
      path: "/account/profile/experience",
      completed: rolesDone.value,
    },
    {
      title: "Add Role Descriptions",
      description:
        "Add detailed role descriptions to spotlight achievements and skills. Elevate your profile with a meticulous portrayal of your professional journey.",
      path: "/account/profile/experience",
      completed: roleDescriptionsDone.value,
    },
    {
      title: "Add Career highlights",
      description:
        "Stand out by highlighting key achievements that enrich your professional profile.",
      path: "/account/profile/career-highlights",
      completed: careerHighlightDone.value,
      scrollTo: "career_highlight",
      setFocusTo: "textarea",
    },
    {
      title: "Add education",
      description:
        "Complete your profile with your educational background for a well-rounded overview.",
      path: "/account/profile/education",
      completed: educationCoursesDone.value,
    },
  ];
});

const nextStepToComplete = computed(() => {
  return steps.value.filter(step => !step.completed)[0];
});

function scrollToItem() {
  const scrollToElement = nextStepToComplete.value.scrollTo;
  const setFocusTo = nextStepToComplete.value.setFocusTo;
  if (scrollToElement) {
    setTimeout(() => {
      const element = document.getElementById(scrollToElement);
      if (element) {
        const topBuffer = 20;

        window.scrollTo({
          top: getElementOffset(element) - getHeaderHeight() - topBuffer,
          behavior: "smooth",
        });

        if (setFocusTo) {
          element.querySelector(setFocusTo).focus({
            preventScroll: true,
          });
        }
      }
    }, 0);
  }
}

function getHeaderHeight() {
  const sticky = Array.from(document.querySelectorAll(".sticky")).filter(
    el => el.getBoundingClientRect().height > 0
  );
  const lastSticky = sticky[sticky.length - 1];
  const { top, height } = lastSticky.getBoundingClientRect();
  return top + height;
}

function getElementOffset(element) {
  return element.getBoundingClientRect().top + window.pageYOffset;
}
</script>
