import { Ref, ref } from "vue";
import { apiAxios } from "@/lib/axios";
import { Individual } from "@/types/Individual";
import { trackProfileStats } from "@/lib/trackProfileStats";

export const individual: Ref<Individual | any> = ref({});
const isMember = ref(false);
export const isLoaded = ref(false);
const changesMade = ref(0);

export async function getIndividual(punchThroughToken?: string): Promise<void> {
  try {
    const url = punchThroughToken
      ? `/individual/token/${punchThroughToken}`
      : "/individual";
    const { data } = await apiAxios.get(url);

    individual.value = data;
    individual.value.completed_personal_details =
      !!data.first_name && !!data.last_name;
    isLoaded.value = true;
    isMember.value = !!individual.value.has_registered;
  } catch {
    return;
  }
}

export async function updateIndividual(payload, changeSource): Promise<void> {
  if (individual.value.id) {
    try {
      individual.value = { ...individual.value, ...payload };
      await apiAxios.put(
        `/individual/${individual.value.id}`,
        individual.value
      );
      trackProfileStats(individual.value, changeSource);
      changesMade.value++;
    } catch {
      return;
    }
  }
}

export default function useIndividual() {
  return {
    changesMade,
    isLoaded,
    individual,
    getIndividual,
    updateIndividual,
    isMember,
  };
}
