<template>
  <button
    @click="$emit('on-select', organisation)"
    class="w-full py-4 px-6 hover:bg-grey-50 cursor-pointer text-left text-inputs-content"
    :class="{ 'bg-grey-100': isHighlighted }"
  >
    <div class="copy-bold">
      {{ organisation.name }}
    </div>
    <organisation-badges :organisation="organisation" />
  </button>
</template>

<script lang="ts">
import { RolesTableOrganisation } from "@/types/RolesTableRole";
import { PropType } from "vue";
import OrganisationBadges from "./OrganisationBadges.vue";
export default {
  components: { OrganisationBadges },
  name: "OrganisationOption",
  props: {
    organisation: {
      type: Object as PropType<RolesTableOrganisation>,
      required: true,
    },
    isHighlighted: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["on-select"],
};
</script>
