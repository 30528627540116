<template>
  <button
    class="p-2 sm:p-4 md:p-3 rounded-full cursor-pointer"
    :class="[selected ? highlightColor : `bg-grey-50 ${hoverColor}`]"
  >
    <slot></slot>
  </button>
</template>

<script lang="ts">
export default {
  name: "SmileFeedbackSingle",
  props: {
    value: { default: "", type: String },
    selected: { default: false, type: Boolean },
  },

  computed: {
    highlightColor() {
      switch (this.value) {
        case "hate":
          return "bg-red-600";
        case "dislike":
          return "bg-orange-600";
        case "neutral":
          return "bg-orange-400";
        case "like":
          return "bg-sage-600";
        case "love":
          return "bg-blue-700";
        default:
          return "bg-ivory-100";
      }
    },

    hoverColor() {
      switch (this.value) {
        case "hate":
          return " hover:bg-red-50";
        case "dislike":
          return " hover:bg-orange-50";
        case "neutral":
          return " hover:bg-ivory-100";
        case "like":
          return " hover:bg-sage-50";
        case "love":
          return " hover:bg-blue-50";
        default:
          return " hover:bg-ivory-100";
      }
    },
  },
};
</script>
