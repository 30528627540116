<template>
  <div
    class="rounded-lg px-6 flex items-center"
    :class="{ 'bg-red-50': isError, 'bg-blue-50': !isError }"
  >
    <tasks-icon :color="iconColor" class="mr-2 w-10" />
    <div class="flex flex-col items-start justify-center">
      <span class="copy-emphasis text-grey-800">
        {{ message }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import TasksIcon from "@/components/roleApplication/TasksIcon.vue";

export default {
  components: {
    TasksIcon,
  },
  props: {
    isError: {
      type: Boolean,
    },
    message: {
      type: String,
    },
  },
  computed: {
    iconColor() {
      return this.isError ? "red" : "blue";
    },
  },
};
</script>
