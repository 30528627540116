<template>
  <button
    class="flex gap-2 rounded-full border text-lg leading-[120%] tracking-[0.54px]"
    :class="{
      [type]: true,
      error: hasError,
      'icon-only': !label,
      'with-label': !!label,
      bright,
    }"
    @click="emit('click')"
  >
    <span v-if="showLeftIcon" class="material-icons-round text-base">
      {{ leftIcon }}
    </span>
    <span class="flex-1 text-nowrap">{{ label }}</span>
    <span v-if="showRightIcon" class="material-icons-round text-base">
      {{ rightIcon }}
    </span>
  </button>
</template>

<script setup lang="ts">
defineProps<{
  type: "primary" | "secondary" | "tertiary" | "disabled";
  label?: string;
  leftIcon?: string;
  rightIcon?: string;
  showLeftIcon?: boolean;
  showRightIcon?: boolean;
  hasError?: boolean;
  bright?: boolean;
}>();

const emit = defineEmits(["click"]);
</script>

<style scoped>
.icon-only {
  @apply py-2 px-2;
}
.with-label {
  @apply py-3 px-6;
  @media screen and (max-width: 768px) {
    min-width: 320px;
  }
}
.primary {
  @apply border-sage-600 bg-sage-600 text-white font-bold;
}
.primary:hover {
  @apply bg-sage-700 border-sage-700;
}
.primary:active {
  @apply bg-sage-800 border-sage-800;
}
.secondary {
  @apply border-sage-700 bg-white text-sage-600 font-semibold;
}
.secondary:hover {
  @apply bg-sage-50 border-sage-800;
  @apply text-sage-900;
}
.secondary:active {
  @apply bg-sage-100 border-sage-900;
  @apply text-sage-900;
}
.tertiary {
  @apply border-transparent bg-none text-sage-600 uppercase font-semibold;
}
.tertiary:hover {
  @apply text-sage-700;
  @apply underline underline-offset-[6px];
}
.tertiary:active {
  @apply text-sage-800;
  @apply underline underline-offset-[6px];
}
.disabled {
  @apply border-ivory-200 bg-ivory-200 text-ivory-700 font-bold;
}
.error.primary {
  @apply border-red-700 bg-red-700 text-white;
}
.error.secondary {
  @apply bg-white text-red-800;
}
.error.tertiary {
  @apply bg-transparent border-transparent text-red-700;
}
.error:hover.primary {
  @apply border-red-800 bg-red-800;
}
.error:hover.secondary {
  @apply bg-red-50 text-red-800;
}
.error:hover.tertiary {
  @apply text-red-700;
}
.error:active.primary {
  @apply border-red-900 bg-red-900;
}
.error:active.secondary {
  @apply bg-red-100 text-red-900;
}
.error:active.tertiary {
  @apply text-red-800;
}
</style>
