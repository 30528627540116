<template>
  <div
    v-if="image"
    class="rounded-full border-transparent border-3 border-solid overflow-hidden items-center justify-center flex w-10 h-10"
    :class="clickableClasses"
    :data-cy="dataCy"
  >
    <img
      :src="image"
      :alt="altText"
      width="40"
      height="40"
      class="object-cover"
    />
  </div>
  <div
    v-else
    class="flex items-center rounded-full bg-sage-600 border-white border-3 border-solid box-content w-10 h-10"
    :class="clickableClasses"
  >
    <div class="text-cta-primary text-center w-full text-white">
      {{ initials }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseAvatar",

  props: {
    individual: { required: true },
    altText: { type: String },
    dataCy: { type: String },
    isDisabled: { type: Boolean },
  },

  computed: {
    clickableClasses(): string {
      if (this.isDisabled) return "";

      const classes =
        "cursor-pointer  hover:border-grey-100 active:border-sage-500";

      return this.image
        ? classes
        : classes + "  hover:bg-sage-700 active:bg-sage-800";
    },

    image(): string {
      return this.individual.picture;
    },

    initials(): string {
      if (!this.individual.first_name || !this.individual.last_name) return "";
      return `${this.individual.first_name.charAt(
        0
      )}${this.individual.last_name.charAt(0)}`;
    },
  },
});
</script>
