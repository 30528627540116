<template>
  <!-- Roles held (including recently deleted) -->
  <mini-cv-role
    v-for="role in sortedRolesHeld"
    :key="role.id"
    :role="role"
    :allow-delete="!hideDelete"
    :allow-pin="allowPin"
    :show-org-description="showOrgDescription"
    @delete-role-held-with-undo="deleteRoleHeldWithUndo"
    @open-role-held="openRoleHeld"
    @open-org-data-modal="openOrgDataModal"
  >
    <!-- Slot used for toggling selected role on applications -->
    <slot v-bind="role"></slot>
  </mini-cv-role>

  <!-- Add or edit role held -->
  <role-held-modal v-if="openedRoleHeld" @close-modal="openedRoleHeld = null">
    <template #title>
      {{ openedRoleHeld.id ? "Edit experience" : "Add experience" }}
    </template>
    <role-held-form
      :role-held="openedRoleHeld"
      :show-org-description="showOrgDescription"
      @close-form="openedRoleHeld = null"
      @save-role-held="handleSaveRoleHeld"
      @delete-role-held="handleDeleteRoleHeld"
    />
  </role-held-modal>

  <!-- Add or edit organisation data -->
  <organisation-data-modal
    v-if="openedOrgDataModal"
    @close-modal="openedOrgDataModal = null"
    :role-held="openedOrgDataModal"
    :selected-button="selectedOrgDataButton"
    @save-role-held-org-data="handleSaveRoleHeld"
  />

  <roles-held-list-skeleton v-if="showSkeleton" />
  <add-role-button @click="openRoleHeld" class="mt-5 sm:mt-7" />
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import MiniCvRole from "@/components/MiniCvRole.vue";
import RoleHeldModal from "@/components/miniCv/RoleHeldModal.vue";
import RoleHeldForm from "./miniCv/RoleHeldForm.vue";
import AddRoleButton from "@/components/miniCv/AddRoleButton.vue";
import useMiniCv from "@/composables/useMiniCv";
import { sortRolesHeld } from "@/helpers/miniCv";
import type { RoleHeld } from "@/composables/useMiniCv";
import RolesHeldListSkeleton from "@/components/RolesHeldListSkeleton.vue";
import OrganisationDataModal from "@/components/miniCv/OrganisationDataModal.vue";

defineProps({
  hideDelete: Boolean,
  allowPin: Boolean,
  showOrgDescription: {
    type: Boolean,
    default: true,
  },
});
defineExpose({ openRoleHeld });

const {
  rolesHeld,
  getRolesHeld,
  deleteWithUndo,
  deletedRoles,
  deleteRoleHeld,
  saveRoleHeld,
} = useMiniCv();

const showSkeleton = ref(true);
const openedRoleHeld = ref<RoleHeld | null>(null);
const openedOrgDataModal = ref<RoleHeld | null>(null);
const selectedOrgDataButton = ref("");

onMounted(async () => {
  showSkeleton.value = rolesHeld.value.length == 0;
  await getRolesHeld();
  showSkeleton.value = false;
});

function openRoleHeld(roleHeld: Partial<RoleHeld> = {}) {
  openedRoleHeld.value = roleHeld;
}

function openOrgDataModal(roleHeld: Partial<RoleHeld> = {}, button: string) {
  openedOrgDataModal.value = roleHeld;
  selectedOrgDataButton.value = button;
}

async function handleSaveRoleHeld(roleHeld: RoleHeld) {
  await saveRoleHeld(roleHeld);
  openedRoleHeld.value = null;
  openedOrgDataModal.value = null;
  selectedOrgDataButton.value = "";
}

async function handleDeleteRoleHeld(id: string) {
  await deleteRoleHeld(id);
  openedRoleHeld.value = null;
}

async function deleteRoleHeldWithUndo(roleHeld: RoleHeld) {
  await deleteWithUndo(roleHeld);
}

const sortedRolesHeld = computed(() => {
  return sortRolesHeld(rolesHeld.value.concat(deletedRoles.value));
});
</script>
