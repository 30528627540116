<template>
  <div class="flex items-center pt-7">
    <div
      class="flex-grow border-t border-b-0 border-solid border-grey-100"
    ></div>
    <span class="flex-shrink mx-4 copy-emphasis">(OR)</span>
    <div
      class="flex-grow border-t border-b-0 border-solid border-grey-100"
    ></div>
  </div>
</template>

<script lang="ts">
export default {
  name: "OrDivider",
};
</script>
