import { apiAxios } from "@/lib/axios";
import { Ref, readonly, ref } from "vue";
import * as Sentry from "@sentry/browser";
import { Education } from "@/types/Education";
import { RequestQueue } from "@/utils/RequestQueue";

const educationCourses: Ref<Education[]> = ref([]);
const isLoaded = ref(false);

export default function useEducation() {
  async function getEducation() {
    try {
      const { data } = await apiAxios.get("/education_courses");
      educationCourses.value = data;
      isLoaded.value = true;
    } catch (error: any) {
      if (error.response?.status != 401) {
        Sentry.captureException({
          message: "Failed to fetch education courses",
          error,
        });
      }
    }
  }

  async function createEducation(education: Education) {
    try {
      const { data } = await apiAxios.post("/education_course", education);
      educationCourses.value.push(data as Education);
    } catch (error) {
      Sentry.captureException({
        message: "Failed to add education course",
        error,
      });
    }
  }

  async function updateEducation(education: Education) {
    try {
      const payload = { ...education, id: undefined };
      const { data } = await apiAxios.put(
        `/education_course/${education.id}`,
        payload
      );
      const index = educationCourses.value.findIndex(
        e => e.id === education.id
      );
      educationCourses.value[index] = data as Education;
    } catch (error) {
      Sentry.captureException({
        message: "Failed to update education course",
        error,
      });
    }
  }

  async function deleteEducation(education: Education) {
    try {
      await apiAxios.delete(`/education_course/${education.id}`);
      const index = educationCourses.value.findIndex(
        e => e.id === education.id
      );
      educationCourses.value.splice(index, 1);
    } catch (error) {
      Sentry.captureException({
        message: "Failed to delete education course",
        error,
      });
    }
  }

  const searchRequestQueue = new RequestQueue();

  async function searchUniversities(query: string): Promise<[]> {
    return new Promise(resolve => {
      searchRequestQueue.enqueue({
        apiCall: () => {
          return apiAxios.get("/universities", {
            params: { q: encodeURIComponent(query) },
          });
        },
        onSuccess: (response: any) => {
          const orgs = response.data.map((university: string) => {
            return {
              id: university,
              name: university,
              size: { value: 0 },
            };
          });
          resolve(orgs);
        },
        onError: () => {
          resolve([]);
        },
      });
    });
  }

  function getDisplayDates(education: Education) {
    const from = education.from?.split("-")[0];
    const to = education.to?.split("-")[0];

    return [from, to].filter(Boolean).join(" - ");
  }

  const getDisplayDegree = course => {
    if (course.degree && course.field_of_study) {
      return `${course.degree} • ${course.field_of_study}`;
    } else if (course.degree) {
      return course.degree;
    } else if (course.field_of_study) {
      return course.field_of_study;
    } else {
      return "";
    }
  };

  return {
    educationCourses: readonly(educationCourses),
    isLoaded: readonly(isLoaded),
    getEducation,
    createEducation,
    updateEducation,
    deleteEducation,
    searchUniversities,
    getDisplayDates,
    getDisplayDegree,
  };
}
