<template>
  <img
    alt="Nurole"
    src="@/assets/images/nurole-initials.svg"
    class="animate-logo"
  />
</template>

<style scoped>
.animate-logo {
  transform: scale(1);
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.95);
  }
}
</style>
