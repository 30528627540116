<template>
  <button @click="handleClick" style="color: #dddddd" class="p-0 text-center">
    Post a role
  </button>
</template>

<script lang="ts">
import { apiAxios } from "@/lib/axios";
export default {
  name: "PostARoleButton",
  methods: {
    async handleClick() {
      await apiAxios.post("/mixpanel/click-post-a-role");
      window.location.href = `${process.env.VITE_MARKETING_BASE_URL}/post-a-role`;
    },
  },
};
</script>
