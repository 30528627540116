<template>
  <base-modal-header @close="closeModal"> Check your email </base-modal-header>
  <p class="my-8">
    We've sent a verification link link to {{ props.guestEmail }}. Please check
    your email and click the link to continue with your application.
  </p>
  <p>
    Didn't receive the email? If you can't find the email, it might be worth
    checking your spam folder, or
    <button
      @click="resendEmail"
      class="underline font-semibold"
      :class="{
        'text-sage-600 hover:text-sage-700': countDownSeconds == 0,
        'text-gray-400': countDownSeconds > 0,
      }"
    >
      click here to resend
    </button>
    <span class="ml-2">{{
      countDownSeconds > 0 ? countdownSecondsString : ""
    }}</span>
  </p>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import BaseModalHeader from "./shared/BaseModalHeader.vue";
import { authAxios } from "@/lib/axios";

const props = defineProps(["guestEmail"]);
const emit = defineEmits(["close-magic-link-confirmation"]);
const countDownSeconds = ref(30);
const isCountingDown = ref(true);
const timer = ref(null);
const currentRoute = useRoute();

function closeModal() {
  emit("close-magic-link-confirmation");
}

function countDownResendTimer() {
  if (countDownSeconds.value > 0 && isCountingDown.value) {
    countDownSeconds.value--;
  } else {
    clearInterval(timer);
    isCountingDown.value = false;
  }
}

async function resendEmail() {
  if (countDownSeconds.value == 0) {
    await authAxios.post("/generate-sign-in-link", {
      email: props.guestEmail,
      redirect_uri: `https://${window.location.host}${currentRoute.fullPath}/apply`,
    });
    countDownSeconds.value = 30;
    isCountingDown.value = true;
    timer.value = setInterval(countDownResendTimer, 1000);
  }
}
onMounted(() => {
  timer.value = setInterval(countDownResendTimer, 1000);
});

const countdownSecondsString = computed(() => {
  if (countDownSeconds.value > 9) {
    return `in 00:${countDownSeconds.value}.`;
  } else if (countDownSeconds.value > 0) {
    return `in 00:0${countDownSeconds.value}.`;
  } else {
    return ".";
  }
});
</script>
