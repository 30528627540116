<template>
  <div class="max-w-screen-md w-full mx-auto p-10">
    <h1 class="text-title-h1">Contact us</h1>
    <p class="">Do you have questions or feedback? Please get in touch.</p>

    <div class="flex items-center mt-8">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="23"
        viewBox="0 0 64 45"
        fill="none"
        class="mr-3"
      >
        <path
          d="M0 0V44.3077H64V0H0ZM10.6154 4.92308H53.3846L32 19.1538L10.6154 4.92308ZM4.92308 7.07692L30.6154 24.2308L32 25.0769L33.3846 24.2308L59.0769 7.07692V39.3846H4.92308V7.07692Z"
          fill="#1e4b37"
        />
      </svg>
      <a href="mailto:enquiries@nurole.com" class="text-cta-tertiary"
        >enquiries@nurole.com</a
      >
    </div>

    <div class="flex items-center mt-3">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="32"
        viewBox="0 0 56 64"
        fill="none"
        class="mr-3"
      >
        <path
          d="M27.8526 0C13.9396 0 2.80914 11.1304 2.80914 25.0435C1.97436 25.0435 1.1374 25.6011 0.580881 26.1576C0.0243585 26.9924 -0.252815 27.8283 0.303707 28.663L3.08632 37.0109C3.36458 38.1239 4.47871 38.9565 5.59175 38.9565H6.98849C7.52128 39.7577 8.10278 40.5183 8.72219 41.25C9.58021 42.5753 10.5966 43.7808 11.7439 44.8533C6.1317 49.5683 2.80914 56.5737 2.80914 64H8.37436C8.37436 57.6844 11.3801 51.852 16.5102 48.1196C19.0897 49.3683 21.983 50.087 25.07 50.087H30.6352C33.683 50.087 36.5423 49.3875 39.0972 48.1685C44.1719 51.75 47.3309 57.7525 47.3309 64H52.8961C52.8961 56.6118 49.5984 49.6439 43.8798 44.9293C45.0607 43.8382 46.1047 42.6066 46.9831 41.25C47.6025 40.5183 48.184 39.7577 48.7168 38.9565H50.1135C51.2265 38.9565 52.3407 38.1239 52.6189 37.0109L55.4015 28.663C55.9581 27.8283 55.6809 26.9924 55.1244 26.1576C54.5678 25.6011 53.7309 25.0435 52.8961 25.0435C52.8961 11.1304 41.7657 0 27.8526 0ZM27.8526 5.56522C37.64 5.56522 45.5935 12.5916 47.0646 21.9293C44.5341 18.7466 40.6652 16.6957 36.2004 16.6957H19.5048C15.04 16.6957 11.1711 18.7466 8.64066 21.9293C10.1118 12.5916 18.0653 5.56522 27.8526 5.56522ZM19.5048 22.2609H36.2004C40.9309 22.2609 44.5483 25.8783 44.5483 30.6087C44.5483 32.5989 44.1425 34.4758 43.4178 36.1739H30.6352C28.9657 36.1739 27.8526 37.5652 27.8526 38.9565C27.8526 40.6261 29.2439 41.7391 30.6352 41.7391H37.9233C34.9913 43.4964 31.5588 44.5217 27.8526 44.5217C22.0075 44.5217 16.8246 42.0049 13.2711 38C11.9482 35.8653 11.157 33.3514 11.157 30.6087C11.157 25.8783 14.7744 22.2609 19.5048 22.2609Z"
          fill="#1e4b37"
        />
      </svg>
      <a href="tel:+442036371012" class="text-cta-tertiary"
        >+44 (0) 20 3637 1012</a
      >
    </div>

    <div
      v-if="hasSentMessage"
      class="mt-8 mb-48 rounded-lg px-6 py-2 bg-sage-50 text-sage-600 copy-bold"
    >
      Your message has been sent successfully. We will get back to you shortly.
    </div>

    <div v-else class="pt-6 pb-24 flex flex-col gap-6">
      <div class="grid gap-6 md:grid-cols-2">
        <label class="text-inputs-title font-semibold block"
          >Your first name
          <input
            v-model="first_name"
            type="text"
            class="text-input text-inputs-content w-full mt-2"
          />
        </label>
        <label class="text-inputs-title font-semibold"
          >Your last name
          <input
            v-model="last_name"
            type="text"
            class="text-input text-inputs-content w-full mt-2"
          />
        </label>
        <label
          class="text-inputs-title font-semibold"
          @input="errorMessage = null"
          >Your email
          <input
            v-model="email"
            type="email"
            class="text-input text-inputs-content w-full mt-2"
          />
        </label>
        <label
          class="text-inputs-title font-semibold"
          @input="errorMessage = null"
          >Your telephone
          <input
            v-model="telephone"
            type="tel"
            class="text-input text-inputs-content w-full mt-2"
          />
        </label>
      </div>

      <fieldset class="block">
        <legend class="text-inputs-title font-semibold">
          How would you like us to contact you?
        </legend>

        <div class="flex gap-6 mt-2">
          <label
            for="email"
            class="text-cta-tertiary m-0 text-sage-700 flex gap-2 items-center"
            ><input
              type="radio"
              id="email"
              value="email"
              v-model="contact_method"
              class="w-5 h-5 text-sage-700"
            />Email</label
          >

          <label
            for="telephone"
            class="text-cta-tertiary m-0 text-sage-700 flex gap-2 items-center"
            ><input
              type="radio"
              id="telephone"
              value="telephone"
              v-model="contact_method"
              class="w-5 h-5 text-sage-700"
            />Telephone</label
          >
        </div>
      </fieldset>

      <label class="text-inputs-title font-semibold"
        >Your organisation<input
          v-model="organisation"
          class="text-input text-inputs-content w-full mt-2"
          placeholder="Optional"
      /></label>

      <label class="text-inputs-title font-semibold"
        >Message<textarea
          v-model="message"
          type="text"
          @input="errorMessage = null"
          class="textarea text-inputs-content w-full mt-2"
          placeholder="Let us know how we can help ..."
        ></textarea>
      </label>

      <div v-if="errorMessage" class="state-error mt-5">
        {{ errorMessage }}
      </div>
      <button
        :disabled="isSendingMessage"
        @click="sendMessage"
        class="button-primary text-cta-primary mt-5"
      >
        Send message
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import useIndividual from "@/composables/useIndividual";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf, email, minLength } from "@vuelidate/validators";
import { apiAxios } from "@/lib/axios";

export default {
  setup() {
    const { individual, getIndividual } = useIndividual();

    return {
      individual,
      getIndividual,
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      errorMessage: null,
      isSendingMessage: false,
      hasSentMessage: false,
      first_name: "",
      last_name: "",
      email: "",
      telephone: "",
      contact_method: "email",
      organisation: "",
      message: "",
    };
  },
  async mounted() {
    window.scrollTo(0, 0);
    await this.getIndividual();
    this.first_name = this.individual.first_name;
    this.last_name = this.individual.last_name;
    this.email = this.individual.email;
    this.telephone = this.individual.telephone;
  },
  methods: {
    async sendMessage() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return (this.errorMessage = this.getErrorMessage());
      }

      this.isSendingMessage = true;
      try {
        await apiAxios.post("/enquiry", {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          telephone: this.telephone,
          contact_method: this.contact_method,
          organisation: this.organisation,
          message: this.message,
        });
        this.hasSentMessage = true;
        window.scrollTo(0, 0);
      } catch {
        this.errorMessage = "Oops! Something went wrong.";
      } finally {
        this.isSendingMessage = false;
      }
    },
    getErrorMessage() {
      if (this.message == "") return "Message is required";
      if (this.contact_method == "email" && this.email == "")
        return "Email is required";
      if (this.contact_method == "telephone" && this.telephone == "")
        return "Telephone is required";
      return "Please enter your details correctly and try again.";
    },
  },
  validations() {
    return {
      email: {
        required: requiredIf(() => this.contact_method == "email"),
        email,
      },
      telephone: {
        required: requiredIf(() => this.contact_method == "telephone"),
        minLength: minLength(8),
      },
      message: { required },
    };
  },
};
</script>
