<template>
  <page-with-title-layout title="Unsubscribe">
    <div class="px-6">
      <svg
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M39.6154 8C36.8942 8 34.6923 10.2019 34.6923 12.9231C34.6923 13.1346 34.7404 13.3365 34.7692 13.5385C26.2596 15.7115 19.9231 23.4423 19.9231 32.6154V54.7692C19.9231 56.1635 18.8558 57.2308 17.4615 57.2308H15V62.1538H32.6923C32.4135 62.9327 32.2308 63.75 32.2308 64.6154C32.2308 68.6635 35.5673 72 39.6154 72C43.6635 72 47 68.6635 47 64.6154C47 63.75 46.8173 62.9327 46.5385 62.1538H64.2308V57.2308H61.7692C60.375 57.2308 59.3077 56.1635 59.3077 54.7692V33.3077C59.3077 24.0577 53.125 15.8077 44.4615 13.5385C44.4904 13.3365 44.5385 13.1346 44.5385 12.9231C44.5385 10.2019 42.3365 8 39.6154 8ZM38.5385 17.8462C38.8942 17.8173 39.25 17.8462 39.6154 17.8462C39.7692 17.8462 39.9231 17.8462 40.0769 17.8462C48.1154 18.0865 54.3846 25.1346 54.3846 33.3077V54.7692C54.3846 55.6346 54.5673 56.4519 54.8462 57.2308H24.3846C24.6635 56.4519 24.8462 55.6346 24.8462 54.7692V32.6154C24.8462 24.7981 30.8654 18.4038 38.5385 17.8462ZM39.6154 62.1538C41 62.1538 42.0769 63.2308 42.0769 64.6154C42.0769 66 41 67.0769 39.6154 67.0769C38.2308 67.0769 37.1538 66 37.1538 64.6154C37.1538 63.2308 38.2308 62.1538 39.6154 62.1538Z"
          fill="#242726"
        />
      </svg>
      <h2 class="text-title-h2 mt-6">
        We’re sorry you are thinking of leaving. Before you go ...
      </h2>
      <div class="card-outlined py-8 px-10 mt-8">
        <div class="copy-emphasis">
          Have we been sending you the wrong notifications?
        </div>
        <div class="mt-4">
          You can change your preferences so that you only receive notifications
          for roles that are of interest to you. In addition we are working hard
          every day to strengthen our matching capability, we hope you continue
          to see these improvements
        </div>
        <router-link
          class="button-primary text-cta-primary mt-8 block w-full text-center md:text-left md:w-auto md:inline-block"
          to="/reconfigure"
          >Update my preferences</router-link
        >
      </div>
      <snooze-notifications />

      <div class="rounded-lg bg-red-50 py-8 px-10 mt-8">
        <div class="copy-emphasis">Close your account</div>
        <div class="mt-4">
          We know that there are times in your career when you don’t have
          capacity. Nonetheless, Nurole will still be here for you if things
          change. Why don’t you turn off your notifications?
        </div>
        <textarea
          v-model="feedback"
          class="text-inputs-content textarea mt-8 w-full"
          rows="4"
          placeholder="Let us know why you're leaving (optional)"
        />
        <button
          @click="closeAccount"
          class="button-error-primary text-cta-primary mt-8 block w-full md:w-auto md:inline-block"
        >
          Close my account permanently
        </button>
      </div>
    </div>
  </page-with-title-layout>
</template>

<script lang="ts" setup>
import PageWithTitleLayout from "@/components/PageWithTitleLayout.vue";
import SnoozeNotifications from "@/components/account/SnoozeNotifications.vue";
import { apiAxios, authAxios } from "@/lib/axios";
import { onMounted, ref } from "vue";

const feedback = ref("");

async function closeAccount() {
  if (confirm("Are you sure?")) {
    await apiAxios.post("/close-account", {
      feedback: feedback.value,
    });
    await authAxios.post("/logout");
    window.location.replace("/request");
  }
}
onMounted(() => {
  window.scrollTo(0, 0);
});
</script>
