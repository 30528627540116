<template>
  <div
    class="border border-solid rounded-lg bg-white pb-2"
    :class="{
      'border-grey-300': !warnIfBelowMinimum,
      'border-sage-400': isWithinRange,
      'border-orange-500':
        (warnIfBelowMinimum && isBelowMinimum) || isOverLimit,
    }"
  >
    <div
      class="p-3 md:p-6 copy-bold rounded-t-xl bg-opacity-10 text-grey-800 border-b border-r-0 border-l-0 border-t-0 border-solid"
      :class="{
        'border-ivory-400': !wordCount,
        'border-grey-300': !warnIfBelowMinimum,
        'border-sage-400 bg-sage-400': isWithinRange,
        'border-orange-500 bg-orange-500':
          (warnIfBelowMinimum && isBelowMinimum) || isOverLimit,
      }"
    >
      <div
        :key="message"
        class="flex items-center"
        :data-cy="`${uniqueId}-message`"
      >
        <div
          class="rounded-full w-10 h-10 flex-none flex items-center justify-center mr-3 text-white"
          :class="{
            'bg-transparent': !warnIfBelowMinimum,
            'bg-sage-400': isWithinRange,
            'bg-orange-500':
              (warnIfBelowMinimum && isBelowMinimum) || isOverLimit,
          }"
        >
          <span class="material-icons-round" v-if="isWithinRange"> info </span>
          <span
            class="material-icons-round"
            v-else-if="isOverLimit || (isBelowMinimum && warnIfBelowMinimum)"
          >
            warning
          </span>
          <icon-idea v-else />
        </div>
        {{ message }}
      </div>
    </div>
    <editor
      class="outline-none mx-6 py-4"
      :api-key="API_KEY"
      :init="{
        selector: 'div.tinymce',
        plugins: ['wordcount', 'lists', 'autoresize'],
        mobile: {
          plugins: ['wordcount', 'lists'],
        },
        autoresize_bottom_margin: 10,
        browser_spellcheck: true,
        min_height: 160,
        menubar: false,
        statusbar: false,
        inline_boundaries: false,
        toolbar:
          'bold italic underline | bullist numlist outdent indent | cut copy paste undo redo removeformat',
        paste_retain_style_properties: 'ol,ul,li',
        paste_filter_drop: false,
        paste_as_text: true,
        format: {
          removeformat: [
            {
              selector: '*',
              remove: 'all',
              split: true,
              expand: false,
              block_expand: true,
              deep: true,
            },
          ],
        },
      }"
      v-model="text"
      @init="handleInit"
      placeholder="Your text here"
      :id="`${uniqueId}-editor`"
      :data-cy="dataCy"
    />
    <div
      class="text-grey-600 p-6 border-t border-b-0 border-l-0 border-r-0 border-solid border-grey-100"
    >
      {{ wordCount }} {{ wordCount === 1 ? "word" : "words" }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";
import Editor from "@tinymce/tinymce-vue";
import IconIdea from "@/components/icons/IconIdea.vue";
import { nextTick } from "vue";

const props = defineProps({
  uniqueId: String,
  initialText: {
    type: String,
    default: "",
  },
  recommendedWordCount: {
    type: Number,
    default: 500,
  },
  customMessage: {
    type: Function,
    required: false,
  },
  dataCy: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["text-updated"]);

const text = ref(props.initialText);
const editorInstance = ref(null);
const wordCount = ref(0);
const warnIfBelowMinimum = ref(false);
const API_KEY = process.env.VITE_TINYMCE_API_KEY;

const upperWordLimit = computed(() => props.recommendedWordCount * 2);
const lowerWordLimit = computed(() => props.recommendedWordCount * 0.6);
const isBelowMinimum = computed(() => wordCount.value < lowerWordLimit.value);
const isWithinRange = computed(
  () =>
    wordCount.value >= lowerWordLimit.value &&
    wordCount.value <= upperWordLimit.value
);
const isOverLimit = computed(() => wordCount.value > upperWordLimit.value);

function handleInit(_event, editor) {
  editorInstance.value = editor;
  wordCount.value = editor.plugins.wordcount.body.getWordCount();
  if (text.value) warnIfBelowMinimum.value = true;

  editor.on("blur", () => {
    if (text.value) warnIfBelowMinimum.value = true;
  });

  editor.on("focus", async () => {
    await nextTick();
    const editorEl = document.getElementById(`${props.uniqueId}-editor_ifr`);
    if (editorEl) {
      editorEl.scrollIntoView({
        block: "center",
      });
    }
  });
}

const message = computed(() => {
  let customMessage;
  if (props.customMessage) {
    customMessage = props.customMessage(props.recommendedWordCount);
  }

  if (isBelowMinimum.value && warnIfBelowMinimum.value) {
    return (
      customMessage?.belowMinimum ||
      `You are significantly under the recommended response length of ${props.recommendedWordCount} words, research suggests that very short answers will reduce your chance of progressing.`
    );
  }

  if (isWithinRange.value) {
    return (
      customMessage?.withinRange ||
      `Data suggests succinct answers do better. While you may write more, responses exceeding ${props.recommendedWordCount} words have a materially lower chance of progressing.`
    );
  }

  if (isOverLimit.value) {
    return (
      customMessage?.overLimit ||
      `You’ve exceeded the recommended ${props.recommendedWordCount} word limit. You may write more but data suggests it will reduce your chances of success.`
    );
  }

  return (
    customMessage?.default ||
    `Data suggests optimal responses are around ${props.recommendedWordCount} words in length. Answers materially over or under this will lower your chance of progressing.`
  );
});

function stripImgTags(value) {
  return value.replace(/<img(.*)\/>/g, "").replace(/<img>(.*)<img\/>/g, "");
}

watch(
  text,
  () => {
    if (!editorInstance.value) return;
    wordCount.value =
      editorInstance.value.plugins.wordcount.body.getWordCount();
    if (wordCount.value === 0) warnIfBelowMinimum.value = false;
    emit("text-updated", stripImgTags(text.value));
  },
  { immediate: true }
);
</script>

<style>
.tox:not([dir="rtl"]) {
  outline: 0 !important;
  border: none;
}
</style>
