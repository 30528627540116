import {
  RolesTableOrganisation,
  RolesTableOrganisationSize,
} from "@/types/RolesTableRole";

export default function getOrganisationSize(
  organisation: RolesTableOrganisation
) {
  const size = organisation.size;
  const label = size?.label;
  if (
    size &&
    (label === "Number of employees" ||
      label === "Number of students" ||
      label === "Number of beds")
  ) {
    return getSizeRange(size);
  }
  return formatSizeText(organisation);
}

function getSizeRange({ label, value }: RolesTableOrganisationSize) {
  if (!value || !label) return "";

  let range;
  if (value > 10000) range = "100,000+";
  if (value <= 100000) range = "10,000-100,000";
  if (value <= 10000) range = "500-10,000";
  if (value <= 500) range = "100-500";
  if (value <= 100) range = "50-100";
  if (value <= 50) range = "10-50";
  if (value <= 10) range = "0-10";

  const metric = label.split("Number of ")[1];

  return `${range} ${metric}`;
}

function formatSizeText({ size, revenue_gbp }: RolesTableOrganisation) {
  const originalLabel = size?.label || null;
  const originalValue = size?.value || null;
  const originalCurrency = size?.currency || null;
  const revenueValue = revenue_gbp || null;

  const valueToShow = getSizeValue(originalValue, revenueValue);
  const labelToShow = getSizeLabel(originalValue, originalLabel);

  if (!valueToShow) return;

  const currencyCode = originalCurrency ? ` ${originalCurrency}` : "";

  return `${labelToShow}: ${valueToShow}${currencyCode}`;
}

function getSizeLabel(
  originalValue: number | null,
  originalLabel: string | null
) {
  if (originalValue) return originalLabel;

  return "Revenue";
}

function getSizeValue(
  originalValue: number | null,
  revenueValue: number | null
) {
  if (originalValue) return formatSizeValue(originalValue);

  if (revenueValue) return formatSizeValue(revenueValue);

  return null;
}

function formatSizeValue(value: number) {
  if (value >= 1.0e9) {
    return `${Number((value / 1.0e9).toPrecision(2))} billion`;
  } else if (value >= 1.0e6) {
    return `${Number((value / 1.0e6).toPrecision(2))} million`;
  } else if (value >= 1.0e3) {
    return `${Number((value / 1.0e3).toPrecision(2))} thousand`;
  }
  return value;
}
