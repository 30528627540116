import { Ref, reactive, ref } from "vue";
import { apiAxios } from "./axios";
import { CV } from "@/types/CV";
import { rolesHeld } from "@/composables/useMiniCv";

const EMPTY_STATE: any = {
  quick_apply: true,
  cover_letter: "",
  cv_id: null,
  cv_filename: null,
  cv_location: null,
  selected_roles_held: rolesHeld,
};

export const cvs: Ref<CV[]> = ref([]);
export const isReadyToEdit = ref(false);
export const quickApplication = reactive({ ...EMPTY_STATE });

export async function loadQuickApplication(roleId: string) {
  const [cvsResponse, roleApplicationResponse] = await Promise.all([
    apiAxios.get("/individual/cvs"),
    apiAxios.get(`/latest-application/${roleId}`),
  ]);

  cvs.value = sortByLatestFirst(cvsResponse.data);
  quickApplication.cover_letter =
    roleApplicationResponse.data.cover_letter || "";

  if (roleApplicationResponse.data.cv_id) {
    quickApplication.cv_id = roleApplicationResponse.data.cv_id;
    quickApplication.cv_filename = roleApplicationResponse.data.cv_filename;
    quickApplication.cv_location = roleApplicationResponse.data.cv_location;
  } else {
    attachLatestCv();
  }

  isReadyToEdit.value = true;
}

export async function refreshCvs() {
  const { data } = await apiAxios.get("/individual/cvs");
  cvs.value = sortByLatestFirst(data);
}

export function clearQuickApplication() {
  Object.assign(quickApplication, EMPTY_STATE);
  isReadyToEdit.value = false;
}

function sortByLatestFirst(cvs: CV[]) {
  return cvs.sort(
    (a: CV, b: CV) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  );
}

async function attachLatestCv() {
  const [latestCv] = cvs.value;
  if (latestCv) {
    quickApplication.cv_id = latestCv.id;
    quickApplication.cv_filename = latestCv.filename;
    quickApplication.cv_location = latestCv.location;
  }
}
