import { ref } from "vue";

const backLink = ref<string>("/roles");

function userEnteredRolePage(lastPath: string | null) {
  const backLinkBlacklist = ["/apply"];
  if (
    lastPath &&
    !backLinkBlacklist.find(blacklistedBackLink =>
      lastPath.includes(blacklistedBackLink)
    )
  ) {
    backLink.value = lastPath;
  }
}

export default function useRolePageBackLink() {
  return {
    backLink,
    userEnteredRolePage,
  };
}
