<template>
  <div
    class="py-8 px-6 flex flex-col gap-6 text-center items-center bg-white md:py-44 md:px-28"
  >
    <img src="@/assets/empty-search.svg" class="md:w-auto w-28 md:mr-12" />
    <div class="flex flex-col gap-2">
      <p class="copy-emphasis">This role you have requested does not exist</p>
      <p>
        You may have followed an invalid link, please check the roles list for
        an up to date list of roles
      </p>
    </div>
    <div
      class="flex flex-col gap-6 w-full md:flex-row md:items-center md:justify-center"
    >
      <router-link
        v-if="backLink"
        :to="backLink"
        class="button-primary text-cta-primary flex items-center justify-center"
      >
        Back to roles list
      </router-link>
      <router-link
        class="text-cta-tertiary"
        to="/contact"
        target="_blank"
        rel="noopener"
      >
        Contact support
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: ["backLink"],
};
</script>
