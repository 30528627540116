import { ref, computed, Ref } from "vue";
import flagsmith from "flagsmith";
import { IFlagsmith } from "flagsmith/types";

const updates = ref(0);
const identified = ref(false);

export function updateFlagsmithComputedValues() {
  updates.value += 1; // This is called on flag changes and after waiting to identify
}

export function setIdentityResolved() {
  updateFlagsmithComputedValues();
  identified.value = true;
}

const reactiveFlagsmith = computed(() => {
  // We're using updates.value here to force computed values to update, even though it will never return 'false'
  return updates.value == -1 ? false : flagsmith;
});

export default function useFlagsmith(): {
  flagsmith: Ref<IFlagsmith>;
  identified: Ref<boolean>;
} {
  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    flagsmith: reactiveFlagsmith,
    identified,
  };
}
