<template>
  <div
    class="w-full mx-2 relative flex justify-center items-center p-3 mb-3 cursor-pointer rounded-full h-18 md:h-20 bg-ivory-100 mt-2"
  >
    <a
      :href="sanitizedLocation"
      target="_blank"
      rel="noreferrer noopener"
      class="flex flex-col sm:flex-row items-start w-full justify-between sm:items-center rounded-full"
    >
      <div class="flex flex-row justify-between w-full items-center">
        <div class="flex items-center">
          <span
            class="flex justify-center items-center h-11 w-11 shrink-0 rounded-full bg-white"
          >
            <span class="material-icons-round text-3xl text-sage-600">
              attach_file
            </span>
          </span>
          <div class="flex flex-col w-full items-start mx-2 sm:mx-3">
            <div
              data-cy="upload-cv-success-filename"
              class="text-grey-900 copy-bold truncate w-32 sm:w-full max-w-sm"
            >
              {{ cv.filename }}
            </div>
            <span
              class="text-grey-700 pt-1 text-labelling-inactive hidden sm:inline"
            >
              Added {{ dateAdded }}
            </span>
          </div>
        </div>
        <button
          class="button-error-tertiary text-cta-tertiary hidden xs:block"
          @click.stop.prevent="deleteCv"
          data-cy="delete-cv-button"
        >
          DELETE
        </button>
        <button
          class="button-error-tertiary px-0 flex items-center justify-center block xs:hidden"
          @click.stop.prevent="deleteCv"
          data-cy="delete-cv-button"
        >
          <span class="material-icons-round"> delete </span>
        </button>
      </div>
    </a>
  </div>
</template>

<script setup lang="ts">
import { format } from "date-fns";
import { computed, PropType } from "vue";
import { apiAxios } from "@/lib/axios";
import useIndividual from "@/composables/useIndividual";
import DOMPurify from "dompurify";

const { individual } = useIndividual();
const isSignedIn = computed(() => {
  return !!individual.value?.first_name;
});

interface Cv {
  filename: string;
  location: string;
  created_at: string;
  id: string;
}
const props = defineProps({
  cv: { type: Object as PropType<Cv>, required: true },
});
const emit = defineEmits(["deleted"]);

const dateAdded = computed(() => {
  return props.cv.created_at
    ? format(new Date(props.cv.created_at), "MMMM d, yyyy, h:mma")
    : "";
});

const sanitizedLocation = computed(() => {
  return DOMPurify.sanitize(props.cv.location);
});

async function deleteCv() {
  if (isSignedIn.value) {
    await apiAxios.delete(`individual/cvs/${props.cv.id}`);
  }
  emit("deleted");
}
</script>
