<template>
  <div
    class="mt-4 mb-8 border md:mb-10 focus-within:ring focus-within:ring-orange-500 ring-offset-2"
    :class="{
      'rounded-lg shadow-xl border-solid border-white': isOpen,
      'rounded-full border-solid border-grey-200': !isOpen,
      'md:rounded-lg': !isOpen && selectedRoles.length,
      'md:rounded-full': !isOpen && !selectedRoles.length,
    }"
    data-cy="skills-roles-dropdown"
  >
    <div
      tabindex="0"
      @click="toggleOpen"
      @keydown.enter="toggleOpen"
      @keydown.space.prevent="toggleOpen"
      class="pt-4 pb-3.5 px-6 cursor-pointer outline-none"
    >
      <div
        class="flex items-center justify-between text-grey-400 text-inputs-content"
        :class="
          isOpen
            ? 'pb-4 mb-2 border-b border-t-0 border-l-0 border-r-0 border-solid border-grey-100'
            : ''
        "
      >
        <span v-if="!selectedRoles.length" class="md:my-1.5"
          >Select relevant role(s)</span
        >
        <span v-if="selectedRoles.length" class="md:hidden text-grey-700">
          {{ selectedRoles.length }} organisation{{
            selectedRoles.length === 1 ? "" : "s"
          }}
          selected
        </span>
        <div v-if="selectedRoles.length" class="hidden md:flex">
          <div
            v-for="role in selectedRoles"
            :key="role.id"
            class="py-1.5 px-4 mx-2 rounded bg-sage-50 text-grey-800 nudging"
            data-cy="skills-selected-organisation"
            :class="role"
          >
            {{ role.organisation }}
          </div>
        </div>
        <span
          class="material-icons-round transition-transform text-grey-700"
          :class="isOpen && 'rotate-180'"
        >
          expand_more
        </span>
      </div>
    </div>
    <focus-loop :disabled="!isOpen">
      <div v-if="isOpen" class="px-6 pb-6 flex flex-col justify-between">
        <div class="flex flex-col items-start max-h-48 overflow-y-scroll">
          <label
            class="inline-flex items-center mx-1 pl-1 my-2.5 outline-none focus-visible:ring focus-visible:ring-orange-500"
            v-for="role in roles"
            :key="role.id"
            @keydown.enter="toggleRole(role)"
            @keydown.space.prevent="toggleRole(role)"
            tabindex="0"
          >
            <input
              type="checkbox"
              class="hidden"
              @click="toggleRole(role)"
              :checked="selectedRoles.includes(role)"
              :disabled="checkboxDisabledForRole(role)"
            />
            <span
              class="material-icons-round text-sage-700 cursor-pointer"
              :class="selectedRoles.includes(role) ? '' : 'hidden'"
            >
              check_box
            </span>
            <span
              data-cy="skills-role-checkbox"
              class="material-icons-round text-grey-600 cursor-pointer"
              :class="{
                hidden: selectedRoles.includes(role),
                'opacity-30': checkboxDisabledForRole(role),
              }"
            >
              check_box_outline_blank
            </span>
            <span
              class="ml-3 cursor-pointer"
              :class="
                selectedRoles.includes(role)
                  ? 'text-grey-700'
                  : 'text-grey-600 '
              "
              >{{ role.position }} at {{ role.organisation }}
            </span>
          </label>
        </div>
        <button
          type="button"
          class="text-cta-secondary button-secondary mt-2 w-full flex items-center justify-center space-x-2 md:w-auto"
          :disabled="!selectedRoles.length"
          @click="closeDropdown"
          data-cy="skills-roles-continue"
        >
          Continue
        </button>
      </div>
    </focus-loop>
  </div>
</template>

<script lang="ts">
import { Role } from "@/models/Role";
import { FocusLoop } from "@vue-a11y/focus-loop";

export default {
  props: {
    roles: Array,
    selectedRoles: {
      type: Array,
      default() {
        return [];
      },
    },
    openByDefault: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  components: {
    FocusLoop,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  created() {
    this.isOpen = this.openByDefault;
  },
  methods: {
    closeDropdown() {
      this.isOpen = false;
    },
    toggleOpen() {
      this.isOpen = !this.isOpen;
    },
    toggleRole(role: Role) {
      this.$emit("toggle-role", role);
    },
    checkboxDisabledForRole(role) {
      return (
        this.selectedRoles.length === 3 && !this.selectedRoles.includes(role)
      );
    },
  },
};
</script>
