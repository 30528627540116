<template>
  <div>
    <div
      :data-id="role.id"
      class="bg-white flex-col relative mb-0 rounded-lg"
      :class="{
        'shadow-sage': isFirstRole && individual && individual.new_onboarding,
        'bg-ivory-100': isWatching,
        'rounded-b-none': isBadgePresent,
      }"
      data-cy="roles-card"
    >
      <div class="p-4 pt-4 pb-7 sm:px-10 md:py-8 lg:px-16">
        <div class="flex justify-between items-start">
          <div class="flex items-start">
            <roles-watchlist-button
              class="hidden lg:inline focus:ring focus:ring-orange-500"
              :individual="individual"
              :roleId="role.id"
              :isWatching="isWatching"
              @update-individual="updateIndividual"
              :squareEdge="false"
            />
            <div
              data-cy="role-title"
              class="text-title-h3 text-sage-700 pr-3 md:pr-10 pt-1"
            >
              {{ role.title }}
            </div>
          </div>
          <roles-match-badge
            v-if="isMatch && matchStrengthBadgesEnabled"
            :matchStrength="role.match_strength"
          />
        </div>
        <div
          class="flex pt-3 flex-row flex-wrap items-start w-full text-grey-800 text-nudging"
        >
          <div class="py-1 mr-4 flex items-center">
            <span class="material-icons-round mr-1 text-16/16"> work </span>
            {{ compensationDetails }}
          </div>
          <div v-if="formattedLocation" class="py-1 mr-4 flex items-center">
            <span class="material-icons-round mr-1 text-16/16">
              location_on
            </span>
            {{ formattedLocation }}
          </div>
          <div class="py-1 mr-4 flex items-center">
            <span class="material-icons-round mr-1 text-16/16">
              calendar_today
            </span>
            {{ commitment }}
          </div>
          <div v-if="formattedDeadline" class="py-1 mr-4 flex items-center">
            <span class="material-icons-round mr-1 text-16/16"> timer </span>
            Midnight on
            {{ formattedDeadline }}
          </div>
        </div>
        <div class="pt-3 text-grey-800 leading-6">
          {{ role.role_summary }}
        </div>
        <div class="flex sm:flex-row flex-col-reverse mt-8">
          <router-link
            :to="`/roles/${role.id}`"
            data-cy="role-details-btn"
            class="button-primary text-cta-primary whitespace-nowrap flex justify-center flex-wrap-reverse mb-3 sm:mb-0 mr-2"
          >
            View details
          </router-link>
          <div class="flex sm:flex-row flex-col mr-2">
            <button
              v-if="quickApplyEnabled"
              @click="openQuickApplyModal"
              class="button-secondary text-cta-secondary whitespace-nowrap hover:bg-sage-50 flex justify-center active:bg-sage-100 mb-3 sm:mb-0 items-center gap-2"
              :class="isWatching ? 'bg-ivory-100' : 'bg-white'"
              data-cy="role-apply-button"
            >
              {{ quickApplyButtonText }}
            </button>
            <router-link
              v-else
              data-cy="role-apply-button"
              :to="`/roles/${role.id}/apply`"
              class="button-secondary text-cta-secondary whitespace-nowrap hover:bg-sage-50 flex justify-center active:bg-sage-100 mb-3 sm:mb-0"
              :class="isWatching ? 'bg-ivory-100' : 'bg-white'"
            >
              {{ applicationButtonText }}
            </router-link>
          </div>
          <quick-apply ref="quickApplyModal" :role="role" />
          <a
            :href="`/applications/${role.id}`"
            v-if="role.current_user_has_applied"
            class="button-secondary text-cta-secondary whitespace-nowrap lg:block hidden sm:mr-2"
          >
            View application
          </a>
          <router-link
            data-cy="role-recommend-btn"
            :to="`/roles/${role.id}/recommend`"
            class="button-secondary text-cta-secondary whitespace-nowrap hover:bg-sage-50 active:bg-sage-100 lg:block hidden"
            :class="isWatching ? 'bg-ivory-100' : 'bg-white'"
          >
            Recommend
          </router-link>
        </div>
      </div>
    </div>
    <div
      class="bg-blue-700 text-ivory-50 rounded-b-lg text-nudging flex flex-col sm:flex-row"
    >
      <div
        class="px-4 py-1 first:mt-2 last:mb-2 sm:my-2 whitespace-nowrap border-solid border-0 sm:border-r border-blue-800 last:border-r-0"
        v-if="isFirstTenBadge"
      >
        <span
          class="material-icons-round text-blue-800 bg-ivory-100 rounded-full text-xs p-1"
        >
          group
        </span>
        <span class="pl-2"> Be in the first 10 applicants</span>
      </div>
      <div
        class="px-4 py-1 first:mt-2 last:mb-2 sm:my-2 whitespace-nowrap border-solid border-0 sm:border-r border-blue-800 last:border-r-0"
        v-if="isFirstTimeNEDbadge"
      >
        <span
          class="material-icons-round text-blue-800 bg-ivory-100 rounded-full text-xs p-1"
        >
          description
        </span>
        <span class="pl-2"> Open to first time board members</span>
      </div>
      <div
        class="px-4 py-1 first:mt-2 last:mb-2 sm:my-2 whitespace-nowrap border-solid border-0 sm:border-r border-blue-800 last:border-r-0"
        v-if="isSocialImpactBadge"
      >
        <span
          class="material-icons-round text-blue-800 bg-ivory-100 rounded-full text-xs p-1"
        >
          favorite
        </span>
        <span class="pl-2">{{ socialImpactText }}</span>
      </div>
      <div
        class="px-4 py-1 first:mt-2 last:mb-2 sm:my-2 whitespace-nowrap border-solid border-0 sm:border-r border-blue-800 last:border-r-0"
        v-if="isDiversityPreferenceBadge"
      >
        <span
          class="material-icons-round text-blue-800 bg-ivory-100 rounded-full text-xs p-1"
        >
          sentiment_satisfied
        </span>
        <span class="pl-2">{{ diversityPreferenceText }}</span>
      </div>
    </div>
    <div
      class="bg-orange-700 text-ivory-50 rounded-b-lg text-nudging flex flex-col sm:flex-row"
    ></div>
    <role-nudging-banner
      v-if="showPreferencesNudge && isFirstRole && isOnlyRole && notifiableRole"
      :role-types="roleTypes"
      :roleId="roleId"
    ></role-nudging-banner>
    <role-notification-reason-banner
      v-if="!notifiableRole"
      :reason="reasonForNotNotifying"
      :roleId="roleId"
      :role-request-additional-user-info="role.request_additional_user_info"
      :role-diversity-requirements="role.diversity"
      :role-types="roleTypes"
    ></role-notification-reason-banner>
    <role-relevancy-feedback v-if="feedbackEnabled" :roleId="roleId" />
  </div>
</template>

<style scoped lang="scss">
.interest-decision-banner::after {
  content: "";
  background-color: #41578a;
  position: absolute;
  width: 24px;
  height: 24px;
  bottom: -12px;
  left: 75px;
  border-radius: 0 0 4px 0;
  transform: rotate(45deg);
  z-index: 1;
}
</style>

<script lang="ts">
import { Role } from "@/models/Role";
import RoleRelevancyFeedback from "@/components/roles/RoleRelevancyFeedback.vue";
import RolesMatchBadge from "@/components/roles/RolesMatchBadge.vue";
import RolesWatchlistButton from "@/components/roles/RolesWatchlistButton.vue";
import RoleNudgingBanner from "./RoleNudgingBanner.vue";
import RoleNotificationReasonBanner from "./RoleNotificationReasonBanner.vue";
import { RoleTyper } from "@/lib/RoleTyper";
import {
  formatDeadline,
  formatLocation,
  formatCompensation,
} from "@/helpers/roleHelpers";
import { Individual } from "@/models/Individual";
import { PropType } from "vue";
import useFlagsmith from "@/composables/useFlagsmith";
import { NotificationCalculationDetails } from "@/lib/notificationCalculationDetails";
import useWatchlist from "@/composables/useWatchlist";
import useIndividual from "@/composables/useIndividual";
import QuickApply from "@/components/QuickApply.vue";

export default {
  name: "RolesCard",
  components: {
    RolesMatchBadge,
    RolesWatchlistButton,
    RoleRelevancyFeedback,
    RoleNudgingBanner,
    RoleNotificationReasonBanner,
    QuickApply,
  },
  setup() {
    const { flagsmith } = useFlagsmith();
    const { watchlist } = useWatchlist();
    const { individual } = useIndividual();

    return {
      watchlist,
      flagsmith,
      individual,
    };
  },
  props: {
    role: {
      required: true,
      type: Object as PropType<Role>,
    },
    isFirstRole: {
      type: Boolean,
      default: false,
    },
    showDeadline: {
      type: Boolean,
      default: false,
    },
    showLocation: {
      type: Boolean,
      default: false,
    },
    isOnlyRole: {
      type: Boolean,
      default: true,
    },
    notifiableRole: {
      type: Boolean,
      default: false,
    },
    notificationCalculationDetails: {
      type: Object as PropType<Array<NotificationCalculationDetails>>,
    },
  },
  data: () => ({
    response: "",
  }),
  methods: {
    async updateIndividual(individual: Individual) {
      this.individual = individual;
    },
    checkIfCalculationsContain(name) {
      return this.notificationCalculationDetails?.some(
        calculation =>
          calculation.name.includes(name) && calculation.applied === -1
      );
    },
    flagEnabled(featureName: string, rolesListName: string) {
      return (
        this.flagsmith.hasFeature(featureName) &&
        this.flagsmith.getValue(featureName) == "visible" &&
        this.flagsmith.hasFeature(rolesListName) &&
        (this.flagsmith.getValue(rolesListName) || "")
          .toString()
          .indexOf(this.role.id) > -1
      );
    },
    openQuickApplyModal() {
      this.$refs.quickApplyModal.modalOpen = true;
    },
  },
  computed: {
    isWatching() {
      return this.watchlist && this.watchlist.indexOf(this.role.id) >= 0;
    },
    applicationButtonText() {
      if (this.role.current_user_has_partially_completed) {
        return "Continue application";
      } else if (this.role.current_user_has_applied) {
        return "Reapply";
      } else {
        return "Apply for role";
      }
    },
    quickApplyButtonText() {
      if (this.applicationButtonText === "Apply for role") {
        return "Quick Apply";
      }
      return this.applicationButtonText;
    },
    quickApplyEnabled() {
      return this.role.is_quick_apply;
    },
    compensationDetails() {
      return formatCompensation(this.role);
    },
    commitment() {
      return this.role.part_time ? this.role.commitment : "Full time";
    },
    isMatch() {
      return (
        this.role.match_strength == "strong" ||
        this.role.match_strength == "good"
      );
    },
    matchStrengthBadgesEnabled() {
      return this.flagsmith.hasFeature("match_strength_badges");
    },
    formattedLocation() {
      return this.showLocation ? formatLocation(this.role) : null;
    },
    formattedDeadline() {
      return this.showDeadline ? formatDeadline(this.role.deadline) : null;
    },
    isFirstTenBadge() {
      return this.flagEnabled("first_ten_feature", "first_ten_roles");
    },
    isFirstTimeNEDbadge() {
      return this.flagEnabled(
        "first_time_non_exec_feature",
        "first_time_non_exec_roles"
      );
    },
    isBadgePresent() {
      return (
        this.isFirstTimeNEDbadge ||
        this.isFirstTenBadge ||
        this.isSocialImpactBadge
      );
    },
    isDiversityPreferenceBadge() {
      const isWhiteMale =
        this.individual &&
        this.individual.gender === "male" &&
        this.individual.ethnicity_group === "1_ethnicity_white";
      return (
        isWhiteMale &&
        this.role.diversity &&
        this.flagEnabled(
          "diversity_preference_feature",
          "diversity_preference_roles"
        )
      );
    },
    diversityPreferenceText() {
      return (
        (this.flagsmith.hasFeature("diversity_preference_text") &&
          this.flagsmith.getValue("diversity_preference_text")) ||
        "Strong Diversity Preference"
      );
    },
    isSocialImpactBadge() {
      return this.flagEnabled("social_impact_feature", "social_impact_roles");
    },
    socialImpactText() {
      return (
        (this.flagsmith.hasFeature("social_impact_text") &&
          this.flagsmith.getValue("social_impact_text")) ||
        "Social Impact"
      );
    },
    feedbackEnabled() {
      return this.flagsmith.hasFeature("right_roles_feature");
    },
    roleId() {
      return this.role?.id;
    },
    individualId() {
      return this.individual?.id;
    },
    roleTypes() {
      const roleTyper = new RoleTyper();
      return roleTyper.getRoleTypes(this.role);
    },
    showPreferencesNudge() {
      return (
        this.roleTypes.includes("pro-bono") ||
        this.roleTypes.includes("education")
      );
    },
    reasonForNotNotifying() {
      if (this.checkIfCalculationsContain("experience")) {
        return "experience";
      } else if (this.checkIfCalculationsContain("diversity")) {
        return "diversity";
      } else if (
        this.checkIfCalculationsContain("location") ||
        this.checkIfCalculationsContain("uk_probono")
      ) {
        return "location";
      } else {
        return "preferences";
      }
    },
  },
};
</script>
