import { orgsAxios } from "@/lib/axios";
import { RequestQueue } from "@/utils/RequestQueue";

const searchRequestQueue = new RequestQueue();

export async function searchOrganisations(orgName: string, individualId = "") {
  return new Promise(resolve => {
    searchRequestQueue.enqueue({
      apiCall: () => {
        // Organisations from organisation database
        const orgParams = new URLSearchParams();
        orgParams.append("query", encodeURIComponent(orgName));
        orgParams.append("used_by", individualId);
        return orgsAxios.get(`/search-organisations?${orgParams.toString()}`);
      },
      onSuccess: (response: any) => {
        resolve(response.data);
      },
      onError: () => {
        resolve([]);
      },
    });
  });
}
