<template>
  <label class="text-inputs-title font-semibold block" for="size-value">
    Size
  </label>
  <div
    class="flex flex-col max-w-[50rem] items-start gap-3 self-stretch"
    ref="elementRef"
  >
    <div class="flex flex-col md:flex-row items-center gap-4 self-stretch">
      <div class="relative h-14 w-full md:w-min">
        <button
          ref="sizeRef"
          class="flex text-input border-grey-200 text-center text-inputs-content whitespace-nowrap w-full bg-white h-full flex items-center"
          :class="{
            'text-grey-500': !modelValue.currency || sizeTypeOpen,
            'rounded-b-none ring-offset-0 rounded-t-lg shadow-md': sizeTypeOpen,
          }"
          @click="sizeTypeOpen = !sizeTypeOpen"
        >
          <span class="flex-grow">
            {{ sizeType }}
          </span>
          <span
            class="material-icons-round text-grey-500"
            v-if="!sizeTypeOpen"
            @click.stop="sizeTypeOpen = !sizeTypeOpen"
            >expand_more</span
          >
          <span
            class="material-icons-round text-grey-500"
            v-else
            @click.stop="sizeTypeOpen = !sizeTypeOpen"
            >expand_less</span
          >
        </button>
        <simple-option-selector
          v-if="sizeTypeOpen"
          :options="sizeTypes"
          @on-select="selectSizeType"
          @on-close="sizeTypeOpen = false"
        />
      </div>
      <div
        class="flex flex-col md:flex-row items-center gap-4 flex-grow self-stretch"
        v-if="showCurrencyAndMultiplier"
      >
        <div
          class="flex p-0 items-center gap-0 w-full md:w-44 rounded-full border-grey-200 border border-solid"
        >
          <div class="h-14 relative w-1/3 md:w-20">
            <button
              class="flex rounded-l-full focus:ring focus:ring-orange-500 py-4 pl-6 pr-2 text-center text-inputs-content whitespace-nowrap w-full bg-white h-full items-center"
              :class="{
                'text-grey-500': !modelValue.currency || currencyOpen,
                'rounded-l-none rounded-b-none ring-0 ring-offset-0 rounded-tl-3xl shadow-md':
                  currencyOpen,
              }"
              @click="currencyOpen = true"
            >
              <span class="flex-grow">{{ currency }}</span>
              <span
                class="material-icons-round"
                v-if="!currencyOpen"
                @click.stop="currencyOpen = !currencyOpen"
                >expand_more</span
              >
              <span
                class="material-icons-round"
                v-else
                @click.stop="currencyOpen = !currencyOpen"
                >expand_less</span
              >
            </button>

            <simple-option-selector
              v-if="currencyOpen"
              :options="currencies"
              @on-select="selectCurrency"
              @on-close="currencyOpen = false"
              :displayKey="'display'"
            />
          </div>
          <input
            type="number"
            class="focus:ring focus:ring-orange-500 rounded-r-full border-l py-4 px-4 text-inputs-content w-2/3 md:w-24"
            placeholder="0"
            v-model="nonMultipliedSizeValue"
            @input="updateSizeValue"
            data-size-value
            id="size-value"
          />
        </div>
        <div class="w-full md:flex-1 h-14 text-center">
          <div class="flex-1 grid grid-cols-3 md:w-full h-full">
            <button
              v-for="(option, index) in ['Thousand', 'Million', 'Billion']"
              class="text-labelling-inactive"
              :class="{
                'button-group-primary text-ivory-50': option == multiplier,
                'button-group-secondary text-sage-600': option != multiplier,
                'rounded-tl-full rounded-bl-full': index == 0,
                'rounded-tr-full rounded-br-full': index == 2,
              }"
              :key="option"
              data-size-multiplier
              @click="setMultiplier(option)"
            >
              {{ option }}
            </button>
          </div>
        </div>
      </div>
      <input
        v-else
        type="number"
        class="focus:ring focus:ring-orange-500 rounded-full my-4 md:my-0 border border-solid border-grey-200 py-[15px] px-6 text-inputs-content w-full md:w-36 md:mr-3"
        placeholder="0"
        v-model="nonMultipliedSizeValue"
        @input="updateSizeValue"
        data-size-value
        id="size-value"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { OrganisationSize as Size } from "@/models/MiniCv";
import { defineComponent, PropType, ref } from "vue";
import SimpleOptionSelector from "../shared/SimpleOptionSelector.vue";
import useOutsidePress from "@/composables/useOutsidePress";

interface Currency {
  display: string;
  value: string;
}

const currencies: Currency[] = [
  {
    display: "£",
    value: "GBP",
  },
  {
    display: "$",
    value: "USD",
  },
  {
    display: "€",
    value: "EUR",
  },
];

const sizeTypes = [
  "Revenue",
  "Income",
  "Head Count",
  "AUM",
  "Market Cap",
  "No. of students",
];

export default defineComponent({
  components: { SimpleOptionSelector },
  name: "OrganisationSize",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      required: true,
      type: Object as PropType<Size>,
    },
    sizeLabel: {
      required: false,
    },
    focus: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const elementRef = ref(null);
    const sizeTypeOpen = ref(false);
    useOutsidePress(elementRef, () => (sizeTypeOpen.value = false));
    return {
      currencies,
      sizeTypes,
      elementRef,
      sizeTypeOpen,
    };
  },
  created() {
    this.sizeType = this.modelValue.label || this.sizeLabel;
  },
  mounted() {
    const size = parseInt(this.modelValue.value || "0");

    if (!this.showCurrencyAndMultiplier) {
      this.multiplier = null;
      this.nonMultipliedSizeValue = size;
      return;
    }

    if (size > 1000000000) {
      this.nonMultipliedSizeValue = size / 1000000000;
      this.multiplier = "Billion";
    } else if (size > 1000000) {
      this.nonMultipliedSizeValue = size / 1000000;
      this.multiplier = "Million";
    } else {
      this.nonMultipliedSizeValue = size / 1000;
      this.multiplier = "Thousand";
    }

    if (this.focus) {
      this.$refs.sizeRef.focus();
    }
  },
  data() {
    return {
      nonMultipliedSizeValue: 0,
      multiplier: null,
      currencyOpen: false,
      sizeType: "",
    };
  },
  computed: {
    currency() {
      return this.currencies.find(
        (currency: Currency) => currency.value == this.modelValue.currency
      )?.display;
    },
    showCurrencyAndMultiplier() {
      return [
        "Revenue",
        "Income",
        "Assets under management",
        "Gross written premium",
        "AUM",
        "Market Cap",
      ].includes(this.sizeType);
    },
  },
  methods: {
    setMultiplier(multiplier: "Thousand" | "Million" | "Billion" | null) {
      this.multiplier = multiplier;
      this.updateSizeValue();
    },
    updateSizeValue() {
      if (!this.showCurrencyAndMultiplier) {
        this.multiplier = null;
      }
      if (this.multiplier == "Thousand") {
        this.update("value", this.nonMultipliedSizeValue * 1000);
      } else if (this.multiplier == "Million") {
        this.update("value", this.nonMultipliedSizeValue * 1000000);
      } else if (this.multiplier == "Billion") {
        this.update("value", this.nonMultipliedSizeValue * 1000000000);
      } else {
        this.update("value", this.nonMultipliedSizeValue);
      }
    },
    selectCurrency(currency: Currency) {
      this.update("currency", currency.value);
      this.currencyOpen = false;
    },
    selectSizeType(sizeType) {
      this.sizeType = sizeType;
      this.nonMultipliedSizeValue = 0;
      this.multiplier = null;
      if (this.showCurrencyAndMultiplier) {
        this.resetModelValue(sizeType, "GBP", 0);
      } else {
        this.resetModelValue(sizeType, null, 0);
      }
      this.sizeTypeOpen = false;
    },
    update(key: "label" | "currency" | "value", value: string | number | null) {
      this.$emit("update:modelValue", { ...this.modelValue, [key]: value });
    },
    resetModelValue(sizeType, currency, value) {
      this.$emit("update:modelValue", {
        ["label"]: sizeType,
        ["currency"]: currency,
        ["value"]: value,
      });
    },
  },
});
</script>
