<template>
  <div
    class="bg-white px-5 sm:px-12 lg:px-28 w-full py-5 md:py-12 my-5 md:my-0 md:border-b md:border-t-0 md:border-l-0 md:border-r-0 md:border-solid md:border-grey-100"
  >
    <preview-accordion v-if="roleApplication.cover_letter">
      <template v-slot:title>
        <span class="text-title-h2 text-grey-800">
          {{ title }}
        </span>
      </template>
      <template v-slot:content>
        <div class="text-grey-800 mt-3">
          <p>
            {{ role.cover_letter_intro }}
          </p>
          <p>
            For guidance and advice on what to include in your application,
            please see the
            <a
              :href="`${marketingAppURL}/news-and-guides/good_application_guide`"
              class="text-cta-tertiary"
              >NUROLE GUIDE TO GOOD APPLICATIONS</a
            >.
          </p>
        </div>
        <div class="flex justify-between flex-col"></div>
        <div
          class="bg-grey-50 text-grey-80 pt-5 pb-1 px-5 mt-4 role-application-reason-preview"
        >
          <p v-html="roleApplication.cover_letter"></p>
        </div>
      </template>
    </preview-accordion>
    <div v-else>
      <div class="text-title-h2 text-grey-800 mb-4">
        {{ title }}
      </div>
      <preview-incomplete-section />
    </div>
  </div>
</template>

<script lang="ts">
import PreviewAccordion from "@/components/roleApplication/PreviewAccordion.vue";
import PreviewIncompleteSection from "@/components/roleApplication/PreviewIncompleteSection.vue";
import useRoleApplication from "@/composables/useRoleApplication";
import useRole from "@/composables/useRole";

export default {
  components: {
    PreviewAccordion,
    PreviewIncompleteSection,
  },
  setup() {
    const { roleApplication } = useRoleApplication();
    const { role } = useRole();

    return {
      role,
      roleApplication,
      marketingAppURL: process.env.VITE_MARKETING_BASE_URL,
    };
  },
  computed: {
    title() {
      return "Reason for application";
    },
  },
};
</script>

<style>
.role-application-reason-preview ol,
.role-application-reason-preview ul,
.role-application-reason-preview li {
  all: revert;
}
</style>
