<template>
  <div class="relative" ref="elementRef">
    <button
      class="text-input border-grey-200 text-inputs-content w-full"
      :class="{
        'text-grey-500': !modelValue || yearsOpen,
        'rounded-b-none ring-0 ring-offset-0 rounded-t-lg shadow-md': yearsOpen,
      }"
      @click="yearsOpen = true"
    >
      {{ year }}
    </button>
    <simple-option-selector
      v-if="yearsOpen"
      :options="years"
      @on-select="selectYear"
      @on-close="yearsOpen = false"
      :defaultIndex="defaultIndex"
    />
  </div>
</template>

<script lang="ts">
import SimpleOptionSelector from "../shared/SimpleOptionSelector.vue";
import useOutsidePress from "@/composables/useOutsidePress";
import { ref } from "vue-demi";
export default {
  components: { SimpleOptionSelector },
  name: "RoleHeldDateSelector",
  props: {
    modelValue: {
      type: Number,
      required: false,
    },
    fromYear: {
      type: Number,
      required: false,
    },
  },
  setup() {
    const elementRef = ref(null);
    const yearsOpen = ref(false);
    useOutsidePress(elementRef, () => (yearsOpen.value = false));
    return {
      elementRef,
      yearsOpen,
    };
  },
  computed: {
    year() {
      if (this.yearsOpen) return "Select";
      return this.modelValue || "Year";
    },
    years() {
      const length = this.fromYear
        ? new Date().getFullYear() - parseInt(this.fromYear) + 9
        : 80;

      return Array.from(
        { length },
        (_x, i) => new Date().getFullYear() + 8 - i
      );
    },
    defaultIndex() {
      if (this.modelValue) {
        return this.years.indexOf(parseInt(this.modelValue));
      }

      if (this.fromYear) {
        return this.years.indexOf(parseInt(this.fromYear) + 2);
      }

      return this.years.indexOf(new Date().getFullYear());
    },
  },
  methods: {
    selectYear(year: string) {
      this.$emit("update:modelValue", year);
      this.yearsOpen = false;
    },
  },
};
</script>
