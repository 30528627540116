<template>
  <div class="relative" ref="elementRef">
    <button
      class="text-input border-grey-200 text-inputs-content w-full"
      :class="{
        'text-grey-500': !modelValue || monthsOpen,
        'rounded-b-none ring-0 ring-offset-0 rounded-t-lg shadow-md':
          monthsOpen,
      }"
      @click="monthsOpen = true"
      data-month
    >
      {{ month }}
    </button>
    <simple-option-selector
      v-if="monthsOpen"
      :options="months"
      @on-select="selectMonth"
      @on-close="monthsOpen = false"
      :defaultIndex="defaultIndex"
    />
  </div>
</template>

<script lang="ts">
import { ref } from "vue-demi";
import SimpleOptionSelector from "../shared/SimpleOptionSelector.vue";
import useOutsidePress from "@/composables/useOutsidePress";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function monthToNumber(month: string): string {
  return (months.indexOf(month) + 1).toString().padStart(2, "0");
}

function numberToMonth(number: string): string {
  return months[parseInt(number) - 1];
}

export default {
  components: { SimpleOptionSelector },
  name: "RoleHeldMonthSelector",
  props: {
    modelValue: {
      type: String,
      required: false,
    },
  },
  setup() {
    const elementRef = ref(null);
    const monthsOpen = ref(false);
    useOutsidePress(elementRef, () => (monthsOpen.value = false));
    return {
      elementRef,
      monthsOpen,
      months,
    };
  },
  methods: {
    selectMonth(month: string) {
      this.$emit("update:modelValue", monthToNumber(month));
      this.monthsOpen = false;
    },
  },
  computed: {
    defaultIndex() {
      return this.modelValue
        ? months.indexOf(numberToMonth(this.modelValue))
        : null;
    },
    month() {
      if (this.monthsOpen) return "Select";
      return this.modelValue ? numberToMonth(this.modelValue) : "Month";
    },
  },
};
</script>
