<template>
  <div class="flex w-full sm:w-auto bg-blue-50 mt-4 rounded-lg">
    <div class="flex bg-blue-100 py-3 rounded-l-lg">
      <span
        class="material-icons-outlined my-auto text-2xl text-blue-700 mx-2 justify-self-start"
      >
        info
      </span>
    </div>
    <div class="sm:flex my-3 pl-4 bg-blue-50 w-full justify-between sm:pl-0">
      <p class="mt-2 sm:my-auto text-lg text-grey-900 font-normal mb-0 sm:pl-4">
        {{ props.validationMessage }}
      </p>
    </div>
  </div>
</template>
<script lang="ts" setup>
const props = defineProps({
  validationMessage: {
    type: String,
    required: true,
  },
});
</script>
