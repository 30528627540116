import { Role } from "@/models/Role";

function matches(valueToLookFor: string, attribute: string) {
  const regex = new RegExp(valueToLookFor, "i");
  return regex.test(attribute);
}

export default function textMatches(role: Role, searchValue: string) {
  return Object.values(role)
    .filter(Boolean)
    .some(item => matches(searchValue, item.toString()));
}
