<template>
  <div class="absolute top-7 right-8 hidden md:group-hover/pinrole:inline">
    <icon-with-tooltip :tooltip-text="isPinned ? 'Unpin role' : 'Pin role'">
      <button
        @click.stop.prevent="togglePin()"
        class="p-3 rounded-full bg-sage-50 hover:bg-sage-100"
        :disabled="isLoading"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.8334 10.7248C15.8334 10.3332 15.5501 10.0165 15.1667 9.90817C14.1084 9.6165 13.3334 8.64984 13.3334 7.49984V3.33317H14.1667C14.6251 3.33317 15.0001 2.95817 15.0001 2.49984C15.0001 2.0415 14.6251 1.6665 14.1667 1.6665H5.83341C5.37508 1.6665 5.00008 2.0415 5.00008 2.49984C5.00008 2.95817 5.37508 3.33317 5.83341 3.33317H6.66675V7.49984C6.66675 8.64984 5.89175 9.6165 4.83341 9.90817C4.45008 10.0165 4.16675 10.3332 4.16675 10.7248V10.8332C4.16675 11.2915 4.54175 11.6665 5.00008 11.6665H9.15008L9.16675 17.4998C9.16675 17.9582 9.54175 18.3332 10.0001 18.3332C10.4584 18.3332 10.8334 17.9582 10.8334 17.4998L10.8167 11.6665H15.0001C15.4584 11.6665 15.8334 11.2915 15.8334 10.8332V10.7248Z"
            fill="#3C6955"
          />
        </svg>
      </button>
    </icon-with-tooltip>
  </div>
</template>
<script setup lang="ts">
import useRoleHeldPins from "@/composables/useRoleHeldPins";
import { computed, ref } from "vue";
import IconWithTooltip from "../IconWithTooltip.vue";

const { pins, pinRole, unpinRole } = useRoleHeldPins();
const isLoading = ref(false);

const props = defineProps({
  roleHeldId: { type: String, required: true },
});

const isPinned = computed(() => pins.value.has(props.roleHeldId));

const togglePin = async () => {
  isLoading.value = true;
  if (isPinned.value) {
    await unpinRole(props.roleHeldId);
  } else {
    await pinRole(props.roleHeldId);
  }
  isLoading.value = false;
};
</script>
