<template>
  <div
    class="error-message cv-error-message text-red-700 text-inputs-title text-center w-full mt-3"
  >
    {{ errorMessage }}
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps({
  cvError: {
    type: Object,
    default: null,
  },
});

const errorMessage = computed(() => {
  if (props.cvError?.response?.status === 413) {
    return "Please upload a file smaller than 8MB";
  } else if (props.cvError?.response?.data === "No positions found") {
    return window.location.pathname.includes("onboarding")
      ? "No roles identified, please try re-uploading or add roles manually on the last page"
      : "No roles identified, please try re-uploading or add roles manually on your account";
  } else if (props.cvError.type === "pdf-too-large") {
    return "Please upload a PDF with less than 10 pages";
  } else if (
    props.cvError.type === "unsupported-file-type" ||
    props.cvError?.response?.data === "Unsupported file type"
  ) {
    return "Please upload one of the supported file types above";
  } else {
    return "There was an issue with the file, please double-check and re-upload";
  }
});
</script>
