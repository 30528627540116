<template>
  <div
    class="bg-white max-w-screen-xl mx-auto w-full p-4 md:px-12 md:pt-6 lg:px-28 lg:pt-10 pb-24"
  >
    <div class="flex flex-col gap-4 md:gap-5 lg:gap-6">
      <div class="flex flex-col gap-2 text-grey-900">
        <h1 class="copy-emphasis">
          My Recommendations
          <span v-if="!isLoading">({{ recommendations.length }})</span>
        </h1>
        <p class="">
          To recommend someone for a specific role, please click the "Recommend"
          button against the relevant role in
          <router-link to="/roles" class="underline text-current"
            >the list of current roles</router-link
          >.
        </p>
      </div>
      <recommend-someone-banner />
      <recommendation-list-empty-state
        v-if="showRecommendationListEmptyState"
      />
      <recommendation
        v-for="(recommendation, index) in recommendations"
        :key="index"
        :recommendation="recommendation"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { apiAxios } from "@/lib/axios";
import { RecommendationResponse } from "@/models/RecommendationResponse";
import RecommendSomeoneBanner from "@/components/account/RecommendSomeoneBanner.vue";
import RecommendationListEmptyState from "@/components/account/RecommendationListEmptyState.vue";
import Recommendation from "@/components/account/Recommendation.vue";

export default {
  components: {
    RecommendSomeoneBanner,
    RecommendationListEmptyState,
    Recommendation,
  },
  data(): {
    isLoading: boolean;
    recommendations: RecommendationResponse[];
  } {
    return {
      isLoading: true,
      recommendations: [],
    };
  },
  async mounted() {
    const { data } =
      await apiAxios.get<RecommendationResponse[]>("/recommendations");
    this.recommendations = data;
    this.isLoading = false;
  },
  computed: {
    showRecommendationListEmptyState() {
      return this.recommendations.length < 1 && !this.isLoading;
    },
  },
};
</script>
