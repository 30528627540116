import { onBeforeUnmount, onMounted, Ref } from "vue";

export default function useOutsidePress(element: Ref, callback: Function) {
  async function handleClick(event: Event) {
    if (element.value && !element.value.contains(event.target)) {
      callback(event);
    }
  }

  onMounted(() => {
    window.addEventListener("click", handleClick);
  });

  onBeforeUnmount(() => {
    window.removeEventListener("click", handleClick);
  });
}
