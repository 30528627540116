<template>
  <div class="flex items-center my-6 flex-wrap">
    <div class="flex items-center">
      <svg
        width="14"
        height="12"
        viewBox="0 0 14 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.6667 2.85333V1.33333C12.6667 0.6 12.0667 0 11.3334 0H2.00008C1.26008 0 0.666748 0.6 0.666748 1.33333V10.6667C0.666748 11.4 1.26008 12 2.00008 12H11.3334C12.0667 12 12.6667 11.4 12.6667 10.6667V9.14667C13.0601 8.91333 13.3334 8.49333 13.3334 8V4C13.3334 3.50667 13.0601 3.08667 12.6667 2.85333ZM12.0001 4V8H7.33341V4H12.0001ZM2.00008 10.6667V1.33333H11.3334V2.66667H7.33341C6.60008 2.66667 6.00008 3.26667 6.00008 4V8C6.00008 8.73333 6.60008 9.33333 7.33341 9.33333H11.3334V10.6667H2.00008Z"
          fill="#242726"
        />
        <path
          d="M9.33341 7C9.8857 7 10.3334 6.55228 10.3334 6C10.3334 5.44772 9.8857 5 9.33341 5C8.78113 5 8.33341 5.44772 8.33341 6C8.33341 6.55228 8.78113 7 9.33341 7Z"
          fill="#242726"
        />
      </svg>
      <span class="text-nudging ml-3">{{ compensation }}</span>
    </div>

    <div class="flex items-center ml-6">
      <svg
        width="14"
        height="16"
        viewBox="0 0 14 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.3333 2.00002H11.6666V0.666687H10.3333V2.00002H3.66659V0.666687H2.33325V2.00002H1.66659C0.933252 2.00002 0.333252 2.60002 0.333252 3.33335V14C0.333252 14.7334 0.933252 15.3334 1.66659 15.3334H12.3333C13.0666 15.3334 13.6666 14.7334 13.6666 14V3.33335C13.6666 2.60002 13.0666 2.00002 12.3333 2.00002ZM12.3333 14H1.66659V6.66669H12.3333V14ZM12.3333 5.33335H1.66659V3.33335H12.3333V5.33335Z"
          fill="#242726"
        />
      </svg>
      <span class="text-nudging ml-3">{{ commitment }}</span>
    </div>

    <div class="flex items-center ml-6">
      <svg
        width="10"
        height="14"
        viewBox="0 0 10 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.99992 1.66665C6.28659 1.66665 8.33325 2.59998 8.33325 5.09998C8.33325 6.53998 7.18659 8.21331 4.99992 9.97998C2.81325 8.21331 1.66659 6.53331 1.66659 5.09998C1.66659 2.59998 3.71325 1.66665 4.99992 1.66665ZM4.99992 0.333313C2.81992 0.333313 0.333252 1.97331 0.333252 5.09998C0.333252 7.17998 1.88659 9.37331 4.99992 11.6666C8.11325 9.37331 9.66659 7.17998 9.66659 5.09998C9.66659 1.97331 7.17992 0.333313 4.99992 0.333313Z"
          fill="#242726"
        />
        <path
          d="M4.99992 3.66665C4.26659 3.66665 3.66659 4.26665 3.66659 4.99998C3.66659 5.73331 4.26659 6.33331 4.99992 6.33331C5.35354 6.33331 5.69268 6.19284 5.94273 5.94279C6.19278 5.69274 6.33325 5.3536 6.33325 4.99998C6.33325 4.64636 6.19278 4.30722 5.94273 4.05717C5.69268 3.80712 5.35354 3.66665 4.99992 3.66665ZM0.333252 12.3333H9.66659V13.6666H0.333252V12.3333Z"
          fill="#242726"
        />
      </svg>
      <span class="text-nudging ml-3">{{ location }}</span>
    </div>

    <div class="flex items-center ml-6">
      <svg
        width="12"
        height="14"
        viewBox="0 0 12 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 0H4V1.33333H8V0ZM5.33333 8.66667H6.66667V4.66667H5.33333V8.66667ZM10.6867 4.26L11.6333 3.31333C11.3467 2.97333 11.0333 2.65333 10.6933 2.37333L9.74667 3.32C8.71333 2.49333 7.41333 2 6 2C2.68667 2 0 4.68667 0 8C0 11.3133 2.68 14 6 14C9.32 14 12 11.3133 12 8C12 6.58667 11.5067 5.28667 10.6867 4.26ZM6 12.6667C3.42 12.6667 1.33333 10.58 1.33333 8C1.33333 5.42 3.42 3.33333 6 3.33333C8.58 3.33333 10.6667 5.42 10.6667 8C10.6667 10.58 8.58 12.6667 6 12.6667Z"
          fill="#242726"
        />
      </svg>
      <span class="text-nudging ml-3">{{ deadline }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { formatDetails } from "@/helpers/roleHelpers";
import { Role } from "@/models/Role";

const props = defineProps<{
  role: Role;
}>();

const { compensation, commitment, location, deadline } = formatDetails(
  props.role
);
</script>
