import { apiAxios } from "@/lib/axios";
import { Ref, ref } from "vue";
import router from "@/lib/theVueRouter";
import { RouteLocation } from "vue-router";

export const authLevel: Ref<
  | "pending"
  | "logged-out"
  | "preview-mode"
  | "punchthrough"
  | "impersonate"
  | "standard"
> = ref("pending");

function redirectToLogin(redirect: string) {
  window.location.replace(
    process.env.VITE_ACCOUNT_DOMAIN + "/login?redirect=" + redirect
  );
}

const lastToRouteLocation = ref<RouteLocation | null>(null);

export function preventUnauthorizedAccess(to: RouteLocation) {
  lastToRouteLocation.value = to;
  const {
    mustBeLoggedIn,
    denyPunchthroughAccess,
    denyPreviewModeAccess,
    allowToUseEmailVerification,
  } = to.meta || {};
  const redirect = window.location.origin + to.fullPath;

  if (
    mustBeLoggedIn &&
    authLevel.value == "logged-out" &&
    !allowToUseEmailVerification
  ) {
    redirectToLogin(redirect);
  }

  if (denyPunchthroughAccess && authLevel.value == "punchthrough") {
    redirectToLogin(redirect);
  }

  if (denyPreviewModeAccess && authLevel.value == "preview-mode") {
    redirectToLogin(redirect);
  }
}

async function getAuthLevel() {
  try {
    const { data } = await apiAxios.get("/auth-level");
    authLevel.value = data;
  } catch (error: any) {
    if (error.response?.status == 401) {
      authLevel.value = "logged-out";
    }
  } finally {
    preventUnauthorizedAccess(
      lastToRouteLocation.value || router.currentRoute.value
    );
  }
}

export default function useAuth() {
  return {
    authLevel,
    getAuthLevel,
  };
}
