export function configureGoogleAnalyticsTags() {
  const scriptId = "ga-gtag";

  if (document.getElementById(scriptId)) return;

  const { head } = document;
  const script = document.createElement("script");
  const gtagId = process.env.VITE_GTAG_ID;
  script.id = scriptId;
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;
  head.insertBefore(script, head.firstChild);

  window.dataLayer = window.dataLayer || [];
  function gtag(...args) {
    window.dataLayer.push(args);
  }

  gtag("js", new Date());
  window.gtag = gtag;
}
