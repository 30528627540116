<template>
  <div class="pt-4 w-full">
    <uploaded-cv v-if="props.cv" :cv="props.cv" @deleted="onCvDeleted" />
    <div v-else>
      <upload-cv-s3 @cv-file-uploaded="onCvUpload" @failed="onUploadFailure" />
      <upload-cv-error v-if="cvUploadError" :cv-error="cvUploadError" />
      <linked-in-nudging-banner v-if="!props.cv" />
    </div>
  </div>
</template>

<script setup lang="ts">
import LinkedInNudgingBanner from "./LinkedInNudgingBanner.vue";
import UploadCvError from "./UploadCvError.vue";
import UploadCvS3 from "./UploadCvS3.vue";
import UploadedCv from "./UploadedCv.vue";
import { apiAxios } from "@/lib/axios";
import useIndividual from "@/composables/useIndividual";
import { computed, ref } from "vue";

const props = defineProps({
  cv: { type: Object, required: false, default: null },
});

interface Cv {
  filename: string;
  location: string;
  object_url: string;
  s3_object_key: string;
  id?: string;
  created_at?: string;
}

const emit = defineEmits(["cv-change"]);

const { individual } = useIndividual();
const isSignedIn = computed(() => {
  return !!individual.value?.first_name;
});
const cvUploadError = ref(null);
const currentCv = ref<Cv | null>(null);

async function onCvUpload(filename, s3UploadData) {
  const s3ObjectUrl = s3UploadData.signedUrl.split("?")[0];
  try {
    currentCv.value = {
      filename: filename,
      object_url: s3ObjectUrl,
      location: s3ObjectUrl,
      s3_object_key: s3UploadData.s3ObjectKey,
    };
    if (isSignedIn.value) {
      await saveCv();
    }
    emit("cv-change", currentCv.value);
  } catch (error) {
    onUploadFailure(error);
    throw error;
  }
}

async function saveCv() {
  const response = await apiAxios.post("/individual/cvs", currentCv.value);
  currentCv.value = response.data;
}

function onUploadFailure(error) {
  cvUploadError.value = error;
}

function onCvDeleted() {
  currentCv.value = null;
  emit("cv-change", currentCv.value);
}
</script>
