<template>
  <div>
    <div
      class="w-screen flex flex-col items-center bg-ivory-100 px-0 lg:px-6 xl:px-2 min-h-screen"
    >
      <div
        class="bg-sage-600 w-screen flex justify-center items-center"
        :class="pageTitle ? 'pt-28 sm:pt-28 pb-36' : 'pb-10 lg:pb-28'"
      ></div>
      <div
        class="max-w-screen-lg bg-white rounded-t-0 lg:rounded-lg w-full mx-auto relative -top-16"
      >
        <h1
          class="absolute -top-40 left-0 m-0 mx-0 px-4 xs:-top-24 md:-top-28 sm:px-6 md:px-16 lg:px-0 text-ivory-50 text-title-jumbo"
        >
          {{ pageTitle }}
        </h1>
        <AlreadyMember
          v-if="isSignedIn"
          :individual="individual"
          class="px-2 sm:px-6 md:px-16 lg:px-24"
        />
        <RequestForm v-else />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import RequestForm from "../components/requestInvitation/RequestForm.vue";
import AlreadyMember from "../components/requestInvitation/AlreadyMember.vue";
import useIndividual from "@/composables/useIndividual";

export default {
  components: {
    RequestForm,
    AlreadyMember,
  },
  setup() {
    const { individual, getIndividual } = useIndividual();

    return {
      individual,
      getIndividual,
    };
  },
  async created() {
    await this.getIndividual();
  },
  computed: {
    isSignedIn() {
      return !!(this.individual && this.individual.first_name);
    },
    pageTitle() {
      if (this.isSignedIn) {
        return "Already a member";
      }
      return "Join the Nurole network";
    },
  },
};
</script>
