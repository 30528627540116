<template>
  <div class="bg-white px-0 sm:px-12 lg:px-28 py-0 mb-5 md:my-0 sm:pb-6">
    <div
      data-cy="terms-conditions-error"
      v-if="!hasAgreedTerms && submitAttempted"
      class="text-red-400 copy-bold flex items-center p-3 sm:pl-0 pb-5 bg-grey-50 sm:bg-white"
    >
      <span class="material-icons-round mr-2 text-18/18"> info </span>
      You need to agree with our terms and conditions to progress
    </div>
    <div
      @click="updateAgreedTerms"
      class="flex items-center sm:rounded-lg cursor-pointer p-4"
      :class="{
        'bg-sage-50': hasAgreedTerms,
        'bg-blue-50': !hasAgreedTerms && !submitAttempted,
        'bg-red-50': !hasAgreedTerms && submitAttempted,
      }"
    >
      <button data-cy="preview-terms-conditions" class="flex items-center mr-3">
        <input
          tabindex="-1"
          type="checkbox"
          class="opacity-0 w-0"
          :checked="hasAgreedTerms"
        />
        <span class="material-icons-round text-grey-700" v-if="!hasAgreedTerms">
          check_box_outline_blank
        </span>
        <span class="material-icons-round text-sage-800" v-else>
          check_box
        </span>
      </button>
      <span class="copy-emphasis text-grey-800">
        I've read and agreed to Nurole's
        <a
          :href="`${marketingAppURL}/terms-and-conditions`"
          class="underline text-grey-800"
          target="_blank"
          >Terms and Conditions
        </a>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import useRoleApplication from "@/composables/useRoleApplication";

export default {
  props: {
    hasAgreedTerms: Boolean,
    submitAttempted: Boolean,
  },
  setup() {
    const { updateRoleApplication } = useRoleApplication();

    return {
      updateRoleApplication,
      marketingAppURL: process.env.VITE_MARKETING_BASE_URL,
    };
  },
  methods: {
    updateAgreedTerms() {
      this.updateRoleApplication({
        agreed_terms_and_conditions: !this.hasAgreedTerms,
      });
    },
  },
};
</script>

<style></style>
