<template>
  <EducationPreferences
    title="Preferences for education roles"
    :individual="individual"
    @update-individual-and-put="updateIndvidualPreferences"
  >
  </EducationPreferences>
</template>
<script lang="ts" setup>
import EducationPreferences from "@/views/onboarding/EducationPreferences.vue";
import useIndividual from "@/composables/useIndividual";

const { individual, updateIndividual } = useIndividual();

const updateIndvidualPreferences = async (event: any) => {
  await updateIndividual(event, "preferences_education");
};
</script>
