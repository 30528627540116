<template>
  <div v-if="modalOpen" ref="wrapper">
    <the-modal :easyToClose="true" @close-modal="modalOpen = false">
      <template #header>
        <h2 class="copy-emphasis sm:text-title-h2">
          Quick apply to {{ role.name }}
        </h2>
        <h5 class="text-primary mt-2 copy">
          Your professional experience and CV will appear as part of your
          application
        </h5>
      </template>
      <quick-apply-steps :role="role" @navigate="scrollToModalTop" />
    </the-modal>
  </div>
</template>

<script setup lang="ts">
import TheModal from "@/components/TheModal.vue";
import QuickApplySteps from "@/components/QuickApplySteps.vue";
import { ref } from "vue";

const wrapper = ref<HTMLDivElement | null>(null);
const modalOpen = ref(false);

defineProps(["role"]);
defineExpose({ modalOpen });

const scrollToModalTop = () =>
  wrapper.value?.querySelector(".overflow-y-scroll")?.scrollTo(0, 0);
</script>
