<template>
  <div class="bg-white shadow-ivory p-10 md:px-28 rounded-xl">
    <div class="flex pb-3 border-b border-gray-900">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.75 3.75L14.082 16.6992C10.8871 18.6861 8.75 22.2193 8.75 26.25C8.75 32.4525 13.7963 37.5 20 37.5C26.2038 37.5 31.25 32.4525 31.25 26.25C31.25 22.2193 29.1129 18.6861 25.918 16.6992L31.25 3.75H28.5474L23.6572 15.6226C22.8357 15.339 21.9761 15.1419 21.0815 15.0562L25.7349 3.75H23.0322L18.3423 15.1367C17.6532 15.2391 16.9855 15.4007 16.3428 15.6226L11.4526 3.75H8.75ZM14.2651 3.75L17.4414 11.4673L18.7939 8.18359L16.9678 3.75H14.2651ZM20 17.5C24.8237 17.5 28.75 21.425 28.75 26.25C28.75 31.075 24.8237 35 20 35C15.1762 35 11.25 31.075 11.25 26.25C11.25 21.425 15.1762 17.5 20 17.5ZM20 21.4526L18.54 24.7266L15 25.1147L17.6489 27.4951L16.9043 31.0083L20 29.2212L23.0957 31.0083L22.3511 27.4951L25 25.1147L21.46 24.7266L20 21.4526Z"
          fill="#242726"
        />
      </svg>

      <h3 class="ml-2 mt-1">Career Highlights</h3>
    </div>

    <ul class="mt-6 flex flex-col gap-4">
      <li
        v-for="highlight in careerHighlightsToShow"
        :key="highlight"
        class="pb-4 border-b border-grey-100 last:border-b-0"
      >
        {{ highlight }}
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { Profile } from "@/types/Profile";

const props = defineProps<{
  careerHighlights: Profile["career_highlights"];
}>();

const careerHighlightsToShow = props.careerHighlights.filter(
  highlight => highlight.length
);
</script>
