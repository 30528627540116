export function trackProfileStats(individual, changeSource) {
  const payload = {
    updatedAt: new Date().toISOString(),
    individualId: individual.id,
    changeSource,
    profilePrivacy: individual.profile_privacy,
    educationComplete: individual.education_courses?.length > 0,
    careerHighlightsComplete: individual.career_highlights?.length > 0,
    careerSummaryComplete: individual.career_summary?.length > 0,
    professionalExperienceComplete:
      individual.professional_experiences?.length > 0,
  };
  window.analytics.track("MyProfile_Updated", payload);
}
