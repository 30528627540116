import { createApp } from "vue";
import { VueShowdownPlugin } from "vue-showdown";
import * as Sentry from "@sentry/vue";
import App from "@/App.vue";
import router from "@/lib/theVueRouter";
import "@/lib/addInterceptors";

import { refreshRoles } from "@/composables/useRoles";
refreshRoles();

declare global {
  interface Window {
    Cypress: boolean;
    ga: (command: string, event: string, origin: string) => void;
    askNicelyConversation: Function;
  }
}

import "@/lib/flagsmith";
import "@/lib/mixpanel";
import "@/lib/identify";
import "@/lib/clarity";
import Toast, { toastOptions } from "@/lib/toastConfig";
import { configureGoogleAnalyticsTags } from "@/lib/gtag";
configureGoogleAnalyticsTags();

// Styles
import "@/assets/styles.css";

const app = createApp(App);

app.use(router);
app.use(VueShowdownPlugin);

const environment = process.env.VITE_SENTRY_ENV;
Sentry.init({
  app,
  dsn: "https://de1c53ec014743cbaf9307e790c3af49@o424465.ingest.sentry.io/5356374",
  release: process.env.VITE_VERSION,
  integrations: [Sentry.browserTracingIntegration({ router })],
  environment: environment,
  tracesSampleRate: environment === "live" ? 1.0 : 0.1,
  enabled: environment === "live" || environment === "test",
});

app.mount("#vue-app");
app.use(Toast, toastOptions);
