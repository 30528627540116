<template>
  <div>
    <div
      class="flex flex-col items-start p-0 gap-2 flex-none order-none self-stretch flex-grow"
    >
      <div
        class="flex flex-row items-start p-0 gap-4 flex-none order-none self-stretch grow-0"
      >
        <div
          class="copy-bold text-grey-900 flex-1 order-none flex-grow min-h-13"
        >
          {{ heading }}
        </div>
        <span
          class="material-icons-outlined h-8 w-8 flex-1 order-1 grow-0 text-grey-900 text-32/32"
        >
          {{ icon }}
        </span>
      </div>
      <div
        class="flex text-labelling-inactive text-grey-900 flex-none order-1 self-stretch grow-0"
      >
        {{ message }}
      </div>
    </div>
    <router-link
      v-if="!ctaExternalLink"
      @click="trackClick"
      class="flex flex-row items-center p-0 gap-2 link-button flex-none order-1 self-stretch grow-0 text-cta-tertiary text-sage-700"
      :to="ctaLink"
    >
      <div class="flex-none order-none grow-0">
        {{ ctaLabel }}
      </div>
    </router-link>
    <a
      v-else
      class="flex flex-row items-center p-0 gap-2 link-button flex-none order-1 self-stretch grow-0 text-cta-tertiary text-sage-700"
      target="_blank"
      @click="trackClick"
      :href="ctaLink"
    >
      <div class="flex-none order-none grow-0">
        {{ ctaLabel }}
      </div>
      <span
        v-if="ctaExternalLink"
        class="material-icons-round h-4 w-4 leading-4 flex-none order-1 grow-0 text-base"
      >
        launch
      </span>
    </a>
  </div>
</template>

<script lang="ts">
export default {
  name: "NoRecommendedRolesCard",
  props: {
    heading: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    ctaLabel: {
      type: String,
      required: true,
    },
    ctaLink: {
      type: String,
      required: true,
    },
    ctaExternalLink: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    trackClick() {
      window.analytics.track("No Recommended Roles CTA Link clicked", {
        heading: this.heading,
        ctaLabel: this.ctaLabel,
        ctaLink: this.ctaLink,
        ctaExternalLink: this.ctaExternalLink,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.link-button {
  border-radius: 32px;
}
</style>
