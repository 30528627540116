<template>
  <div
    class="p-4 md:p-8 md:pb-4 lg:pb-8 lg:p-12 rounded-b-xl"
    :class="moreDetailOpen ? 'bg-white' : ''"
  >
    <fieldset class="pb-4 md:pb-0 md:flex">
      <h3 class="text-grey-900 copy-bold md:my-1 md:mr-5">
        Is this role right for you?
      </h3>
      <div class="flex flex-row justify-between gap-x-4">
        <button
          data-cy="yes-role-relevant-button"
          class="py-2 md:py-1 font-semibold w-2/4 sm:w-max flex flex-row justify-center items-center px-4 rounded-full mr-2"
          :class="
            roleRelevant === true
              ? 'bg-sage-600 text-ivory-100  hover:bg-sage-700'
              : 'bg-white text-sage-600  hover:text-sage-700'
          "
          @click="toggleRoleRelevant(true)"
        >
          <span class="material-icons-round pr-2 text-sm">thumb_up</span>
          Yes
        </button>
        <button
          class="py-2 md:py-1 font-semibold w-2/4 sm:w-max flex flex-row justify-center items-center px-4 rounded-full mr-2"
          :class="
            roleRelevant === false
              ? 'bg-claret-700 text-ivory-100  hover:bg-claret-800'
              : 'bg-white text-claret-700  hover:text-claret-800'
          "
          @click="toggleRoleRelevant(false)"
        >
          <span class="material-icons-round pr-2 text-sm">thumb_down</span>
          Not Really
        </button>
      </div>
    </fieldset>
    <div
      class="bg-sage-50 p-4 md:p-8 md:mt-8 rounded-lg flex flex-col gap-4 relative"
      v-if="moreDetailOpen"
    >
      <h3
        v-if="!submittedMoreDetail"
        class="m-0 pr-6 md:pr-10 text-grey-900 copy-bold"
      >
        {{
          roleRelevant
            ? "What makes this role right for you?"
            : "What makes this role not right for you?"
        }}
      </h3>
      <textarea
        id="more-detail"
        name="more-detail"
        rows="3"
        aria-describedby="more-detail-hint"
        class="textarea p-4 md:p-8 rounded-lg"
        placeholder="Your feedback here"
        v-model="relevantRoleDetails"
        v-if="!submittedMoreDetail"
      ></textarea>
      <button
        class="button-primary text-cta-primary whitespace-nowrap flex justify-center m-0 md:w-min"
        v-on:click="submitRelevantRoleDetails"
        v-if="!submittedMoreDetail"
      >
        Submit
      </button>
      <span class="material-icons-round text-3xl" v-if="submittedMoreDetail"
        >task_alt</span
      >
      <h3 v-if="submittedMoreDetail" class="m-0">
        Submitted, thank you for your feedback
      </h3>

      <button
        class="absolute top-0 right-0 p-4 md:p-8"
        v-on:click="closeMoreDetail"
      >
        <span class="material-icons-round">close</span>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "RoleRelevancyFeedback",
  props: {
    roleId: {
      required: true,
      type: String,
    },
    individualId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      roleRelevant: null,
      moreDetailOpen: false,
      relevantRoleDetails: "",
      submittedMoreDetail: false,
    };
  },
  methods: {
    toggleRoleRelevant(relevant: boolean) {
      if (this.roleRelevant === relevant) {
        this.roleRelevant = null;
        this.moreDetailOpen = false;
      } else {
        this.roleRelevant = relevant;
        this.moreDetailOpen = true;
        this.relevantRoleDetails = "";
        this.submittedMoreDetail = false;
      }
      window.analytics.track("Changed Relevant Role Feedback", {
        roleId: this.roleId,
        individualId: this.individualId,
        relevant: this.roleRelevancyReadableValue(),
      });
    },
    closeMoreDetail() {
      this.moreDetailOpen = false;
    },
    submitRelevantRoleDetails() {
      window.analytics.track("Submitted Relevant Role Feedback Details", {
        roleId: this.roleId,
        individualId: this.individualId,
        relevant: this.roleRelevancyReadableValue(),
        relevancyDetails: this.relevantRoleDetails,
      });
      this.submittedMoreDetail = true;
    },
    roleRelevancyReadableValue() {
      switch (this.roleRelevant) {
        case null:
          return "Role relevancy value cleared";
        case true:
          return "Role is relevant";
        case false:
          return "Role is not relevant";
        default:
          return "Invalid value";
      }
    },
  },
};
</script>
