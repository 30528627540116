<template>
  <div
    class="flex flex-col pt-4 pb-4 md:pb-6 border-l-0 border-r-0 border-t-0 border-solid border-grey-100 border-b"
  >
    <div class="flex justify-between">
      <div class="flex md:space-x-4">
        <div
          class="flex flex-col text-grey-800 flex-grow space-y-2 md:space-y-0"
        >
          <span class="copy-bold" :class="index == 2 ? `text-grey-400` : ''">{{
            role.position
          }}</span>
          <span class="" :class="index == 2 ? `text-grey-200` : ''">{{
            role.organisation
          }}</span>
        </div>
      </div>

      <div
        v-if="isMissingDates"
        class="mt-3 rounded-lg px-2 py-1 bg-red-50 text-red-600 copy-bold w-max"
      >
        Missing dates
      </div>
      <span>{{ getDisplayDates(this.role) }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from "vue";
import { getDisplayDates } from "@/helpers/miniCv";
import { RoleHeld } from "@/composables/useMiniCv";

export default {
  name: "RoleApplyProfessionalExperienceRole",
  props: {
    role: {
      type: Object as PropType<RoleHeld>,
      required: true,
    },
    index: Number,
  },
  setup() {
    return {
      getDisplayDates,
    };
  },

  computed: {
    isMissingDates() {
      return !this.role.from;
    },
  },
};
</script>
