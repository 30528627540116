<template>
  <div class="copy-emphasis mb-8">Location & contact</div>
  <div class="md:flex items-center">
    <div class="flex-1">
      <div class="text-inputs-title mb-2">Country</div>
      <select
        v-model="country"
        class="select-input w-full"
        @input="saveChanges"
        data-cy="country-select"
      >
        <option v-for="option in countries" :key="option" :value="option">
          {{ option }}
        </option>
      </select>
    </div>
    <div class="flex-1 md:ml-3 md:mt-0 mt-6">
      <div class="text-inputs-title mb-2">Postcode</div>
      <input
        type="text"
        class="text-input w-full"
        v-model="postcode"
        @input="saveChanges"
        data-cy="postcode-input"
        name="postcode"
        autocomplete="postal-code"
      />
    </div>
  </div>
  <div class="text-inputs-title mb-2 mt-6">Mobile number</div>
  <input
    type="tel"
    class="text-input w-full"
    v-model="telephone"
    @input="saveChanges"
    data-cy="telephone-input"
  />
</template>

<script lang="ts">
import useIndividual from "@/composables/useIndividual";
import countries from "@/lib/countries";
import debounce from "@/utils/debounce";

export default {
  setup() {
    const { isLoaded, individual, updateIndividual } = useIndividual();
    return {
      countries,
      individual,
      updateIndividual,
      isLoaded,
    };
  },
  data() {
    return {
      country: "",
      postcode: "",
      telephone: "",
    };
  },
  methods: {
    saveChanges: debounce(async function () {
      await this.updateIndividual(this.$data, "location_and_contact");
    }, 500),
  },
  watch: {
    isLoaded: {
      immediate: true,
      handler(isLoaded) {
        if (isLoaded) {
          this.country = this.individual.country;
          this.postcode = this.individual.postcode;
          this.telephone = this.individual.telephone;
        }
      },
    },
  },
};
</script>
