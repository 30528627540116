<template>
  <form @submit.prevent novalidate>
    <div class="flex flex-col gap-4">
      <label class="copy-emphasis block" for="career_summary"
        >Career summary</label
      >
      <example-slider :examples="examples" title="Example" />
      <div class="flex flex-col gap-3 lg:flex-row-reverse lg:justify-stretch">
        <div
          class="bg-blue-50 py-4 px-6 rounded-lg flex flex-col gap-3 lg:basis-1/3"
        >
          <p class="text-divider text-guidance flex gap-2 items-center">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.00065 1.33398C4.32065 1.33398 1.33398 4.32065 1.33398 8.00065C1.33398 11.6807 4.32065 14.6673 8.00065 14.6673C11.6807 14.6673 14.6673 11.6807 14.6673 8.00065C14.6673 4.32065 11.6807 1.33398 8.00065 1.33398ZM8.00065 11.334C7.63398 11.334 7.33398 11.034 7.33398 10.6673V8.00065C7.33398 7.63398 7.63398 7.33398 8.00065 7.33398C8.36732 7.33398 8.66732 7.63398 8.66732 8.00065V10.6673C8.66732 11.034 8.36732 11.334 8.00065 11.334ZM8.66732 6.00065H7.33398V4.66732H8.66732V6.00065Z"
                fill="#41578A"
              />
            </svg>
            What to include
          </p>
          <ul class="list-disc pl-5">
            <li>Senior roles: e.g. Chair, C-suite</li>
            <li>Company type: e.g. Public, Private Equity, Non-Profit</li>
            <li>Sectors: e.g. Financial Services, Consumer Goods</li>
            <li>Geographies: e.g. domestic, international, specific regions</li>
            <li>
              Key milestones: Significant career events, awards, or other
              achievements
            </li>
          </ul>
        </div>
        <text-area-word-count
          :model-value="career_summary"
          @update:modelValue="
            career_summary = $event;
            saveChanges();
          "
          data-cy="career-summary-input"
          id="career_summary"
          :rows="5"
          :target-word-count="75"
          placeholder="Enter your career summary"
        ></text-area-word-count>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import TextAreaWordCount from "../shared/TextAreaWordCount.vue";
import useIndividual from "@/composables/useIndividual";
import debounce from "@/utils/debounce";
import { useToast } from "vue-toastification";
import { ref, watch } from "vue";
import ExampleSlider from "./ExampleSlider.vue";

const { individual, isLoaded, updateIndividual } = useIndividual();
const toast = useToast();
const examples = [
  "Alex served as a C-suite executive in three Fortune 500 companies in the Tech and Consumer sectors. With a footprint in the US and Europe, his leadership led to two successful IPOs and a 40% CAGR over five years. Awards include Fortune's Top 40 Under 40.",
  "Liz is a seasoned CFO with over 20 years in the Renewable Energy sector, she has steered financial operations for both public and venture-backed firms in North America and Europe. Her strategic planning led to a 25% cost reduction and raised $100M in Series B funding. Honoured with the Finance Monthly Game Changer Award in 2021.",
  "Diane is the serving Chair of a UK mid-cap insurance company and was previously the Chair of a non governmental public body. Diane’s career started in investment banking, and she has since navigated across sectors including Financial Services, Government, Technology, and Healthcare. Geographically her footprint extends from the UK to Asia and the United States. In her current role she has led the organisation through a significant governance restructure.",
];
const career_summary = ref(individual.value.executive_summary || "");

const saveChanges = debounce(async function () {
  await updateIndividual(
    {
      executive_summary: career_summary.value,
    },
    "career_summary"
  );
  toast.success("Changes saved successfully", { timeout: 3000 });
}, 1000);

watch(isLoaded, () => {
  if (isLoaded) {
    career_summary.value = individual.value.executive_summary || "";
  }
});
</script>
