<template>
  <div class="flex flex-col md:flex-row content-center">
    <div
      class="flex md:flex-col flex-row items-center md:items-start md:flex-1 my-2"
      v-for="stage in stages"
      :key="stage.label"
    >
      <span
        class="material-icons-round rounded-full bg-sage-100 text-sage-600 p-2 text-22/22 md:mb-4"
        v-if="stage.isComplete"
      >
        check
      </span>
      <span
        v-else
        class="material-icons-round rounded-full bg-ivory-300 text-ivory-900 p-2 text-22/22 md:mb-4"
        >watch_later
      </span>
      <img
        :src="stage.image"
        class="ml-6 mr-3 md:mx-0 md:mb-2 w-10 h-10"
        alt=""
      />
      <div class="text-grey-900 flex-grow md:w-16 text-inputs-content">
        {{ stage.label }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import documentsIcon from "@/assets/images/stages-documents.svg";
import mailIcon from "@/assets/images/stages-mail.svg";
import cogIcon from "@/assets/images/stages-cog.svg";
import speechBubbleIcon from "@/assets/images/stages-speech-bubble.svg";
import businessCaseIcon from "@/assets/images/stages-business-case.svg";
export default {
  name: "WhatHappensNext",
  data() {
    return {
      stages: [
        {
          isComplete: true,
          image: documentsIcon,
          label: "Draft application",
        },
        {
          isComplete: true,
          image: mailIcon,
          label: "Submit",
        },
        {
          isComplete: false,
          image: cogIcon,
          label: "Application review",
        },
        {
          isComplete: false,
          image: speechBubbleIcon,
          label: "Application outcome",
        },
        {
          isComplete: false,
          image: businessCaseIcon,
          label: "Next steps",
        },
      ],
    };
  },
};
</script>
