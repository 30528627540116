<template>
  <div
    class="w-full h-full content-start m-0 md:w-80 flex flex-col md:shadow-lg bg-white shadow-xl landscape:h-full overflow-scroll copy-bold px-6 py-6"
  >
    <div class="text-right md:hidden xs:mb-5">
      <span
        class="material-icons-round mb-0 text-28/28"
        @click="$emit('menu-close')"
      >
        close
      </span>
    </div>

    <div v-if="individual" class="flex flex-col justify-between white h-full">
      <div>
        <div class="flex flex-col">
          <template v-for="(link, index) in links" :key="index">
            <router-link
              :to="link.href"
              :target="link.openInNewTab ? '_blank' : ''"
              :rel="link.openInNewTab ? 'noopener' : ''"
              v-if="link.useVueRouter"
              @click="$emit('close-menu')"
              class="py-4 text-grey-900 border-solid border-t-0 border-r-0 border-b flex gap-2 items-center"
              >{{ link.label
              }}<mark v-if="link.highlightAsNew">NEW</mark></router-link
            >
            <a
              v-else
              @click="$emit('close-menu')"
              :href="link.href"
              class="py-4 text-grey-900 border-solid border-t-0 border-r-0 border-b"
              >{{ link.label }}</a
            >
          </template>

          <a
            :href="communityURL"
            target="_blank"
            rel="noopener"
            class="py-4 text-grey-900 border-solid border-b flex gap-2 items-center"
          >
            Community
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="ml-3"
            >
              <path
                d="M1.33341 0.666626V1.99996H7.06008L0.666748 8.39329L1.60675 9.33329L8.00008 2.93996V8.66663H9.33341V0.666626H1.33341Z"
                fill="#C8C8C8"
              />
            </svg>
          </a>
        </div>
        <div class="border-b border-0 border-solid border-grey-300">
          <mobile-profile-button
            label="My Profile"
            :individual="individual"
            @click="toggleProfile"
            :is-open="profileOpen"
          />
        </div>
      </div>

      <div v-if="profileOpen" class="h-full flex flex-col">
        <div class="flex flex-col px-4 py-1 sm:py-2 flex-1">
          <router-link
            v-for="(link, index) in profileLinks"
            :key="index"
            @click="handleLinkClick(link.label)"
            :to="link.href"
            class="py-4 text-grey-900 border-solid border-t-0 border-r-0 border-b flex items-center gap-2"
          >
            {{ link.label }}
            <mark v-if="link.highlightAsNew">NEW</mark>
          </router-link>

          <a
            href="https://help.nurole.com"
            target="_blank"
            rel="noopener"
            class="py-4 text-grey-900 border-solid border-t-0 border-r-0 border-b-0 flex items-center"
          >
            Help Centre<svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="ml-3"
            >
              <path
                d="M1.33341 0.666626V1.99996H7.06008L0.666748 8.39329L1.60675 9.33329L8.00008 2.93996V8.66663H9.33341V0.666626H1.33341Z"
                fill="#C8C8C8"
              />
            </svg>
          </a>
        </div>
      </div>
      <div class="flex flex-col space-y-2 my-6">
        <a
          :href="`/recommend`"
          @click="$emit('close-menu')"
          class="bg-sage-600 hover:bg-sage-700 active:bg-sage-800 text-ivory-50 text-cta-primary text-center py-3 border border-solid border-transparent"
        >
          Recommend someone</a
        >
        <button
          @click="logout"
          class="text-cta-secondary border border-solid border-sage-600 text-sage-600 hover:border-sage-700 hover:text-sage-700 bg-white hover:bg-sage-50 active:bg-sage-100 text-center py-3"
        >
          Sign out
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import MobileProfileButton from "@/components/shared/MobileProfileButton.vue";
import logout from "@/lib/logout";

export default {
  name: "MobileMenu",
  components: {
    MobileProfileButton,
  },
  props: {
    individual: {
      type: Object,
    },
  },
  setup() {
    return {
      communityURL: process.env.VITE_COMMUNITY_DOMAIN,
      marketingAppURL: process.env.VITE_MARKETING_BASE_URL,
      logout,
    };
  },
  data: () => ({
    profileOpen: false,
    route: "",
  }),
  mounted() {
    this.route = location.pathname;
  },
  methods: {
    toggleProfile() {
      this.profileOpen = !this.profileOpen;
    },

    isActive(route) {
      return this.route === route;
    },

    handleLinkClick(linkLabel: string) {
      window.analytics.track(linkLabel + " clicked");
      this.$emit("close-menu");
    },
  },
  computed: {
    links() {
      return [
        {
          label: "Home",
          href: this.marketingAppURL,
        },
        {
          label: "View roles",
          href: "/roles",
          useVueRouter: true,
        },
        {
          label: "Podcast",
          href: `${this.marketingAppURL}/nurole-podcast-enter-the-boardroom`,
        },
      ];
    },
    profileLinks() {
      return [
        {
          label: "Manage My Profile",
          href: "/account/profile",
        },
        {
          label: "My preferences",
          href: "/account/preferences",
        },
        {
          label: "Watchlist",
          href: "/account/watchlist",
        },
        {
          label: "Applications",
          href: "/account/applications",
        },
        {
          label: "Recommendations",
          href: "/account/recommendations",
        },
        {
          label: "Settings",
          href: "/account/settings",
        },
      ];
    },
  },
};
</script>
