import { RouteRecordRaw } from "vue-router";

const resconfigureOnlyRoutes = ["compensated", "pro-bono", "education"];
const finalRoute = ["/roles"];
const reconfigureRoutes = resconfigureOnlyRoutes.concat(finalRoute);

const onboardingRoutes = ["personal-details", "add-your-cv"]
  .concat(resconfigureOnlyRoutes)
  .concat(["your-experience"])
  .concat(finalRoute);

export const onboardingPages = [
  {
    step: onboardingRoutes[0],
    shortTitle: "Personal Details",
    title: "Your details",
  },
  {
    step: onboardingRoutes[1],
    shortTitle: "Add your CV",
    title: "Add your CV",
  },
  {
    step: onboardingRoutes[2],
    shortTitle: "Compensated",
    title: "Preferences for compensated roles",
  },
  {
    step: onboardingRoutes[3],
    shortTitle: "Charities",
    title: "Preferences for charity roles",
  },
  {
    step: onboardingRoutes[4],
    shortTitle: "Education Sector",
    title: "Preferences for education roles",
  },
  {
    step: onboardingRoutes[5],
    shortTitle: "Your Experience",
    title: "Professional experience",
  },
];

export const onboarding: RouteRecordRaw[] = [
  {
    path: "/onboarding",
    name: "Onboarding",
    redirect: "/onboarding/personal-details",
    component: () => import("@/views/onboarding/Onboarding.vue"),
    meta: {
      scrollTopWhenGoingTo: ["RolesList"],
      mustBeLoggedIn: true,
    },
    props: {
      routes: onboardingRoutes,
    },
    children: [
      {
        path: onboardingRoutes[0],
        name: "Onboarding Personal Details",
        component: () => import("@/views/onboarding/PersonalDetails.vue"),
      },
      {
        path: onboardingRoutes[1],
        name: "Add your CV",
        component: () => import("@/views/onboarding/AddYourCv.vue"),
      },
      {
        path: onboardingRoutes[2],
        name: "Onboarding Compensated",
        component: () =>
          import("@/views/onboarding/CompensatedPreferences.vue"),
      },
      {
        path: onboardingRoutes[3],
        name: "Onboarding ProBono",
        component: () => import("@/views/onboarding/CharityPreferences.vue"),
      },
      {
        path: onboardingRoutes[4],
        name: "Onboarding Education",
        component: () => import("@/views/onboarding/EducationPreferences.vue"),
      },
      {
        path: onboardingRoutes[5],
        name: "Onboarding Your Experience",
        component: () => import("@/views/onboarding/YourExperience.vue"),
      },
    ],
  },
  {
    path: "/reconfigure",
    name: "Reconfigure",
    redirect: "/reconfigure/compensated",
    component: () => import("@/views/onboarding/Onboarding.vue"),
    meta: {
      scrollTopWhenGoingTo: ["RolesList"],
      mustBeLoggedIn: true,
    },
    props: {
      routes: reconfigureRoutes,
    },
    children: [
      {
        path: reconfigureRoutes[0],
        name: "Reconfigure Compensated",
        component: () =>
          import("@/views/onboarding/CompensatedPreferences.vue"),
      },
      {
        path: reconfigureRoutes[1],
        name: "Reconfigure ProBono",
        component: () => import("@/views/onboarding/CharityPreferences.vue"),
      },
      {
        path: reconfigureRoutes[2],
        name: "Reconfigure Education",
        component: () => import("@/views/onboarding/EducationPreferences.vue"),
      },
    ],
  },
  {
    path: "/onboarding/register/:inviteId",
    name: "AccountDetails",
    component: () => import("@/views/onboarding/AccountDetails.vue"),
  },
];
