<template>
  <div
    class="absolute w-full left-0 right-0 shadow-md rounded-b-lg z-90 bg-white"
  >
    <div class="max-h-56 overflow-y-scroll">
      <button
        @click="$emit('on-select', option)"
        v-for="(option, index) in options"
        :key="index"
        class="w-full p-4 hover:bg-grey-50 cursor-pointer text-left text-inputs-content"
        :class="{
          'bg-grey-100': index == highlightedIndex,
          'bg-white': index != highlightedIndex,
        }"
      >
        {{ option }}
      </button>
    </div>
    <div class="p-2" v-if="options.length">
      <button
        @click="$emit('on-none-of-these')"
        class="button-secondary text-cta-secondary w-full"
      >
        None of these
      </button>
    </div>
  </div>
</template>

<script>
import useOptionsKeyPresses from "@/composables/useOptionsKeyPresses";
import { ref } from "vue-demi";
export default {
  name: "QualificationSelector",
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  emits: ["on-select", "on-close", "on-none-of-these"],
  setup(props, context) {
    const highlightedIndex = ref(-1);
    useOptionsKeyPresses({
      highlightedIndex,
      options: props.options,
      onEnter: () => {
        if (props.options[highlightedIndex.value]) {
          context.emit("on-select", props.options[highlightedIndex.value]);
        } else {
          context.emit("on-close");
        }
      },
      onTab: () => context.emit("on-close"),
    });

    return {
      highlightedIndex,
    };
  },
};
</script>
