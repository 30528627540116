<template>
  <div>
    <div class="flex flex-col gap-4 mt-2">
      <button
        @click="option.handleToggle"
        class="border border-solid rounded-lg py-6 px-8 text-left md:flex md:gap-4 items-center underline-offset-4 hover:underline bg-white focus:ring-4 ring-orange-400"
        :class="{
          'border-sage-700': option.checked,
          'border-grey-200': !option.checked,
        }"
        v-for="option in optionsToShow"
        :key="option.value"
      >
        <div class="flex-1">
          <div v-html="option.icon"></div>
          <h4 class="copy-bold mt-3">{{ option.name }}</h4>
          <p class="mt-1">{{ option.desc }}</p>
        </div>
        <label
          @click.prevent
          class="switch text-sage-600 cursor-pointer mt-4 md:mt-0"
        >
          <input type="checkbox" :checked="option.checked" />
          <span class="slider round"></span>
        </label>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { individual, updateIndividual } from "@/composables/useIndividual";
import { computed, onMounted } from "vue";

const props = defineProps({
  optionToShow: {
    type: String,
    required: false,
    default: null,
  },
});

const options = computed(() => [
  {
    value: "clients",
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12 7V3H2V21H22V7H12ZM6 19H4V17H6V19ZM6 15H4V13H6V15ZM6 11H4V9H6V11ZM6 7H4V5H6V7ZM10 19H8V17H10V19ZM10 15H8V13H10V15ZM10 11H8V9H10V11ZM10 7H8V5H10V7ZM20 19H12V17H14V15H12V13H14V11H12V9H20V19ZM18 11H16V13H18V11ZM18 15H16V17H18V15Z"
            fill="#242726" />
        </svg>`,
    name: "Hiring boards using Nurole",
    desc: "Allow Nurole to show your profile to relevant boards",
    checked:
      individual.value.profile_privacy == "clients" ||
      individual.value.profile_privacy == "public",
    handleToggle() {
      if (
        individual.value.profile_privacy == "clients" ||
        individual.value.profile_privacy == "public"
      ) {
        updateIndividual({ profile_privacy: "nurole" }, "profile_privacy");
      } else {
        updateIndividual({ profile_privacy: "clients" }, "profile_privacy");
      }
    },
  },
  {
    value: "public",
    icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM2 10C2 9.39 2.08 8.79 2.21 8.22L6.99 13V14C6.99 15.1 7.89 16 8.99 16V17.93C5.06 17.43 2 14.07 2 10ZM15.89 15.4C15.63 14.59 14.89 14 13.99 14H12.99V11C12.99 10.45 12.54 10 11.99 10H5.99V8H7.99C8.54 8 8.99 7.55 8.99 7V5H10.99C12.09 5 12.99 4.1 12.99 3V2.59C15.92 3.77 18 6.65 18 10C18 12.08 17.19 13.98 15.89 15.4Z"
          fill="#242726" />
      </svg>`,
    name: "Public",
    desc: "Anyone on the internet will be able to view your Nurole profile",
    checked: individual.value.profile_privacy == "public",
    handleToggle() {
      if (individual.value.profile_privacy == "public") {
        updateIndividual({ profile_privacy: "clients" }, "profile_privacy");
      } else {
        updateIndividual({ profile_privacy: "public" }, "profile_privacy");
      }
    },
  },
]);

const optionsToShow = computed(() => {
  if (props.optionToShow) {
    return options.value.filter(option => option.value == props.optionToShow);
  }
  return options.value;
});

onMounted(() => {
  window.analytics.track("ProfilePrivacyOptions mounted");
});
</script>
