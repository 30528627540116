<template>
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 10.6364C18 8.57784 19.6688 6.90909 21.7273 6.90909H50.7773C51.756 6.90909 52.6954 7.294 53.3927 7.98071L64.8881 19.3017C65.5994 20.0023 66 20.9589 66 21.9574V69.1818C66 71.2403 64.3312 72.9091 62.2727 72.9091H21.7273C19.6688 72.9091 18 71.2403 18 69.1818V10.6364Z"
      fill="white"
      :stroke="primary"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M51 8.70632C51 7.49145 52.4688 6.88304 53.3279 7.74209L65.2579 19.6721C66.117 20.5312 65.5086 22 64.2937 22H52.3636C51.6105 22 51 21.3895 51 20.6364V8.70632Z"
      fill="#F5F5F2"
      :stroke="primary"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <line
      x1="41"
      y1="36.8182"
      x2="57"
      y2="36.8182"
      :stroke="secondary"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <line
      x1="41"
      y1="45.8182"
      x2="57"
      y2="45.8182"
      :stroke="secondary"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <line
      x1="41"
      y1="54.8182"
      x2="57"
      y2="54.8182"
      :stroke="secondary"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26 36.0455L27.7727 37.8182L32.5 33.0909"
      :stroke="secondary"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26 45.5L27.7727 47.2727L32.5 42.5455"
      :stroke="secondary"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26 54.9545L27.7727 56.7273L32.5 52"
      :stroke="secondary"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script lang="ts">
export default {
  props: {
    color: String,
  },

  computed: {
    primary() {
      if (this.color === "red") return "#A3181C";
      if (this.color === "orange") return "#D67325";
      if (this.color === "blue") return "#2649FF";
      return "#00B3B3";
    },
    secondary() {
      if (this.color === "red") return "#DA3C3C";
      if (this.color === "orange") return "#EB8A31";
      if (this.color === "blue") return "#2F8AFF";
      return "#1E4B37";
    },
  },
};
</script>
