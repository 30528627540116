<template>
  <no-recommended-roles-banner v-if="zeroNotifiableRoles" />
  <div
    v-else
    class="bg-sage-700 rounded-lg px-6 py-10 sm:px-4 sm:py-12 sm:px-14 lg:px-28 text-white mx-4 sm:mx-0 my-6 md:my-10"
  >
    <div class="flex flex-row gap-4">
      <div class="flex flex-col gap-4">
        <svg
          width="32"
          height="30"
          viewBox="0 0 32 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="hidden sm:block transform-gpu translate-y-1"
        >
          <path
            d="M14.6667 0.5C12.9427 0.5 11.474 1.625 10.9167 3.16667H0V5.83333H10.9167C11.474 7.375 12.9427 8.5 14.6667 8.5C16.3906 8.5 17.8594 7.375 18.4167 5.83333H32V3.16667H18.4167C17.8594 1.625 16.3906 0.5 14.6667 0.5ZM14.6667 3.16667C15.4167 3.16667 16 3.75 16 4.5C16 5.25 15.4167 5.83333 14.6667 5.83333C13.9167 5.83333 13.3333 5.25 13.3333 4.5C13.3333 3.75 13.9167 3.16667 14.6667 3.16667ZM24 11.1667C22.276 11.1667 20.8073 12.2917 20.25 13.8333H0V16.5H20.25C20.8073 18.0417 22.276 19.1667 24 19.1667C25.724 19.1667 27.1927 18.0417 27.75 16.5H32V13.8333H27.75C27.1927 12.2917 25.724 11.1667 24 11.1667ZM24 13.8333C24.75 13.8333 25.3333 14.4167 25.3333 15.1667C25.3333 15.9167 24.75 16.5 24 16.5C23.25 16.5 22.6667 15.9167 22.6667 15.1667C22.6667 14.4167 23.25 13.8333 24 13.8333ZM9.33333 21.8333C7.60938 21.8333 6.14063 22.9583 5.58333 24.5H0V27.1667H5.58333C6.14063 28.7083 7.60938 29.8333 9.33333 29.8333C11.0573 29.8333 12.526 28.7083 13.0833 27.1667H32V24.5H13.0833C12.526 22.9583 11.0573 21.8333 9.33333 21.8333ZM9.33333 24.5C10.0833 24.5 10.6667 25.0833 10.6667 25.8333C10.6667 26.5833 10.0833 27.1667 9.33333 27.1667C8.58333 27.1667 8 26.5833 8 25.8333C8 25.0833 8.58333 24.5 9.33333 24.5Z"
            fill="white"
          />
        </svg>
      </div>
      <div class="flex flex-col gap-4">
        <h2 class="text-title-h2 my-0">
          You have reached the end of your recommended roles
        </h2>
        <p class="my-0 mb-0">
          We make recommendations based on your preferences and personal
          experience. The more information we have, the better our suggestions.
          To ensure you see all the most relevant roles, please update your
          information below. Otherwise, keep scrolling to see more open roles.
        </p>
        <div class="flex flex-col text-center md:flex-row gap-4">
          <router-link
            class="block button-secondary text-cta-primary text-grey-900 bg-ivory-100"
            to="/account/profile"
            >Update Profile</router-link
          >
          <router-link
            @click="reportReconfigureClick"
            class="block button-secondary text-cta-primary text-grey-900 hover:bg-ivory-300 bg-ivory-100"
            to="/reconfigure"
            >Update Preferences</router-link
          >
        </div>
      </div>
    </div>
  </div>
  <roles-list-section-header :notifiable="false" />
</template>

<script lang="ts">
import RolesListSectionHeader from "@/components/roles/RolesListSectionHeader.vue";
import NoRecommendedRolesBanner from "@/components/roles/NoRecommendedRolesBanner.vue";

export default {
  name: "NotifiableSeparatorBanner",
  props: {
    zeroNotifiableRoles: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  components: {
    RolesListSectionHeader,
    NoRecommendedRolesBanner,
  },
  methods: {
    reportReconfigureClick() {
      window.analytics.track(
        "Reconfigure options clicked from NotifiableSeparator Banner"
      );
    },
  },
};
</script>
