<template>
  <div class="h-8 w-8 rounded cursor-pointer" :class="classes">
    <div class="transition-transform flex items-center justify-center h-8 w-8">
      <span
        class="material-icons-round transition-transform"
        :class="isOpen && 'rotate-180'"
        >expand_more</span
      >
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "BaseAccordionToggle",
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    classes(): string {
      return this.isOpen
        ? "text-grey-700 bg-grey-100  hover:bg-grey-200"
        : "bg-sage-600  hover:bg-sage-700 text-white";
    },
  },
});
</script>
