<template>
  <div class="relative flex flex-col items-center group/tooltip">
    <slot></slot>
    <div
      class="absolute -top-14 flex flex-col items-center hidden group-hover/tooltip:flex"
    >
      <span
        class="relative z-10 p-2 rounded-lg text-labelling-active whitespace-no-wrap text-ivory-50 bg-grey-900 shadow-lg whitespace-nowrap"
        >{{ tooltipText }}</span
      >
      <div class="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "IconWithTooltip",

  props: {
    tooltipText: {
      type: String,
      required: true,
    },
  },
};
</script>
