<template>
  <div v-if="text" :class="containerClasses">
    <div :class="textClasses" ref="textRef" @click="showText">
      <p v-html="DOMPurify.sanitize(text)"></p>
    </div>
    <div
      class="md:text-labelling-active text-sage-700 whitespace-nowrap cursor-pointer hover:underline"
      @click="toggleText"
      v-if="isTextClamped"
    >
      <span v-if="isHidingText">See more</span>
      <span v-else>See less</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import DOMPurify from "dompurify";
import { ref, computed, onMounted, onUnmounted, Ref, watchEffect } from "vue";

const props = defineProps<{
  text: string | undefined;
  refsToWatch: Array<Ref>;
  classes: string | undefined;
}>();

const isHidingText = ref(true);
const textRef = ref(null);
const isTextClamped = ref(false);

const checkTextClamped = () => {
  if (textRef.value?.scrollHeight > textRef.value?.clientHeight) {
    isTextClamped.value = true;
  } else {
    isTextClamped.value = false;
  }
};

checkTextClamped();

onMounted(() => {
  window.addEventListener("resize", checkTextClamped);
});

onUnmounted(() => {
  window.removeEventListener("resize", checkTextClamped);
});

watchEffect(() => {
  props.refsToWatch;
  setTimeout(checkTextClamped, 100);
});

const containerClasses = computed(() => ({
  "mt-3": true,
  "flex items-end": isHidingText.value,
}));

const textClasses = computed(() => [
  {
    "line-clamp-2": isHidingText.value,
  },
  props.classes,
]);

const showText = () => {
  if (isTextClamped.value) {
    isHidingText.value = false;
  }
};

const toggleText = () => {
  if (isTextClamped.value) {
    isHidingText.value = !isHidingText.value;
  }
};
</script>

<style scoped>
h2,
:deep(h2) {
  @apply mt-12 mb-4;
}

p,
:deep(p) {
  @apply mb-4;
}

ul,
:deep(ul) {
  @apply list-disc ml-6 mb-8;
}

li,
:deep(li) {
  @apply list-disc mb-1;
}

h6,
:deep(h6) {
  @apply mt-12;
}

hr,
:deep(hr) {
  @apply border-solid md:my-6 lg:my-10;
}

/* Typography */
p,
:deep(p),
li,
:deep(li),
ul,
:deep(ul) {
  @apply text-lg;
  line-height: 26px;
}
</style>
