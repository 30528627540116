<template>
  <div
    class="cursor-pointer my-1 py-2.5 outline-none w-full flex items-center"
    :data-cy="dataCy"
  >
    <div class="flex items-center">
      <base-avatar :individual="individual" size="40" altText="Profile Image" />
    </div>
    <div class="pl-4 flex-grow">
      {{ label }}
    </div>
    <span v-if="isOpen" class="material-icons-round">keyboard_arrow_up</span>
    <span v-else class="material-icons-round">keyboard_arrow_down</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BaseAvatar from "./BaseAvatar.vue";

export default defineComponent({
  name: "MobileProfileButton",
  components: {
    BaseAvatar,
  },
  props: {
    label: { type: String },
    dataCy: { type: String },
    individual: { required: true },
    isOpen: { type: Boolean, default: false },
  },
});
</script>
