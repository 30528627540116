<template>
  <div style="height: 444px">
    <!-- Position -->
    <div class="h-14 bg-grey-50 animate-pulse w-full rounded-full mt-8"></div>

    <!-- Organisation -->
    <div class="h-14 bg-grey-50 animate-pulse w-full rounded-full mt-8"></div>

    <!-- Start date -->
    <div class="w-full grid grid-cols-2 gap-3 mt-8">
      <div class="h-14 bg-grey-50 animate-pulse w-full rounded-full"></div>
      <div class="h-14 bg-grey-50 animate-pulse w-full rounded-full"></div>
    </div>

    <!-- End date unknown? -->
    <div class="h-14 bg-grey-50 animate-pulse w-full rounded-full mt-8"></div>

    <!-- Save or cancel -->
    <div class="h-14 bg-grey-50 animate-pulse w-full rounded-full mt-8"></div>
  </div>
</template>

<script lang="ts">
export default {
  name: "EditRoleHeldFormSkeleton",
};
</script>
