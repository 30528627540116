<template>
  <div
    v-if="saveSuccess || isSaving || saveFailure"
    class="rounded-lg max-w-max max-h-max p-1 px-3"
    :class="{
      'bg-sage-50': saveSuccess,
      'bg-orange-50': isSaving,
      'bg-red-50': saveFailure,
    }"
    data-cy="role-apply-save-status"
  >
    <div v-if="isSaving" class="flex items-center text-orange-600">
      <span class="material-icons-round mr-2 animate animate-spin">
        autorenew
      </span>
      <span class="text-nudging"> Saving </span>
    </div>
    <div v-else-if="saveSuccess" class="flex items-center text-sage-600">
      <span class="material-icons-round mr-2 text-sm my-auto"> check </span>
      <span class="text-nudging"> Saved </span>
    </div>
    <div v-else-if="saveFailure" class="flex items-center text-red-400">
      <span class="material-icons-round mr-2"> error </span>
      <span class="text-nudging"> Saving issue </span>
    </div>
  </div>
</template>

<script lang="ts">
import useRoleApplication from "@/composables/useRoleApplication";

export default {
  setup() {
    const { isSaving, saveSuccess, saveFailure } = useRoleApplication();

    return {
      isSaving,
      saveSuccess,
      saveFailure,
    };
  },
};
</script>

<style></style>
