import { apiAxios } from "@/lib/axios";
import { ref } from "vue";
import debounce from "@/utils/debounce";

const roleId = ref(null);
const roleApplication = ref({});
const isSaving = ref(false);
const saveSuccess = ref(false);
const saveFailure = ref(false);

const hideSavingState = debounce(function () {
  isSaving.value = false;
}, 500);

export default function useRoleApplication() {
  const setRoleId = id => {
    roleId.value = id;
  };

  const getLatestRoleApplication = async () => {
    const { data } = await apiAxios.get(`/latest-application/${roleId.value}`);
    roleApplication.value = data;
  };

  const createNewApplication = async () => {
    const { data } = await apiAxios.post(`/apply/${roleId.value}`, {
      role_id: roleId.value,
    });

    roleApplication.value = data;
  };

  const updateRoleApplication = async updatedFields => {
    isSaving.value = true;
    roleApplication.value = { ...roleApplication.value, ...updatedFields };
    try {
      await apiAxios.put(`/apply/${roleId.value}`, updatedFields);
      saveFailure.value = false;
      saveSuccess.value = true;
    } catch {
      saveSuccess.value = false;
      saveFailure.value = true;
    }

    hideSavingState();
  };

  return {
    roleId,
    roleApplication,
    isSaving,
    saveSuccess,
    saveFailure,
    setRoleId,
    getLatestRoleApplication,
    updateRoleApplication,
    createNewApplication,
  };
}
