<template>
  <span class="error-message bg-red-50 rounded-lg flex copy-bold">
    <span
      class="material-icons-round text-red-600 bg-red-100 rounded-l-lg p-2 flex justify-center items-center"
    >
      error
    </span>
    <span class="p-2 px-4"> {{ message }} </span>
  </span>
</template>

<script lang="ts">
export default {
  name: "ErrorMessage",

  props: {
    message: {
      type: String,
      default: null,
    },
  },
};
</script>
