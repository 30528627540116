import { ref } from "vue";
import { v4 as uuidv4 } from "uuid";
import { apiAxios } from "@/lib/axios";
import * as Sentry from "@sentry/browser";

const isDoingLookup = ref(false);

export default function useBoardexLookup() {
  const lookupIndividualInBoardex = async (name, roles, wrongIds) => {
    if (isDoingLookup.value) return;
    isDoingLookup.value = true;

    try {
      const { data } = await apiAxios.post(
        "/boardex/profile",
        formatBoardexLookupBody(name, roles, wrongIds)
      );

      return (
        data && {
          ...data,
          roles: formatBoardexRoles(data.roles),
        }
      );
    } catch (error) {
      if (error.response?.status !== 404) Sentry.captureException(error);
    }
    isDoingLookup.value = false;
  };

  const formatBoardexLookupBody = (name, roles, wrongIds) => {
    const mappedRoles = roles.map(role => ({
      title: role.title || "",
      organisation: role.organisation?.name,
      from: formatDateForBoardexLookup(role.from),
      to: formatDateForBoardexLookup(role.to),
    }));

    return {
      name,
      roles_held: mappedRoles,
      incorrect_ids: wrongIds,
    };
  };

  const formatBoardexRoles = roles => {
    if (!roles) return [];

    const boardexIndividualRoles = roles.map(role => {
      return {
        id: uuidv4(),
        position: role.roleName,
        organisation: role.organisation,
        from: role.roleStartDate,
        to: role.roleEndDate,
        isCurrent: role.isCurrent,
        isRoleFromBoardexLookup: true,
        endDateUnknown: !role.roleEndDate,
        organisationId: role.organisation.id,
      };
    });

    const sortedCurrentRoles = boardexIndividualRoles
      .filter(role => role.isCurrent)
      .sort((a, b) => {
        const firstStartDate = new Date(
          b.from?.split("-")[0] || 0,
          b.from?.split("-")[1] || 0
        );
        const secondStartDate = new Date(
          a.from?.split("-")[0] || 0,
          a.from?.split("-")[1] || 0
        );

        return firstStartDate.getTime() - secondStartDate.getTime();
      });

    const sortedHistoricRoles = boardexIndividualRoles
      .filter(role => !role.isCurrent)
      .sort((a, b) => {
        const firstEndDate = new Date(
          b.to?.split("-")[0] || 0,
          b.to?.split("-")[1] || 0
        );
        const secondEndDate = new Date(
          a.to?.split("-")[0] || 0,
          a.to?.split("-")[1] || 0
        );

        return firstEndDate.getTime() - secondEndDate.getTime();
      });

    return [...sortedCurrentRoles, ...sortedHistoricRoles];
  };

  const formatDateForBoardexLookup = date => {
    if (!date || !date.year) return null;

    if (!date.month) return `${date.year}`;

    return `${date.year}-${date.month < 10 ? "0" + date.month : date.month}`;
  };

  return {
    lookupIndividualInBoardex,
  };
}
