interface PunchThroughTokenAndExpiry {
  value: string;
  expiry: number;
}

export class PunchThrough {
  static removeToken() {
    localStorage.removeItem("pt");
  }

  static getToken() {
    const punchThroughTokenAndExpiryString = localStorage.getItem("pt");
    if (!punchThroughTokenAndExpiryString) {
      return undefined;
    }
    const token: PunchThroughTokenAndExpiry = JSON.parse(
      punchThroughTokenAndExpiryString
    );
    if (new Date().getTime() > token.expiry) {
      this.removeToken();
      window.location.replace("/signin");
      return undefined;
    }
    this.refreshToken();
    return token.value;
  }

  static refreshToken() {
    const punchThroughTokenAndExpiryString = localStorage.getItem("pt");
    if (!punchThroughTokenAndExpiryString) {
      return;
    }
    const token: PunchThroughTokenAndExpiry = JSON.parse(
      punchThroughTokenAndExpiryString
    );
    token.expiry = new Date().getTime() + 30 * 60 * 1000; // 30 minutes from now
    localStorage.setItem("pt", JSON.stringify(token));
  }

  static harvestTokenFromURL() {
    const searchParams = new URLSearchParams(window.location.search);
    const ptValue = searchParams.get("pt");
    if (ptValue) {
      const punchThroughTokenAndExpiry: PunchThroughTokenAndExpiry = {
        value: ptValue,
        expiry: new Date().getTime() + 30 * 60 * 1000, // Adding 30 minutes to current time
      };
      localStorage.setItem("pt", JSON.stringify(punchThroughTokenAndExpiry));
      searchParams.delete("pt");
      window.location.search = searchParams.toString();
    }
  }
}
