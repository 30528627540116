<template>
  <svg viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 0.800049V39.2H29.5385V10.5385L29.1231 10.077L20.2615 1.21543L19.8 0.800049H0ZM2.95385 3.75389H17.7231V12.6154H26.5846V36.2462H2.95385V3.75389ZM20.6769 5.87697L24.4615 9.66159H20.6769V5.87697ZM7.38461 15.5693V18.5231H22.1538V15.5693H7.38461ZM7.38461 21.477V24.4308H22.1538V21.477H7.38461ZM7.38461 27.3847V30.3385H22.1538V27.3847H7.38461Z"
      class="fill-current"
      :class="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "text-grey-900",
    },
  },
};
</script>
