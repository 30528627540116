<template>
  <div
    class="flex flex-col items-center justify-start flex-grow w-full bg-white my-0 md:my-6"
  >
    <div
      class="border-solid border-r-0 border-l-0 border-t-0 w-full md:pt-5 px-5 md:px-24"
    >
      <h2 class="text-title-h2 text-left">
        {{ title }}
      </h2>
    </div>

    <div class="w-full px-5 md:px-24 pb-6">
      <h2 class="copy-emphasis pt-10 pb-2">
        Choose your interest in education roles
      </h2>
      <interest-level-radio-buttons
        :interest-options="interestOptions"
        :interest-level="individual?.education_interest_level"
        @update-interest-level="updateInterestLevel"
        ref="interestLevelRadioButtons"
      />
    </div>

    <div v-if="isInterested">
      <div class="flex flex-col justify-start w-full px-5 md:px-24">
        <h2 class="copy-emphasis text-left">
          What age range are you interested in?
        </h2>
        <div
          class="flex flex-col items-start xs:flex-row flex-wrap xs:items-center bg-white"
        >
          <tag
            v-for="[tag, label] in agesTags"
            :key="tag"
            :tag="tag"
            :label="label"
            :is-selected="isSelected(tag)"
            @update-tag="updateTag"
            @track-analytics="trackTagUpdate"
          />
        </div>
        <div v-if="!hasAtLeast1AgeSelected && showValidation" class="pt-5">
          <error-message
            message="Please select at least 1 age range"
            class="mb-0"
          />
        </div>
      </div>

      <div
        v-if="showSchoolsSection"
        class="flex flex-col justify-start w-full px-5 md:px-24"
      >
        <h2 class="copy-emphasis mt-12 text-left">
          What type of schools interest you?
        </h2>
        <div
          class="flex flex-col items-start xs:flex-row flex-wrap xs:items-center bg-white"
        >
          <tag
            v-for="[tag, label] in schoolsTags"
            :key="tag"
            :tag="tag"
            :label="label"
            :is-selected="isSelected(tag)"
            @update-tag="updateTag"
            @track-analytics="trackTagUpdate"
          />
        </div>
      </div>

      <div class="flex flex-col justify-start w-full px-5 md:px-24">
        <h2 class="copy-emphasis mt-12 text-left">
          If you are from the education sector, what type of educational
          experience do you bring?
        </h2>
        <div
          class="flex flex-col items-start xs:flex-row flex-wrap xs:items-center bg-white"
        >
          <tag
            v-for="[tag, label] in experienceTags"
            :key="tag"
            :tag="tag"
            :label="label"
            :is-selected="isSelected(tag)"
            @update-tag="updateTag"
            @track-analytics="trackTagUpdate"
          />
        </div>
      </div>

      <div class="w-full mt-10 px-5 md:px-24">
        <div class="border-solid border-r-0 border-l-0 border-b-0">
          <h2 class="copy-emphasis mt-12">
            Are there any schools or institutions that you have a particular
            interest in or connection to?
          </h2>
          <textarea
            v-model="preferencesText"
            @input="onTextUpdated"
            placeholder="Your text here"
            class="textarea w-full mt-6 mb-16"
            rows="3"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import debounce from "@/utils/debounce";
import { Individual } from "@/types/Individual";
import { PreferenceTag } from "@/types/PreferenceTag";
import preferenceTags from "@/assets/preference_tags.json";
import { PropType } from "vue";
import InterestLevelRadioButtons from "@/components/onboarding/InterestLevelRadioButtons.vue";
import Tag from "@/components/onboarding/Tag.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";

type Data = {
  preferencesText: string;
  selectedTags: PreferenceTag;
  showValidation: boolean;
};

const EDUCATION_TAGS = preferenceTags["education"];

export default {
  name: "EducationPreferences",

  components: {
    Tag,
    InterestLevelRadioButtons,
    ErrorMessage,
  },

  emits: [
    "update-individual-and-put",
    "set-on-finish-step-callback",
    "track-analytics",
  ],

  props: {
    title: {
      type: String,
    },
    individual: {
      type: Object as PropType<Individual>,
    },
  },

  data(): Data {
    return {
      selectedTags: this.individual?.preference_tags || {},
      preferencesText: "",
      showValidation: false,
    };
  },

  computed: {
    isInterested() {
      const interestLevel = this.individual.education_interest_level;
      const isInterestLevelDefined = interestLevel !== null;
      return isInterestLevelDefined ? interestLevel > 0 : false;
    },

    interestOptions(): string[] {
      return [
        "Not at all interested",
        "Possibly interested, but low capacity",
        "Interested, but only if it's a good match",
        "As interested in education as compensated roles",
        "More interested in education than compensated roles",
      ];
    },

    agesTags(): [string, string][] {
      return Object.entries(EDUCATION_TAGS["ages"]["subItems"]);
    },

    schoolsTags(): [string, string][] {
      return Object.entries(EDUCATION_TAGS["schools"]["subItems"]);
    },

    experienceTags(): [string, string][] {
      return Object.entries(EDUCATION_TAGS["experience_type"]["subItems"]);
    },

    showSchoolsSection() {
      return (
        this.selectedTags["primary_education"] ||
        this.selectedTags["secondary_education"]
      );
    },

    hasAtLeast1AgeSelected() {
      const ageTagNames = Object.keys(EDUCATION_TAGS["ages"]["subItems"]);
      return ageTagNames.some(tag => this.selectedTags[tag]);
    },
  },

  created() {
    this.preferencesText = this.individual.education_passions;
    this.$emit("set-on-finish-step-callback", this.isValid);
  },

  methods: {
    onTextUpdated: debounce(function () {
      this.$emit("update-individual-and-put", {
        education_passions: this.preferencesText,
      });
    }, 1000),

    updateInterestLevel(interestLevel: number) {
      this.$emit("update-individual-and-put", {
        education_interest_level: interestLevel,
        new_onboarding: true,
      });
    },

    updateTag(tag: string) {
      this.selectedTags[tag] = !this.selectedTags[tag];
      this.updateTags();
    },

    updateTags: debounce(function () {
      this.$emit("update-individual-and-put", {
        preference_tags: this.selectedTags,
      });
    }, 1200),

    trackTagUpdate(data) {
      this.$emit("track-analytics", "preference tag update", {
        type: "education",
        ...data,
      });
    },

    isSelected(tag: string): boolean {
      return !!this.selectedTags[tag];
    },

    async hasValidAgeAndInterest() {
      this.showValidation = true;

      if (this.isInterested && !this.hasAtLeast1AgeSelected) {
        await this.$nextTick();
        const errorElement =
          document.getElementsByClassName("error-message")[0];

        errorElement?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return false;
      } else {
        return true;
      }
    },

    async isValid() {
      if (this.$refs.interestLevelRadioButtons.levelSelected) {
        return this.isInterested ? this.hasValidAgeAndInterest() : true;
      } else {
        return this.$refs.interestLevelRadioButtons.isValid();
      }
    },
  },
};
</script>
