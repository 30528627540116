import { RoleType } from "@/lib/RoleTyper";

const RECONFIGURE_URL = "/reconfigure";
const EDUCATION_RECONFIGURE_URL = RECONFIGURE_URL + "/education";
const PROBONO_RECONFIGURE_URL = RECONFIGURE_URL + "/pro-bono?q=return_to_roles";
import router from "./theVueRouter";

export class RoleNudgingHelper {
  public determineNudgingButtonText(roleTypes: RoleType[]): string {
    if (roleTypes.includes("education")) return "Update education preferences";
    if (roleTypes.includes("pro-bono")) return "Update pro bono preferences";
    return "Update preferences";
  }

  public getPreferencesUrl(roleTypes: RoleType[]): string {
    let url = "";

    if (this.isEducationRole(roleTypes)) {
      url = EDUCATION_RECONFIGURE_URL;
    } else if (this.isProBonoRole(roleTypes)) {
      url = PROBONO_RECONFIGURE_URL;
    } else {
      url = RECONFIGURE_URL;
    }

    return url;
  }

  public async navigateToPreferences(roleTypes: RoleType[]): Promise<void> {
    if (this.isEducationRole(roleTypes)) {
      await router.push("/reconfigure/education");
    } else if (this.isProBonoRole(roleTypes)) {
      await router.push("/reconfigure/pro-bono?q=return_to_roles");
    }
  }

  public isEducationRole(roleTypes: RoleType[]): boolean {
    return roleTypes.includes("education");
  }

  public isProBonoRole(roleTypes: RoleType[]): boolean {
    return roleTypes.includes("pro-bono");
  }

  public setNudgingAnalytics(
    roleTypes: RoleType[],
    organisationId?: string,
    roleId?: string
  ): void {
    let roleType = "Not defined";
    if (roleTypes.includes("education")) roleType = "education";
    if (roleTypes.includes("pro-bono")) roleType = "pro-bono";

    if (organisationId) {
      window.analytics.track(
        "Navigated to preferences from role nudging footer",
        {
          organisationId: organisationId,
          roleType: roleType,
        }
      );
    } else if (roleId) {
      window.analytics.track(
        "Navigated to preferences from role nudging banner",
        {
          roleId: roleId,
          roleType: roleType,
        }
      );
    }
  }
}
