<template>
  <h3 class="text-divider mb-2">PROFESSIONAL EXPERIENCE</h3>
  <quick-apply-profile-check />

  <h3 class="text-divider mt-8 mb-2">SELECT CV</h3>
  <quick-apply-select-cv @select-cv="selectCv" />

  <div class="flex justify-end mt-8">
    <button
      @click="$emit('next')"
      class="button-primary text-cta-primary"
      :disabled="rolesHeld.length < 3"
    >
      Next
    </button>
  </div>
</template>

<script setup lang="ts">
import { apiAxios } from "@/lib/axios";
import { quickApplication, refreshCvs } from "@/lib/quickApply";
import { getIndividual } from "@/composables/useIndividual";
import { onBeforeUnmount, onMounted } from "vue";
import { getRolesHeld, rolesHeld } from "@/composables/useMiniCv";
import QuickApplyProfileCheck from "./QuickApplyProfileCheck.vue";
import QuickApplySelectCv from "./QuickApplySelectCv.vue";

defineEmits(["next"]);

const props = defineProps(["role"]);

const refreshData = () => {
  getIndividual();
  refreshCvs();
  getRolesHeld();
};

async function selectCv({ id, filename, location }) {
  const cvData = {
    cv_id: id,
    cv_filename: filename,
    cv_location: location,
  };

  Object.assign(quickApplication, cvData);
  await apiAxios.put(`/apply/${props.role.id}`, cvData);
}

onMounted(() => {
  refreshData();
  window.addEventListener("focus", refreshData);
});

onBeforeUnmount(() => {
  window.removeEventListener("focus", refreshData);
});
</script>
