<template>
  <div class="relative transition-all">
    <deleted-role-held v-if="role.isDeleted" :deleted-role="role" />

    <div
      v-else
      data-cy="mini-cv-role"
      class="p-4 md:p-6 rounded-lg my-3 hover:shadow-md group/pinrole"
      :class="{
        'border border-solid border-red-600': isMissingAnything,
        'border border-solid border-t-0 border-r-0 border-l-0 border-grey-100':
          !isMissingAnything,
        'bg-gray-75': isPinned,
      }"
    >
      <div
        class="text-grey-600 flex items-center mb-2 text-labelling-inactive"
        v-if="isPinned"
      >
        <span class="material-icons-round text-grey-600 text-sm">
          push_pin</span
        >
        <div class="ml-3">FEATURED</div>
      </div>
      <div
        class="text-grey-600 flex items-center mb-2 text-labelling-inactive"
        v-if="!isPinned && isCurrent"
      >
        <span class="material-icons-round text-grey-600 text-sm">
          event_note</span
        >
        <div class="ml-3">CURRENT ROLE</div>
      </div>
      <div
        v-if="isMissingPosition"
        class="rounded-lg px-2 py-1 bg-red-50 text-red-600 copy-bold w-max"
      >
        Missing position
      </div>
      <div
        v-else
        class="copy-emphasis group-hover:underline underline-offset-4"
        data-cy="role-held-position"
      >
        {{ role.position }}
      </div>
      <div
        v-if="isMissingOrganisation"
        class="mt-3 rounded-lg px-2 py-1 bg-red-50 text-red-600 copy-bold w-max"
      >
        Missing organisation
      </div>
      <div v-else class="mt-3 group-hover:underline underline-offset-2">
        {{ role.organisation }}
      </div>
      <div
        v-if="isMissingDates"
        class="mt-3 rounded-lg px-2 py-1 bg-red-50 text-red-600 copy-bold w-max"
      >
        Missing dates
      </div>
      <div
        class="mt-1 group-hover:underline underline-offset-2"
        data-cy="role-held-dates"
        v-else
      >
        {{ getDisplayDates(role) }}
      </div>
      <clamped-text
        v-if="showOrgDescription"
        :text="role.organisation_description"
        :refs-to-watch="[rolesHeld as any]"
      ></clamped-text>
      <div class="md:flex mt-2">
        <div class="md:flex-1 flex flex-wrap items-center">
          <template v-if="sectorBadges.length">
            <button
              v-for="badge in sectorBadges"
              :key="badge"
              class="whitespace-nowrap text-nudging rounded px-3 py-1 mr-2 mt-2 badge"
              :class="{ 'bg-grey-75': !isPinned, 'bg-white': isPinned }"
              data-organisation-badge
              @click="editOrganisationData('sector')"
            >
              <span>
                {{ badge }}
              </span>
              <span class="material-icons-round text-sm ml-1 leading-5">
                edit
              </span>
            </button>
          </template>
          <div
            v-else
            class="whitespace-nowrap text-nudging border rounded px-3 py-1 mr-2 mt-2 text-sage-700 cursor-pointer missing-data-badge"
            data-organisation-badge
            @click="editOrganisationData('sector')"
          >
            Add sector
          </div>
          <template v-if="ownershipBadges.length">
            <button
              v-for="badge in ownershipBadges"
              :key="badge"
              class="whitespace-nowrap text-nudging rounded px-3 py-1 mr-2 mt-2 badge"
              :class="{ 'bg-grey-75': !isPinned, 'bg-white': isPinned }"
              data-organisation-badge
              @click="editOrganisationData('ownership')"
            >
              <span>
                {{ badge }}
              </span>
              <span class="material-icons-round text-sm ml-1 leading-5">
                edit
              </span>
            </button>
          </template>
          <div
            v-else
            class="whitespace-nowrap text-nudging border rounded px-3 py-1 mr-2 mt-2 text-sage-700 cursor-pointer missing-data-badge"
            data-organisation-badge
            @click="editOrganisationData('ownership')"
          >
            Add ownership
          </div>
          <template v-if="sizeBadges.length">
            <button
              v-for="badge in sizeBadges"
              :key="badge"
              class="whitespace-nowrap text-nudging rounded px-3 py-1 mr-2 mt-2 badge"
              :class="{ 'bg-grey-75': !isPinned, 'bg-white': isPinned }"
              data-organisation-badge
              @click="editOrganisationData('size')"
            >
              <span>
                {{ badge }}
              </span>
              <span class="material-icons-round text-sm ml-1 leading-5">
                edit
              </span>
            </button>
          </template>
          <div
            v-else
            class="whitespace-nowrap text-nudging border rounded px-3 py-1 mr-2 mt-2 text-sage-700 cursor-pointer missing-data-badge"
            data-organisation-badge
            @click="editOrganisationData('size')"
          >
            Add size
          </div>
          <div
            class="whitespace-nowrap text-nudging border rounded px-3 py-1 mr-2 mt-2 text-sage-700 cursor-pointer missing-data-badge"
            data-organisation-badge
            @click="addOrganisationDescription"
            v-if="showOrgDescription && !role.organisation_description"
          >
            Add description
          </div>
        </div>
        <div
          class="flex space-x-2 justify-between hidden xs:justify-start md:flex md:flex-row w-full sm:w-auto mt-6 md:mt-0"
        >
          <button
            v-if="allowDelete"
            class="text-cta-tertiary text-red-600 flex items-center mr-4"
            @click.stop.prevent="$emit('delete-role-held-with-undo', role)"
          >
            <span class="material-icons-round mr-2 text-base"> delete </span>
            Delete
          </button>
          <button
            class="text-cta-tertiary text-sage-700 flex items-center"
            @click="$emit('open-role-held', role)"
          >
            <span class="material-icons-round mr-2 text-base"> edit </span>
            Edit
          </button>
        </div>

        <!-- Slot used for toggling selected role on applications -->
        <slot v-bind="role"></slot>
      </div>
    </div>
    <button
      class="absolute top-4 right-4 inline-block md:hidden"
      @click="isMobileActionsOpen = true"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
          fill="#F7F7F7"
        />
        <path
          d="M12 8C13.1 8 14 7.1 14 6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6C10 7.1 10.9 8 12 8ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM12 16C10.9 16 10 16.9 10 18C10 19.1 10.9 20 12 20C13.1 20 14 19.1 14 18C14 16.9 13.1 16 12 16Z"
          fill="#565958"
        />
      </svg>
    </button>
  </div>
  <the-modal
    v-if="isMobileActionsOpen"
    :easyToClose="true"
    @close-modal="isMobileActionsOpen = false"
  >
    <template #header>
      <h2 class="copy-emphasis sm:text-title-h2">{{ role.position }}</h2>
    </template>

    <div class="flex flex-col gap-6 mb-8">
      <button
        class="button-primary text-cta-primary flex justify-center items-center gap-2 w-full md:w-auto"
        @click="$emit('open-role-held', role)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M1.99902 11.6409V13.6676C1.99902 13.8543 2.14569 14.0009 2.33236 14.0009H4.35902C4.44569 14.0009 4.53236 13.9676 4.59236 13.9009L11.8724 6.6276L9.37236 4.1276L2.09902 11.4009C2.03236 11.4676 1.99902 11.5476 1.99902 11.6409ZM13.8057 4.69427C14.0657 4.43427 14.0657 4.01427 13.8057 3.75427L12.2457 2.19427C11.9857 1.93427 11.5657 1.93427 11.3057 2.19427L10.0857 3.41427L12.5857 5.91427L13.8057 4.69427Z"
            fill="white"
          />
        </svg>
        Edit
      </button>

      <button
        v-if="allowPin"
        class="text-cta-secondary button-secondary flex items-center justify-center gap-2 w-full md:w-auto"
        @click="togglePin()"
        :disabled="isPinLoading"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.6668 8.57992C12.6668 8.26659 12.4402 8.01325 12.1335 7.92659C11.2868 7.69325 10.6668 6.91992 10.6668 5.99992V2.66659H11.3335C11.7002 2.66659 12.0002 2.36659 12.0002 1.99992C12.0002 1.63325 11.7002 1.33325 11.3335 1.33325H4.66683C4.30016 1.33325 4.00016 1.63325 4.00016 1.99992C4.00016 2.36659 4.30016 2.66659 4.66683 2.66659H5.3335V5.99992C5.3335 6.91992 4.7135 7.69325 3.86683 7.92659C3.56016 8.01325 3.3335 8.26659 3.3335 8.57992V8.66659C3.3335 9.03325 3.6335 9.33325 4.00016 9.33325H7.32016L7.3335 13.9999C7.3335 14.3666 7.6335 14.6666 8.00016 14.6666C8.36683 14.6666 8.66683 14.3666 8.66683 13.9999L8.6535 9.33325H12.0002C12.3668 9.33325 12.6668 9.03325 12.6668 8.66659V8.57992Z"
            fill="#3C6955"
          />
        </svg>
        {{ isPinned ? "UnPin role" : "Pin role" }}
      </button>

      <button
        v-if="allowDelete"
        class="text-cta-secondary button-secondary text-red-600 flex items-center justify-center gap-2 w-full md:w-auto border-red-600 hover:bg-red-50"
        @click.stop.prevent="$emit('delete-role-held-with-undo', role)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M4.00016 12.6667C4.00016 13.4 4.60016 14 5.3335 14H10.6668C11.4002 14 12.0002 13.4 12.0002 12.6667V6C12.0002 5.26667 11.4002 4.66667 10.6668 4.66667H5.3335C4.60016 4.66667 4.00016 5.26667 4.00016 6V12.6667ZM12.0002 2.66667H10.3335L9.86016 2.19333C9.74016 2.07333 9.56683 2 9.3935 2H6.60683C6.4335 2 6.26016 2.07333 6.14016 2.19333L5.66683 2.66667H4.00016C3.6335 2.66667 3.3335 2.96667 3.3335 3.33333C3.3335 3.7 3.6335 4 4.00016 4H12.0002C12.3668 4 12.6668 3.7 12.6668 3.33333C12.6668 2.96667 12.3668 2.66667 12.0002 2.66667Z"
            fill="#DF2211"
          />
        </svg>
        Delete
      </button>
    </div>
  </the-modal>
</template>

<script lang="ts" setup>
import { getDisplayDates, isRoleHeldCurrent } from "@/helpers/miniCv";
import { PropType, computed, ref } from "vue";
import { RoleHeld, rolesHeld } from "@/composables/useMiniCv";
import DeletedRoleHeld from "./miniCv/DeletedRoleHeld.vue";
import getOrganisationSize from "@/helpers/getOrganisationSize";
import useRoleHeldPins from "@/composables/useRoleHeldPins";
import TheModal from "./TheModal.vue";
import ClampedText from "./shared/ClampedText.vue";

const emit = defineEmits([
  "open-role-held",
  "delete-role-held-with-undo",
  "open-org-data-modal",
]);

const props = defineProps({
  role: {
    type: Object as PropType<RoleHeld>,
    required: true,
  },
  allowDelete: {
    type: Boolean,
    required: false,
    default: true,
  },
  allowPin: {
    type: Boolean,
    required: false,
    default: false,
  },
  showOrgDescription: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const { pins, pinRole, unpinRole } = useRoleHeldPins();
const isMobileActionsOpen = ref(false);

const isCurrent = computed(() => {
  return isRoleHeldCurrent(props.role);
});

const isPinned = computed(() => {
  return pins.value.has(props.role.id);
});

const isPinLoading = ref(false);

const togglePin = async () => {
  isPinLoading.value = true;
  if (isPinned.value) {
    await unpinRole(props.role.id);
  } else {
    await pinRole(props.role.id);
  }
  isPinLoading.value = false;
  isMobileActionsOpen.value = false;
};

const addOrganisationDescription = () => {
  emit("open-role-held", props.role);
  setTimeout(() => {
    const input = document.getElementById("organisationDescription");
    if (input) {
      input.scrollIntoView();
      input.focus();
    }
  });
};

const editOrganisationData = (button: string) => {
  emit("open-org-data-modal", props.role, button);
};

const isMissingAnything = computed(() => {
  return (
    isMissingDates.value ||
    isMissingPosition.value ||
    isMissingOrganisation.value
  );
});

const isMissingDates = computed(() => {
  return !props.role.from;
});

const isMissingPosition = computed(() => {
  return !props.role.position || props.role.position == "";
});

const isMissingOrganisation = computed(() => {
  return !props.role.organisation || props.role.organisation == "";
});

const sectorBadges = computed(() => {
  return [props.role.organisation_sector].filter(
    badge => badge && badge.length
  );
});

const ownershipBadges = computed(() => {
  return [props.role.organisation_ownership].filter(
    badge => badge && badge.length
  );
});

const sizeBadges = computed(() => {
  return [getOrganisationSize({ size: props.role.organisation_size })].filter(
    badge => badge && badge.length
  );
});
</script>

<style scoped>
.badge {
  border: solid 1px transparent !important;
}

.missing-data-badge {
  border-color: #e8e8e8;
}

.badge:hover,
.missing-data-badge:hover {
  background-color: var(--sage-50, #edf2f1);
  border-color: var(--sage-600, #3c6955) !important;
}
</style>
