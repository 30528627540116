<template>
  <div
    v-if="showPopup"
    class="fixed z-10 left-0 bottom-0 p-8 w-full"
    style="max-width: 660px"
  >
    <div
      class="py-6 px-8 bg-blue-700 text-white rounded-lg flex flex-col md:flex-row items-center"
    >
      <div>
        The Nurole website uses cookies. By using our website you consent to all
        cookies in accordance with our
        <a
          class="text-white underline"
          href="https://www.nurole.com/privacy"
          rel="noopener"
          >Privacy Policy</a
        >
      </div>
      <button
        data-cy="cookie-popup-close"
        @click="acknowledgeCookies"
        class="rounded-full text-white bg-blue-600 px-8 py-4 text-cta-primary ml-3 hover:bg-blue-500 active:bg-blue-600 focus:ring focus:ring-orange-500"
      >
        Okay
      </button>
    </div>
  </div>
</template>

<script lang="ts">
function isInIframe() {
  try {
    return window.self !== window.top;
  } catch (errror) {
    return true;
  }
}

export default {
  name: "CookiesPopup",
  data() {
    return {
      showPopup: false,
    };
  },
  mounted() {
    const welcomePage = window.location.href.match(
      /invite\/[0-9a-f-]+\/welcome/
    );
    const previouslyShown =
      document.cookie.replace(
        /(?:(?:^|.*;\s*)cookieAgreed\s*=\s*([^;]*).*$)|^.*$/,
        "$1"
      ) === "true";
    const searchBot = /bot|googlebot|crawler|spider|robot|crawling/i.test(
      navigator.userAgent
    );
    const prerenderBot = /prerender/i.test(navigator.userAgent);

    this.showPopup = !(
      welcomePage ||
      previouslyShown ||
      searchBot ||
      prerenderBot ||
      isInIframe()
    );
  },
  methods: {
    acknowledgeCookies() {
      document.cookie =
        "cookieAgreed=true; path=/; max-age=630720000; domain=nurole.com;";
      this.showPopup = false;
    },
  },
};
</script>
