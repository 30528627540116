<template>
  <div
    v-if="v$.$errors.length"
    class="scrollable-error rounded-lg px-6 mt-6 flex items-center bg-red-50"
  >
    <tasks-icon color="red" class="mr-2 w-10" />
    <div class="flex flex-col items-start justify-center">
      <span class="copy-emphasis text-grey-800">
        Please fill in your details
      </span>
    </div>
  </div>
  <div class="flex flex-col lg:flex-row mt-4 md:mt-2 gap-4 lg:gap-0">
    <form class="flex flex-col gap-4 w-full md:pt-6 rounded-lg">
      <div class="flex flex-col md:flex-row gap-4">
        <fieldset class="flex-1 flex flex-col gap-3">
          <label for="firstName" class="text-grey-900 text-inputs-title my-0">
            First Name
          </label>
          <input
            type="text"
            id="firstName"
            class="text-input w-full"
            v-model="first_name"
            @blur="saveChanges()"
            placeholder="First Name"
            autocomplete="first-name"
          />
          <span
            v-if="v$.first_name.$errors.length"
            class="text-red-500 text-nudging mb-2"
          >
            Your first name is required
          </span>
        </fieldset>
        <fieldset class="flex-1 flex flex-col gap-3">
          <label for="lastName" class="text-grey-900 text-inputs-title my-0">
            Last Name
          </label>
          <input
            type="text"
            id="lastName"
            class="text-input w-full"
            v-model="last_name"
            @blur="saveChanges()"
            placeholder="Last Name"
            autocomplete="last-name"
          />
          <span
            v-if="v$.last_name.$errors.length"
            class="text-red-500 text-nudging mb-2"
          >
            Your last name is required
          </span>
        </fieldset>
      </div>
      <fieldset class="flex flex-col gap-3">
        <label for="telephone" class="text-grey-900 text-inputs-title my-0">
          Phone number
        </label>
        <input
          type="tel"
          id="telephone"
          class="text-input w-full"
          v-model="telephone"
          placeholder="Enter your phone number"
          data-cy="edit-details-telephone-field"
          autocomplete="tel"
          @blur="saveChanges()"
        />
        <span
          v-if="v$.telephone.$errors.length"
          class="text-red-500 text-nudging mb-2"
        >
          Phone number is not valid
        </span>
      </fieldset>

      <fieldset class="flex flex-col gap-3">
        <label
          for="location-select"
          class="text-inputs-title my-0 text-grey-900"
        >
          Location
        </label>
        <country-dropdown
          :initial-selection="country"
          @selection-made="setCountry"
          class="mb-2 bg-white"
        />
      </fieldset>

      <fieldset class="flex flex-col gap-3">
        <label class="text-grey-900 text-inputs-title my-0" for="linkedin">
          LinkedIn
        </label>
        <linkedin-field
          :initial-value="linkedin"
          @value-changed="setLinkedin"
          class="mb-2 bg-white"
        />
        <span
          v-if="v$.linkedin.$errors.length"
          class="text-red-500 text-nudging mb-2"
        >
          Please use the format linkedin.com/in/username.
        </span>
      </fieldset>
    </form>
  </div>
</template>

<script lang="ts">
import CountryDropdown from "@/components/roleApplication/CountryDropdown.vue";
import LinkedinField from "@/components/roleApplication/LinkedinField.vue";
import TasksIcon from "@/components/roleApplication/TasksIcon.vue";
import useVuelidate from "@vuelidate/core";
import { required, minLength, numeric } from "@vuelidate/validators";
import useIndividual from "@/composables/useIndividual";
const { updateIndividual } = useIndividual();

const linkedUrl = value => !value || value.includes("linkedin.com/in/");

export default {
  components: { LinkedinField, CountryDropdown, TasksIcon },

  props: {
    individual: { required: true },
  },

  data() {
    return {
      first_name: "",
      last_name: "",
      linkedin: "",
      country: "",
      telephone: "",
      saving: false,
      error: false,
    };
  },

  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      linkedin: { linkedUrl },
      telephone: { numeric, minLength: minLength(8) },
      first_name: {
        required,
      },
      last_name: {
        required,
      },
    };
  },

  emits: ["close-edit-details", "details-updated"],

  created() {
    const { first_name, last_name, telephone, country, linkedin } =
      this.individual;
    this.first_name = first_name || "";
    this.last_name = last_name || "";
    this.telephone = telephone || "";
    this.country = country || "";
    this.linkedin = linkedin || "";
  },

  computed: {
    changesMade(): boolean {
      return (
        this.first_name !== this.individual.first_name ||
        this.last_name !== this.individual.last_name ||
        this.telephone !== this.individual.telephone ||
        this.country !== this.individual.country ||
        this.linkedin !== this.individual.linkedin
      );
    },
  },

  methods: {
    setLinkedin(linkedin: string) {
      this.linkedin = linkedin;
      this.saveChanges();
    },

    setCountry(country: string) {
      this.country = country;
      this.saveChanges();
    },

    async saveChanges() {
      if (this.saving) return;

      const valid = await this.v$.$validate();
      if (!valid) return;

      this.saving = true;
      this.error = false;

      try {
        await updateIndividual(
          {
            first_name: this.first_name,
            last_name: this.last_name,
            telephone: this.telephone,
            country: this.country,
            linkedin: this.linkedin,
          },
          "personal_details"
        );

        this.saving = false;
      } catch (error) {
        this.saving = false;
        this.error = true;
      }
    },
  },
};
</script>
