<template>
  <segment
    hide-divider
    variant="h4"
    :title="field.heading"
    :description="field.description"
  />
  <textarea
    @input="textUpdated"
    v-model="text"
    placeholder="Your text here"
    data-cy="extra-fields-input"
    rows="4"
    class="textarea border border-solid text-grey-800 placeholder-grey-400 px-6 py-4 rounded-lg w-full outline-none text-inputs-content focus-visible:ring focus-visible:ring-orange-500"
    :class="field.response ? 'border-grey-300' : 'border-ivory-400'"
  />
</template>

<script>
import Segment from "@/components-v2/ui-kit/Segment.vue";

export default {
  name: "RoleApplyExtraField",

  components: {
    Segment,
  },

  props: {
    field: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      text: "",
    };
  },

  created() {
    this.text = this.field.response;
  },

  methods: {
    textUpdated(e) {
      const text = e.target.value;
      this.$emit("text-updated", { text, id: this.field.id });
    },
  },
};
</script>
