<template>
  <editor
    class="outline-none mx-6 py-4"
    :api-key="API_KEY"
    :disabled="isLoaded"
    :init="{
      selector: 'div.tinymce',
      plugins: ['wordcount', 'lists', 'autoresize'],
      mobile: {
        plugins: ['wordcount', 'lists'],
      },
      autoresize_bottom_margin: 10,
      browser_spellcheck: true,
      min_height: 160,
      menubar: false,
      statusbar: false,
      inline_boundaries: false,
      toolbar:
        'bold italic underline | bullist numlist outdent indent | cut copy paste undo redo removeformat',
      paste_retain_style_properties: 'ol,ul,li',
      paste_filter_drop: false,
      paste_as_text: true,
      format: {
        removeformat: [
          {
            selector: '*',
            remove: 'all',
            split: true,
            expand: false,
            block_expand: true,
            deep: true,
          },
        ],
      },
    }"
    v-model="text"
    :placeholder="editorPlaceholder"
    id="rich-text-editor"
  />
</template>
<script lang="ts">
import Editor from "@tinymce/tinymce-vue";

export default {
  name: "RoleApplyRichTextEditor",
  components: {
    editor: Editor,
  },
  emits: ["text-updated"],
  props: {
    uniqueId: String,
    initialText: {
      type: String,
      default: "",
    },
    isLoaded: Boolean,
    editorPlaceholder: {
      type: String,
      default: "Add your answer here",
    },
  },
  data() {
    return {
      text: this.initialText || "",
      editorInstance: null,
      API_KEY: process.env.VITE_TINYMCE_API_KEY,
    };
  },

  methods: {
    handleInit(_event, editor) {
      this.editorInstance = editor;
    },

    stripImgTags(value: string) {
      return value.replace(/<img(.*)\/>/g, "").replace(/<img>(.*)<img\/>/g, "");
    },
  },
  watch: {
    text: {
      immediate: true,
      handler() {
        this.$emit("text-updated", this.stripImgTags(this.text));
      },
    },
  },
};
</script>
