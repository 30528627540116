<template>
  <div v-if="showExtraFields" class="px-10 max-w-[800px] mx-auto">
    <component
      v-for="field in extraFields"
      :field="field"
      :key="field.id"
      @text-updated="onTextUpdated"
      :is="
        field.use_rich_text_form
          ? 'RoleApplyExtraFieldRichText'
          : 'RoleApplyExtraField'
      "
    />
  </div>
</template>

<script lang="ts">
import useRoleApplication from "@/composables/useRoleApplication";
import debounce from "@/utils/debounce";

import RoleApplyExtraField from "./RoleApplyExtraField.vue";
import RoleApplyExtraFieldRichText from "./RoleApplyExtraFieldRichText.vue";

export default {
  name: "RoleApplyExtraFields",

  components: {
    RoleApplyExtraField,
    RoleApplyExtraFieldRichText,
  },

  setup() {
    const { roleApplication, updateRoleApplication } = useRoleApplication();

    return {
      roleApplication,
      updateRoleApplication,
    };
  },

  computed: {
    showExtraFields() {
      return this.roleApplication.extra_fields.length > 0;
    },

    extraFields() {
      return this.roleApplication.extra_fields;
    },
  },

  methods: {
    onTextUpdated: debounce(function (value) {
      const { text, id } = value;
      const updatedExtraFields = this.extraFields.map(extra_field =>
        extra_field.id === id
          ? {
              ...extra_field,
              response: text,
            }
          : extra_field
      );
      this.updateRoleApplication({ extra_fields: updatedExtraFields });
    }, 500),
  },
};
</script>
