<template>
  <div class="p-16 bg-ivory-200">
    <div class="mx-auto max-w-2xl py-10">
      <h2 class="text-title-h2 mb-6">You've reached our Member Site</h2>
      <div class="bg-white rounded-lg shadow-lg p-8 w-full">
        <h4 class="text-title-h4 mb-6">
          Are you looking for our Client portal?
        </h4>
        <div class="md:flex">
          <button
            @click="goToClients"
            class="button-primary text-cta-primary w-full mb-2 md:mb-0 sm:w-max sm:mr-2"
          >
            Go to client portal
          </button>
          <button
            @click="goToRequestPage"
            class="button-secondary text-cta-secondary text-center w-full sm:w-max"
          >
            Become a Nurole member
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
export default {
  methods: {
    goToClients() {
      return window.location.assign(process.env.VITE_CLIENTS_DOMAIN);
    },
    goToRequestPage() {
      this.$router.push("/request");
    },
  },
};
</script>
