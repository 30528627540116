<template>
  <svg
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0C5.39063 0 0 5.39063 0 12C0 14.5573 1.125 17.2083 2.66667 19.5833C3.8125 21.349 5.18229 22.974 6.66667 24.2083V28C6.66667 29.4583 7.875 30.6667 9.33333 30.6667L10.6667 32H13.3333L14.6667 30.6667C16.125 30.6667 17.3333 29.4583 17.3333 28V24.2083C18.8177 22.974 20.1875 21.349 21.3333 19.5833C22.875 17.2083 24 14.5573 24 12C24 5.39063 18.6094 0 12 0ZM12 2.66667C17.1719 2.66667 21.3333 6.82813 21.3333 12C21.3333 13.7448 20.474 16.0469 19.125 18.125C17.9219 19.9792 16.3385 21.6302 14.875 22.6667H9.125C7.66146 21.6302 6.07813 19.9792 4.875 18.125C3.52604 16.0469 2.66667 13.7448 2.66667 12C2.66667 6.82813 6.82813 2.66667 12 2.66667ZM9.66667 25.3333H14.3333C14.4375 25.4063 14.5469 25.4583 14.6667 25.5V28H9.33333V25.5C9.45313 25.4583 9.5625 25.4063 9.66667 25.3333Z"
      fill="#242726"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "IconIdea",
});
</script>
