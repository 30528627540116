<template>
  <informative-banner
    :is-title-emphasis="false"
    title="Did you know you can upload a PDF from LinkedIn as your CV?"
    icon="lightbulb"
    class="mb-7 mt-3 mb-5 w-full"
  >
    <div
      class="flex md:items-center md:space-y-0 space-y-2 md:space-x-3 flex-col md:flex-row"
    >
      <span
        class="border border-solid border-t-0 border-l-0 border-r-0 border-transparent hover:border-sage-700"
      >
        <a
          href="https://help.nurole.com/hc/en-us/articles/8238258444828"
          rel="noopener"
          target="_blank"
          class="button-tertiary text-cta-tertiary p-0 flex items-center no-underline"
          @click="trackHelpPageOpen"
        >
          <span> Read the guide </span>
          <span class="material-icons-round sm:text-base pl-1"> launch </span>
        </a>
      </span>
    </div>
  </informative-banner>
</template>

<script setup lang="ts">
import InformativeBanner from "@/components/InformativeBanner.vue";

async function trackHelpPageOpen() {
  window.analytics.track("LinkedIn pdf help page opened");
}
</script>
