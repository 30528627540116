// Adding preview and signature params if available to allow drafted roles to be previewed: https://github.com/Nurole/api/blob/master/app/roles.rb#L113
import { apiAxios } from "@/lib/axios";
import { PunchThrough } from "@/helpers/punchThrough";

PunchThrough.harvestTokenFromURL();
const punchThroughToken = PunchThrough.getToken();

let preview: string | null = null;
let signature: string | null = null;
let token: string | null = null;

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

if (urlParams.get("preview") && urlParams.get("signature")) {
  preview = urlParams.get("preview");
  signature = urlParams.get("signature");
}

if (urlParams.get("token")) {
  token = urlParams.get("token");
}

apiAxios.interceptors.request.use(config => {
  config.params = config.params || {};
  if (preview && signature) {
    config.params["preview"] = preview;
    config.params["signature"] = signature;
  }

  if (punchThroughToken) {
    config.params["pt"] = punchThroughToken;
  }

  if (token) {
    config.params["token"] = token;
  }

  return config;
});
