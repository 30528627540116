<template>
  <span
    data-cy="role-match-badge"
    class="flex justify-center items-center whitespace-nowrap rounded"
    :class="isStrongMatch ? 'bg-orange-50' : 'bg-orange-50'"
  >
    <span
      class="material-icons-round p-1 rounded md:rounded-r-none text-white text-xs"
      :class="isStrongMatch ? 'bg-orange-600' : 'bg-orange-500'"
    >
      thumb_up
    </span>

    <span
      data-cy="role-badge-text"
      class="text-nudging hidden text-grey-800 py-0.5 px-2 md:block"
    >
      {{ matchBadgeText }}
    </span>
  </span>
</template>

<script lang="ts">
export default {
  name: "RolesMatchBadge",
  props: {
    matchStrength: {
      required: true,
      type: String,
    },
  },
  computed: {
    matchBadgeText() {
      if (this.matchStrength == "strong") return "Strong match";
      return "Good match";
    },

    isStrongMatch() {
      return this.matchStrength == "strong";
    },

    matchBadgeClasses() {
      if (this.matchStrength == "strong") return "bg-orange-600 text-white";
      return "bg-orange-50 text-orange-800";
    },
  },
};
</script>
