<template>
  <div
    class="fixed w-screen h-screen z-100 inset-0 bg-grey-900/40 backdrop-blur"
  >
    <div
      class="fixed sm:p-10 pt-6 px-4 pb-10 z-100 bg-white rounded-t-lg mx-auto sm:my-auto mb-0 mt-auto translate-y-0 inset-0 items-center transition-all sm:rounded-lg shadow-xl flex flex-col justify-between overflow-y-hidden max-w-[48rem] h-fit sm:w-fit w-full"
      aria-modal="true"
    >
      <div class="flex flex-col items-center gap-6 bg-white rounded-lg">
        <div class="flex flex-col items-center gap-4 self-stretch">
          <div class="flex content-center items-start gap-10 self-stretch">
            <span class="text-title-h2 text-grey-900 flex-grow"
              >Complete your application</span
            >
            <button class="material-icons-round w-6 h-6" @click="closeModal">
              close
            </button>
          </div>
          <div
            v-if="shouldNudgeReasonForApplication"
            class="flex py-2 px-4 content-center items-center gap-2 self-stretch rounded-lg border border-orange-200 bg-orange-50"
          >
            <span class="material-icons-round w-6 h-6 text-orange-900">
              warning
            </span>
            <span class="copy-bold text-orange-900">
              Missing reason for application
            </span>
          </div>
          <div
            v-if="shouldNudgeDesiredSkills"
            class="flex py-2 px-4 content-center items-center gap-2 self-stretch rounded-lg border border-orange-200 bg-orange-50"
          >
            <span class="material-icons-round w-6 h-6 text-orange-900">
              warning
            </span>
            <span class="copy-bold text-orange-900">
              {{ details }}
            </span>
          </div>
          <div
            v-if="shouldNudgeUserDetails"
            class="flex py-2 px-4 content-center items-center gap-2 self-stretch rounded-lg border border-orange-200 bg-orange-50"
          >
            <span class="material-icons-round w-6 h-6 text-orange-900">
              warning
            </span>
            <span class="copy-bold text-orange-900">
              Missing personal details
            </span>
          </div>
          <div class="flex flex-col items-center gap-4 self-stretch">
            <span class="copy text-grey-900">
              It looks like your application is incomplete. If you choose to
              continue anyway, it may significantly reduce your chance of
              progressing.
            </span>
          </div>
        </div>
        <div class="flex sm:flex-row flex-col items-center gap-4 self-stretch">
          <button
            data-cy="role-apply-nudge-continue"
            @click="amend"
            class="button-primary text-cta-primary flex items-center content-center gap-2 py-3 px-6 sm:w-fit w-full justify-center"
          >
            {{ primaryButtonText }}
          </button>
          <button
            data-cy="role-apply-nudge-amend"
            @click="showPreview"
            class="button-secondary text-cta-secondary flex items-center content-center gap-2 py-3 px-6 sm:w-fit w-full justify-center"
          >
            {{ secondaryButtonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useRoleApplication from "@/composables/useRoleApplication";
import { RoleApplicationSkill } from "@/models/RoleApplication";
import { computed } from "vue";
import { useRouter } from "vue-router";

const { roleApplication, updateRoleApplication } = useRoleApplication();
const emits = defineEmits(["close-modal", "scroll-to-amend"]);

const closeModal = () => {
  emits("close-modal");
};

const props = defineProps({
  shouldNudgeReasonForApplication: {
    type: Boolean,
    required: false,
    default: false,
  },
  shouldNudgeDesiredSkills: {
    type: Boolean,
    required: false,
    default: false,
  },
  shouldNudgeUserDetails: {
    type: Boolean,
    required: false,
    default: false,
  },
  missingDesiredSkillIds: {
    type: Array,
    required: false,
    default: () => [],
  },
});

const router = useRouter();

const showPreview = () => {
  if (props.shouldNudgeDesiredSkills) {
    skipSkills();
  }

  router.push(`/roles/${roleApplication.value.role_id}/apply/preview`);
};

const skipSkills = () => {
  const skills = roleApplication?.value.skills.map(
    (skill: RoleApplicationSkill) =>
      props.missingDesiredSkillIds.includes(skill.skill_id)
        ? {
            ...skill,
            response: { ...skill.response, has_been_skipped: true },
          }
        : skill
  );

  updateRoleApplication({ skills });
};

const amend = () => {
  closeModal();
  emits("scroll-to-amend");
};

const shouldNudgeAll = computed(
  () =>
    props.shouldNudgeReasonForApplication &&
    props.shouldNudgeDesiredSkills &&
    props.shouldNudgeUserDetails
);

const details = computed(() => {
  const desiredSkillText =
    props.missingDesiredSkillIds.length > 1 ? "skills" : "skill";

  return `Missing desired ${desiredSkillText}`;
});

const secondaryButtonText = computed(() => {
  if (shouldNudgeAll.value || props.shouldNudgeReasonForApplication)
    return "Continue anyway";

  return "Skip";
});

const primaryButtonText = computed(() => {
  if (shouldNudgeAll.value) return "Edit application";
  if (props.shouldNudgeReasonForApplication) return "Add answer";

  return "Edit application";
});
</script>
