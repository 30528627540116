<template>
  <CharityPreferences
    title="Preferences for charity roles"
    :individual="individual"
    @update-individual-and-put="updateIndvidualPreferences"
  ></CharityPreferences>
</template>
<script lang="ts" setup>
import CharityPreferences from "@/views/onboarding/CharityPreferences.vue";
import useIndividual from "@/composables/useIndividual";

const { individual, updateIndividual } = useIndividual();

const updateIndvidualPreferences = async (event: any) => {
  await updateIndividual(event, "preferences_charity");
};
</script>
