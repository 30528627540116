<template>
  <div
    class="absolute w-full left-0 right-0 shadow-md rounded-b-lg z-90 bg-white"
  >
    <div class="max-h-56 overflow-y-scroll py-1">
      <div
        v-for="i in [1, 2]"
        :key="i"
        class="w-full px-2 py-1 text-left text-inputs-content"
      >
        <div
          class="h-16 w-full bg-grey-100 animate animate-pulse rounded"
        ></div>
      </div>
    </div>
  </div>
</template>
