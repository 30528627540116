export default {
  NED: ["Non Executive Director"],
  CEO: ["Chief Executive Officer"],
  CFO: ["Chief Financial Officer"],
  CTO: ["Chief Technical Officer"],
  SD: ["Senior Director"],
  md: ["Managing Director"],
  ED: ["Executive Director"],
  GFD: ["Group Finance Director"],
  VP: ["Vice President"],
  HRD: ["Human Resources Director"],
  HR: ["Human Resources"],
  COO: ["Chief Operating Officer"],
  Ops: ["Operations"],
  GFC: ["Group Finance Controller"],
  IT: ["Information Technology"],
  Mktg: ["Marketing"],
  CCO: ["Chief Commercial Officer", "Chief Compliance Officer"],
  FD: ["Finance Director"],
  CRO: [
    "Chief Revenue Officer",
    "Chief Risk Officer",
    "Chief Research Officer",
  ],
  IR: ["Investor Relations"],
  "M&A": ["Mergers & Acquisitions"],
  CMO: ["Chief Marketing Officer", "Chief Medical Officer"],
  CSO: [
    "Chief Sales Officer",
    "Chief Scientific Officer",
    "Chief Security Officer",
    "Chief Strategy Officer",
  ],
  "R&D": ["Research & Development"],
  CHRO: ["Chief Human Resources Officer"],
  CDO: [
    "Chief Diversity Officer",
    "Chief Data Officer",
    "Chief Digital Officer",
  ],
  CIO: [
    "Chief Investment Officer",
    "Chief Information Officer",
    "Chief Innovation Officer",
  ],
  CPO: [
    "Chief Product Officer",
    "Chief Process Officer",
    "Chief Personnel Officer",
    "Chief Process Officer",
    "Chief Performance Officer",
    "Chief Planning Officer",
    "Chief Procurement Officer",
  ],
  MP: ["Member of Parliament"],
  SID: ["Senior Independent Director"],
  INTL: ["International"],
  AC: ["Audit Committee"],
  EXEC: ["Executive"],
  PR: ["Public Relations"],
};
