import { ref, watch } from "vue";
import { apiAxios } from "@/lib/axios";
import useBoardexLookup from "@/composables/useBoardexLookup";

const name = ref("");
const email = ref("");
const howDidYouHear = ref("");
const anythingElse = ref("");
const cv = ref(null);
const roles = ref([]);
const confirmedBoardexId = ref("");
const boardexMarkedWrongIds = ref([]);
const suggestedBoardexIndividual = ref(null);

export default function useRequestInvite() {
  const { lookupIndividualInBoardex } = useBoardexLookup();

  const updateRoles = async newRoles => {
    roles.value = newRoles;

    if (!confirmedBoardexId.value) {
      const boardexIndividual = await lookupIndividualInBoardex(
        name.value,
        roles.value,
        boardexMarkedWrongIds.value
      );

      if (boardexIndividual) {
        suggestedBoardexIndividual.value = boardexIndividual;
      }
    }
  };

  const ensureValidDate = (date, assumeDecember) => {
    const yyyyMmDd = /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/;
    if (yyyyMmDd.test(date)) {
      const dateParts = date.split("-");
      const yyyyMm = `${dateParts[0]}-${dateParts[1]}`;
      return yyyyMm;
    }
    const yyyy = /^\d{4}$/;
    if (yyyy.test(date)) {
      const assumedMonth = assumeDecember ? "12" : "01";
      const yyyyMm = `${date}-${assumedMonth}`;
      return yyyyMm;
    }
    return date;
  };

  const confirmBoardexLookup = async () => {
    confirmedBoardexId.value = suggestedBoardexIndividual.value.id;
    const boardex_roles = suggestedBoardexIndividual.value.roles.map(role => {
      const sameStartAndEndDate = role.from == role.to;
      role.from = ensureValidDate(role.from);
      role.to = ensureValidDate(role.to, sameStartAndEndDate);
      return role;
    });
    await updateRoles([...roles.value, ...boardex_roles]);

    suggestedBoardexIndividual.value = null;
  };

  const rejectBoardexLookup = () => {
    boardexMarkedWrongIds.value = [
      ...boardexMarkedWrongIds.value,
      suggestedBoardexIndividual.value.id,
    ];
    suggestedBoardexIndividual.value = null;
  };

  const submitForm = async () => {
    const parsedCvRolesHeld = cv.value?.roles_held || [];
    await apiAxios.post("/request", {
      name: name.value,
      email: email.value,
      boardex_id: confirmedBoardexId.value,
      cv_filename: cv.value.filename,
      cv_location: cv.value.location,
      s3_object_key: cv.value.s3_object_key,
      is_from_boardex_lookup: !!confirmedBoardexId.value,
      experience: [...parsedCvRolesHeld, ...roles.value],
      how_did_you_hear: howDidYouHear.value,
      anything_else: anythingElse.value,
      self_recommendation: true,
    });

    localStorage.removeItem("request_invite_data");
  };

  const getDataFromLocalStorage = async () => {
    const localStorageData = JSON.parse(
      localStorage.getItem("request_invite_data") || "{}"
    );

    name.value = localStorageData.name || "";
    email.value = localStorageData.email || "";
    cv.value = localStorageData.cv || "";
    howDidYouHear.value = localStorageData.howDidYouHear || "";
    anythingElse.value = localStorageData.anythingElse || "";
    confirmedBoardexId.value = localStorageData.confirmedBoardexId || "";
    boardexMarkedWrongIds.value = localStorageData.boardexMarkedWrongIds || [];

    await updateRoles(localStorageData.roles || []);
  };

  const saveDataToLocalStorage = () => {
    localStorage.setItem(
      "request_invite_data",
      JSON.stringify({
        name: name.value,
        email: email.value,
        howDidYouHear: howDidYouHear.value,
        cv: cv.value,
        anythingElse: anythingElse.value,
        roles: roles.value.filter(role => !!role.organisation),
        confirmedBoardexId: confirmedBoardexId.value,
        boardexMarkedWrongIds: boardexMarkedWrongIds.value,
      })
    );
  };

  watch(
    [
      name,
      email,
      howDidYouHear,
      anythingElse,
      cv,
      roles,
      confirmedBoardexId,
      boardexMarkedWrongIds,
    ],
    () => {
      saveDataToLocalStorage();
    }
  );

  return {
    name,
    email,
    howDidYouHear,
    anythingElse,
    cv,
    roles,
    suggestedBoardexIndividual,
    submitForm,
    updateRoles,
    confirmBoardexLookup,
    rejectBoardexLookup,
    getDataFromLocalStorage,
  };
}
