<template>
  <svg viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.42308 20.4231V18.4615H14.0385V20.4231H5.42308Z"
      class="fill-current"
      :class="color"
    />
    <path
      d="M16.5 20.4231V18.4615H20.1923V20.4231H16.5Z"
      class="fill-current"
      :class="color"
    />
    <path
      d="M5.42308 25.3462V23.3846H14.0385V25.3462H5.42308Z"
      class="fill-current"
      :class="color"
    />
    <path
      d="M16.5 25.3462V23.3846H20.1923V25.3462H16.5Z"
      class="fill-current"
      :class="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.35 9.45455C6.35 7.55522 7.88066 6 9.75 6C11.6193 6 13.15 7.55522 13.15 9.45455C13.15 10.4194 12.7516 11.2931 12.1174 11.9206C13.2529 12.6999 14 14.0156 14 15.5H12.3C12.3 14.0595 11.1678 12.9091 9.75 12.9091C8.33223 12.9091 7.2 14.0595 7.2 15.5H5.5C5.5 14.0156 6.24707 12.6999 7.38262 11.9206C6.74844 11.2931 6.35 10.4194 6.35 9.45455ZM11.45 9.45455C11.45 8.4897 10.6996 7.72727 9.75 7.72727C8.80039 7.72727 8.05 8.4897 8.05 9.45455C8.05 10.4194 8.80039 11.1818 9.75 11.1818C10.6996 11.1818 11.45 10.4194 11.45 9.45455Z"
      class="fill-current"
      :class="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.5 32V0H16.9928L17.3606 0.370192L24.7452 7.75481L25.1154 8.1226V32H0.5ZM15.2692 2.46154H2.96154V29.5385H22.6538V9.84615H15.2692V2.46154ZM20.9303 7.38462L17.7308 4.1851V7.38462H20.9303Z"
      class="fill-current"
      :class="color"
    />
  </svg>
</template>

<script>
export default {
  name: "IconCv",
  props: {
    color: {
      type: String,
      default: "text-grey-900",
    },
  },
};
</script>
