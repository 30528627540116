<template>
  <div
    class="flex flex-col items-center justify-center pt-10 w-full success-message"
    data-cy="success-message"
  >
    <i class="las la-envelope-open la-5x" />
    <p class="my-10 px-10 text-center w-full sm:px-18 sm:w-4/5 copy-bold">
      Thank you - your request for an invitation has been successfully
      submitted.
    </p>
    <div
      class="bg-grey-50 flex flex-col items-center rounded-lg mb-12 px-5 pb-5 sm:px-3"
    >
      <p class="mt-7 sm:text-lg text-center">
        You can discover more of our content on
      </p>
      <div
        class="flex flex-col sm:flex-row justify-center space-y-3 sm:space-y-0 sm:space-x-2 w-full"
      >
        <a
          :href="`${marketingAppURL}/post-a-role`"
          class="button-primary text-cta-primary text-center w-full sm:w-auto"
        >
          Discuss a hire
        </a>
        <a
          :href="`${marketingAppURL}/news-and-guides`"
          class="button-primary text-cta-primary text-center w-full sm:w-auto"
        >
          News and Guides
        </a>
        <a
          :href="`${marketingAppURL}/success-stories`"
          class="button-primary text-cta-primary text-center w-full sm:w-auto"
        >
          Success stories
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "RequestInvitationSuccess",
  setup() {
    return {
      marketingAppURL: process.env.VITE_MARKETING_BASE_URL,
    };
  },
};
</script>
