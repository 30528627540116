<template>
  <div
    class="flex flex-column grow-0 self-stretch order-none justify-center py-6 px-10 gap-8 items-center w-full sm:w-auto border-t-0 border border-sage-100 border-solid bg-white rounded-b"
  >
    <div
      class="w-full flex flex-row order-none self-stretch grow-0 items-center p-0 gap-8"
    >
      <div
        class="items-start p-0 gap-2 order-none flex-grow"
        data-cy="preferences-nudge-footer-prompt"
      >
        <p
          class="text-title-h4 text-grey-900 order-none self-stretch mb-2"
          data-cy="preferences-nudge-footer-heading"
        >
          Not interested in these roles?
        </p>
        <p
          class="text-grey-700 order-1 self-stretch mb-0"
          data-cy="preferences-nudge-footer-text"
        >
          You can edit your preferences to get better roles.
        </p>
      </div>
      <button
        class="flex flex-row items-start p-0 gap-4 order-1 grow-0 text-cta-tertiary text-sage-700"
        data-cy="preferences-nudge-footer-button"
        @click="goToPreferences()"
      >
        {{ this.buttonText }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { RoleType } from "@/lib/RoleTyper";
import { RoleNudgingHelper } from "@/lib/RoleNudgingHelper";
import { PropType } from "vue";
export default {
  name: "RoleNudgingFooter",
  props: {
    roleTypes: {
      type: Array as PropType<RoleType[]>,
      required: true,
    },
    organisationId: {
      type: String,
      required: true,
    },
  },
  computed: {
    buttonText() {
      const roleNudgingHelper = new RoleNudgingHelper();
      return roleNudgingHelper.determineNudgingButtonText(this.roleTypes);
    },
  },
  methods: {
    goToPreferences() {
      const roleNudgingHelper = new RoleNudgingHelper();
      roleNudgingHelper.setNudgingAnalytics(
        this.roleTypes,
        this.organisationId
      );
      return roleNudgingHelper.navigateToPreferences(this.roleTypes);
    },
  },
};
</script>
