<template>
  <h2 class="text-title-h2 m-0 mb-2">Recommend someone</h2>
  <p class="m-0">
    Making a recommendation is a great way to connect talented people in your
    network with new board-level roles. They will need to have either sat on a
    board before, or have skills and experience that make them board-ready.
  </p>
  <form class="mt-8 flex flex-col gap-8" @submit.prevent="submitRecommendation">
    <fieldset
      class="flex flex-col gap-4 md:grid md:grid-cols-2 md:grid-flow-row"
    >
      <legend class="m-0 contents">
        <em class="copy-emphasis col-span-2">Their contact details</em>
      </legend>
      <label
        class="m-0 text-inputs-title flex flex-col gap-3 md:flex-grow"
        for="name"
      >
        Their full name
        <input
          class="text-input text-inputs-content"
          type="text"
          id="name"
          name="name"
          v-model="name"
          autocomplete="off"
          placeholder="John Smith"
        />
      </label>
      <label
        class="m-0 text-inputs-title flex flex-col gap-3 md:flex-grow"
        for="email"
      >
        Their email address
        <input
          class="text-input text-inputs-content"
          type="email"
          id="email"
          name="email"
          v-model="email"
          autocomplete="off"
          placeholder="email@address.com"
        />
      </label>
      <label
        class="m-0 text-inputs-title flex flex-col gap-3 md:flex-grow"
        for="position"
      >
        Their current position
        <input
          class="text-input text-inputs-content"
          type="text"
          id="position"
          name="position"
          v-model="position"
          autocomplete="off"
          placeholder="Financial Director"
        />
      </label>
      <label
        class="m-0 text-inputs-title flex flex-col gap-3 md:flex-grow"
        for="organisation"
      >
        Their current organisation
        <input
          class="text-input text-inputs-content"
          type="text"
          id="organisation"
          name="organisation"
          v-model="organisation"
          autocomplete="off"
          placeholder="Microsoft"
        />
      </label>
    </fieldset>
    <fieldset class="flex flex-col gap-4">
      <legend class="m-0 copy-emphasis pb-4">
        Reason for your recommendation
      </legend>
      <label
        class="m-0 text-inputs-title flex flex-col gap-3 md:flex-grow"
        for="reason"
      >
        <p class="m-0 copy">
          Making a recommendation is a great way to connect talented people in
          your network with new board-level roles. They will need to have either
          sat on a board before, or have skills and experience that make them
          board-ready.
        </p>
        <textarea
          class="textarea text-inputs-content resize-y rounded-lg"
          name="reason"
          v-model="reason"
          id="reason"
          rows="5"
          autocomplete="off"
          placeholder="I think this person would be a good fit because…"
        ></textarea>
      </label>
    </fieldset>
    <p class="m-0 bg-blue-50 rounded-lg p-6 copy-emphasis">
      We will review your recommendation and invite the person to join and apply
      for this role if they are eligible. We will also let you know when they
      have been invited.
    </p>
    <div class="flex flex-col gap-4 md:flex-row">
      <button
        class="button-primary text-cta-primary"
        type="submit"
        :disabled="v$.$invalid || savingRecommendation"
        data-cy="submit-recommendation-button"
      >
        Submit recommendation
      </button>
      <router-link
        :to="backLink"
        class="button-secondary text-cta-secondary flex items-center justify-center"
      >
        Back
      </router-link>
    </div>
  </form>
</template>

<script lang="ts">
import { apiAxios } from "@/lib/axios";
import useVuelidate from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import router from "@/lib/theVueRouter";

export default {
  props: ["role", "backLink"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      name: "",
      email: "",
      position: "",
      organisation: "",
      reason: "",
      savingRecommendation: false,
    };
  },
  methods: {
    async submitRecommendation() {
      this.savingRecommendation = true;
      await apiAxios.post("/recommend", {
        role: this.role, // TODO: Remove when this is deployed: https://github.com/Nurole/api/pull/316
        role_id: this.role.id,
        ...this.$data,
      });
      this.savingRecommendation = false;
      router.push({
        name: "RoleDetails",
        params: {
          roleId: this.role.id,
        },
        query: {
          recommended: "true",
        },
        hash: "#tabs",
      });
    },
  },
  validations() {
    return {
      name: { required },
      email: { required, email },
      position: { required },
      organisation: { required },
      reason: { required },
    };
  },
};
</script>
