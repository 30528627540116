<template>
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 0C9.42092 0 0 9.42092 0 21C0 32.5791 9.42092 42 21 42C32.5791 42 42 32.5791 42 21C42 9.42092 32.5791 0 21 0ZM21 3.23077C30.7973 3.23077 38.7692 11.2027 38.7692 21C38.7692 30.7973 30.7973 38.7692 21 38.7692C11.2027 38.7692 3.23077 30.7973 3.23077 21C3.23077 11.2027 11.2027 3.23077 21 3.23077ZM11.396 13.1944L9.60397 15.8825L12.434 17.7692L9.60397 19.6559L11.396 22.344L18.2583 17.7692L11.396 13.1944ZM30.604 13.1944L23.7417 17.7692L30.604 22.344L32.396 19.6559L29.566 17.7692L32.396 15.8825L30.604 13.1944ZM21 25.8462C16.7935 25.8462 13.9985 29.3334 13.0083 31.2476C12.7094 31.8243 13.2557 32.4718 13.8727 32.2698C15.5156 31.7303 18.333 30.6923 21 30.6923C23.667 30.6923 26.4844 31.7319 28.1273 32.2698C28.7459 32.4734 29.2906 31.8243 28.9917 31.2476C28.0015 29.335 25.2065 25.8462 21 25.8462Z"
      :fill="fill"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "IconSmileHate",

  props: {
    fill: {
      type: String,
      default: "#F04132",
    },
  },
});
</script>
