<template>
  <div
    class="bg-grey-50 flex flex-col gap-2 text-center text-grey-700 rounded-lg px-4 py-8 md:px-10 md:py-12"
  >
    <p class="copy-emphasis">You haven't yet recommended anybody</p>
    <p class="">
      Once you do so, your recommendations will be listed here for future
      reference.
    </p>
  </div>
</template>
