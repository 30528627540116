<script setup>
import { ownerships } from "@/lib/rolesTableStaticData";
import SimpleOptionSelector from "../shared/SimpleOptionSelector.vue";
import useOutsidePress from "@/composables/useOutsidePress";
import { ref } from "vue-demi";
import { onMounted } from "vue";

const elementRef = ref(null);
const ownershipListOpen = ref(false);
useOutsidePress(elementRef, () => (ownershipListOpen.value = false));

const props = defineProps(["modelValue", "focus"]);
const emit = defineEmits(["update:modelValue"]);

const ownership = ref(props.modelValue);

const ownershipRef = ref(null);

onMounted(() => {
  if (props.focus) {
    ownershipRef.value.focus();
  }
});

function selectOwnership(selectedOwnership) {
  emit("update:modelValue", selectedOwnership.display);
  ownership.value = selectedOwnership.display;
  ownershipListOpen.value = false;
}
</script>

<template>
  <div class="w-full relative" ref="elementRef">
    <button
      ref="ownershipRef"
      class="text-input border-grey-200 text-inputs-content whitespace-nowrap w-full bg-white h-full flex items-center py-[15px]"
      :class="{
        'text-grey-400': ownership === '' || ownership === null,
        'rounded-b-none ring-offset-0 rounded-t-lg shadow-md':
          ownershipListOpen,
      }"
      @click="ownershipListOpen = !ownershipListOpen"
    >
      <span class="flex-grow text-start">
        {{ ownership || "Choose Ownership type" }}
      </span>
      <span
        class="material-icons-round text-grey-500"
        v-if="!ownershipListOpen"
        @click.stop="ownershipListOpen = !ownershipListOpen"
        >expand_more</span
      >
      <span
        class="material-icons-round text-grey-500"
        v-else
        @click.stop="ownershipListOpen = !ownershipListOpen"
        >expand_less</span
      >
    </button>
    <simple-option-selector
      v-if="ownershipListOpen"
      :options="ownerships"
      displayKey="display"
      @on-select="selectOwnership"
    />
  </div>
</template>
