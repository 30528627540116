import flagsmith from "flagsmith";
import { updateFlagsmithComputedValues } from "@/composables/useFlagsmith";

flagsmith.init({
  environmentID: process.env.VITE_FLAGSMITH_ENVIRONMENT_ID,
  api: "https://api.flagsmith.com/api/v1/",
  onChange() {
    updateFlagsmithComputedValues();
  },
});
