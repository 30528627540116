<template>
  <form @submit.prevent novalidate>
    <div class="flex md:flex-row flex-col">
      <!-- Position -->
      <div class="flex-grow md:pr-4">
        <role-held-position v-model="position" />
        <div
          class="text-labelling-active text-alert mt-2"
          v-if="v$.position.$invalid && v$.position.$error"
          data-position-error
        >
          Please enter your position
        </div>
      </div>

      <!-- Information -->

      <div class="flex gap-4 flex-col md:mt-14 mt-5">
        <fieldset class="pill-toggle flex-grow max-h-[58px]">
          <input
            type="radio"
            id="non-executive"
            name="exec-or-not"
            v-model="executive"
            :value="false"
          />
          <label tabindex="0" for="non-executive"> Non-executive </label>

          <input
            type="radio"
            id="executive"
            name="exec-or-not"
            v-model="executive"
            :value="true"
          />
          <label tabindex="0" for="executive"> Executive </label>
        </fieldset>
      </div>
    </div>

    <!-- Organisation -->
    <role-held-organisation v-model="organisation" />
    <div
      class="text-labelling-active text-alert mt-2"
      v-if="v$.organisation.$invalid && v$.organisation.$error"
      data-organisation-error
    >
      Please enter an organisation name
    </div>

    <div class="flex md:flex-row flex-col flex-grow mt-6 mb-2 gap-6">
      <div
        class="flex flex-col flex-grow from-date-selector md:w-80 w-full gap-3"
      >
        <!-- Start date -->
        <label class="text-inputs-title font-semibold block">Start date*</label>
        <div class="w-full gap-3">
          <role-held-date-selector
            :date-value="from"
            @update:date-value="from = $event"
          />
        </div>
        <div
          class="text-labelling-active text-alert"
          v-if="
            (v$.from.month.$invalid && v$.from.month.$error) ||
            (v$.from.year.$invalid && v$.from.year.$error)
          "
          data-start-date-error
        >
          Please enter a valid start date
        </div>
        <!-- End date unknown? -->
        <base-checkbox
          label="I currently work here"
          class="flex items-end"
          :class="{
            'bg-grey-50 rounded md:bg-transparent': end_date_unknown,
          }"
          :checked="end_date_unknown"
          @value="toggleEndDateUnknown"
          data-toggle-end-date-unknown
        />
      </div>
      <div
        v-if="!end_date_unknown"
        class="flex flex-col flex-grow md:w-80 w-full gap-3"
      >
        <label class="text-inputs-title font-semibold block">End date*</label>
        <div class="w-full gap-3">
          <role-held-date-selector
            :disabled="end_date_unknown"
            :date-value="to"
            :min-date="from ? new Date(from.year, from.month) : null"
            @update:date-value="to = $event"
          />
        </div>
        <div
          class="text-labelling-active text-alert"
          v-if="
            (v$.to.month.$invalid && v$.to.month.$error) ||
            (v$.to.year.$invalid && v$.to.year.$error)
          "
          data-end-date-error
        >
          Please enter a valid end date
        </div>
      </div>
    </div>

    <template v-if="showOrgDescription">
      <label
        class="text-inputs-title font-semibold block mt-6 mb-2"
        for="organisationDescription"
        >Description</label
      >
      <div
        class="w-full relative mt-3 border border-grey-200 border-solid rounded-lg p-2"
      >
        <rich-text-editor
          v-if="isLoaded"
          :isLoaded="false"
          :initialText="organisation_description"
          editorPlaceholder="Please provide a summary of the organisation during your tenure and the core aspects of your role, including key achievements."
          @text-updated="updateOrgDescription"
        />
      </div>
    </template>

    <!-- Save, cancel, or delete -->
    <div class="md:flex items-center mt-8">
      <button
        @click="saveChanges"
        :disabled="saveInProgress"
        class="button-primary text-cta-primary w-full md:w-auto mb-3 md:mb-0"
        data-save-role-held
      >
        Save
      </button>
      <button
        v-if="roleHeld.id"
        class="button-error text-cta-primary md:ml-3 flex items-center w-full md:w-auto"
        @click="$emit('delete-role-held', roleHeld.id)"
        data-delete-experience
      >
        <span class="material-icons-round mr-2"> delete </span>
        <span class="text-center md:text-left flex-1">Delete experience</span>
      </button>
      <button
        v-else
        class="button-secondary text-cta-secondary md:ml-3 w-full md:w-auto"
        @click="$emit('close-form')"
      >
        Cancel
      </button>
    </div>
  </form>
</template>

<script lang="ts">
import { PropType } from "vue-demi";
import RoleHeldPosition from "./RoleHeldPosition.vue";
import RoleHeldOrganisation from "./RoleHeldOrganisation.vue";
import BaseCheckbox from "../shared/BaseCheckbox.vue";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import { RoleHeld } from "@/composables/useMiniCv";
import useIndividual from "@/composables/useIndividual";
import {
  roleHeldToFormData,
  formDataToSaveParams,
} from "@/helpers/roleHeldFormHelpers";
import RichTextEditor from "../shared/RichTextEditor.vue";
import debounce from "@/utils/debounce";
import RoleHeldDateSelector from "@/components/miniCv/RoleHeldDateSelector.vue";

export default {
  components: {
    RoleHeldDateSelector,
    RoleHeldPosition,
    RoleHeldOrganisation,
    BaseCheckbox,
    RichTextEditor,
  },
  name: "RoleHeldForm",
  emits: ["save-role-held", "delete-role-held", "close-form"],
  props: {
    roleHeld: {
      type: Object as PropType<RoleHeld>,
      required: true,
    },
    showOrgDescription: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup() {
    const { individual, updateIndividual, isLoaded } = useIndividual();
    return {
      individual,
      updateIndividual,
      isLoaded,
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      RoleHeldFormData: {
        position: "",
        executive: undefined,
        compensated: undefined,
        organisation: {
          name: "",
          id: null,
          size: {
            value: 0,
          },
          sector: "",
          ownership: "",
          website: "",
        },
        organisation_description: "",
        from: {
          year: undefined,
          month: undefined,
        },
        to: {
          year: undefined,
          month: undefined,
        },
        end_date_unknown: false,
      },
      saveInProgress: false,
    };
  },
  created() {
    Object.assign(this.$data, roleHeldToFormData(this.roleHeld));
  },
  computed: {
    saveParams(): Partial<RoleHeld> {
      return formDataToSaveParams({ id: this.roleHeld.id, ...this.$data });
    },
  },
  methods: {
    toggleEndDateUnknown() {
      this.end_date_unknown = !this.end_date_unknown;
      this.to.year = null;
      this.to.month = null;
    },

    updateOrgDescription: debounce(async function (event) {
      this.organisation_description = event;
    }, 200),

    saveChanges() {
      if (this.saveInProgress) return;
      this.saveInProgress = true;
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.saveInProgress = false;
        return;
      }

      this.$emit("save-role-held", {
        id: this.roleHeld.id,
        ...this.saveParams,
      });
    },
  },
  validations() {
    return {
      position: { required },
      organisation: {
        name: { required },
      },
      from: {
        year: { required },
        month: { required },
      },
      to: {
        year: {
          required: requiredIf(function () {
            return !this.end_date_unknown;
          }),
        },
        month: {
          required: requiredIf(function () {
            return !this.end_date_unknown;
          }),
        },
      },
    };
  },
};
</script>

<style scoped>
@media (min-width: 768px) {
  .from-date-selector {
    max-width: calc(50% - 0.75rem);
  }
}
</style>
