<template>
  <div>
    <button
      class="button-secondary text-cta-secondary whitespace-nowrap flex items-center justify-center gap-2 w-full md:w-auto"
      @click="shareProfile"
      :disabled="copiedToClipboard"
    >
      <template v-if="copiedToClipboard"
        ><span class="material-icons-outlined text-md">check</span>Copied to
        clipboard</template
      >
      <template v-else>
        <span class="material-icons-outlined text-md">ios_share</span>
        Share my profile
      </template>
    </button>
    <the-modal
      v-if="modalOpen"
      :easyToClose="true"
      @close-modal="modalOpen = false"
    >
      <template #header>
        <h2 class="copy-emphasis sm:text-title-h2">
          Set your profile as Public to share your profile as a link
        </h2>
      </template>
      <profile-privacy-options
        option-to-show="public"
      ></profile-privacy-options>
      <button
        class="button-primary text-cta-primary w-full mt-6"
        @click="continueHandler()"
        :disabled="individual.profile_privacy !== 'public'"
      >
        Continue
      </button>
    </the-modal>
  </div>
</template>
<script setup lang="ts">
import { individual } from "@/composables/useIndividual";
import { computed, ref } from "vue";
import { useToast } from "vue-toastification";
import TheModal from "../TheModal.vue";
import ProfilePrivacyOptions from "../profile/ProfilePrivacyOptions.vue";

const toast = useToast();
const modalOpen = ref(false);
const copiedToClipboard = ref(false);

const name = computed(
  () => `${individual.value.first_name} ${individual.value.last_name}`
);

const profileLink = computed(() => {
  return `${window.location.origin}${individual.value.public_profile_link}`;
});

const continueHandler = () => {
  modalOpen.value = false;
  shareProfile();
  if (!navigator.canShare) {
    toast.success("Copied to clipboard");
  }
};

const shareProfile = () => {
  if (individual.value.profile_privacy !== "public") {
    modalOpen.value = true;
    return;
  }

  const data = {
    title: `${name.value}'s Nurole Profile`,
    text: `Here is my Nurole Profile`,
    url: profileLink.value,
  };

  if (navigator.canShare && navigator.canShare(data)) {
    navigator.share(data);
  } else {
    navigator.clipboard.writeText(profileLink.value);
    copiedToClipboard.value = true;
    setTimeout(() => {
      copiedToClipboard.value = false;
    }, 3000);
  }
};
</script>
