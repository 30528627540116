<template>
  <div class="flex flex-col lg:flex-grow lg:basis-2/3">
    <textarea
      @input="
        $emit('update:modelValue', ($event.target as HTMLInputElement)?.value)
      "
      type="text"
      class="textarea rounded-t-lg rounded-b-none resize-none flex-grow"
      :rows="rows ?? 5"
      :placeholder="placeholder ?? ''"
      :value="modelValue"
      :disabled="disabled"
    ></textarea>
    <div
      class="text-secondary py-3 px-4 border border-solid border-grey-200 rounded-b-lg border-t-0 flex justify-between items-center"
    >
      <p v-if="wordCountRemaining >= 0">
        We recommend keeping responses to c.{{ targetWordCount }} words:
        {{ targetWordCount - wordCountRemaining }}/{{ targetWordCount }}
      </p>
      <p v-else>
        We recommend keeping responses to c.{{ targetWordCount }} words:
        <span class="text-orange-600"
          >{{ targetWordCount - wordCountRemaining }}/{{
            targetWordCount
          }}</span
        >
      </p>
      <slot></slot>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";

const props = defineProps({
  modelValue: { type: String, required: true },
  targetWordCount: { type: Number, required: true },
  rows: Number,
  placeholder: String,
  disabled: Boolean,
});

defineEmits(["update:modelValue"]);

const wordCountRemaining = computed(() => {
  const wordCount = props.modelValue
    ?.trim()
    .split(/\s*[\s,]\s*/)
    .filter(x => x.length).length;

  return props.targetWordCount - (wordCount ?? 0);
});
</script>
