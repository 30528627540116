<template>
  <div class="card-outlined py-8 px-10 mt-8">
    <div class="copy-emphasis">Not actively looking?</div>
    <div class="mt-4">
      We know that there are times in your career when you don’t have capacity.
      Nonetheless, Nurole will still be here for you if things change. Why don’t
      you pause your notifications for 6 months?
    </div>
    <div v-if="isSnoozed" class="state-error mt-6">
      You are currently not receiving updates about new roles. Your
      notifications will resume on {{ notificationsResumeDate }}.
    </div>
    <button
      v-if="isSnoozed"
      :disabled="isSnoozing"
      @click="toggleSnooze"
      class="button-secondary text-cta-secondary mt-8 block w-full md:w-auto md:inline-block"
    >
      Un-snooze notifications
    </button>
    <button
      @click="toggleSnooze"
      v-else
      :disabled="isSnoozing"
      class="button-primary text-cta-primary mt-8 block w-full md:w-auto md:inline-block"
    >
      Snooze notifications
    </button>
  </div>
</template>

<script lang="ts">
import useIndividual from "@/composables/useIndividual";
import { apiAxios } from "@/lib/axios";
export default {
  name: "SnoozeNotifications",
  setup() {
    const { individual, getIndividual } = useIndividual();

    return {
      individual,
      getIndividual,
    };
  },
  data() {
    return {
      isSnoozing: false,
      feedback: "",
    };
  },
  methods: {
    async toggleSnooze() {
      this.isSnoozing = true;
      await apiAxios.post("/snooze-notifications", {
        snooze: !this.isSnoozed,
      });
      await this.getIndividual();
      this.isSnoozing = false;
    },
  },
  computed: {
    isSnoozed() {
      return this.individual?.snoozed;
    },
    notificationsResumeDate() {
      return new Date(this.individual?.snoozed_until).toLocaleDateString();
    },
  },
};
</script>
