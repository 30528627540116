<template>
  <button
    v-if="isPublicRole"
    class="text-cta-secondary button-secondary flex items-center justify-center gap-2 w-full md:w-auto"
    @click="share()"
  >
    <span class="material-icons-round text-base">share</span>
    <span>Share</span>
  </button>
  <div
    v-if="modalOpen"
    class="fixed w-screen h-screen z-100 inset-0 bg-grey-900/40 backdrop-blur"
  >
    <div
      class="fixed px-5 z-100 bg-white rounded-t-2xl mx-auto sm:my-auto mb-0 mt-auto translate-y-0 inset-0 items-center transition-all sm:rounded-lg shadow-xl sm:px-12 flex flex-col justify-between overflow-y-hidden h-fit w-screen sm:max-w-3xl"
      aria-modal="true"
    >
      <div class="flex flex-col w-full py-7">
        <div class="px-4 p-2">
          <base-modal-header
            @close="closeModal"
            :show-close-button="showCloseButton"
          >
            Share
          </base-modal-header>
          <div class="gap-4">
            <span class="flex items-start self-stretch text-primary copy">
              Share via
            </span>
            <div class="Social flex gap-6 py-2">
              <button
                class="button"
                data-sharer="linkedin"
                :data-title="shareText"
                :data-url="roleUrl"
              >
                <img src="@/assets/social/linkedin.svg" />
              </button>
              <button
                class="button"
                data-sharer="whatsapp"
                :data-title="shareText"
              >
                <img src="@/assets/social/whatsapp.svg" />
              </button>
              <button
                class="button"
                data-sharer="twitter"
                :data-title="shareText"
              >
                <img src="@/assets/social/x.svg" />
              </button>
              <button
                class="button"
                data-sharer="facebook"
                :data-title="shareText"
              >
                <img src="@/assets/social/facebook.svg" />
              </button>
              <button
                class="button"
                data-sharer="email"
                :data-title="emailText"
                :data-subject="emailSubject"
              >
                <img src="@/assets/social/email.svg" />
              </button>
            </div>
            <div class="mt-6">
              <span class="flex items-start self-stretch text-primary copy">
                Or copy link to role
              </span>
              <div
                class="block text-input text-inputs-content w-full mt-2 mb-6 flex gap-4 justify-between"
              >
                <div class="flex flex-col justify-center">
                  <span class="max-w-[460px] overflow-hidden text-ellipsis">{{
                    roleUrl
                  }}</span>
                </div>
                <button
                  class="button-primary text-cta-primary whitespace-nowrap flex justify-center flex-wrap-reverse mb-3 sm:mb-0 md:w-28"
                  @click="copyLink"
                  :disabled="linkCopied"
                >
                  <span v-if="linkCopied" class="mx-6">Copied</span>
                  <span v-else>Copy link</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, computed } from "vue";
import { useRoute } from "vue-router";
import BaseModalHeader from "@/components/shared/BaseModalHeader.vue";

const props = defineProps(["role"]);
const modalOpen = ref(false);
const showCloseButton = ref(true);
const linkCopied = ref(false);
const route = useRoute();
watch(modalOpen, modalOpen => {
  if (modalOpen) {
    window.requestAnimationFrame(() => {
      window.Sharer.init();
    });
  }
});
function closeModal() {
  modalOpen.value = false;
}

function share() {
  if (isMobileBrowser() && typeof navigator.share === "function") {
    navigator.share({
      url: roleUrl.value,
      text: shareText.value,
    });
  } else {
    modalOpen.value = true;
  }
}

const roleUrl = computed(() => {
  return `${window.location.origin}/roles/${props.role.id}`;
});

function copyLink() {
  navigator.clipboard.writeText(roleUrl.value);
  linkCopied.value = true;
  setTimeout(() => {
    linkCopied.value = false;
  }, 5000);
}
const isPublicRole = computed(() => {
  return route.meta.isPublicRole;
});

const shareText = computed(() => {
  return `Looking for a new challenge?\n${props.role.role_summary}...\nYou can find out more and apply ${roleUrl.value}`;
});

const emailSubject = computed(() => {
  return `${props.role.title} Opportunity at ${props.role.name}`;
});
const emailText = computed(() => {
  return `Hello,

I recently came across an opening for the position of ${props.role.title} at ${props.role.name} and thought this could be of interest. You can find more details here - ${roleUrl.value}.

All the best,
`;
});

function isMobileBrowser() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}
</script>
