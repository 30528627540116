<template>
  <div>
    <update-roles-with-cv @add-a-role="openRoleHeldModal" />
  </div>
  <validation-message
    v-if="!rolesDone"
    validationMessage="Please add at least 3 roles"
  />
  <div class="pb-12 mt-4 md:mt-8">
    <div id="mini_cv">
      <mini-cv ref="miniCvComponent" :allow-pin="true">
        <template v-slot="role">
          <pin-role-button :role-held-id="role.id"></pin-role-button>
        </template>
      </mini-cv>
    </div>
  </div>
</template>

<script lang="ts" setup>
import PinRoleButton from "./PinRoleButton.vue";
import UpdateRolesWithCv from "@/components/account/UpdateRolesWithCv.vue";
import MiniCv from "@/components/MiniCv.vue";
import ValidationMessage from "@/components/ValidationMessage.vue";
import { computed, ref } from "vue";
import { getRolesHeld, rolesHeld } from "@/composables/useMiniCv";

getRolesHeld();

const rolesDone = computed(() => rolesHeld?.value?.length >= 3);
const miniCvComponent = ref();

const openRoleHeldModal = () => {
  miniCvComponent.value.openRoleHeld();
};
</script>
