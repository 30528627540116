<template>
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 0C9.41992 0 0 9.41992 0 21C0 32.5801 9.41992 42 21 42C32.5801 42 42 32.5801 42 21C42 9.41992 32.5801 0 21 0ZM21 3.5C30.6865 3.5 38.5 11.3135 38.5 21C38.5 30.6865 30.6865 38.5 21 38.5C11.3135 38.5 3.5 30.6865 3.5 21C3.5 11.3135 11.3135 3.5 21 3.5ZM13.125 14C11.6758 14 10.5 15.1758 10.5 16.625C10.5 18.0742 11.6758 19.25 13.125 19.25C14.5742 19.25 15.75 18.0742 15.75 16.625C15.75 15.1758 14.5742 14 13.125 14ZM28.875 14C27.4258 14 26.25 15.1758 26.25 16.625C26.25 18.0742 27.4258 19.25 28.875 19.25C30.3242 19.25 31.5 18.0742 31.5 16.625C31.5 15.1758 30.3242 14 28.875 14ZM21 24.5C16.3311 24.5 12.2158 26.8379 9.67969 30.3516L12.5234 32.375C14.4307 29.7227 17.4863 28 21 28C24.5137 28 27.5693 29.7227 29.4766 32.375L32.3203 30.3516C29.7842 26.8379 25.6689 24.5 21 24.5Z"
      :fill="fill"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "IconSmileDislike",

  props: {
    fill: {
      type: String,
      default: "#E69105",
    },
  },
});
</script>
