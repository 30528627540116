import { ref } from "vue";
import { apiAxios } from "@/lib/axios";
import useRoles, { Role } from "@/composables/useRoles";

const { roles } = useRoles();
const role = ref<Role>();

async function getRoleById(roleId: string) {
  role.value = roles.value.find(role => role.id === roleId);
  if (!role.value) {
    const { data } = await apiAxios.get(`/roles/${roleId}`);
    role.value = data;
  }
  return role.value;
}

export default function useRole() {
  return {
    role,
    getRoleById,
  };
}
