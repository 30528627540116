<template>
  <div>
    <select-cv-for-parsing-modal
      v-if="showSelectCvForParseModal"
      :any-existing-roles="anyExistingRoles"
      @close-modal="showSelectCvForParseModal = false"
    />
    <cv-parsing-progress-modal
      v-if="cvParsingStatus == 'in_progress'"
      :any-existing-roles="anyExistingRoles"
      @parsed="onCvParseSuccess"
    />
    <div
      id="professionalExperience"
      class="flex-col items-start sm:flex-row flex w-full justify-between sm:items-center"
    >
      <h4 class="text-title-h2 flex-1 hidden md:block">
        Professional experience
      </h4>
      <add-roles-dropdown
        class="pt-5 sm:pt-0"
        :any-existing-roles="anyExistingRoles"
        @add-roles-from-cv="showSelectCvForParseModal = true"
        @add-a-role="$emit('add-a-role')"
      />
    </div>
    <informative-banner
      v-if="showUndoBanner"
      :title="undoBannerTitle"
      icon="info"
      class="my-4"
    >
      <div class="flex flex-col items-start">
        Review and make any necessary changes to ensure your experience is
        up-to-date and relevant.
        <div
          v-if="isReverting"
          class="text-cta-tertiary button-tertiary text-blue-500 px-0 pb-0 pt-2"
        >
          Reverting ...
        </div>
        <button
          v-else
          class="text-cta-tertiary button-tertiary sage-800 px-0 pb-0 pt-2"
          @click="undoCvParse"
        >
          UNDO (REVERT TO OLD VERSION)
        </button>
      </div>
    </informative-banner>
  </div>
</template>

<script setup lang="ts">
import AddRolesDropdown from "@/components/account/cv/AddRolesDropdown.vue";
import InformativeBanner from "@/components/InformativeBanner.vue";
import SelectCvForParsingModal from "@/components/account/cv/SelectCvForParsingModal.vue";
import CvParsingProgressModal from "@/components/account/cv/CvParsingProgressModal.vue";
import { useToast } from "vue-toastification";
import { apiAxios } from "@/lib/axios";
import useMiniCv from "@/composables/useMiniCv";
import { computed, ref } from "vue";
import useCvParsing from "@/composables/useCvParsing";

const toast = useToast();

const showSelectCvForParseModal = ref(false);
const showUndoBanner = ref(false);
const undoBannerTitle = ref("");
const isReverting = ref(false);
const anyExistingRoles = computed(
  () => useMiniCv().rolesHeld.value?.length > 0
);
const cvParsingStatus = computed(() => useCvParsing().getCvParsingStatus());

function scrollToProfessionalExperience() {
  setTimeout(() => {
    const element = document.getElementById("professionalExperience");
    const top = element?.offsetTop;
    if (top) window.scrollTo({ left: 0, top: top - 20, behavior: "smooth" });
  }, 500);
}

async function onCvParseSuccess() {
  const cv = useCvParsing().getCv();
  showUndoBanner.value = true;
  undoBannerTitle.value = `Roles added from ${cv.filename}`;
  const rolesHeld = useMiniCv().rolesHeld.value;
  await showToast(rolesHeld);
  scrollToProfessionalExperience();
  window.analytics.track("MiniCv - CV parsed successfully", {
    rolesCount: rolesHeld?.length,
  });
}

async function showToast(rolesHeld: any[]) {
  const rolesAddedText = `${
    rolesHeld.length == 1 ? "1 role" : `${rolesHeld.length} roles`
  }`;
  const snackbarText = `You have successfully added ${rolesAddedText}`;
  toast(snackbarText);
}

async function undoCvParse() {
  isReverting.value = true;
  await apiAxios.post("/individual/cvs/undo_last_parse");
  await useMiniCv().getRolesHeld();
  showUndoBanner.value = false;
  toast("Changes reverted");
  scrollToProfessionalExperience();
  isReverting.value = false;
  window.analytics.track("MiniCv - Undo CV parse");
}
</script>
