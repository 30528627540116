<template>
  <div class="flex flex-col gap-4">
    <div>
      <label for="application-reason" class="copy-emphasis"
        >Reason for application</label
      >
      <div class="bg-guidance flex flex-col rounded-lg mt-3">
        <div
          v-if="role.cover_letter_intro?.length"
          class="py-3 px-5 copy"
          data-cover-letter-intro
        >
          {{ role.cover_letter_intro }}
        </div>
      </div>
      <div class="mt-4">
        <rich-text-editor
          v-if="isReadyToEdit"
          v-model="editorContext"
          :guidanceMessage="guidance.message"
          :guidanceVariant="guidance.variant"
          placeholder="Add your answer here"
        />
      </div>
    </div>

    <p class="text-primary">
      Submitting this application means that your personal data will be
      processed in accordance with
      <a
        class="underline"
        target="_blank"
        rel="noopener"
        href="https://www.nurole.com/privacy"
        >Nurole's privacy notice</a
      >
    </p>
    <div class="flex justify-between">
      <button
        @click="$emit('prev')"
        class="button-secondary text-cta-secondary"
      >
        Back
      </button>
      <button
        :disabled="isSubmitting || !editorContext.text.length"
        class="button-primary text-cta-primary sm:w-max"
        @click="submitApplication"
      >
        Submit
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { apiAxios } from "@/lib/axios";
import debounce from "@/utils/debounce";
import { quickApplication, isReadyToEdit } from "@/lib/quickApply";
import router from "@/lib/theVueRouter";
import RichTextEditor from "@/components-v2/ui-kit/RichTextEditor.vue";
import { getRFAGuidance } from "@/helpers/guidanceIndicator";
import { parseNumber } from "@/utils/number";
import type { RichTextEditorContext } from "@/helpers/richTextEditor";

const editorContext = ref<RichTextEditorContext>({
  text: quickApplication.cover_letter || "",
});
const isSubmitting = ref(false);
const props = defineProps(["role"]);

async function submitApplication() {
  isSubmitting.value = true;
  const { data } = await apiAxios.put(`/apply/${props.role.id}`, {
    ...quickApplication,
    submitted: true,
  });
  isSubmitting.value = false;
  router.push(`/roles/${props.role.id}/success/${data.id}`);
}

const autoSaveCoverLetter = debounce(async (text: string) => {
  quickApplication.cover_letter = text;
  await apiAxios.put(`/apply/${props.role.id}`, {
    cover_letter: text,
  });
});

watch(() => editorContext.value.text, autoSaveCoverLetter);

const guidance = computed(() =>
  getRFAGuidance(
    editorContext.value,
    parseNumber(props.role?.cover_letter_limit, 0)
  )
);
</script>
