<template>
  <div class="flex flex-col gap-4" id="career_highlight">
    <h4 class="text-title-h2 hidden md:block">Career highlights</h4>
    <example-slider :examples="examples" title="Example highlights" />
    <span class="text-inputs-title block mt-5">Add up to 5 highlights</span>
    <div class="flex flex-col gap-3 lg:flex-row-reverse lg:justify-stretch">
      <div
        class="bg-blue-50 py-4 px-4 rounded-lg flex flex-col gap-3 lg:basis-1/3"
      >
        <p class="text-divider text-guidance flex gap-2 items-center uppercase">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.00065 1.33398C4.32065 1.33398 1.33398 4.32065 1.33398 8.00065C1.33398 11.6807 4.32065 14.6673 8.00065 14.6673C11.6807 14.6673 14.6673 11.6807 14.6673 8.00065C14.6673 4.32065 11.6807 1.33398 8.00065 1.33398ZM8.00065 11.334C7.63398 11.334 7.33398 11.034 7.33398 10.6673V8.00065C7.33398 7.63398 7.63398 7.33398 8.00065 7.33398C8.36732 7.33398 8.66732 7.63398 8.66732 8.00065V10.6673C8.66732 11.034 8.36732 11.334 8.00065 11.334ZM8.66732 6.00065H7.33398V4.66732H8.66732V6.00065Z"
              fill="#41578A"
            />
          </svg>
          Tips
        </p>
        <ul class="list-disc pl-5">
          <li>
            <strong class="font-semibold">Differentiate yourself:</strong>
            This is your elevator pitch. Identify 3-5 unique factors that make
            you stand out. Consider where you could add value to a board e.g.
            functional, sector or geographic expertise, stakeholders, or
            leadership experience.
          </li>
          <li>
            <strong class="font-semibold">Support with evidence:</strong> Back
            up your points with examples and quantifiable metrics. Numbers often
            speak louder than words, whether it's revenue growth, cost
            reduction, or speed of implementation.
          </li>
          <li>
            <strong class="font-semibold">Assume no knowledge:</strong> Avoid
            jargon and acronyms. Assume the reader is not familiar with the
            organisations and sectors in your profile - if in doubt, spell it
            out.
          </li>
        </ul>
      </div>
      <div class="flex flex-col w-full gap-4">
        <text-area-word-count
          v-for="(careerHighlight, index) in careerHighlights"
          :key="index"
          :model-value="careerHighlight"
          @update:modelValue="
            careerHighlights[index] = $event;
            saveChanges();
          "
          :rows="3"
          :placeholder="`Add your ${
            index === 0
              ? 'first'
              : index === 1
                ? 'second'
                : index === 2
                  ? 'third'
                  : index === 3
                    ? 'fourth'
                    : index === 4
                      ? 'fifth'
                      : index + 1 + 'th'
          } career highlight here`"
          :targetWordCount="50"
          :disabled="!isLoaded"
        >
          <icon-with-tooltip tooltipText="Delete Highlight" v-if="index > 2">
            <button @click="deleteHighlight(index)">
              <span
                class="material-icons-round text-base px-1 hover:text-alert hover:bg-red-50 rounded-full"
                >delete</span
              >
            </button>
          </icon-with-tooltip>
        </text-area-word-count>
        <button
          v-if="careerHighlights.length < MAX_HIGHLIGHTS"
          @click="careerHighlights.push('')"
          class="button-secondary text-cta-secondary whitespace-nowrap flex items-center justify-center mt-5 xs:mt-0"
        >
          <span class="material-icons-round mr-2 text-sm">add</span>
          Add new highlight
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useIndividual from "@/composables/useIndividual";
import debounce from "@/utils/debounce";
import { useToast } from "vue-toastification";
import { reactive, watch } from "vue";
import TextAreaWordCount from "../shared/TextAreaWordCount.vue";
import IconWithTooltip from "../IconWithTooltip.vue";
import ExampleSlider from "./ExampleSlider.vue";

const { individual, isLoaded, updateIndividual } = useIndividual();
const toast = useToast();

const examples = [
  "Governance thought leader: host of Enter the Boardroom, Top 10 Apple podcast on the Boardroom featuring board member insights going to an audience of 50,000+ board members, judge for Growing Business Awards and course speaker for the Institute of Directors’ Role of the Non Executive Director Course.",
  "High growth leader, from 0-$75M in revenues; 15% of sales from retail and opening 5 physical stores, 15% wholesale & 70% digital. As MD of Reformation was responsible for P&L and growth of UK, European region ~20% of global business.",
  "Recognized leader in fashion retail space: Voted “Most Sustainable Women's Clothing & Accessories Managing Director (London)” twice by the 2022 and 2023 SME Managing Director of the Year Awards, one of 300 leaders selected regionally while MD of Reformation.",
  "Scale up CEO: helped bootstrap Nurole from 0 to >60,000 members with 50+ employees, ranking in the FT1000 fastest growing companies in Europe (2019-22) and winner of UK digital startup of the year. Key achievements include increasing placement rates from ~30% to >90%, launching paid membership subscription revenue streams, successful GDPR and Cyber Essential third party accreditation, and building a product centric and data-led organisation.",
];

const careerHighlights = reactive(Array<string>());

const MAX_HIGHLIGHTS = 5;
const INITIAL_HIGHLIGHTS = 3;

const initialiseHighlights = () => {
  const numberOfHighlights = Math.max(
    individual.value.career_highlights?.length,
    INITIAL_HIGHLIGHTS
  );
  for (let i = 0; i < numberOfHighlights; i++) {
    careerHighlights.push(individual.value.career_highlights?.[i] || "");
  }
};

initialiseHighlights();

watch(isLoaded, () => {
  if (isLoaded.value) {
    initialiseHighlights();
  }
});

const deleteHighlight = (index: number) => {
  careerHighlights.splice(index, 1);
  saveChanges();
};

const saveChanges = debounce(async function () {
  await updateIndividual(
    {
      career_highlights: careerHighlights,
    },
    "career_highlights"
  );
  toast.success("Changes saved successfully", { timeout: 3000 });
}, 1000);
</script>
