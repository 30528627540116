export default [
  {
    name: "Acting Chief Executive Officer",
    searchValues: ["ACEO"],
  },
  {
    name: "Acting Chief Executive Officer/Chief Financial Officer",
    searchValues: ["ACEOCFO"],
  },
  {
    name: "Account Manager",
    searchValues: [],
  },
  {
    name: "Acting Chief Financial Officer",
    searchValues: ["ACFO"],
  },
  {
    name: "Advisor",
    searchValues: ["Advisor", "Adviser"],
  },
  {
    name: "Advisory Board Member",
    searchValues: ["Board Member", "Member of the Advisory Board"],
  },
  {
    name: "Advisory Council Member",
    searchValues: [],
  },
  {
    name: "Advisory Chair",
    searchValues: ["Advisory Chairman"],
  },
  {
    name: "Associate",
    searchValues: [],
  },
  {
    name: "Assistant Director",
    searchValues: [],
  },
  {
    name: "Associate Director",
    searchValues: [],
  },
  {
    name: "Chief Sustainability Officer",
    searchValues: ["CSO"],
  },
  {
    name: "Acting Finance Director",
    searchValues: ["AFD"],
  },
  {
    name: "Acting President/Chief Executive Officer",
    searchValues: ["APCEO"],
  },
  {
    name: "Assistant Manager",
    searchValues: [],
  },
  {
    name: "Assistant Vice President",
    searchValues: ["AVP"],
  },
  {
    name: "Assistant Vice President - Finance",
    searchValues: ["AVPF"],
  },
  {
    name: "Audit Committee Chair",
    searchValues: ["ACC", "Chair of Audit", "Audit Chair"],
  },
  {
    name: "Barrister",
    searchValues: ["Barrister", "lawyer"],
  },
  {
    name: "Board Member - Executive Director",
    searchValues: ["BMED"],
  },
  {
    name: "Board of Trustees",
    searchValues: ["BT"],
  },
  {
    name: "Business Development Manager",
    searchValues: ["BD Manager"],
  },
  {
    name: "Chair of Trustees",
    searchValues: ["CT"],
  },
  {
    name: "Chair",
    searchValues: ["Chair (Non-executive)", "Chair (Executive)"],
  },
  {
    name: "Remuneration Committee Chair",
    searchValues: [
      "Remco Chair",
      "Chair of Remco",
      "Chair of the Remuneration Committee",
    ],
  },
  {
    name: "Chair of the Board",
    searchValues: ["CB"],
  },
  {
    name: "Chairman & Chief Executive Officer",
    searchValues: ["CCEO"],
  },
  {
    name: "Chairman (Independent Non-Executive Director)",
    searchValues: ["CINED"],
  },
  {
    name: "Chairman (Non-Executive)",
    searchValues: ["CNE"],
  },
  {
    name: "Chairman and Chief Executive Officer",
    searchValues: ["CCEO"],
  },
  {
    name: "Chairman of Trustees",
    searchValues: ["CT"],
  },
  {
    name: "Chairman of the Board",
    searchValues: ["CB"],
  },
  {
    name: "Chairman/Acting Chief Executive Officer",
    searchValues: ["CACEO"],
  },
  {
    name: "Chairman/Chief Executive Officer",
    searchValues: ["CCEO"],
  },
  {
    name: "Chairman/Chief Executive Officer/Chief Financial Officer",
    searchValues: ["CCEOCFO"],
  },
  {
    name: "Chairman/Chief Executive Officer/Managing Director",
    searchValues: ["CCEOMD"],
  },
  {
    name: "Chairman/Chief Financial Officer",
    searchValues: ["CCFO"],
  },
  {
    name: "Chairman/Finance Director",
    searchValues: ["CFD"],
  },
  {
    name: "Chairman/Group Chief Executive Officer",
    searchValues: ["CGCEO"],
  },
  {
    name: "Chairman/Interim Chief Executive Officer",
    searchValues: ["CICEO"],
  },
  {
    name: "Chairman/Managing Director",
    searchValues: ["CMD"],
  },
  {
    name: "Chairman/President/Chief Executive Officer",
    searchValues: ["CPCEO"],
  },
  {
    name: "Chairwoman/Chief Executive Officer",
    searchValues: ["CCEO"],
  },
  {
    name: "Chief Commercial Officer",
    searchValues: ["CCO"],
  },
  {
    name: "Chief Compliance Officer",
    searchValues: ["CCO"],
  },
  {
    name: "Chief Communications Officer",
    searchValues: ["CCO"],
  },
  {
    name: "Chief Creative Officer",
    searchValues: ["CCO"],
  },
  {
    name: "Chief Customer Officer",
    searchValues: ["CCO"],
  },
  { name: "Chief Content Officer", searchValues: ["CCO"] },
  {
    name: "Chief Data Officer",
    searchValues: ["CDO"],
  },
  {
    name: "Chief Digital Officer",
    searchValues: ["CDO"],
  },
  {
    name: "Chief Diversity Officer",
    searchValues: ["CDO"],
  },
  {
    name: "Chief Design Officer",
    searchValues: ["CDO"],
  },
  { name: "Chief Development Officer", searchValues: ["CDO"] },
  {
    name: "Chief Executive Officer",
    searchValues: ["CEO"],
  },
  {
    name: "Chief Ethics Officer",
    searchValues: [],
  },
  {
    name: "Chief Environmental Officer",
    searchValues: [],
  },
  {
    name: "Chief Executive Officer & Chairman",
    searchValues: ["CEOC"],
  },
  {
    name: "Chief Executive Officer & Co-Founder",
    searchValues: ["CEOCF"],
  },
  {
    name: "Chief Executive Officer & Founder",
    searchValues: ["CEOF"],
  },
  {
    name: "Chief Executive Officer Designate",
    searchValues: ["CEOD"],
  },
  {
    name: "Chief Executive Officer and Chairman",
    searchValues: ["CEOC"],
  },
  {
    name: "Chief Executive Officer and Co-Founder",
    searchValues: ["CEOCF"],
  },
  {
    name: "Chief Executive Officer and Co-founder",
    searchValues: ["CEOC"],
  },
  {
    name: "Chief Executive Officer and Founder",
    searchValues: ["CEOF"],
  },
  {
    name: "Chief Executive Officer-in-Residence",
    searchValues: ["CEOR"],
  },
  {
    name: "Chief Executive Officer/Chief Financial Officer",
    searchValues: ["CEOCFO"],
  },
  {
    name: "Chief Executive Officer/Chief Information Officer",
    searchValues: ["CEOCIO"],
  },
  {
    name: "Chief Executive Officer/Chief Innovation Officer",
    searchValues: ["CEOCIO"],
  },
  {
    name: "Chief Executive Officer/Chief Investment Officer",
    searchValues: ["CEOCIO"],
  },
  {
    name: "Chief Executive Officer/Chief Operating Officer",
    searchValues: ["CEOCOO"],
  },
  {
    name: "Chief Executive Officer/Chief Technical Officer",
    searchValues: ["CEOCTO"],
  },
  {
    name: "Chief Executive Officer/Company Secretary",
    searchValues: ["CEOCS"],
  },
  {
    name: "Chief Executive Officer/Division Head",
    searchValues: ["CEODH"],
  },
  {
    name: "Chief Executive Officer/Division Managing Director",
    searchValues: ["CEODMD"],
  },
  {
    name: "Chief Executive Officer/Finance Director",
    searchValues: ["CEOFD"],
  },
  {
    name: "Chief Executive Officer/Founder",
    searchValues: ["CEOF"],
  },
  {
    name: "Chief Executive Officer/General Manager",
    searchValues: ["CEOGM"],
  },
  {
    name: "Chief Executive Officer/Managing Director",
    searchValues: ["CEOMD"],
  },
  {
    name: "Chief Executive Officer/Managing Partner",
    searchValues: ["CEOMP"],
  },
  {
    name: "Chief Executive Officer/Secretary",
    searchValues: ["CEOS"],
  },
  {
    name: "Chief Financial Officer",
    searchValues: [],
  },
  {
    name: "Chief Finance Officer",
    searchValues: ["CFO"],
  },
  {
    name: "Chief Financial Officer & Chief Operating Officer",
    searchValues: ["CFOCOO"],
  },
  {
    name: "Chief Financial Officer Designate",
    searchValues: ["CFOD"],
  },
  {
    name: "Chief Financial Officer and Chief Operating Officer",
    searchValues: ["CFOCOO"],
  },
  {
    name: "Chief Financial Officer/Chief Accounting Officer",
    searchValues: ["CFOCAO"],
  },
  {
    name: "Chief Financial Officer/Chief Administrative Officer",
    searchValues: ["CFOCAO"],
  },
  {
    name: "Chief Financial Officer/Chief Compliance Officer",
    searchValues: ["CFOCCO"],
  },
  {
    name: "Chief Financial Officer/Chief Information Officer",
    searchValues: ["CFOCIO"],
  },
  {
    name: "Chief Financial Officer/Chief Innovation Officer",
    searchValues: ["CFOCIO"],
  },
  {
    name: "Chief Financial Officer/Chief Investment Officer",
    searchValues: ["CFOCIO"],
  },
  {
    name: "Chief Financial Officer/Chief Operating Officer",
    searchValues: ["CFOCOO"],
  },
  {
    name: "Chief Financial Officer/Chief Risk Officer",
    searchValues: ["CFOCRO"],
  },
  {
    name: "Chief Financial Officer/Commercial Director",
    searchValues: ["CFOCD"],
  },
  {
    name: "Chief Financial Officer/Company Secretary",
    searchValues: ["CFOCS"],
  },
  {
    name: "Chief Financial Officer/Controller",
    searchValues: ["CFOC"],
  },
  {
    name: "Chief Financial Officer/Corporate Secretary",
    searchValues: ["CFOCS"],
  },
  {
    name: "Chief Financial Officer/Deputy Chief Executive Officer",
    searchValues: ["CFODCEO"],
  },
  {
    name: "Chief Financial Officer/Director - Finance",
    searchValues: ["CFODF"],
  },
  {
    name: "Chief Financial Officer/Division Head",
    searchValues: ["CFODH"],
  },
  {
    name: "Chief Financial Officer/Executive Vice President - Finance",
    searchValues: ["CFOEVPF"],
  },
  {
    name: "Chief Financial Officer/Finance Director",
    searchValues: ["CFOFD"],
  },
  {
    name: "Chief Financial Officer/Head of Division",
    searchValues: ["CFOHD"],
  },
  {
    name: "Chief Financial Officer/Interim Chief Executive Officer",
    searchValues: ["CFOICEO"],
  },
  {
    name: "Chief Financial Officer/Labour Director",
    searchValues: ["CFOLD"],
  },
  {
    name: "Chief Financial Officer/Partner",
    searchValues: ["CFOP"],
  },
  {
    name: "Chief Financial Officer/Principal",
    searchValues: ["CFOP"],
  },
  {
    name: "Chief Financial Officer/Principal Accounting Officer",
    searchValues: ["CFOPAO"],
  },
  {
    name: "Chief Financial Officer/Secretary",
    searchValues: ["CFOS"],
  },
  {
    name: "Chief Financial Officer/Secretary/Treasurer",
    searchValues: ["CFOST"],
  },
  {
    name: "Chief Financial Officer/Senior Vice President - Finance",
    searchValues: ["CFOSVPF"],
  },
  {
    name: "Chief Financial Officer/Treasurer",
    searchValues: ["CFOT"],
  },
  {
    name: "Chief Financial Officer/Vice President - Finance",
    searchValues: ["CFOVPF"],
  },
  {
    name: "Chief Financial and Operations Officer",
    searchValues: ["CFOO"],
  },
  {
    name: "Chief Human Resources Officer",
    searchValues: ["CHRO"],
  },
  {
    name: "Chief Human Rights Officer",
    searchValues: ["CHRO"],
  },
  {
    name: "Chief Information Officer",
    searchValues: ["CIO"],
  },
  {
    name: "Chief Innovation Officer",
    searchValues: ["CIO"],
  },
  {
    name: "Chief Investment Officer",
    searchValues: ["CIO"],
  },
  {
    name: "Chief Intelligence Officer",
    searchValues: ["CIO"],
  },
  {
    name: "Chief Information Security Officer",
    searchValues: ["CISO"],
  },
  {
    name: "Chief Internet Security Officer",
    searchValues: ["CISO"],
  },
  {
    name: "Chief Marketing Officer",
    searchValues: ["CMO"],
  },
  {
    name: "Chief Medical Officer",
    searchValues: ["CMO"],
  },
  { name: "Chief Merchandising Officer", searchValues: ["CMO"] },
  { name: "Chief Mobility Officer", searchValues: ["CMO"] },
  {
    name: "Chief Operating Officer",
    searchValues: [],
  },
  {
    name: "Chief Operations Officer",
    searchValues: ["COO"],
  },
  {
    name: "Chief Operating Officer and Co-founder",
    searchValues: ["COOC"],
  },
  {
    name: "Chief Operating Officer/Chief Financial Officer",
    searchValues: ["COOCFO"],
  },
  {
    name: "Chief Operating Officer/Chief Financial Officer/Secretary",
    searchValues: ["COOCFOS"],
  },
  {
    name: "Chief Operating Officer/Chief Technical Officer",
    searchValues: ["COOCTO"],
  },
  {
    name: "Chief Operating Officer/Finance Director",
    searchValues: ["COOFD"],
  },
  {
    name: "Chief Operating Officer/Finance Director/Secretary",
    searchValues: ["COOFDS"],
  },
  {
    name: "Chief Operating Officer/Secretary",
    searchValues: ["COOS"],
  },
  {
    name: "Chief Performance Officer",
    searchValues: ["CPO"],
  },
  {
    name: "Chief Personnel Officer",
    searchValues: ["CPO"],
  },
  {
    name: "Chief Planning Officer",
    searchValues: ["CPO"],
  },
  {
    name: "Chief Process Officer",
    searchValues: ["CPO"],
  },
  {
    name: "Chief Procurement Officer",
    searchValues: ["CPO"],
  },
  {
    name: "Chief Product Officer",
    searchValues: ["CPO"],
  },
  {
    name: "Chief People Officer",
    searchValues: ["CPO"],
  },
  {
    name: "Chief Privacy Officer",
    searchValues: ["CPO"],
  },
  {
    name: "Chief Product and Marketing Officer",
    searchValues: ["CPMO"],
  },
  {
    name: "Chief Research Officer",
    searchValues: ["CRO"],
  },
  {
    name: "Chief Revenue Officer",
    searchValues: ["CRO"],
  },
  {
    name: "Chief Risk Officer",
    searchValues: ["CRO"],
  },
  {
    name: "Chief Responsibility Officer",
    searchValues: ["CRO"],
  },
  {
    name: "Chief Resilience Officer",
    searchValues: ["CRO"],
  },
  {
    name: "Chief Sales Officer",
    searchValues: ["CSO"],
  },
  {
    name: "Chief Scientific Officer",
    searchValues: ["CSO"],
  },
  {
    name: "Chief Security Officer",
    searchValues: ["CSO"],
  },
  {
    name: "Chief Strategy Officer",
    searchValues: ["CSO"],
  },
  {
    name: "Chief Science Officer",
    searchValues: ["CSO"],
  },
  {
    name: "Chief Sustainability Officer",
    searchValues: ["CSO"],
  },
  {
    name: "Chief Technical Officer",
    searchValues: ["CTO"],
  },
  {
    name: "Chief Technology Officer",
    searchValues: ["CTO"],
  },
  {
    name: "Chief Transformation Officer",
    searchValues: ["CTO"],
  },
  {
    name: "Chief Technical Officer & Co-Founder",
    searchValues: ["CTOCF"],
  },
  {
    name: "Chief of Staff",
    searchValues: ["CS"],
  },
  {
    name: "Co-Chairman",
    searchValues: ["CC"],
  },
  {
    name: "Co-Chief Executive Officer",
    searchValues: ["CCEO"],
  },
  {
    name: "Co-Chief Financial Officer",
    searchValues: ["CCFO"],
  },
  {
    name: "Co-Founder",
    searchValues: ["CF"],
  },
  {
    name: "Co-Founder & Chief Executive Officer",
    searchValues: ["CFCEO"],
  },
  {
    name: "Co-Founder and Chief Executive Officer",
    searchValues: ["CFCEO"],
  },
  {
    name: "Co-Fund Manager",
    searchValues: ["CFM"],
  },
  {
    name: "Co-Head",
    searchValues: ["CH"],
  },
  {
    name: "Co-Head - Investment Banking",
    searchValues: ["CHIB"],
  },
  {
    name: "Co-Head of Division",
    searchValues: ["CHD"],
  },
  {
    name: "Co-Managing Director",
    searchValues: ["CMD"],
  },
  {
    name: "Co-Managing Partner",
    searchValues: ["CMP"],
  },
  {
    name: "Co-President",
    searchValues: ["CP"],
  },
  {
    name: "Co-founder & Chief Executive Officer",
    searchValues: ["CCEO"],
  },
  {
    name: "Co-founder and Chief Executive Officer",
    searchValues: ["CCEO"],
  },
  {
    name: "Co-founder and Chief Operating Officer",
    searchValues: ["CCOO"],
  },
  {
    name: "Co-founder and Chief Performance Officer",
    searchValues: ["CCPO"],
  },
  {
    name: "Co-founder and Chief Personnel Officer",
    searchValues: ["CCPO"],
  },
  {
    name: "Co-founder and Chief Planning Officer",
    searchValues: ["CCPO"],
  },
  {
    name: "Co-founder and Chief Process Officer",
    searchValues: ["CCPO"],
  },
  {
    name: "Co-founder and Chief Procurement Officer",
    searchValues: ["CCPO"],
  },
  {
    name: "Co-founder and Chief Product Officer",
    searchValues: ["CCPO"],
  },
  {
    name: "Co-founder and Chief Technical Officer",
    searchValues: ["CCTO"],
  },
  {
    name: "Commercial Director",
    searchValues: [],
  },
  {
    name: "Commercial Finance Director",
    searchValues: ["CFD"],
  },
  {
    name: "Corporate Finance Director",
    searchValues: ["CFD"],
  },
  {
    name: "Corporate Vice President",
    searchValues: ["CVP"],
  },
  {
    name: "Corporate Vice President - Human Resources",
    searchValues: ["CVPHR"],
  },
  {
    name: "Corporate Vice President/Chief Financial Officer",
    searchValues: ["CVPCFO"],
  },
  {
    name: "Country Managing Director",
    searchValues: ["CMD"],
  },
  {
    name: "Deputy Chief Executive Officer",
    searchValues: ["DCEO"],
  },
  {
    name: "Deputy Chief Executive Officer/Chief Financial Officer",
    searchValues: ["DCEOCFO"],
  },
  {
    name: "Deputy Chief Executive Officer/Chief Operating Officer",
    searchValues: ["DCEOCOO"],
  },
  {
    name: "Deputy Chief Financial Officer",
    searchValues: ["DCFO"],
  },
  {
    name: "Deputy Chief Operating Officer",
    searchValues: ["DCOO"],
  },
  {
    name: "Deputy Finance Director",
    searchValues: ["DFD"],
  },
  {
    name: "Deputy Group Chief Executive Officer",
    searchValues: ["DGCEO"],
  },
  {
    name: "Deputy Group Chief Financial Officer",
    searchValues: ["DGCFO"],
  },
  {
    name: "Deputy Group Finance Director",
    searchValues: ["DGFD"],
  },
  {
    name: "Deputy Managing Director",
    searchValues: ["DMD"],
  },
  {
    name: "Director",
  },
  {
    name: "Director - Business Development",
    searchValues: [
      "Director of Business Development",
      "Director of BD",
      "Business Development Director",
    ],
  },
  {
    name: "Director - Development",
    searchValues: ["Director of Development"],
  },
  {
    name: "Director - Finance/Information Technology",
    searchValues: ["DFIT", "Director of Finance/Information Technology"],
  },
  {
    name: "Director - Finance/Operations",
    searchValues: ["DFO", "Director of Finance/Operations"],
  },
  {
    name: "Director - Operations",
    searchValues: ["DO", "Director of Operations"],
  },
  {
    name: "Director - Public Relations",
    searchValues: ["DPR", "Director of Public Relations"],
  },
  {
    name: "Director - Tax",
    searchValues: ["DT"],
  },
  {
    name: "Director Non-Board",
    searchValues: ["DNB"],
  },
  {
    name: "Director - Finance",
    searchValues: ["DF", "Director of Finance"],
  },
  {
    name: "Division Acting Chief Executive Officer",
    searchValues: ["DACEO"],
  },
  {
    name: "Division Chairman/Chief Executive Officer",
    searchValues: ["DCCEO"],
  },
  {
    name: "Division Chairman/Division Chief Executive Officer",
    searchValues: ["DCDCEO"],
  },
  {
    name: "Division Chairman/Managing Director",
    searchValues: ["DCMD"],
  },
  {
    name: "Division Chief Executive Officer",
    searchValues: ["DCEO"],
  },
  {
    name: "Division Chief Executive Officer/Division Managing Director",
    searchValues: ["DCEODMD"],
  },
  {
    name: "Division Chief Executive Officer/Managing Director",
    searchValues: ["DCEOMD"],
  },
  {
    name: "Division Chief Financial Officer",
    searchValues: ["DCFO"],
  },
  {
    name: "Division Chief Information Officer",
    searchValues: ["DCIO"],
  },
  {
    name: "Division Chief Innovation Officer",
    searchValues: ["DCIO"],
  },
  {
    name: "Division Chief Investment Officer",
    searchValues: ["DCIO"],
  },
  {
    name: "Division Chief Operating Officer",
    searchValues: ["DCOO"],
  },
  {
    name: "Division Chief Technical Officer",
    searchValues: ["DCTO"],
  },
  {
    name: "Division Co-Chairman",
    searchValues: ["DCC"],
  },
  {
    name: "Division Co-Chief Executive Officer",
    searchValues: ["DCCEO"],
  },
  {
    name: "Division Co-Managing Director",
    searchValues: ["DCMD"],
  },
  {
    name: "Division Deputy Chief Executive Officer",
    searchValues: ["DDCEO"],
  },
  {
    name: "Division Director",
  },
  {
    name: "Division Director - Human Resources",
    searchValues: ["DDHR"],
  },
  {
    name: "Division Director - Operations",
    searchValues: ["DDO"],
  },
  {
    name: "Division Director - Finance",
    searchValues: [],
  },
  {
    name: "Division Executive Director",
    searchValues: ["DED"],
  },
  {
    name: "Division Executive Vice President",
    searchValues: ["DEVP"],
  },
  {
    name: "Division Executive Vice President/Chief Financial Officer",
    searchValues: ["DEVPCFO"],
  },
  {
    name: "Division Finance Director",
    searchValues: ["DFD"],
  },
  {
    name: "Division Head",
    searchValues: [],
  },
  {
    name: "Division Interim Chief Executive Officer",
    searchValues: ["DICEO"],
  },
  {
    name: "Division Co-Head",
    searchValues: ["DCH"],
  },
  {
    name: "Divison Manager",
    searchValues: [],
  },
  {
    name: "Division Manager - Human Resources",
    searchValues: ["DMHR"],
  },
  {
    name: "Division Managing Director",
    searchValues: ["DMD"],
  },
  {
    name: "Division Managing Director/Division Chief Financial Officer",
    searchValues: ["DMDDCFO"],
  },
  {
    name: "Division Managing Director/Head",
    searchValues: ["DMDH"],
  },
  {
    name: "Division President - Operations",
    searchValues: ["DPO"],
  },
  {
    name: "Division President/Chief Executive Officer",
    searchValues: ["DPCEO"],
  },
  {
    name: "Division President/Chief Operating Officer",
    searchValues: ["DPCOO"],
  },
  {
    name: "Division President/Division Chief Executive Officer",
    searchValues: ["DPDCEO"],
  },
  {
    name: "Division Senior Managing Director",
    searchValues: ["DSMD"],
  },
  {
    name: "Division Senior Vice President",
    searchValues: ["DSVP"],
  },
  {
    name: "Division Senior Vice President/Chief Financial Officer",
    searchValues: ["DSVPCFO"],
  },
  {
    name: "Division Senior Vice President/General Manager",
    searchValues: ["DSVPGM"],
  },
  {
    name: "Division Vice President",
    searchValues: ["DVP"],
  },
  {
    name: "Division Vice President - Business Development",
    searchValues: ["DVPBD"],
  },
  {
    name: "Division Vice President - Finance",
    searchValues: ["DVPF"],
  },
  {
    name: "Division Vice President - Human Resources",
    searchValues: ["DVPHR"],
  },
  {
    name: "Division Vice President - Marketing",
    searchValues: ["DVPM"],
  },
  {
    name: "Division Vice President - Operations",
    searchValues: ["DVPO"],
  },
  {
    name: "Division Vice President - Sales/Marketing",
    searchValues: ["DVPSM"],
  },
  {
    name: "Division Vice President/Chief Financial Officer",
    searchValues: ["DVPCFO"],
  },
  {
    name: "Division Vice President/General Manager",
    searchValues: ["DVPGM"],
  },
  {
    name: "Editor-in-Chief",
    searchValues: ["EC"],
  },
  {
    name: "Engineer",
    searchValues: [],
  },
  {
    name: "Entrepreneur-in-Residence",
    searchValues: ["ER"],
  },
  {
    name: "Executive Director",
    searchValues: ["ED"],
  },
  {
    name: "Executive Director - Business Development",
    searchValues: ["EDBD"],
  },
  {
    name: "Executive Director - Commercial",
    searchValues: ["EDC"],
  },
  {
    name: "Executive Director - Development",
    searchValues: ["EDD"],
  },
  {
    name: "Executive Director - Finance",
    searchValues: ["EDF"],
  },
  {
    name: "Executive Director - Human Resources",
    searchValues: ["EDHR"],
  },
  {
    name: "Executive Director - Information Technology",
    searchValues: ["EDIT"],
  },
  {
    name: "Executive Director - Marketing",
    searchValues: ["EDM"],
  },
  {
    name: "Executive Director - Non-Board",
    searchValues: ["EDNB"],
  },
  {
    name: "Executive Director - Operations",
    searchValues: ["EDO"],
  },
  {
    name: "Executive Director - People",
    searchValues: ["EDP"],
  },
  {
    name: "Executive Director - Retail",
    searchValues: ["EDR"],
  },
  {
    name: "Executive Director - Sales/Marketing",
    searchValues: ["EDSM"],
  },
  {
    name: "Executive Director - Strategy",
    searchValues: ["EDS"],
  },
  {
    name: "Executive Director/Secretary",
    searchValues: ["EDS"],
  },
  {
    name: "Executive Managing Director",
    searchValues: ["EMD"],
  },
  {
    name: "Executive Vice President",
    searchValues: ["EVP"],
  },
  {
    name: "Executive Vice President & Chief Financial Officer",
    searchValues: ["EVPCFO"],
  },
  {
    name: "Executive Vice President - Business Development",
    searchValues: ["EVPBD"],
  },
  {
    name: "Executive Vice President - Commercial",
    searchValues: ["EVPC"],
  },
  {
    name: "Executive Vice President - Commercial Operations",
    searchValues: ["EVPCO"],
  },
  {
    name: "Executive Vice President - Corporate Development",
    searchValues: ["EVPCD"],
  },
  {
    name: "Executive Vice President - Development",
    searchValues: ["EVPD"],
  },
  {
    name: "Executive Vice President - Finance",
    searchValues: ["EVPF"],
  },
  {
    name: "Executive Vice President - Finance/Administration",
    searchValues: ["EVPFA"],
  },
  {
    name: "Executive Vice President - Global Sales",
    searchValues: ["EVPGS"],
  },
  {
    name: "Executive Vice President - Human Resources",
    searchValues: ["EVPHR"],
  },
  {
    name: "Executive Vice President - Marketing",
    searchValues: ["EVPM"],
  },
  {
    name: "Executive Vice President - Operations",
    searchValues: ["EVPO"],
  },
  {
    name: "Executive Vice President - Research & Development",
    searchValues: ["EVPRD"],
  },
  {
    name: "Executive Vice President - Sales",
    searchValues: ["EVPS"],
  },
  {
    name: "Executive Vice President - Sales/Marketing",
    searchValues: ["EVPSM"],
  },
  {
    name: "Executive Vice President - Services",
    searchValues: ["EVPS"],
  },
  {
    name: "Executive Vice President - Strategy",
    searchValues: ["EVPS"],
  },
  {
    name: "Executive Vice President - Technology",
    searchValues: ["EVPT"],
  },
  {
    name: "Executive Vice President/Chief Administrative Officer",
    searchValues: ["EVPCAO"],
  },
  {
    name: "Executive Vice President/Chief Executive Officer",
    searchValues: ["EVPCEO"],
  },
  {
    name: "Executive Vice President/Chief Financial Officer",
    searchValues: ["EVPCFO"],
  },
  {
    name: "Executive Vice President/Chief Financial Officer/Chief Accounting Officer",
    searchValues: ["EVPCFOCAO"],
  },
  {
    name: "Executive Vice President/Chief Financial Officer/Chief Operating Officer",
    searchValues: ["EVPCFOCOO"],
  },
  {
    name: "Executive Vice President/Chief Financial Officer/Principal Accounting Officer",
    searchValues: ["EVPCFOPAO"],
  },
  {
    name: "Executive Vice President/Chief Financial Officer/Principal Financial Officer",
    searchValues: ["EVPCFOPFO"],
  },
  {
    name: "Executive Vice President/Chief Financial Officer/Secretary",
    searchValues: ["EVPCFOS"],
  },
  {
    name: "Executive Vice President/Chief Financial Officer/Treasurer",
    searchValues: ["EVPCFOT"],
  },
  {
    name: "Executive Vice President/Chief Human Resources Officer",
    searchValues: ["EVPCHRO"],
  },
  {
    name: "Executive Vice President/Chief Information Officer",
    searchValues: ["EVPCIO"],
  },
  {
    name: "Executive Vice President/Chief Investment Officer",
    searchValues: ["EVPCIO"],
  },
  {
    name: "Executive Vice President/Chief Marketing Officer",
    searchValues: ["EVPCMO"],
  },
  {
    name: "Executive Vice President/Chief Operating Officer",
    searchValues: ["EVPCOO"],
  },
  {
    name: "Executive Vice President/Chief Technology Officer",
    searchValues: ["EVPCTO"],
  },
  {
    name: "Executive Vice President/Division Chief Executive Officer",
    searchValues: ["EVPDCEO"],
  },
  {
    name: "Executive Vice President/Division General Manager",
    searchValues: ["EVPDGM"],
  },
  {
    name: "Executive Vice President/Division Head",
    searchValues: ["EVPDH"],
  },
  {
    name: "Executive Vice President/Division President",
    searchValues: ["EVPDP"],
  },
  {
    name: "Executive Vice President/General Counsel",
    searchValues: ["EVPGC"],
  },
  {
    name: "Executive Vice President/General Counsel/Corporate Secretary",
    searchValues: ["EVPGCCS"],
  },
  {
    name: "Executive Vice President/General Manager",
    searchValues: ["EVPGM"],
  },
  {
    name: "Executive Vice President/Group Chief Financial Officer",
    searchValues: ["EVPGCFO"],
  },
  {
    name: "Executive Vice President/Managing Director",
    searchValues: ["EVPMD"],
  },
  {
    name: "Executive-in-Residence",
    searchValues: ["ER"],
  },
  {
    name: "Finance Director",
    searchValues: ["FD"],
  },
  {
    name: "Finance Director/Chief Financial Officer/Company Secretary",
    searchValues: ["FDCFOCS"],
  },
  {
    name: "Finance Director/Company Secretary",
    searchValues: ["FDCS"],
  },
  {
    name: "Finance Director/Controller",
    searchValues: ["FDC"],
  },
  {
    name: "Finance Director/Deputy Chief Executive Officer",
    searchValues: ["FDDCEO"],
  },
  {
    name: "Finance Director/Secretary",
    searchValues: ["FDS"],
  },
  {
    name: "Financial Director",
    searchValues: ["FD"],
  },
  {
    name: "Finance Manager",
  },
  {
    name: "Former Chief Executive Officer",
    searchValues: ["FCEO"],
  },
  {
    name: "Former Chief Financial Officer",
    searchValues: ["FCFO"],
  },
  {
    name: "Founder & Chief Executive Officer",
    searchValues: ["FCEO"],
  },
  {
    name: "Founder / Chief Executive Officer",
    searchValues: ["FCEO"],
  },
  {
    name: "Founder and Chairman",
    searchValues: ["FC"],
  },
  {
    name: "Founder and Chief Executive Officer",
    searchValues: ["FCEO"],
  },
  {
    name: "Founder and Managing Partner",
    searchValues: ["FMP"],
  },
  {
    name: "Founder/Chief Executive Officer",
    searchValues: ["FCEO"],
  },
  {
    name: "Founding Partner",
    searchValues: [],
  },
  {
    name: "Founding Partner/Chief Executive Officer",
    searchValues: ["FPCEO"],
  },
  {
    name: "Fund Manager",
    searchValues: [],
  },
  {
    name: "General Manager",
    searchValues: ["GM"],
  },
  {
    name: "Division General Manager",
    searchValues: [],
  },
  {
    name: "General Manager - Human Resources",
    searchValues: ["GMHR"],
  },
  {
    name: "General Partner",
    searchValues: [],
  },
  {
    name: "Global Chief Executive Officer",
    searchValues: ["GCEO"],
  },
  {
    name: "Global Chief Financial Officer",
    searchValues: ["GCFO"],
  },
  {
    name: "Global Finance Director",
    searchValues: ["GFD"],
  },
  {
    name: "Global Head",
    searchValues: [],
  },
  {
    name: "Global Co-Head",
    searchValues: ["GCH"],
  },
  {
    name: "Global Head of Human Resources",
    searchValues: ["GHHR"],
  },
  {
    name: "Global Head of Marketing",
    searchValues: ["GHM"],
  },
  {
    name: "Global Human Resources Director",
    searchValues: ["GHRD"],
  },
  {
    name: "Group Chief Executive Officer",
    searchValues: ["GCEO"],
  },
  {
    name: "Group Chief Financial Officer",
    searchValues: ["GCFO"],
  },
  {
    name: "Group Chief Financial Officer/Company Secretary",
    searchValues: ["GCFOCS"],
  },
  {
    name: "Group Chief Operating Officer",
    searchValues: ["GCOO"],
  },
  {
    name: "Group Chief Technical Officer",
    searchValues: ["GCTO"],
  },
  {
    name: "Group Director",
    searchValues: [],
  },
  {
    name: "Group Director - Human Resources",
    searchValues: ["GDHR", "Group Director - HR"],
  },
  {
    name: "Group Director - Information Technology",
    searchValues: ["GDIT", "Group Director - IT"],
  },
  {
    name: "Group Executive Director",
    searchValues: ["GED"],
  },
  {
    name: "Group Executive Vice President",
    searchValues: ["GEVP"],
  },
  {
    name: "Group Executive/Chief Financial Officer",
    searchValues: ["GECFO"],
  },
  {
    name: "Group Finance Controller",
    searchValues: ["GFC"],
  },
  {
    name: "Group Finance Director",
    searchValues: ["GFD"],
  },
  {
    name: "Group Finance Director Designate",
    searchValues: ["GFDD"],
  },
  {
    name: "Group Finance Director/Chief Operating Officer",
    searchValues: ["GFDCOO"],
  },
  {
    name: "Group Finance Director/Company Secretary",
    searchValues: ["GFDCS"],
  },
  {
    name: "Group Finance Director/Secretary",
    searchValues: ["GFDS"],
  },
  {
    name: "Group Head - Human Resources",
    searchValues: ["GHHR"],
  },
  {
    name: "Group Human Resources Director",
    searchValues: ["GHRD"],
  },
  {
    name: "Group Manager - Human Resources",
    searchValues: ["GMHR"],
  },
  {
    name: "Group Managing Director",
    searchValues: ["GMD"],
  },
  {
    name: "Group President/Chief Executive Officer",
    searchValues: ["GPCEO"],
  },
  {
    name: "Group Senior Managing Director",
    searchValues: ["GSMD"],
  },
  {
    name: "Group Vice President",
    searchValues: ["GVP"],
  },
  {
    name: "NED (Non-Executive Director)",
    searchValues: ["NED"],
  },
];
