<template>
  <compensated
    title="Preferences for compensated roles"
    :individual="individual"
    @update-individual-and-put="updateProfessionalDNA"
  ></compensated>
</template>
<script lang="ts" setup>
import Compensated from "@/views/onboarding/CompensatedPreferences.vue";

import useIndividual from "@/composables/useIndividual";
const { individual, updateIndividual } = useIndividual();

const updateProfessionalDNA = async (event: any) => {
  await updateIndividual(event, "preferences_compensated");
};
</script>
