<template>
  <p
    v-if="$route.query.recommended"
    data-cy="recommendation-success-message"
    class="m-0 bg-sage-100 rounded-lg p-6 copy-emphasis my-10"
  >
    Your recommendation has been successfully submitted.
  </p>
  <h2 class="mt-0">About {{ role.name }}</h2>
  <p class="text-w-link" v-html="role.summary"></p>
  <template v-if="role.culture">
    <h2>Culture</h2>
    <p class="text-w-link" v-html="processedCulture"></p>
  </template>
  <template v-if="role.role_specification">
    <h2>Role specification</h2>
    <p class="text-w-link" v-html="processedRoleSpecification"></p>
  </template>
  <template v-if="role.person_specification">
    <h2>Person specification</h2>
    <p class="text-w-link" v-html="processedPersonSpecification"></p>
  </template>
  <template v-if="role.required">
    <h2>Required for this role</h2>
    <ul>
      <li v-for="(required, index) in role.required" :key="index">
        <h6>
          <em class="copy-emphasis">
            {{ required.substituted_title }}
          </em>
        </h6>
        {{ required.substituted_description }}
      </li>
    </ul>
  </template>
  <template v-if="role.desired">
    <h2>
      >Desired for this role{{
        role.one_desired_required
          ? " (candidates should bring one of the following)"
          : ""
      }}
      >
    </h2>
    <ul>
      <template v-for="(desired, index) in role.desired" :key="index">
        <p
          v-if="index !== 0 && role.one_desired_required"
          style="font-weight: 900; text-align: center"
        >
          OR
        </p>
        <li>
          <h6>
            <em class="copy-emphasis">
              {{ desired.substituted_title }}
            </em>
          </h6>
          {{ desired.substituted_description }}
        </li>
      </template>
    </ul>
  </template>
  <template v-if="role.board_composition">
    <h2>Board composition</h2>
    <p class="text-w-link" v-html="processedBoardComposition"></p>
  </template>
  <template v-for="n in 5" :key="n">
    <template v-if="role[`additional_${n}_title`]">
      <h2>
        {{ role[`additional_${n}_title`] }}
      </h2>
      <p class="text-w-link" v-html="processedAdditionalText(n)"></p>
    </template>
  </template>
  <hr v-if="isMemberOrPreview && !hideRoleInteractionElements" />
  <role-questions
    v-if="isMemberOrPreview && !hideRoleInteractionElements"
    :role="role"
  />
  <hr />
  <h2>Role timetable</h2>
  <p data-cy="timetable-deadline">
    Deadline for applications: Midnight on
    {{ formatDeadline(role.deadline) }}
  </p>
  <p data-cy="timetable-contacted">
    Candidates can expect to be contacted by:
    {{ formatDate(role.first_contact_date, "do MMMM") }}
  </p>
  <p data-cy="timetable-completed">
    Candidates can expect the process to be completed by:
    {{ formatDate(role.decision_date, "do MMMM") }}
  </p>
  <template v-if="role.timeline">
    <p class="text-w-link" v-html="processedTimeline"></p>
  </template>
  <hr v-if="!hideRoleInteractionElements" />

  <div
    class="flex flex-col gap-4 md:flex-row"
    v-if="!hasApplied && !hideRoleInteractionElements"
  >
    <button
      v-if="showQuickApplyButton"
      @click="openQuickApplyModal"
      class="button-primary text-cta-primary flex justify-center items-center gap-2"
    >
      Quick apply
    </button>
    <button
      v-else
      @click.prevent="applyToRole"
      class="button-primary text-cta-primary flex justify-center items-center"
    >
      Apply for role
    </button>

    <router-link
      v-if="isMember"
      :to="`/roles/${role.id}/recommend#tabs`"
      class="button-secondary text-cta-secondary flex justify-center items-center"
    >
      Recommend someone
    </router-link>
    <watchlist-button v-if="isMember" :roleId="role.id" />
  </div>
  <quick-apply :role="role" ref="quickApplyModal" />
  <magic-link-modal
    v-if="showMagicLinkModal"
    :redirect-link="magicLinkRedirect"
    @close-magic-link-modal="showMagicLinkModal = false"
  />
</template>

<script lang="ts">
import RoleQuestions from "@/components/RoleQuestions.vue";
import { formatDeadline } from "@/helpers/roleHelpers";
import { format as formatDate } from "date-fns/format";
import WatchlistButton from "@/components/WatchlistButton.vue";
import QuickApply from "@/components/QuickApply.vue";
import useIndividual from "@/composables/useIndividual";
import MagicLinkModal from "@/components/MagicLinkModal.vue";
import useAuth from "@/composables/useAuth";
import { addAttributeTo } from "@/utils/html";

export default {
  props: ["role", "backLink"],
  components: {
    RoleQuestions,
    WatchlistButton,
    QuickApply,
    MagicLinkModal,
  },
  data() {
    return {
      showMagicLinkModal: false,
      magicLinkRedirect: `https://${window.location.host}${this.$router.currentRoute.value.fullPath}/apply`,
    };
  },
  setup() {
    const { individual, isMember } = useIndividual();
    const { authLevel } = useAuth();
    return {
      formatDeadline,
      individual,
      authLevel,
      isMember,
    };
  },
  methods: {
    formatDate: (date: string, format: string) => {
      if (!date) return "???";
      return formatDate(Date.parse(date), format);
    },
    openQuickApplyModal() {
      this.$refs.quickApplyModal.modalOpen = true;
    },
    applyToRole() {
      if (
        this.authLevel == "standard" ||
        this.authLevel == "punchthrough" ||
        this.authLevel == "impersonate"
      ) {
        this.$router.push(`/roles/${this.role.id}/apply`);
      } else {
        this.showMagicLinkModal = true;
      }
    },
  },
  computed: {
    hasApplied() {
      return this.role.current_user_has_applied;
    },
    quickApplyEnabled() {
      return this.role.is_quick_apply;
    },
    showQuickApplyButton() {
      return this.quickApplyEnabled && this.isMember;
    },
    isMemberOrPreview() {
      return this.isMember || this.authLevel == "preview-mode";
    },
    hideRoleInteractionElements() {
      return (
        this.authLevel == "preview-mode" &&
        this.$route.meta.hideInteractionElements
      );
    },
    processedRoleSpecification() {
      return addAttributeTo(this.role.role_specification, "a", {
        target: "_blank",
      });
    },
    processedPersonSpecification() {
      return addAttributeTo(this.role.person_specification, "a", {
        target: "_blank",
      });
    },
    processedCulture() {
      return addAttributeTo(this.role.culture, "a", {
        target: "_blank",
      });
    },
    processedBoardComposition() {
      return addAttributeTo(this.role.board_composition, "a", {
        target: "_blank",
      });
    },
    processedTimeline() {
      return addAttributeTo(this.role.timeline, "a", {
        target: "_blank",
      });
    },
    processedAdditionalText() {
      return (n: number) =>
        addAttributeTo(this.role[`additional_${n}_text`], "a", {
          target: "_blank",
        });
    },
  },
};
</script>

<style scoped>
h2,
:deep(h2) {
  @apply mt-12 mb-4;
}

p,
:deep(p) {
  @apply mb-4;
}

p a,
:deep(p a) {
  text-decoration: underline;
}

ul,
:deep(ul) {
  @apply list-disc ml-6 mb-8;
}

li,
:deep(li) {
  @apply list-disc mb-1;
}

h6,
:deep(h6) {
  @apply mt-12;
}

hr,
:deep(hr) {
  @apply border-solid md:my-6 lg:my-10;
}

/* Typography */
p,
:deep(p),
li,
:deep(li),
ul,
:deep(ul) {
  @apply text-lg;
  line-height: 26px;
}
</style>
