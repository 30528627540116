<template>
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 0C9.41992 0 0 9.41992 0 21C0 32.5801 9.41992 42 21 42C32.5801 42 42 32.5801 42 21C42 9.41992 32.5801 0 21 0ZM21 3.5C30.6865 3.5 38.5 11.3135 38.5 21C38.5 30.6865 30.6865 38.5 21 38.5C11.3135 38.5 3.5 30.6865 3.5 21C3.5 11.3135 11.3135 3.5 21 3.5ZM11.375 12.25C9.92578 12.25 8.75 13.4258 8.75 14.875C8.75 15.0391 8.76367 15.1963 8.80469 15.3672C8.87988 15.7842 9.05078 16.1396 9.29688 16.4609C10.6094 18.7236 14 21 14 21C14 21 19.25 17.6641 19.25 14.875C19.25 13.4258 18.0742 12.25 16.625 12.25C15.1758 12.25 14 13.4258 14 14.875C14 13.4258 12.8242 12.25 11.375 12.25ZM25.375 12.25C23.9258 12.25 22.75 13.4258 22.75 14.875C22.75 15.0391 22.7637 15.1963 22.8047 15.3672C22.8799 15.7842 23.0508 16.1396 23.2969 16.4609C24.6094 18.7236 28 21 28 21C28 21 31.4795 18.7441 32.7578 16.4062C33.0381 15.8867 33.25 15.3809 33.25 14.875C33.25 13.4258 32.0742 12.25 30.625 12.25C29.1758 12.25 28 13.4258 28 14.875C28 13.4258 26.8242 12.25 25.375 12.25ZM11.9219 26.25L8.91406 28C11.334 32.1768 15.832 35 21 35C26.168 35 30.666 32.1768 33.0859 28L30.0781 26.25C28.2598 29.3877 24.8965 31.5 21 31.5C17.1035 31.5 13.7402 29.3877 11.9219 26.25Z"
      :fill="fill"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "IconSmileLove",

  props: {
    fill: {
      type: String,
      default: "#41578A",
    },
  },
});
</script>
