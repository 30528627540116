<template>
  <button
    @click="updateWatchlist"
    class="focus:outline-none hover:opacity-50 p-0"
    data-cy="roles-watchlist-button"
  >
    <div
      class="text-cta-secondary w-full block py-3 bg-white text-sage-700 border border-solid border-sage-700 rounded-full whitespace-nowrap sm:w-56 lg:hidden"
      :class="{
        'bg-sage-200': isWatching,
      }"
    >
      <span
        v-if="isLoading"
        class="material-icons-round animate-spin text-sm text-ivory-700"
      >
        refresh
      </span>
      <div v-else class="px-6 flex items-center justify-center">
        <span
          class="fade-in material-icons-round text-18/18"
          v-show="isWatching"
        >
          star
        </span>
        <span
          class="fade-in material-icons-round text-18/18"
          v-show="!isWatching"
        >
          star_outline
        </span>
        <div class="fade-in pl-1" v-show="!isLoading">
          {{ text }}
        </div>
      </div>
    </div>
    <div
      data-cy="role-watchlist-btn"
      class="rounded-watchlist-btn hidden lg:inline absolute top-0 left-0 cursor-pointer text-sage-700 pt-2 pb-4 pl-3 pr-5"
      :class="backgroundClasses"
    >
      <span
        v-if="isLoading"
        class="material-icons-round animate-spin text-20/20 mt-1 text-ivory-800"
      >
        refresh
      </span>
      <span
        class="text-ivory-50 fade-in"
        data-cy="role-watchlist-btn-active"
        v-show="isWatching && !isLoading"
      >
        <span class="material-icons-round text-20/20 mt-1"> star </span>
      </span>

      <span
        class="fade-in"
        data-cy="role-watchlist-btn-inactive"
        v-show="!isWatching && !isLoading"
      >
        <span class="material-icons-round text-20/20 mt-1"> star_outline </span>
      </span>
    </div>
  </button>
</template>

<script lang="ts">
import { Individual } from "@/models/Individual";
import { apiAxios } from "@/lib/axios";
import { PropType } from "vue";
import useWatchlist from "@/composables/useWatchlist";

export default {
  name: "RolesWatchlistButton",
  props: {
    roleId: {
      type: String,
    },
    individual: {
      type: Object as PropType<Individual>,
    },
    isWatching: {
      type: Boolean,
    },
    squareEdge: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { refreshWatchlist } = useWatchlist();

    return {
      refreshWatchlist,
    };
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    text() {
      return this.isWatching ? "Added to watchlist" : "Add to watchlist";
    },

    backgroundClasses() {
      let classes = "";
      if (this.isLoading || !this.isWatching) {
        classes += "bg-ivory-300";
      } else {
        classes += "bg-sage-700";
      }

      if (this.squareEdge) {
        classes += " rounded-tl-none";
      }
      return classes;
    },
  },
  methods: {
    async updateWatchlist() {
      this.isLoading = true;
      if (this.isWatching) {
        await this.removeFromWatchlist(this.roleId);
      } else {
        await this.addToWatchlist(this.roleId);
      }
      this.isLoading = false;
    },

    async removeFromWatchlist(id: string) {
      window.analytics.track("Role removed from watchlist", {
        roleId: id,
        userId: this.individual?.id,
      });
      await apiAxios.delete(`/watchlist`, { data: { role_id: id } });
      await this.refreshWatchlist();
    },

    async addToWatchlist(id: string) {
      window.analytics.track("Role added to watchlist", {
        roleId: id,
        userId: this.individual?.id,
      });
      await apiAxios.post(`/watchlist`, { role_id: id });
      await this.refreshWatchlist();
    },
  },
};
</script>

<style scoped>
.rounded-watchlist-btn {
  border-top-left-radius: 4px;
  border-bottom-right-radius: 90%;
}
</style>
