<template>
  <div>
    <div
      data-cy="roles-organisation"
      tabindex="0"
      class="p-4 pb-12 mt-4 md:mt-10 sm:px-14 md:pt-8 lg:px-28 relative bg-sage-50 w-full focus:outline-none focus:ring focus:ring-orange-500 border-t-3 border-l-0 border-r-0 border-b-0 border-solid border-grey-900"
    >
      <div class="flex justify-between">
        <div class="flex-col space-y-4">
          <div
            class="flex flex-col md:flex-row items-start space-y-4 md:space-y-0 md:items-center md:space-x-6"
          >
            <div class="h-24 w-24 bg-white p-2 rounded">
              <img
                class="object-contain w-full h-full"
                :src="roles[0].logo_thumbnail_public"
                :alt="`${roles[0].name} logo`"
              />
            </div>
            <div class="text-title-h1 text-sage-600">
              {{ roles[0].name }}
            </div>
          </div>

          <div class="flex flex-col md:flex-row md:space-x-14 pb-14 md:pb-0">
            <div class="text-grey-800 w-full md:3/5 lg:w-2/3">
              <div
                v-html="organisation_summary"
                class="mb-6 md:mb-8 copy-bold"
              ></div>
            </div>
            <div
              class="w-full flex flex-col flex-wrap text-grey-700 md:2/5 lg:w-1/3 text-nudging"
            >
              <div v-if="formattedLocation" class="py-1 mr-4 flex items-center">
                <span class="material-icons-round mr-1 text-16/16">
                  location_on
                </span>
                {{ formattedLocation }}
              </div>
              <div v-if="formattedDeadline" class="py-1 mr-4 flex items-center">
                <span class="material-icons-round mr-1 text-16/16">
                  timer
                </span>
                Midnight on
                {{ formattedDeadline }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="roles.length > 1 && individual.new_onboarding"
        class="flex justify-between mt-5"
      >
        <span class="text-divider text-grey-600 whitespace-nowrap mb-6">
          Your best match
        </span>
      </div>
      <roles-card
        :role="firstRole"
        :isFirstRole="true"
        :isOnlyRole="onlyRole"
        :notifiableRole="containsNotifiableRoles"
        :notificationCalculationDetails="firstRole.calculations"
        :showDeadline="!formattedDeadline"
        :showLocation="!formattedLocation"
        data-cy="roles-top-card"
        :data-score="firstRole.score"
      />
      <span
        v-if="roles.length > 1 && individual.new_onboarding"
        class="flex justify-between mb-6 mt-10 uppercase text-grey-600 whitespace-nowrap text-divider"
        >{{ otherRolesText }}</span
      >
      <div v-if="roles.length > 1">
        <div v-for="role in restOfRoles" :key="role.id" class="mb-6">
          <roles-card
            :role="role"
            :isFirstRole="false"
            :showDeadline="!formattedDeadline"
            :showLocation="!formattedLocation"
            :isOnlyRole="onlyRole"
            :notifiableRole="containsNotifiableRoles"
            :notificationCalculationDetails="role.calculations"
            data-cy="roles-other-card"
            :data-score="role.score"
            :class="{
              'mt-6': individual && !individual.new_onboarding,
            }"
          />
        </div>
      </div>
    </div>
    <role-nudging-footer
      v-if="
        !onlyRole &&
        containsProBonoOrEducationRoles &&
        containsNotifiableRoles &&
        organisationId
      "
      :organisationId="organisationId"
      :roleTypes="roleTypes"
    />
  </div>
</template>

<script lang="ts">
import { Role } from "@/models/Role";
import { RoleTyper } from "@/lib/RoleTyper";
import RolesCard from "@/components/roles/RolesCard.vue";
import RoleNudgingFooter from "@/components/roles/RoleNudgingFooter.vue";
import { formatDeadline, formatLocation } from "@/helpers/roleHelpers";
import { PropType } from "vue";
import useIndividual from "@/composables/useIndividual";

export default {
  name: "RolesOrganisation",
  components: { RolesCard, RoleNudgingFooter },
  setup() {
    const { individual, getIndividual } = useIndividual();

    return {
      individual,
      getIndividual,
    };
  },
  props: {
    roles: {
      required: true,
      type: Array as PropType<Role[]>,
    },
  },
  computed: {
    firstRole() {
      const [firstRole] = this.roles;
      return firstRole;
    },
    restOfRoles() {
      return this.roles.map(r => r).slice(1);
    },
    organisation_summary() {
      return this.roles[0].organisation_summary;
    },
    onlyRole() {
      return this.roles.length <= 1;
    },

    otherRolesText() {
      const numberOfRoles = this.roles.length;
      return `${numberOfRoles - 1} other role${
        numberOfRoles - 1 > 1 ? "s" : ""
      } available`;
    },

    formattedLocation() {
      const formattedLocations = this.roles.map(role => formatLocation(role));

      const isLocationSame = formattedLocations.every(
        location => location === formattedLocations[0]
      );

      return isLocationSame ? formattedLocations[0] : null;
    },

    formattedDeadline() {
      const formattedDeadlines = this.roles.map(role => {
        return formatDeadline(role.deadline);
      });

      const isDeadlineSame = formattedDeadlines.every(
        deadline => deadline === formattedDeadlines[0]
      );

      return isDeadlineSame ? formattedDeadlines[0] : null;
    },

    roleTypes() {
      const roleTyper = new RoleTyper();
      return roleTyper.getRolesTypes(this.roles);
    },

    containsProBonoOrEducationRoles() {
      return (
        this.roleTypes.includes("education") ||
        this.roleTypes.includes("pro-bono")
      );
    },

    containsNotifiableRoles() {
      return this.roles.some(role => role.notifiable === true);
    },

    organisationId() {
      return this.roles[0].organisation_id;
    },
  },
};
</script>
