<template>
  <a
    :href="cv.location"
    class="w-full group flex flex-col rounded-lg"
    :class="{
      'bg-sage-600  hover:bg-sage-700 active:bg-sage-800': isSelected,
      'bg-sage-50  hover:bg-sage-100 active:bg-sage-200': !isSelected,
    }"
  >
    <span
      v-if="isSelected"
      class="text-divider text-sage-300 pt-3 pl-6 sm:pl-4"
    >
      SELECTED
    </span>
    <div
      class="flex flex-col items-start sm:items-center sm:flex-row py-3 px-3 w-full justify-between"
    >
      <div class="flex w-full items-start justify-between sm:mt-0">
        <span
          class="hidden sm:flex justify-center items-center h-11 w-11 shrink-0 rounded-full bg-white"
        >
          <span class="material-icons-round text-3xl text-sage-600">
            attach_file
          </span>
        </span>
        <div class="flex flex-col w-full items-start mx-3">
          <span
            v-if="cv"
            data-cy="upload-cv-success-filename"
            class="copy-bold break-all"
            :class="{
              'text-sage-600 group- hover:text-grey-900': !isSelected,
              'text-ivory-50': isSelected,
            }"
          >
            {{ cv.filename }}
          </span>
          <span
            class="text-labelling-inactive"
            :class="{
              'text-grey-700 group- hover:text-grey-900': !isSelected,
              'text-ivory-500': isSelected,
            }"
          >
            Added {{ dateAdded }}
          </span>
        </div>
      </div>
      <div
        class="flex items-start w-full mt-4 flex-row space-x-2 sm:mt-0 sm:w-auto"
      >
        <button
          v-if="isSelected"
          @click.stop.prevent="toggleSelect"
          class="button-secondary-dark text-cta-secondary w-full sm:w-30"
        >
          Deselect
        </button>
        <button
          v-else
          @click.stop.prevent="toggleSelect"
          class="button-primary text-cta-primary w-full sm:w-28"
        >
          Select
        </button>
        <button
          @click.stop.prevent="deleteCv(cv.id)"
          type="button"
          class="rounded-full"
        >
          <span
            class="material-icons-round justify-center items-center border border-solid h-11 w-11 flex shrink-0 rounded-full"
            :class="{
              'text-red-600 border-red-600': !isSelected,
              'text-white border-white': isSelected,
            }"
          >
            delete
          </span>
        </button>
      </div>
    </div>
  </a>
</template>

<script>
import { format } from "date-fns";
import useCvs from "@/composables/useCvs";

export default {
  setup() {
    const { deleteCv, updateRoleApplicationCv } = useCvs();

    return {
      deleteCv,
      updateRoleApplicationCv,
    };
  },
  props: {
    isSelected: {
      type: Boolean,
      default: false,
    },
    cv: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dateAdded() {
      return format(new Date(this.cv.created_at), "MMMM d, yyyy, h:mma");
    },
  },
  methods: {
    toggleSelect() {
      this.$emit("selected");
      if (this.isSelected) {
        this.updateRoleApplicationCv(null);
      } else {
        this.updateRoleApplicationCv(this.cv);
      }
    },
  },
};
</script>
