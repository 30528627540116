<template>
  <div
    class="flex items-center justify-center rounded-full p-1.5 cursor-pointer bg-sage-600 hover:bg-sage-500"
  >
    <base-avatar
      :individual="individual"
      :is-disabled="true"
      alt-text="Profile Image"
      size="40"
      class="hidden md:flex border-solid border-ivory-100"
    />
    <span v-if="isOpen" class="material-icons-round text-ivory-100 pl-2">
      close
    </span>
    <span v-else class="material-icons-round text-ivory-100 pl-2"> menu </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseAvatar from "@/components/shared/BaseAvatar.vue";
import { Individual } from "@/types/Individual";

export default defineComponent({
  name: "BaseAvatarWithHamburger",

  components: {
    BaseAvatar,
  },

  props: {
    individual: { type: Object as PropType<Individual>, required: true },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
});
</script>
