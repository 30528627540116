import { format } from "date-fns";
import { Role } from "@/composables/useRoles";

export type RolePartial = Pick<
  Role,
  | "id"
  | "title"
  | "name"
  | "sector"
  | "deadline"
  | "commitment"
  | "locations"
  | "logo_thumbnail_public"
  | "part_time"
  | "location_region"
  | "locations_formatted"
  | "compensation_details"
>;

export function formatDeadline(deadline: string | Date) {
  return format(new Date(deadline).toISOString().split("T")[0], "do MMMM");
}

export function formatLocation(role: RolePartial) {
  return role.location_region
    ? `${role.locations_formatted}, ${role.location_region}`
    : role.locations_formatted;
}

export function formatCompensation(role: RolePartial) {
  const sector = role.sector === "nfp" ? "Pro bono" : "Compensated";

  return role.compensation_details ? role.compensation_details : sector;
}

export function formatDetails(role: RolePartial) {
  return {
    deadline: role.deadline
      ? "Midnight on " + formatDeadline(role.deadline)
      : "No deadline",
    location: formatLocation(role),
    compensation: formatCompensation(role),
    commitment: role.part_time ? role.commitment : "Full time",
  };
}
