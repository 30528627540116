<template>
  <div
    class="flex flex-row flex-wrap items-start pt-4 px-4 pb-6 tooltip-width sm:w-90 h-fit absolute bottom-0% sm:bottom-119% right-0% z-10 bg-grey-900 rounded-lg text-ivory-100 flex-none flex-grow shadow-ivory"
  >
    <div
      class="flex flex-column items-start p-0 gap-2 flex-none order-none self-stretch flex-grow w-full h-6.5"
    >
      <div
        class="flex flex-column items-start p-0 gap-2 flex-none order-none flex-grow w-full"
      >
        <span
          class="material-icons-outlined w-6 h-6 order-none grow-0 flex-none"
        >
          info
        </span>
        <span class="flex-none order-1 flex-grow" />
        <a
          class="material-icons-round w-6 h-6 order-1 grow-0 flex-none text-ivory-100"
          @click="hideOverlay()"
        >
          close
        </a>
      </div>
    </div>
    <div
      data-cy="role-notification-learn-more-message-text"
      class="flex flex-column items-start p-0 gap-2 flex-none order-none self-stretch flex-grow w-full text-inputs-content whitespace-pre-line mt-4"
    >
      {{ informationMessage }}
    </div>
    <a
      v-if="renderLink"
      data-cy="role-notification-learn-more-message-link"
      class="text-cta-tertiary text-ivory-100 flex-none gap-2 order-1 flex-grow mt-2"
      @click="trackEvent(linkLabel)"
      :href="linkAddress"
    >
      {{ linkLabel }}
    </a>
  </div>
</template>

<script lang="ts">
export default {
  name: "RoleNudgingInfoTooltip",
  props: {
    informationMessage: {
      type: String,
      required: true,
    },
    renderLink: {
      type: Boolean,
      required: true,
    },
    linkAddress: {
      type: String,
    },
    linkLabel: {
      type: String,
    },
    roleId: {
      type: String,
      required: true,
    },
    nudgingType: {
      type: String,
      required: true,
    },
  },
  methods: {
    hideOverlay() {
      this.$parent.hideInformationOverlay();
    },
    trackEvent(linkLabel: string) {
      return window.analytics.track(
        `${linkLabel} link clicked in the Learn More section of non-notified roles ${this.nudgingType} nudging banner.`,
        {
          roleId: this.roleId,
          link: this.linkAddress,
        }
      );
    },
  },
};
</script>

<style lang="css">
@media (max-width: 640px) {
  .tooltip-width {
    width: calc(100% + 40px + 1rem);
  }
}
</style>
