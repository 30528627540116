import { Ownership, SizeLabel } from "@/models/MiniCv";
import { RoleHeld } from "@/composables/useMiniCv";
import {
  differenceInMonths,
  differenceInYears,
  format,
  isFuture,
  isPast,
} from "date-fns";

interface RoleHeldDates {
  from: string;
  to?: string | null;
}

function sortByStartDate(a: RoleHeld, b: RoleHeld) {
  const firstStartDate = new Date(
    Number(b.from?.split("-")[0]) || 0,
    Number(b.from?.split("-")[1]) || 0
  );
  const secondStartDate = new Date(
    Number(a.from?.split("-")[0]) || 0,
    Number(a.from?.split("-")[1]) || 0
  );

  return firstStartDate.getTime() - secondStartDate.getTime();
}

export function isRoleHeldCurrent({ from, to }: RoleHeldDates) {
  return (!to || isFuture(new Date(to))) && isPast(new Date(from));
}

export function sortRolesHeld(roles: RoleHeld[] = []): RoleHeld[] {
  const pinnedRoles = roles.filter(role => role.pinned).sort(sortByStartDate);

  const currentRoles = roles
    .filter(
      role =>
        !role.pinned && isRoleHeldCurrent({ from: role.from, to: role.to })
    )
    .sort(sortByStartDate);

  const pastRoles = roles
    .filter(role => !role.pinned && !isRoleHeldCurrent(role))
    .sort(sortByStartDate);
  return [...pinnedRoles, ...currentRoles, ...pastRoles];
}

// Expects "2000-01"
function formatDate(date?: string): string {
  if (!date) return "";
  return format(
    new Date(Number(date.split("-")[0]), Number(date.split("-")[1]) - 1),
    "MMM yyyy"
  );
}

export function getDisplayDates({ from, to }: RoleHeldDates): string {
  const isCurrent = isRoleHeldCurrent({ from, to });
  const startsInFuture = isFuture(new Date(from));

  if (!from?.length && !to?.length) return "";

  if (isCurrent) {
    return `${formatDate(from)} - Present ${getTenure({ from, to })}`;
  }

  if (startsInFuture) {
    return `Starts in ${formatDate(from)}`;
  }

  if (from && to) {
    return `${formatDate(from)} - ${formatDate(to)} ${getTenure({ from, to })}`;
  }

  return "";
}

function getTenure({ from, to }: RoleHeldDates): string {
  if (from) {
    const startDate = new Date(
      Number(from.split("-")[0]),
      Number(from.split("-")[1])
    );

    const endDate = !to
      ? new Date()
      : new Date(Number(to.split("-")[0]), Number(to.split("-")[1]));

    const years = Math.abs(differenceInYears(startDate, endDate));
    const plural = years > 1 ? "s" : "";

    if (years == 0) {
      const months = Math.abs(differenceInMonths(startDate, endDate));
      const plural = months > 1 ? "s" : "";
      return `(${months} month${plural})`;
    }

    return `(${years} year${plural})`;
  } else {
    return "";
  }
}

export function getSizeLabel(
  ownership: Ownership,
  sector: string
): SizeLabel | null {
  if (!ownership?.length || !sector?.length) return null;

  if (
    ["Asset Management", "Investment Banks", "Investment Companies"].includes(
      sector
    )
  ) {
    return "Assets under management";
  }

  if (sector == "Insurance") {
    return "Gross written premium";
  }

  if (sector == "Not-for-profit") {
    return "Income";
  }

  switch (ownership) {
    case "Government":
      return "Head count";
    case "Armed Forces":
      return "Head count";
    case "Education":
      return "Number of students";
    case "Charity":
      return "Income";
    case "Medical":
      return "Head count";
    default:
      return "Revenue";
  }
}

export function validateRoleHeld(roleHeld: Partial<RoleHeld>): {
  isValid: boolean;
  errors: string[];
} {
  let isValid = true;
  const errors: string[] = [];

  if (!roleHeld.from || roleHeld.from?.length < 7) {
    isValid = false;
    errors.push("Missing from date");
  }

  if (!roleHeld.position || roleHeld.position?.length == 0) {
    isValid = false;
    errors.push("Missing position");
  }

  if (!roleHeld.organisation || roleHeld.organisation?.length == 0) {
    isValid = false;
    errors.push("Missing organisation");
  }

  if (roleHeld.to && roleHeld.to?.length < 7) {
    isValid = false;
    errors.push("Missing end date");
  }

  return {
    isValid,
    errors,
  };
}

export function rolesHeldValid(rolesHeld: Partial<RoleHeld>[] = []) {
  return rolesHeld.every(roleHeld => validateRoleHeld(roleHeld).isValid);
}
