import { Role } from "@/models/Role";
import constants from "@/lib/nurole_constants";

interface RoleTypes {
  proBono: string[];
  executive: string[];
  nonExecutive: string[];
  educational: string[];
}

export type RoleType = "pro-bono" | "education" | "non-exec" | "executive";

export class RoleTyper {
  public getRoleTypes(role: Role): RoleType[] {
    const { proBono, executive, nonExecutive } = this.getTypes();
    const results: RoleType[] = [];
    if (role.is_education_role) {
      results.push("education");
    } else if (role.type) {
      if (proBono.includes(role.type)) results.push("pro-bono");
      if (executive.includes(role.type)) results.push("executive");
      if (nonExecutive.includes(role.type)) results.push("non-exec");
    }
    return results;
  }

  public getRolesTypes(roles: Role[]): RoleType[] {
    const results: RoleType[] = [];
    for (const role of roles) {
      results.push(...this.getRoleTypes(role));
    }
    return results;
  }

  private getTypes(): RoleTypes {
    return {
      proBono: [...Object.keys(constants.nfp_role_types)],
      executive: [...Object.keys(constants.commercial_executive_role_types)],
      nonExecutive: [
        ...Object.keys(constants.commercial_non_executive_role_types),
      ],
      educational: [...Object.keys(constants.nfp_role_types)],
    };
  }
}
