<template>
  <div class="w-full relative">
    <label class="text-inputs-title font-semibold block mb-2"
      >Qualification name*</label
    >
    <input
      :value="name"
      @input="
        name = $event.target.value;
        showResults = true;
      "
      ref="qualification"
      class="text-input text-inputs-content w-full"
      placeholder="E.g. CIPD, ACA, CIMA"
      :class="{
        'rounded-b-none ring-0 ring-offset-0 rounded-t-lg shadow-md':
          showResults && results.length,
      }"
      @focus="showResults = true"
      data-position
      id="qualificationName"
      name="qualificationName"
      data-cy="qualification-name-input"
    />
    <qualification-selector
      v-if="showResults && results.length"
      :options="topTwentyResults"
      @on-select="selectQualification"
      @on-close="handleClose"
      @on-none-of-these="handleClose"
    />
  </div>
  <label class="mt-6 text-inputs-title font-semibold block mb-2"
    >Institution name</label
  >
  <div class="w-full relative">
    <input
      v-model="institutionName"
      class="text-input text-inputs-content w-full"
      placeholder="Enter your institution name"
      id="qualificationInstitution"
      name="qualificationInstitution"
      data-cy="qualification-institution-name-input"
    />
  </div>

  <label class="mt-6 text-inputs-title font-semibold block mb-2"
    >Certification date</label
  >
  <div class="w-full grid grid-cols-2 gap-3">
    <role-held-month-selector
      v-model="certificationDate.month"
      data-cy="certification-month-selector"
    />
    <role-held-year-selector
      v-model="certificationDate.year"
      data-cy="certification-year-selector"
    />
  </div>

  <div class="md:flex items-center mt-8 mb-8">
    <button
      @click="saveChanges()"
      class="button-primary text-cta-primary w-full md:w-auto mb-3 md:mb-0"
      :disabled="!name"
    >
      Save
    </button>
    <button
      v-if="qualification.id"
      class="button-error text-cta-primary md:ml-3 flex items-center w-full md:w-auto"
      @click="$emit('delete-qualification', qualification)"
    >
      <span class="material-icons-round mr-2"> delete </span>
      <span class="text-center md:text-left flex-1">Delete</span>
    </button>
  </div>
</template>
<script lang="ts" setup>
import RoleHeldYearSelector from "../miniCv/RoleHeldYearSelector.vue";
import RoleHeldMonthSelector from "../miniCv/RoleHeldMonthSelector.vue";
import { Qualification } from "@/types/qualification";
import { reactive, ref, computed, watch } from "vue";
import qualifications from "@/lib/qualifications";
import { useVueFuse } from "vue-fuse";
import QualificationSelector from "./QualificationSelector.vue";
const props = defineProps<{
  qualification: Qualification;
}>();

const showResults = ref(false);

const emit = defineEmits(["save-qualification", "delete-qualification"]);

const certificationDate = reactive({
  month: props.qualification?.certification_date?.length
    ? props.qualification.certification_date.split("-")[1]
    : undefined,
  year: props.qualification?.certification_date?.length
    ? parseInt(props.qualification.certification_date.split("-")[0])
    : undefined,
});

const name = ref(props.qualification.name);
const institutionName = ref(props.qualification.institution_name);

const { results, search } = useVueFuse(qualifications, name.value);

const topTwentyResults = computed(() => results.value.slice(0, 20));
const selectQualification = qualification => {
  name.value = qualification;
  showResults.value = false;
};

const saveChanges = () => {
  if (certificationDate.year && !certificationDate.month) {
    certificationDate.month = "01";
  }
  const qualification = {
    ...props.qualification,
    certification_date: certificationDate.year
      ? `${certificationDate.year}-${certificationDate.month}`
      : undefined,
    name: name.value,
    institution_name: institutionName.value,
  };
  emit("save-qualification", qualification);
};

const handleClose = () => {
  showResults.value = false;
};
watch(name, newValue => {
  search.value = newValue;
});
</script>
