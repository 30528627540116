<template>
  <div class="flex flex-col bg-sage-50 rounded gap-4 p-4 md:gap-6 md:p-8">
    <div class="flex gap-1 items-center">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        class="fill-current text-grey-700"
      >
        <path
          d="M12.6667 2.66665H12V1.33331H10.6667V2.66665H5.33333V1.33331H4V2.66665H3.33333C2.59333 2.66665 2.00667 3.26665 2.00667 3.99998L2 13.3333C2 14.0666 2.59333 14.6666 3.33333 14.6666H12.6667C13.4 14.6666 14 14.0666 14 13.3333V3.99998C14 3.26665 13.4 2.66665 12.6667 2.66665ZM12.6667 13.3333H3.33333V6.66665H12.6667V13.3333ZM12.6667 5.33331H3.33333V3.99998H12.6667V5.33331ZM6 9.33331H4.66667V7.99998H6V9.33331ZM8.66667 9.33331H7.33333V7.99998H8.66667V9.33331ZM11.3333 9.33331H10V7.99998H11.3333V9.33331ZM6 12H4.66667V10.6666H6V12ZM8.66667 12H7.33333V10.6666H8.66667V12ZM11.3333 12H10V10.6666H11.3333V12Z"
        />
      </svg>
      <p class="text-labeling-inactive flex-grow text-grey-700">
        {{ dateRecommended }}
      </p>
      <p
        class="px-3 py-1 rounded"
        :class="{
          'bg-sage-700 text-ivory-100': status === 'Approved',
          'bg-sage-100 text-sage-600': status === 'Pending',
          'bg-ivory-100 text-grey-900': status === 'Refused',
        }"
      >
        <span class="text-inputs-title">{{ status }}</span>
      </p>
    </div>
    <div class="p-6 flex flex-col gap-2 bg-white text-grey-900">
      <h4 class="text-title-h4">{{ recommendation.name }}</h4>
      <p class="copy-bold">{{ recommendation.position }}</p>
      <p class="text-grey-700">{{ recommendation.email }}</p>
      <p class="">{{ recommendation.organisation }}</p>
    </div>
    <!-- if there's a role associated with the recommendation -->
    <div
      v-if="recommendation.role_name"
      class="p-6 flex flex-col gap-2 bg-white text-grey-900"
    >
      <p class="text-divider text-grey-700">Role recommended for</p>
      <p class="copy-bold">
        {{ recommendation.role_name }}
      </p>
    </div>
    <p class="text-grey-900">
      {{ recommendation.reason }}
    </p>
  </div>
</template>

<script lang="ts">
import { PropType } from "vue";
import { format } from "date-fns";
import { RecommendationResponse } from "@/models/RecommendationResponse";

export default {
  props: {
    recommendation: {
      type: Object as PropType<RecommendationResponse>,
      required: true,
    },
  },
  computed: {
    status() {
      // TODO: Move this logic to api
      switch (this.recommendation.state) {
        case "ignored":
          return "Refused";
        case "ignored_as_duplicate":
          return "Refused";
        case "notification_queued":
          return "Approved";
        case "on_hold":
          return "Pending";
        case "archived":
          return "Approved";
        case "acknowledgement_queued":
          return "Approved";
        case "invited":
          return "Approved";
        case "invite_queued":
          return "Approved";
        case "invited (failed)":
          return "Approved";
        case "invited (nudged)":
          return "Approved";
        case "new":
          return "Pending";
        case "new_with_individual":
          return "Pending";
        default:
          return "Pending";
      }
    },
    dateRecommended() {
      return format(
        new Date(this.recommendation.created_at),
        "MMMM do yyyy, h.mm a" // Output: "July 18th 2023, 2.25 pm"
      );
    },
  },
};
</script>
