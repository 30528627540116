<template>
  <div class="md:pt-8">
    <div
      class="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-8 lg:gap-0"
    >
      <div>
        <img src="@/assets/images/thumb-up.svg" alt="" class="mb-3" />
        <div class="text-grey-900 copy-emphasis">Thank You</div>
        <div class="text-grey-900">Your application has been submitted</div>
      </div>
      <smile-feedback-scale
        v-if="!feedbackSubmitted"
        :selectedValue="feedback.smileValue"
        @smile-clicked="handleSmileClick"
      />
    </div>
    <div class="text-grey-900 text-title-h2 mt-10">What happens next</div>
    <div class="mt-3">
      <p class="text-grey-900">
        {{ whatHappensNextCopy }}
      </p>
    </div>
    <div
      class="hidden md:block border-b-0 border-r-0 border-l-0 border-t border-solid border-grey-200 w-full z-10 translate-y-11 relative mt-8"
    />
    <div class="z-30 relative">
      <what-happens-next class="pt-4" />
    </div>

    <div
      class="flex bg-sage-50 mt-10 md:mt-14 rounded-lg px-4 py-8 md:px-8 mb-4"
      v-if="feedback.smileValue"
      data-cy="further-fields"
    >
      <div class="flex-row w-full">
        <div
          class="-translate-y-20 md:-translate-y-80"
          ref="furtherFields"
        ></div>
        <div v-if="!feedbackSubmitted">
          <div class="text-grey-900 copy-emphasis mb-1">
            Thanks for your feedback.
          </div>
          <div class="text-grey-900">
            It all helps us improve the Nurole platform and we never share it
            with the hiring organisation.
          </div>
          <div class="text-grey-900 text-inputs-title mt-10">
            Roughly how long did this application take?
          </div>
          <div class="flex w-full flex-col md:flex-row mt-3 gap-2 mb-10">
            <button
              class="flex-1 py-4 mb-1 rounded-full border border-solid"
              :class="
                feedback.timeTaken === option
                  ? 'bg-sage-700 text-ivory-50 border-sage-700  hover:bg-sage-800 active:bg-sage-900  hover:border-sage-800 active:border-sage-900'
                  : 'bg-white text-grey-900 border-grey-300  hover:bg-grey-50 active:bg-grey-100 '
              "
              v-for="option in timeTakenOptions"
              :key="option"
              @click="saveChanges({ timeTaken: option })"
              :data-cy="'time-taken-' + timeTakenOptions.indexOf(option)"
            >
              <div class="flex items-center justify-center">
                {{ option }}
                <span
                  class="material-icons-round text-22/22 ml-2.5"
                  v-if="feedback.timeTaken == option"
                >
                  check
                </span>
              </div>
            </button>
          </div>

          <div class="text-grey-900 copy-emphasis mt-10">
            On a scale of 1 (badly) to 10 (very well) how well do you feel this
            application represents your experience and interest?
          </div>
          <numerical-scale
            class="mt-3"
            :selectedValue="feedback.selfRepresentationScore"
            @numberClicked="
              selfRepresentationScore =>
                saveChanges({ selfRepresentationScore })
            "
          />
          <div class="text-grey-900 copy-emphasis mt-10">
            How could we improve the application experience?
          </div>
          <textarea
            rows="5"
            class="textarea resize-y rounded-lg box-border text-grey-700 placeholder-grey-400 outline-none p-6 text-16/22 border border-ivory-400 w-full mt-3"
            :value="feedback.comments"
            @input="saveChanges({ comments: $event.target.value })"
            placeholder="Your answer here"
            data-cy="free-text-feedback"
          ></textarea>
          <button
            class="w-full md:w-auto my-10 button-primary text-cta-primary"
            @click="submit"
            data-cy="button-submit"
          >
            Submit
          </button>
        </div>
        <div v-if="feedbackSubmitted" id="completed-dialog">
          <div class="flex items-center justify-center flex-col">
            <img
              src="@/assets/images/stages-speech-bubble.svg"
              alt=""
              class="mb-3 w-16"
            />
            <div class="text-grey-900 text-title-h2">
              Thank you for your feedback
            </div>
            <button
              v-if="isMember"
              class="w-full md:w-auto my-6 button-primary text-cta-primary"
              @click="$emit('leave-form')"
              data-cy="button-roles"
            >
              Go to roles listing
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, ref } from "vue";
import SmileFeedbackScale from "@/components/roleApplication/SmileFeedbackScale.vue";
import WhatHappensNext from "@/components/roleApplication/WhatHappensNext.vue";
import NumericalScale from "@/components/roleApplication/NumericalScale.vue";
import useIndividual from "@/composables/useIndividual";

interface Feedback {
  timeTaken?: string;
  selfRepresentationScore?: number;
  comments?: string;
  smileValue?: string;
}

export default {
  name: "RoleApplyFeedbackForm",
  components: {
    WhatHappensNext,
    SmileFeedbackScale,
    NumericalScale,
  },
  props: {
    feedback: {
      required: true,
      type: Object as PropType<Feedback>,
    },
  },
  setup() {
    const { isMember } = useIndividual();

    return {
      isMember,
    };
  },
  data: () => ({
    feedbackSubmitted: false,

    timeTakenOptions: [
      "Less than 30 minutes",
      "30 to 60 minutes",
      "60 to 90 minutes",
      "More than 90 minutes",
    ],

    furtherFieldsRef: ref<HTMLInputElement | null>(null),
  }),
  mounted() {
    this.furtherFieldsRef = this.$refs.furtherFields;
  },
  methods: {
    saveChanges(feedback: Partial<Feedback>) {
      this.$emit("save-changes", feedback);
    },

    handleSmileClick(value: string) {
      this.saveChanges({ smileValue: value });
      this.$nextTick(() => {
        if (this.furtherFieldsRef?.$el) {
          this.furtherFieldsRef.$el.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      });
    },

    async submit() {
      this.$emit("submit-form");
      this.feedbackSubmitted = true;
      await this.$nextTick();

      const element = this.$el.querySelector("#completed-dialog");
      if (element) {
        element.scrollIntoView({ block: "center" });
      }
    },
  },
  computed: {
    whatHappensNextCopy() {
      return this.isMember
        ? "You should expect to receive regular update emails from two weeks after the deadline date. We will always inform you if you're successful or not (email or phone call). You can check the status of your application by clicking on the “applications” section in your account."
        : "You should expect to receive regular update emails from two weeks after the deadline date. We will always inform you if you're successful or not (email or phone call). You can check your application via the link sent to your email.";
    },
  },
};
</script>
