<template>
  <div class="w-full relative" ref="elementRef">
    <input
      ref="sectorRef"
      v-model="search"
      @input="this.showResults = true"
      @focus="
        showResults = true;
        $event.target.select();
      "
      class="text-input text-inputs-content w-full py-[15px]"
      placeholder="Search for a sector"
      :class="{
        'rounded-b-none ring-offset-0 rounded-t-lg shadow-md':
          showResults && topTwentyResults.length,
      }"
      data-sector
      id="sector"
      autocomplete="off"
    />
    <span
      class="material-icons-round text-grey-900 absolute inset-y-0 right-10 xl:right-4 p-4 border border-transparent"
    >
      search
    </span>
    <simple-option-selector
      v-if="showResults"
      :options="topTwentyResults"
      displayKey="display"
      @on-select="selectSector"
    />
  </div>
</template>

<script>
import { sectors } from "@/lib/rolesTableStaticData";
import { useVueFuse } from "vue-fuse";
import SimpleOptionSelector from "../shared/SimpleOptionSelector.vue";
import useOutsidePress from "@/composables/useOutsidePress";
import { ref } from "vue-demi";
export default {
  components: { SimpleOptionSelector },
  name: "RoleHeldSector",
  props: ["modelValue", "focus"],
  setup() {
    const elementRef = ref(null);
    const showResults = ref(false);
    useOutsidePress(elementRef, () => (showResults.value = false));
    const { search, results } = useVueFuse(sectors, {
      keys: ["display", "searchValues"],
    });

    return {
      search,
      results,
      elementRef,
      showResults,
    };
  },
  mounted() {
    this.search = this.modelValue;
    if (this.focus) {
      this.$refs.sectorRef.focus();
    }
  },
  computed: {
    topTwentyResults() {
      return this.search === "" ? sectors : this.results.slice(0, 20);
    },
  },
  methods: {
    selectSector(sector) {
      this.$emit("update:modelValue", sector.display);
      this.showResults = false;
      this.search = sector.display;
    },
  },
};
</script>
