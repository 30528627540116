<template>
  <div class="flex flex-col pt-4 pb-4 md:pb-6" data-cy="ra-exp-role">
    <div class="flex justify-between">
      <div class="mb-2 text-nudging text-grey-800">
        <div v-if="allDatesMissing" class="text-red-500">No dates selected</div>
        <div v-else>
          <span v-if="fromDateMissing" class="text-red-500">
            No starting date
          </span>
          <span v-else class="text-grey-800">
            {{ fromDate }}
          </span>
          -
          <span class="text-grey-800">
            {{ roleIsPresent ? "Present" : toDate }}
          </span>
        </div>
      </div>
      <div
        class="flex-none items-center space-x-3 hidden md:flex"
        v-if="isChoosingRoles"
      >
        <span
          class="text-labelling-inactive text-grey-800"
          v-if="isRoleSelected"
        >
          Included
        </span>
        <span class="text-labelling-inactive text-grey-600" v-else>
          Not included
        </span>
        <div
          class="h-6 w-11 rounded-full shadow-inner cursor-pointer focus-ring"
          @click="toggleRole"
          @keyup.enter="toggleRole"
          @keydown.space.prevent="toggleRole"
          tabindex="0"
          data-cy="ra-exp-role-toggle"
          :class="isRoleSelected ? 'bg-sage-100' : 'bg-grey-50'"
        >
          <div
            class="w-6 h-6 rounded-full shadow transition-transform"
            :class="
              isRoleSelected ? 'translate-x-5 bg-sage-500' : 'bg-grey-400'
            "
          ></div>
        </div>
      </div>
    </div>
    <div class="flex md:space-x-4">
      <div
        class="rounded border border-solid border-grey-200 p-1 items-center hidden md:flex"
      >
        <span class="material-icons-round text-40/40 text-grey-400 flex-none">
          business
        </span>
      </div>
      <div class="flex flex-col text-grey-800 flex-grow space-y-2 md:space-y-0">
        <span class="copy-bold">{{ role.position }}</span>
        <span class="copy-bold">{{ role.organisation }}</span>
      </div>
    </div>
    <div
      class="flex-none items-center space-x-3 flex rounded-full px-3 py-2 mt-2 md:hidden"
      :class="{ 'bg-grey-50': isRoleSelected }"
      v-if="isChoosingRoles"
    >
      <div
        class="h-6 w-11 rounded-full shadow-inner cursor-pointer bg-sage-50"
        tabindex="0"
        @click="toggleRole"
      >
        <div
          class="w-6 h-6 rounded-full shadow transition-transform"
          :class="isRoleSelected ? 'translate-x-5 bg-sage-500' : 'bg-grey-400'"
        ></div>
      </div>
      <span class="text-inputs-content text-grey-800" v-if="isRoleSelected">
        Included
      </span>
      <span class="text-inputs-content text-grey-600" v-else>
        Not included
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from "vue";
import { format } from "date-fns";

type Role = {
  id: string;
  orgnaisation: string;
  position: string;
  from: null | string;
  to: null | string;
};

export default {
  name: "CurrentOrPreviousRole",
  props: {
    role: {
      type: Object as PropType<Role>,
    },
    isChoosingRoles: Boolean,
    isRoleSelected: Boolean,
  },

  computed: {
    allDatesMissing() {
      return !this.role.from && !this.role.to;
    },

    fromDateMissing() {
      return !this.role.from;
    },

    roleIsPresent() {
      return this.role.from && !this.role.to;
    },

    fromDate() {
      return format(new Date(this.role.from), "MMMM yyyy");
    },

    toDate() {
      return format(new Date(this.role.to), "MMMM yyyy");
    },
  },

  methods: {
    toggleRole() {
      this.$emit("toggle-role", this.role);
    },
  },
};
</script>
