<template>
  <div
    class="flex flex-col items-center justify-start flex-grow w-full bg-white my-0 md:my-6"
  >
    <div
      class="border-solid border-r-0 border-l-0 border-t-0 w-full md:pt-5 px-5 md:px-24"
    >
      <h2 class="text-title-h2 text-left">
        {{ title }}
      </h2>
    </div>

    <div class="w-full px-5 md:px-24 pb-6">
      <h2 class="copy-emphasis pt-10 pb-2">
        Choose your interest in pro bono roles
      </h2>
      <interest-level-radio-buttons
        :interest-options="interestOptions"
        :interest-level="individual?.pro_bono_interest_level"
        @update-interest-level="updateInterestLevel"
        ref="interestLevelRadioButtons"
      />
    </div>

    <div
      v-if="isInterested"
      class="border-solid border-r-0 border-l-0 border-b-0 w-full px-5 md:px-24"
    >
      <areas-of-interest
        :individual-preference-tags="individual?.preference_tags"
        @update-tags="updateTags"
        @track-analytics="trackTagUpdate"
        ref="areasOfInterest"
      />
      <div>
        <h2 class="copy-emphasis mt-12">Reason behind your interests</h2>
        <p class="">
          We know that taking on a pro bono role is a very personal thing.
          Please could you help us understand what types of organisations you
          would be most passionate about?
        </p>
        <textarea
          v-model="preferencesText"
          @input="onTextUpdated"
          placeholder="Your text here"
          class="textarea w-full mt-6 mb-16"
          rows="3"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import debounce from "@/utils/debounce";
import { Individual } from "@/types/Individual";
import { PreferenceTag } from "@/types/PreferenceTag";
import { PropType } from "vue";
import InterestLevelRadioButtons from "@/components/onboarding/InterestLevelRadioButtons.vue";
import AreasOfInterest from "@/components/onboarding/AreasOfInterest.vue";

type Data = {
  preferencesText: string;
};

export default {
  name: "CharityPreferences",

  components: {
    InterestLevelRadioButtons,
    AreasOfInterest,
  },

  emits: [
    "update-individual-and-put",
    "set-on-finish-step-callback",
    "track-analytics",
  ],

  props: {
    title: {
      type: String,
    },
    individual: {
      type: Object as PropType<Individual>,
    },
  },

  data(): Data {
    return {
      preferencesText: "",
    };
  },

  computed: {
    isInterested() {
      const interestLevel = this.individual.pro_bono_interest_level;
      const isInterestLevelDefined = interestLevel !== null;
      return isInterestLevelDefined ? interestLevel > 0 : false;
    },

    interestOptions(): string[] {
      return [
        "Not at all interested",
        "Possibly interested, but low capacity",
        "Interested, but only if it's a good match",
        "As interested in pro bono as compensated roles",
        "More interested in pro bono than compensated roles",
      ];
    },
  },

  created() {
    this.preferencesText = this.individual.pro_bono_passions;
    this.$emit("set-on-finish-step-callback", this.isValid);
  },

  methods: {
    onTextUpdated: debounce(function () {
      this.$emit("update-individual-and-put", {
        pro_bono_passions: this.preferencesText,
      });
    }, 1000),

    updateInterestLevel(interestLevel: number) {
      this.$emit("update-individual-and-put", {
        pro_bono_interest_level: interestLevel,
        new_onboarding: true,
      });
    },

    updateTags(tags: PreferenceTag) {
      this.$emit("update-individual-and-put", {
        preference_tags: tags,
      });
    },

    trackTagUpdate(data) {
      this.$emit("track-analytics", "preference tag update", data);
    },

    isValid() {
      if (this.$refs.interestLevelRadioButtons.levelSelected) {
        return this.isInterested ? this.$refs.areasOfInterest.isValid() : true;
      } else {
        return this.$refs.interestLevelRadioButtons.isValid();
      }
    },
  },
};
</script>
