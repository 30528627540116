<template>
  <div
    class="p-6 text-white w-full flex flex-col gap-4 md:flex-row md:justify-between md:items-center bg-orange-700"
  >
    <div class="flex gap-2 md:items-center">
      <svg
        class="hidden md:block"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="22"
        viewBox="0 0 20 22"
        fill="none"
      >
        <path
          d="M14.5 10C15.2 10 15.87 10.1 16.5 10.29V9C16.5 7.9 15.6 7 14.5 7H13.5V5C13.5 2.24 11.26 0 8.5 0C5.74 0 3.5 2.24 3.5 5V7H2.5C1.4 7 0.5 7.9 0.5 9V19C0.5 20.1 1.4 21 2.5 21H8.76C7.97 19.87 7.5 18.49 7.5 17C7.5 13.13 10.63 10 14.5 10ZM5.4 5C5.4 3.29 6.79 1.9 8.5 1.9C10.21 1.9 11.6 3.29 11.6 5V7H5.4V5Z"
          fill="#FFFAF0"
        />
        <path
          d="M14.5 12C11.74 12 9.5 14.24 9.5 17C9.5 19.76 11.74 22 14.5 22C17.26 22 19.5 19.76 19.5 17C19.5 14.24 17.26 12 14.5 12ZM14.5 14C15.33 14 16 14.67 16 15.5C16 16.33 15.33 17 14.5 17C13.67 17 13 16.33 13 15.5C13 14.67 13.67 14 14.5 14ZM14.5 20C13.47 20 12.56 19.48 12.02 18.68C12.75 18.26 13.59 18 14.5 18C15.41 18 16.25 18.26 16.98 18.68C16.44 19.48 15.53 20 14.5 20Z"
          fill="#FFFAF0"
        />
      </svg>
      <h3 class="copy-emphasis">Hiring boards can't view your profile</h3>
    </div>
    <button
      class="text-cta-secondary button-primary-light w-full md:w-auto"
      @click="openModal"
    >
      Allow boards to find me
    </button>
  </div>
  <the-modal v-if="modalOpen" :easyToClose="true" @close-modal="closeModal">
    <template #header>
      <h2 class="copy-emphasis sm:text-title-h2">Profile visibility</h2>
    </template>
    The Nurole team can view your profile to assess your fit for roles. Would
    you like to also allow
    <profile-privacy-options></profile-privacy-options>
    <button
      class="button-primary text-cta-primary w-full mt-6"
      @click="closeModal"
    >
      Save
    </button>
  </the-modal>
</template>

<script setup lang="ts">
import TheModal from "../TheModal.vue";
import { ref } from "vue";
import ProfilePrivacyOptions from "./ProfilePrivacyOptions.vue";

const modalOpen = ref(false);
const emit = defineEmits(["open-modal", "close-modal"]);

const openModal = () => {
  modalOpen.value = true;
  emit("open-modal");
};

const closeModal = () => {
  modalOpen.value = false;
  emit("close-modal");
};
</script>
