<template>
  <div v-if="role">
    <div class="md:flex bg-grey-50 md:bg-white py-2 md:py-0">
      <div
        class="border-0 md:border-r md:border-b border-solid flex h-full md:p-6 p-2 md:border-t lg-border-t-0"
      >
        <span class="text-grey-400 text-divider my-auto mr-2"> DEADLINE </span>
        <div
          v-if="role.deadline"
          class="text-grey-900 line-height-helper my-auto"
        >
          {{ formatDeadline(role.deadline) }}
        </div>
      </div>
      <div
        class="border-0 md:border-r md:border-b border-solid flex h-full md:p-6 p-2 md:border-t lg-border-t-0"
      >
        <span class="text-grey-400 text-divider my-auto mr-2">
          {{ stateOfApplicationLabel }}
        </span>
        <span class="text-grey-900 line-height-helper my-auto">
          {{ stateOfApplicationValue }}
        </span>
      </div>
      <div
        class="flex-grow border-0 md:border-r md:border-b border-solid md:border-t lg-border-t-0"
      >
        <div class="ml-2 p-2 md:p-4">
          <role-apply-save />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDeadline } from "@/helpers/roleHelpers";
import RoleApplySave from "@/components/roleApplication/RoleApplySave.vue";
import useRole from "@/composables/useRole";
import useRoleApplication from "@/composables/useRoleApplication";

export default {
  components: {
    RoleApplySave,
  },

  setup() {
    const { role } = useRole();
    const { roleApplication } = useRoleApplication();
    return { role, formatDeadline, roleApplication };
  },
  computed: {
    stateOfApplicationLabel() {
      return this.roleApplication.submitted
        ? "APPLICATION SUBMITTED"
        : "APPLICATION PROGRESS";
    },
    stateOfApplicationValue() {
      if (this.roleApplication.submitted) {
        return formatDeadline(this.roleApplication.submitted_at);
      } else if (this.roleApplication.created_at) {
        return "Drafting";
      } else {
        return "Not started";
      }
    },
  },
};
</script>
<style>
.line-height-helper {
  line-height: 18px;
}
</style>
