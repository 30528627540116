<template>
  <div class="w-full bg-white">
    <template v-if="showSections">
      <roles-list-section-header
        :notifiable="true"
        :zero-notifiable-roles="Object.keys(notifiableRoles).length === 0"
        v-if="
          Object.keys(notifiableRoles).length +
            Object.keys(nonNotifiableRoles).length >
          0
        "
      />
      <roles-organisation
        v-for="(roles, key) in notifiableRoles"
        :key="key"
        :roles="roles"
        :individual="individual"
      />
      <notifiable-separator-banner
        v-if="Object.keys(nonNotifiableRoles).length > 0"
        :zeroNotifiableRoles="Object.keys(notifiableRoles).length === 0"
      />
      <roles-organisation
        v-for="(roles, key) in nonNotifiableRoles"
        :key="key"
        :roles="roles"
        :individual="individual"
      />
    </template>
    <template v-else>
      <roles-organisation
        v-for="(roles, key) in allRoles"
        :key="key"
        :roles="roles"
        :individual="individual"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { Role } from "@/models/Role";
import RolesOrganisation from "@/components/roles/RolesOrganisation.vue";
import NotifiableSeparatorBanner from "@/components/roles/NotifiableSeparatorBanner.vue";
import RolesListSectionHeader from "@/components/roles/RolesListSectionHeader.vue";
import useIndividual from "@/composables/useIndividual";

export default {
  name: "RolesList",
  components: {
    RolesOrganisation,
    NotifiableSeparatorBanner,
    RolesListSectionHeader,
  },
  props: ["roles", "showSections"],
  setup() {
    const { individual, getIndividual } = useIndividual();

    return {
      individual,
      getIndividual,
    };
  },
  computed: {
    allRoles(): { [key: string]: Role[] } {
      return this.groupRolesByOrganisation(this.roles);
    },
    notifiableRoles(): { [key: string]: Role[] } {
      return this.groupRolesByOrganisation(
        this.roles.filter(role => role.notifiable)
      );
    },
    nonNotifiableRoles(): { [key: string]: Role[] } {
      return this.groupRolesByOrganisation(
        this.roles.filter(role => !role.notifiable)
      );
    },
  },
  methods: {
    groupRolesByOrganisation(roles: Role[]) {
      return roles.reduce((acc, role) => {
        if (!role.name) return acc;
        acc[role.name] = acc[role.name] || [];
        acc[role.name].push(role);
        return acc;
      }, {});
    },
  },
};
</script>
