export default [
  "Mr",
  "Mrs",
  "Miss",
  "Ms",
  "Dr.",
  "Sir",
  "Prof.",
  "Lord",
  "Lady",
  "Baroness",
  "Dame",
  "Hon",
  "Baron",
  "Admiral",
  "Admiral Lord",
  "Admiral Sir",
  "Air Chief Marshal",
  "Air Chief Marshal Sir",
  "Air Commodore",
  "Air Marshal",
  "Air Vice Marshal",
  "Ambassador",
  "Archbishop",
  "Archdeacon",
  "Associate Chief Justice",
  "Associate Professor",
  "Associate Professor Emerita",
  "Associate Professor Emeritus",
  "Baronet",
  "Bishop",
  "Brigadier",
  "Brigadier General",
  "Captain",
  "Cardinal",
  "Chair Emerita",
  "Chair Emeritus",
  "Chancellor",
  "Chief Judge",
  "Chief Justice",
  "Colonel",
  "Commander",
  "Commodore",
  "Commodore First Class",
  "Commodore Second Class",
  "Count",
  "Countess",
  "Dato'",
  "Deacon",
  "Dean",
  "Doctor",
  "Doctor Mrs.",
  "Duchess",
  "Duke",
  "Earl",
  "Father",
  "Field Marshal",
  "Fleet Admiral",
  "Freifrau",
  "Freiherr",
  "General",
  "General Sir",
  "Governor",
  "H. Excellency",
  "H.R.H The Prince",
  "Her Highness",
  "Her Highness Sheikha",
  "His Highness",
  "Lt. Gen.",
  "Madam",
  "Major",
  "Major General",
  "Marquess",
  "Marquessa",
  "Monsignor",
  "Prince",
  "Professor",
  "Professor Sir",
  "Professor Dr",
  "Rear Admiral",
  "Reverend",
  "Senator",
  "Sheikh",
  "Sister",
  "The Hon.",
  "The Hon. Doctor",
  "The Hon. Lord",
  "The Hon. Mrs",
  "The Hon. Sir",
  "The Rt. Hon.",
  "The Rt. Hon. Baroness",
  "The Rt. Hon. Dr",
  "The Rt. Hon. Earl",
  "The Rt. Hon. Lord",
  "The Rt. Hon. Sir",
  "The Rt. Hon. Viscount",
  "The Venerable",
  "Vice Admiral",
  "Viscount",
];
