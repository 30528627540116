<template>
  <label
    data-cy="toggle-selected-role"
    data-toggle-selected-role
    @click.stop.prevent="toggleSelectedRole"
    class="switch text-sage-600 cursor-pointer absolute top-12 right-4"
  >
    <input type="checkbox" :checked="isSelected" />
    <span class="slider round"></span>
  </label>
</template>

<script lang="ts">
import useRoleApplication from "@/composables/useRoleApplication";
import { RoleHeld } from "@/composables/useMiniCv";

export default {
  props: ["slotProps"],
  setup() {
    const { updateRoleApplication } = useRoleApplication();

    return {
      updateRoleApplication,
    };
  },
  methods: {
    toggleSelectedRole() {
      if (this.isSelected) {
        this.updateRoleApplication({
          selected_roles_held: this.selectedRolesHeld.filter(
            ({ id }) => id != this.role.id
          ),
        });
      } else {
        this.updateRoleApplication({
          selected_roles_held: [...this.selectedRolesHeld, this.role],
        });
      }
    },
  },
  computed: {
    role() {
      return this.slotProps.role;
    },
    selectedRolesHeld(): RoleHeld[] {
      return this.slotProps.selectedRolesHeld;
    },
    isSelected() {
      return this.slotProps.selectedRolesHeld
        .map(({ id }) => id)
        .includes(this.role.id);
    },
  },
};
</script>
