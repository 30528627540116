interface Request {
  apiCall: () => Promise<any>;
  onSuccess: (response: any) => void;
  onError: (error: any) => void;
}

// Use this class to queue API calls that should be processed sequentially.
export class RequestQueue {
  private queue: Request[] = [];
  private isProcessing = false;

  // Add a new request to the queue
  enqueue(request: Request) {
    this.queue.push(request);
    this.processQueue().catch(error => {
      console.error("Error processing queue:", error);
    });
  }

  private async processQueue() {
    if (this.isProcessing) {
      return;
    }

    if (this.queue.length > 0) {
      this.isProcessing = true;
      const currentRequest = this.queue.shift() as Request;

      try {
        const result = await currentRequest.apiCall();
        currentRequest.onSuccess(result);
      } catch (error) {
        currentRequest.onError(error);
      } finally {
        this.isProcessing = false;
        this.processQueue().catch(error => {
          console.error("Error processing queue:", error);
        });
      }
    }
  }
}
