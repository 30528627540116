<template>
  <manage-cv-modal />
  <div
    class="bg-white px-5 flex flex-col items-start text-grey-800 w-full py-5 md:py-12 my-5 sm:px-12 lg:px-28 md:my-0 md:border-b md:border-t-0 md:border-l-0 md:border-r-0 md:border-solid md:border-grey-100"
  >
    <span class="copy-emphasis"> Your CV </span>
    <div v-if="roleApplicationCv">
      <a
        class="text-sage-600 text-lg font-bold underline"
        :href="roleApplicationCv.location"
      >
        {{ roleApplicationCv.filename }}
      </a>
    </div>
    <div
      v-else
      class="mt-5 bg-red-50 flex flex-col items-start sm:items-center rounded-lg px-10 py-8 w-full space-y-3 sm:space-y-0 sm:space-x-5 sm:flex-row"
    >
      <icon-cv class="h-10 sm:h-14" color="text-red-400" />
      <div class="flex flex-col justify-start sm:items-start space-y-5">
        <div class="copy-emphasis sm:mr-28">
          You haven’t uploaded a CV, your chance of progressing may be reduced.
        </div>
        <button
          class="text-cta-primary button-error-primary"
          @click="showManageCvModal"
        >
          Upload CV
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ManageCvModal from "@/components/roleApplication/ManageCvModal.vue";
import useCvs from "@/composables/useCvs";
import IconCv from "@/components/icons/IconCv.vue";

export default {
  name: "PreviewCv",
  components: { IconCv, ManageCvModal },
  setup() {
    const { showManageCvModal, roleApplicationCv } = useCvs();

    return { showManageCvModal, roleApplicationCv };
  },
};
</script>
