<template>
  <page-with-title-layout title="Don't want to hear from us right now?">
    <div class="px-6">
      <div class="card-outlined py-8 px-10 mt-8">
        <div class="copy-emphasis">
          Have we been sending you the wrong notifications?
        </div>
        <div class="mt-4">
          You can change your preferences so that you only receive notifications
          for roles that are of interest to you. In addition we are working hard
          every day to strengthen our matching capability, we hope you continue
          to see these improvements
        </div>
        <router-link
          class="button-primary text-cta-primary mt-8 block w-full text-center md:text-left md:w-auto md:inline-block"
          to="/reconfigure"
          >Update my preferences</router-link
        >
      </div>
      <snooze-notifications />
    </div>
  </page-with-title-layout>
</template>

<script lang="ts" setup>
import PageWithTitleLayout from "@/components/PageWithTitleLayout.vue";
import SnoozeNotifications from "@/components/account/SnoozeNotifications.vue";
import { onMounted } from "vue";

onMounted(() => {
  window.scrollTo(0, 0);
});
</script>
