<template>
  <div class="flex items-center flex-wrap">
    <div
      v-for="badge in badges"
      :key="badge"
      class="text-nudging rounded px-2 py-1 mr-2 bg-blue-50 text-sm mt-1"
      data-organisation-badge
    >
      {{ badge }}
    </div>
  </div>
</template>

<script lang="ts">
import getOrganisationSize from "@/helpers/getOrganisationSize";
import { defineComponent } from "vue";

export default defineComponent({
  name: "OrganisationBadges",
  props: ["organisation"],
  computed: {
    badges(): string[] {
      return Array.from(
        new Set(
          [
            ...(this.organisation.sectors || []),
            this.organisation.ownership,
            getOrganisationSize(this.organisation),
          ].filter(badge => badge && badge.length)
        )
      );
    },
  },
});
</script>
