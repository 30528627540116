import mixpanel from "mixpanel-browser";

const mixpanelInstance = mixpanel.init(
  process.env.VITE_MIXPANEL_TOKEN,
  {
    debug: process.env.VITE_RACK_ENV === "development",
  },
  "Public app analytics"
);

window.analytics = mixpanelInstance;
window.analytics.register({
  Platform: "Web App",
  Release: process.env.VITE_VERSION,
});

window.analytics.track("Mixpanel initialised");
