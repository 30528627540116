<template>
  <button
    data-cy="preference-tag"
    @click.stop="updateTagAndTrack"
    class="rounded-lg xs:rounded-full border-solid p-2 px-4 mr-3 mt-4 border flex items-center justify-between w-full text-left xs:w-auto"
    :class="
      isSelected
        ? 'bg-sage-700 border-sage-700 text-white  hover:bg-sage-600  hover:border-sage-600'
        : 'border-sage-200  hover:border-sage-400  hover:bg-sage-50'
    "
  >
    {{ label }}
    <span
      class="material-icons-round ml-2"
      :class="isSelected ? 'text-white' : 'text-sage-700'"
    >
      {{ isSelected ? "check" : "add" }}
    </span>
  </button>
</template>

<script lang="ts">
export default {
  name: "Tag",

  props: {
    tag: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["update-tag", "track-analytics"],

  methods: {
    updateTagAndTrack() {
      this.$emit("update-tag", this.tag);
      this.trackTagUpdate();
    },

    trackTagUpdate() {
      const tagData = {
        tagId: this.tag,
        tagLabel: this.label,
        isChecked: !this.isSelected,
      };

      this.$emit("track-analytics", tagData);
    },
  },
};
</script>
