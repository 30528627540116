<template>
  <div
    class="w-screen h-screen fixed left-0 right-0 top-0 bottom-0 z-100 overflow-y-scroll bg-ivory-200"
    @keydown.esc="$router.push('/account/profile')"
    ref="myProfilePreview"
    tabindex="0"
  >
    <div
      class="bg-grey-900 text-white md:top-0 w-full flex flex-col md:flex-row md:items-center gap-4 py-4 copy-emphasis px-6"
    >
      <span class="flex-1"
        >This is how your profile appears to hiring boards.</span
      >
      <button
        class="bg-white border-white text-cta-secondary text-black button-secondary px-8 flex gap-2 items-center justify-center"
        @click="$router.back()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M2.99902 17.4615V20.5015C2.99902 20.7815 3.21902 21.0015 3.49902 21.0015H6.53902C6.66902 21.0015 6.79902 20.9515 6.88902 20.8515L17.809 9.94152L14.059 6.19152L3.14902 17.1015C3.04902 17.2015 2.99902 17.3215 2.99902 17.4615ZM20.709 7.04152C21.099 6.65152 21.099 6.02152 20.709 5.63152L18.369 3.29152C17.979 2.90152 17.349 2.90152 16.959 3.29152L15.129 5.12152L18.879 8.87152L20.709 7.04152Z"
            fill="#242726"
          />
        </svg>
        Edit profile
      </button>
      <div class="hidden md:block border-l border-white p-2">
        <button class="flex justify-center ml-4" @click="$router.back()">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.3 5.70973C17.91 5.31973 17.28 5.31973 16.89 5.70973L12 10.5897L7.10997 5.69973C6.71997 5.30973 6.08997 5.30973 5.69997 5.69973C5.30997 6.08973 5.30997 6.71973 5.69997 7.10973L10.59 11.9997L5.69997 16.8897C5.30997 17.2797 5.30997 17.9097 5.69997 18.2997C6.08997 18.6897 6.71997 18.6897 7.10997 18.2997L12 13.4097L16.89 18.2997C17.28 18.6897 17.91 18.6897 18.3 18.2997C18.69 17.9097 18.69 17.2797 18.3 16.8897L13.41 11.9997L18.3 7.10973C18.68 6.72973 18.68 6.08973 18.3 5.70973Z"
              fill="#FFFAF0"
            />
          </svg>
        </button>
      </div>
    </div>
    <template
      v-if="individual?.profile_privacy === 'nurole' || privacyModalOpen"
    >
      <privacy-banner
        @open-modal="privacyModalOpen = true"
        @close-modal="privacyModalOpen = false"
      />
    </template>
    <completeness-steps layout="banner"></completeness-steps>
    <the-profile :profile="myProfile"></the-profile>
  </div>
</template>
<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from "vue";
import TheProfile from "@/components/profile/TheProfile.vue";
import useProfile from "@/composables/useProfile";
import useIndividual from "@/composables/useIndividual";
import PrivacyBanner from "@/components/profile/PrivacyBanner.vue";
import CompletenessSteps from "@/components/shared/CompletenessSteps.vue";

const { myProfile, getMyProfile } = useProfile();
const { individual } = useIndividual();

const privacyModalOpen = ref(false);

getMyProfile();

const myProfilePreview = ref(null);

onMounted(() => {
  document.body.classList.toggle("overflow-hidden", true);
  myProfilePreview.value.focus();
});

onBeforeUnmount(() => {
  document.body.classList.toggle("overflow-hidden", false);
});
</script>
