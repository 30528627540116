<template>
  <select
    id="location-select"
    class="select-input w-full mt-0"
    :class="selected ? 'text-grey-900' : 'text-grey-400'"
    v-model="selected"
    @change="$emit('selection-made', selected)"
    placeholder="hello world"
    data-cy="location-select"
  >
    <option value="" disabled selected>Select your location</option>
    <option v-for="(country, index) in countries" :key="index" :value="country">
      {{ country }}
    </option>
  </select>
</template>

<script lang="ts">
import { countries } from "@/lib/nationalities";

export default {
  setup() {
    return {
      countries,
    };
  },

  data() {
    return {
      selected: "",
    };
  },

  emits: ["selection-made"],

  props: {
    initialSelection: {
      type: String,
      default: "",
    },
  },

  created() {
    this.selected = this.initialSelection;
  },
};
</script>
