<template>
  <base-modal v-if="isManagingCv" :isOpen="isManagingCv">
    <base-modal-header @close="closeModal"> Manage your CV </base-modal-header>
    <div
      ref="cvList"
      class="flex flex-col items-center h-screen overflow-y-auto pb-44"
      :class="{
        'sm:pb-20': isShowingMore,
        'sm:pb-16': !isShowingMore,
      }"
    >
      <span class="py-3">
        Please select a CV that you have already uploaded from the list below,
        or upload a new CV
      </span>
      <hr class="border-top border-solid border-grey-200 w-full" />
      <div v-if="roleApplicationCv" class="w-full">
        <transition
          mode="out-in"
          enter-class="opacity-60"
          enter-active-class="transition-all duration-300 ease-in"
          leave-active-class="transition-all duration-800 ease-out"
          leave-to-class="opacity-60"
        >
          <cv-tile
            :isSelected="true"
            :cv="roleApplicationCv"
            :key="roleApplicationCv.id"
          />
        </transition>
      </div>
      <div
        v-else
        class="flex justify-center items-center bg-grey-50 text-grey-600 w-full py-9 px-7"
      >
        <icon-cv color="text-grey-600" class="mr-4 h-6" />
        No CV selected for this application
      </div>
      <div class="flex flex-col space-y-2 mt-5 w-full">
        <div v-for="cv in filteredIndividualCvs" :key="cv.id">
          <cv-tile :cv="cv" :key="cv.id" @selected="scrollToTop" />
        </div>
      </div>
      <button
        v-if="shouldShowExpandButton"
        class="mt-5 w-full rounded-full text-cta-tertiary py-2.5 px-5 sm:w-36 flex justify-center items-center whitespace-nowrap text-sage-700"
        @click="toggleShowCvs"
      >
        {{ isShowingMore ? "Show less" : "Show more" }}
        <span class="material-icons-round text-sage-700 text-xl ml-3">
          {{ isShowingMore ? "visibility_off" : "visibility" }}
        </span>
      </button>
      <hr class="border-top border-solid border-grey-200 w-full" />
      <div class="text-left w-full pb-5">
        <upload-cv @cv-change="cvUploaded" />
      </div>
    </div>
    <base-modal-footer>
      <button
        @click="closeModal"
        class="w-full sm:w-28 text-cta-primary button-primary"
      >
        Done
      </button>
    </base-modal-footer>
  </base-modal>
</template>

<script>
import useCvs from "@/composables/useCvs";
import CvTile from "@/components/roleApplication/CvTile.vue";
import UploadCv from "@/components/cv/UploadCv.vue";
import IconCv from "@/components/icons/IconCv.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseModalHeader from "@/components/shared/BaseModalHeader.vue";
import BaseModalFooter from "@/components/shared/BaseModalFooter.vue";

export default {
  components: {
    CvTile,
    UploadCv,
    IconCv,
    BaseModal,
    BaseModalHeader,
    BaseModalFooter,
  },
  data() {
    return {
      isShowingMore: false,
    };
  },
  setup() {
    const {
      individualCvs,
      isManagingCv,
      roleApplicationCv,
      hideManageCvModal,
      getIndividualCvs,
      updateRoleApplicationCv,
    } = useCvs();

    return {
      roleApplicationCv,
      individualCvs,
      isManagingCv,
      hideManageCvModal,
      getIndividualCvs,
      updateRoleApplicationCv,
    };
  },
  computed: {
    filteredIndividualCvs() {
      const filteredCvs = this.roleApplicationCv
        ? this.individualCvs.filter(cv => cv.id !== this.roleApplicationCv.id)
        : this.individualCvs;

      return this.isShowingMore ? filteredCvs : filteredCvs.slice(0, 2);
    },
    shouldShowExpandButton() {
      return this.individualCvs.length > 3;
    },
  },
  methods: {
    closeModal() {
      this.isShowingMore = false;
      this.hideManageCvModal(false);
    },
    toggleShowCvs() {
      this.isShowingMore = !this.isShowingMore;
    },
    async cvUploaded(cv) {
      this.scrollToTop();
      await this.updateRoleApplicationCv(cv);
      await this.getIndividualCvs();
    },
    scrollToTop() {
      this.isShowingMore = false;
      const cvList = this.$refs?.cvList;

      cvList?.$el?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    },
  },
};
</script>
