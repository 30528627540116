<template>
  <div class="flex gap-4 md:w-min">
    <label
      for="photo-input"
      class="cursor-pointer rounded-full overflow-hidden relative flex h-20 w-20 box-content group focus-ring m-0"
      @keypress="handleLabelKeypress($event)"
      data-cy="profile-picture"
      tabindex="0"
    >
      <img
        v-show="image && imageLoaded"
        @load="onImageLoad"
        :src="image"
        alt="Individuals profile image"
        class="object-cover h-20 w-20 transition ease-in-out duration-300"
      />
      <div
        v-if="!image || !imageLoaded"
        class="bg-sage-700 flex justify-center items-center h-full w-full transition ease-in-out duration-300"
      >
        <h2
          aria-hidden="true"
          class="text-title-h2 m-0 text-center initials-custom-font text-ivory-100"
          v-if="initials"
        >
          {{ initials }}
        </h2>

        <div
          v-if="!initials"
          class="bg-ivory-900 absolute top-0 bottom-0 right-0 left-0 w-full flex items-center justify-center group- hover:bg-[#242726CC] group-focus:bg-[#242726CC]"
        >
          <svg
            width="40"
            height="41"
            viewBox="0 0 40 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="scale-50 md:scale-100"
            role="img"
          >
            <title>Add or edit profile picture icon</title>
            <path
              d="M35.834 10.9056H30.5507L27.5007 7.57227H17.5007V10.9056H26.034L29.084 14.2389H35.834V34.2389H9.16732V19.2389H5.83398V34.2389C5.83398 36.0723 7.33398 37.5723 9.16732 37.5723H35.834C37.6673 37.5723 39.1673 36.0723 39.1673 34.2389V14.2389C39.1673 12.4056 37.6673 10.9056 35.834 10.9056ZM14.1673 24.2389C14.1673 28.8389 17.9007 32.5723 22.5007 32.5723C27.1007 32.5723 30.834 28.8389 30.834 24.2389C30.834 19.6389 27.1007 15.9056 22.5007 15.9056C17.9007 15.9056 14.1673 19.6389 14.1673 24.2389ZM22.5007 19.2389C25.2507 19.2389 27.5007 21.4889 27.5007 24.2389C27.5007 26.9889 25.2507 29.2389 22.5007 29.2389C19.7507 29.2389 17.5007 26.9889 17.5007 24.2389C17.5007 21.4889 19.7507 19.2389 22.5007 19.2389ZM9.16732 10.9056H14.1673V7.57227H9.16732V2.57227H5.83398V7.57227H0.833984V10.9056H5.83398V15.9056H9.16732V10.9056Z"
              fill="#FFFAF0"
            />
          </svg>
        </div>
      </div>
      <input
        class="hidden"
        type="file"
        accept="image/jpeg,image/pjpeg,image/png,image/tiff,image/x-tiff"
        name="photo-input"
        id="photo-input"
        @change="submitImage"
      />
    </label>
  </div>
</template>

<style scoped>
.initials-custom-font {
  font-family:
    Source Sans Pro,
    sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .initials-custom-font {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 0.04em;
  }
}
</style>

<script lang="ts">
import { defineComponent } from "vue";
import useIndividual from "@/composables/useIndividual";

export default defineComponent({
  name: "ProfilePicture",

  data: () => {
    return {
      imageLoaded: false,
    };
  },

  setup() {
    const { individual, updateIndividual } = useIndividual();

    return {
      individual,
      updateIndividual,
    };
  },

  computed: {
    image() {
      return this.individual?.picture;
    },

    initials(): string {
      if (!this.individual?.first_name || !this.individual?.last_name) {
        return "";
      }

      return `${this.individual.first_name.charAt(
        0
      )}${this.individual.last_name.charAt(0)}`;
    },
  },

  methods: {
    handleLabelKeypress(event: KeyboardEvent) {
      if (event.key === " " || event.key === "Enter") {
        event.preventDefault();
        const target = event.target as HTMLLabelElement;
        target.click();
      }
    },
    async getBase64(file) {
      return new Promise((success, failure) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          success(reader.result);
        };
        reader.onerror = error => {
          failure(error);
        };
      });
    },
    async submitImage(event: Event) {
      window.analytics.track("Change profile image", {
        component: "ProfilePicture",
      });
      const target = event.target as HTMLInputElement;
      if (target.files?.length) {
        const base64img = await this.getBase64(target.files[0]);
        this.updateIndividual(
          {
            photo_raw: base64img,
            picture: base64img,
          },
          "profile_picture"
        );
      }
    },
    onImageLoad() {
      this.imageLoaded = true;
    },
  },
});
</script>
