<script setup lang="ts">
const props = defineProps(["label", "for"]);
</script>

<template>
  <div class="flex items-start gap-3 self-stretch flex-col flex-grow">
    <label
      v-if="props.label"
      class="self-stretch text-inputs-title text-grey-900"
      :for="props.for"
    >
      {{ props.label }}
    </label>
    <slot></slot>
  </div>
</template>
