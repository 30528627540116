import { RoleHeld } from "@/composables/useMiniCv";
import { OrganisationSize } from "@/models/MiniCv";

export type RoleHeldFormData = {
  position: string;
  executive?: boolean;
  compensated?: boolean;
  organisation: {
    name: string;
    id: string | null;
    size?: OrganisationSize | null;
    sector?: string;
    ownership?: string;
    website?: string;
  };
  organisation_description?: string;
  from: {
    year?: number;
    month?: number;
  };
  to: {
    year?: number;
    month?: number;
  };
  end_date_unknown: boolean;
};

function formatMonth(month: number) {
  return String(month + 1).padStart(2, "0");
}

export function formDataToSaveParams(
  formData: RoleHeldFormData
): Partial<RoleHeld> {
  const params: Partial<RoleHeld> = {
    position: formData.position,
    organisation: formData.organisation.name,
    organisation_id: formData.organisation.id,
    organisation_description: formData.organisation_description,
    end_date_unknown: formData.end_date_unknown,
    executive: formData.executive,
    compensated: formData.compensated,
  };

  if (
    formData.from &&
    formData.from.year &&
    formData.from.month !== undefined
  ) {
    params.from = `${formData.from.year}-${formatMonth(formData.from.month)}`;
  }

  if (formData.to && formData.to.year && formData.to.month !== undefined) {
    params.to = `${formData.to.year}-${formatMonth(formData.to.month)}`;
  }

  return params;
}

export function roleHeldToFormData(role: Partial<RoleHeld>) {
  return {
    position: role.position || "",
    executive: role.executive ?? false,
    compensated: role.compensated ?? undefined,
    organisation: {
      name: role.organisation || "",
      id: role.organisation_id || null,
      size: role.organisation_size || {
        label: "",
        currency: "",
        value: "",
      },
      sector: role.organisation_sector || "",
      ownership: role.organisation_ownership || "",
      website: role.organisation_website || "",
    },
    organisation_description: role.organisation_description || "",
    from: {
      year: role.from ? parseInt(role.from.split("-")[0]) : undefined,
      month: role.from ? parseInt(role.from.split("-")[1]) - 1 : undefined,
    },
    to: {
      year: role.to ? parseInt(role.to.split("-")[0]) : undefined,
      month: role.to ? parseInt(role.to.split("-")[1]) - 1 : undefined,
    },
    end_date_unknown:
      (typeof role.end_date_unknown !== "undefined" && role.end_date_unknown) ||
      false,
  };
}
