<template>
  <impersonation-banner :individual="individual" />
  <div class="flex items-center h-14 md:h-20 bg-sage-700 sticky top-0 z-50">
    <div
      v-if="showMenu"
      class="fixed inset-0 w-full z-10"
      @click="closeAll"
    ></div>
    <div
      class="w-full flex items-center max-w-screen-xl md:mx-auto z-10 px-4 md:px-6 xl:px-0"
    >
      <a :href="`${marketingAppURL}?noredirect=true`">
        <img src="@/assets/nurole.svg" class="md:w-auto w-28 md:mr-12" />
      </a>
      <div
        class="hidden md:flex items-center text-cta-secondary md:space-x-2 lg:space-x-6"
      >
        <router-link
          to="/roles"
          class="text-ivory-50 hover:text-ivory-300 active:text-ivory-400 border-t-0 border-r-0 border-l-0 border-b-2 pt-2 pb-1 border-solid cursor-pointer border-transparent"
          >View roles</router-link
        >
        <router-link
          to="/account/profile"
          class="text-ivory-50 hover:text-ivory-300 active:text-ivory-400 border-t-0 border-r-0 border-l-0 border-b-2 pt-2 pb-1 border-solid cursor-pointer border-transparent flex gap-2 items-center"
          >My Profile</router-link
        >
        <a
          v-for="(link, index) in externalLinks"
          :target="link.openInNewTab ? '_blank' : ''"
          :rel="link.openInNewTab ? 'noopener' : ''"
          :key="index"
          :href="link.url"
          class="text-ivory-50 hover:text-ivory-300 active:text-ivory-400 border-t-0 border-r-0 border-l-0 border-b-2 pt-2 pb-1 border-solid cursor-pointer border-transparent flex gap-2 items-center"
          >{{ link.label }}<mark v-if="link.highlightAsNew">NEW</mark></a
        >
      </div>

      <div class="flex-grow"></div>
      <post-a-role-button
        class="mx-4 hidden md:block text-cta-primary bg-ivory-50 text-grey-900 py-3 px-6 hover:bg-ivory-300 active:bg-ivory-400"
      />

      <div class="hidden md:flex">
        <div class="relative">
          <burger-menu-button
            :individual="individual"
            :is-open="showMenu"
            @click="toggleMenu"
            data-cy="open-nav-menu-button"
            class="z-50"
          />
          <transition name="fade">
            <desktop-menu
              v-if="showMenu"
              class="absolute z-30 top-17 right-0 hidden md:block"
              :individual="individual"
              @close-menu="closeAll"
            />
          </transition>
        </div>
      </div>

      <div class="z-40 md:hidden">
        <div class="relative">
          <span
            class="material-icons-round ml-5 block text-34/34 cursor-pointer text-ivory-50 hover:bg-sage-600 rounded-full p-0.5"
            @click="toggleMenu"
            v-if="!showMenu"
          >
            menu
          </span>
          <span
            class="material-icons-round ml-5 cursor-pointer hidden text-34/34 md:block p-0.5"
            v-else
            @click="toggleMenu"
          >
            close
          </span>
          <transition name="fade">
            <mobile-menu
              v-if="showMenu"
              class="fixed left-0 top-0 w-full md:hidden z-900"
              :individual="individual"
              @close-menu="closeAll"
              @menu-close="closeAll"
            />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import BurgerMenuButton from "@/components/shared/BurgerMenuButton.vue";
import MobileMenu from "@/components/navigation/MobileMenu.vue";
import DesktopMenu from "@/components/navigation/DesktopMenu.vue";
import ImpersonationBanner from "@/components/navigation/ImpersonationBanner.vue";
import useIndividual from "@/composables/useIndividual";
import PostARoleButton from "./shared/PostARoleButton.vue";

export default {
  name: "NavBar",
  components: {
    BurgerMenuButton,
    MobileMenu,
    DesktopMenu,
    ImpersonationBanner,
    PostARoleButton,
  },
  setup() {
    const { individual } = useIndividual();

    return {
      individual,
      marketingAppURL: process.env.VITE_MARKETING_BASE_URL,
    };
  },
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    toggleMenu() {
      this.showProfile = false;
      this.showMenu = !this.showMenu;
    },

    closeAll() {
      this.showMenu = false;
      this.showProfile = false;
    },
  },
  computed: {
    externalLinks() {
      return [
        {
          label: "Podcast",
          url: `${this.marketingAppURL}/nurole-podcast-enter-the-boardroom`,
          shouldBeSignedIn: true,
          highlightAsNew: true,
        },
        {
          label: "Community",
          url: process.env.VITE_COMMUNITY_DOMAIN,
          shouldBeSignedIn: true,
          openInNewTab: true,
        },
      ];
    },
  },
};
</script>

<style scoped>
.router-link-active {
  @apply border-ivory-50  hover:border-ivory-300 active:border-ivory-400 !important;
}
</style>
