import { apiAxios } from "@/lib/axios";
import { Ref, readonly, ref } from "vue";
import * as Sentry from "@sentry/browser";
import { Qualification } from "@/types/Qualification";
import { format } from "date-fns";

const professionalQualifications: Ref<Qualification[]> = ref([]);
const isLoaded = ref(false);

export default function useQualifications() {
  async function getQualifications() {
    try {
      const { data } = await apiAxios.get("/professional_qualifications");
      professionalQualifications.value = data;
      isLoaded.value = true;
    } catch (error: any) {
      if (error.response?.status != 401) {
        Sentry.captureException({
          message: "Failed to fetch professional qualifications",
          error,
        });
      }
    }
  }

  async function createQualification(qualification: Qualification) {
    try {
      const { data } = await apiAxios.post(
        "/professional_qualification",
        qualification
      );
      professionalQualifications.value.push(data as Qualification);
    } catch (error) {
      Sentry.captureException({
        message: "Failed to add qualification",
        error,
      });
    }
  }

  async function updateQualification(qualification: Qualification) {
    try {
      const payload = { ...qualification, id: undefined };
      const { data } = await apiAxios.put(
        `/professional_qualification/${qualification.id}`,
        payload
      );
      const index = professionalQualifications.value.findIndex(
        e => e.id === qualification.id
      );
      professionalQualifications.value[index] = data as Qualification;
    } catch (error) {
      Sentry.captureException({
        message: "Failed to update professioanl qualification",
        error,
      });
    }
  }

  async function deleteQualification(qualification: Qualification) {
    try {
      await apiAxios.delete(`/professional_qualification/${qualification.id}`);
      const index = professionalQualifications.value.findIndex(
        e => e.id === qualification.id
      );
      professionalQualifications.value.splice(index, 1);
    } catch (error) {
      Sentry.captureException({
        message: "Failed to delete professioanl qualification",
        error,
      });
    }
  }

  function getDisplayDate(qualification: Qualification) {
    const certificationDate = qualification.certification_date?.split("-")[0];

    return [certificationDate].filter(Boolean).join(" - ");
  }

  function getPreviewCertificationDates(qualification: Qualification) {
    if (!qualification.certification_date) return "";
    return format(
      new Date(
        Number(qualification.certification_date.split("-")[0]),
        Number(qualification.certification_date.split("-")[1]) - 1
      ),
      "MMM yyyy"
    );
  }

  return {
    professionalQualifications: readonly(professionalQualifications),
    isLoaded: readonly(isLoaded),
    getQualifications,
    createQualification,
    updateQualification,
    deleteQualification,
    getDisplayDate,
    getPreviewCertificationDates,
  };
}
