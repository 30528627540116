<template>
  <div class="justify-center items-center p-3 w-full h-21 bg-guidance">
    <div class="flex items-center m-3 copy-emphasis">
      <span class="material-icons-outlined text-blue-800 text-3xl pr-2">
        arrow_circle_down
      </span>
      <p class="text-base">
        Please wait as we import roles from
        {{ cv?.filename ? cv.filename : "the CV you've uploaded" }}.
      </p>
    </div>
    <div class="hidden sm:flex items-center justify-center h-full">
      <div
        class="relative justify-center w-1/3 items-center p-3 bg-primary rounded-lg m-3"
        v-for="step in steps"
      >
        <div class="w-full flex justify-between mb-2">
          <div>{{ step.title }}</div>
          <div class="ml-auto">
            <span
              class="material-icons-round text-sage-700 text-sm pt-1.75"
              v-if="step.progress >= 100"
            >
              check_circle
            </span>
            <div v-else>{{ step.progress }}%</div>
          </div>
        </div>
        <div class="w-full flex mb-2 h-1 rounded-full bg-grey-200">
          <div
            class="h-1 rounded-full bg-sage-700"
            :style="{ width: step.progress + '%' }"
          ></div>
        </div>
      </div>
    </div>
    <div
      class="sm:hidden flex items-center justify-center h-full p-3 bg-primary rounded-lg m-3"
    >
      <div
        class="w-1/3 flex h-1 rounded-full m-1 bg-grey-200"
        v-for="step in steps"
      >
        <div
          class="h-1 rounded-full bg-sage-700"
          :style="{ width: step.progress + '%' }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, computed } from "vue";
import useCvParsing from "@/composables/useCvParsing";
import { apiAxios } from "@/lib/axios";
import useMiniCv from "@/composables/useMiniCv";

const cv = useCvParsing().getCv();
const emits = defineEmits(["parsed"]);

const steps = ref([
  {
    title: "Scanning roles",
    progress: 0,
    secondsToComplete: 10,
  },
  {
    title: "Processing roles",
    progress: 0,
    secondsToComplete: 20,
  },
  {
    title: "Populating roles",
    progress: 0,
    secondsToComplete: 30,
  },
]);

const cvParsingStatus = computed(() => useCvParsing().getCvParsingStatus());

onMounted(async () => {
  if (window.location.href.includes("onboarding")) {
    setProgress([100, 100, 0]);
  }
  pollForCvParsingStatus();
  for (let i = 0; i < steps.value.length; i++) {
    await fakeStepProgress(i, steps.value[i].secondsToComplete);
  }
});

async function pollForCvParsingStatus() {
  let numberOfRetries = 0;
  do {
    const newCvParsingStatus = await fetchCvParsingStatus();
    if (newCvParsingStatus != "in_progress") {
      onCvParsingPollingDone(newCvParsingStatus);
      return;
    }
    await new Promise(resolve => setTimeout(resolve, 5000));
    numberOfRetries += 1;
  } while (cvParsingStatus.value === "in_progress" && numberOfRetries < 25);

  onCvParsingPollingDone("failed");
}

async function fetchCvParsingStatus() {
  try {
    const response = await apiAxios.get(
      `/individual/cvs/parsed/${cv.id}/status`
    );
    return response.data.status;
  } catch {
    return "failed";
  }
}

async function onCvParsingPollingDone(status: string) {
  setProgress([100, 100, 100]);
  await new Promise(resolve => setTimeout(resolve, 1000));
  await useMiniCv().getRolesHeld();
  useCvParsing().setCvParsingStatus(status);
  emits("parsed");
}

function setProgress(progresses: number[]) {
  for (let i = 0; i < steps.value.length; i++) {
    steps.value[i].progress = progresses[i];
  }
}

async function fakeStepProgress(stepIndex: number, secondsToComplete: number) {
  const progressPerSecond = 100 / secondsToComplete;
  const isLastStep = stepIndex === steps.value.length - 1;
  while (
    steps.value[stepIndex].progress < 100 &&
    cvParsingStatus.value == "in_progress"
  ) {
    const newProgress = Math.round(
      steps.value[stepIndex].progress + progressPerSecond
    );
    if (isLastStep && newProgress >= 100) {
      steps.value[stepIndex].progress = 99;
      return;
    }
    steps.value[stepIndex].progress = newProgress;
    await new Promise(resolve => setTimeout(resolve, 1000));
  }
}
</script>
