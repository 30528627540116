<template>
  <div
    class="bg-white px-5 md:py-12 lg:px-28 w-full py-5 sm:py-12 my-5 md:my-0 md:border-b md:border-t-0 md:border-l-0 md:border-r-0 md:border-solid md:border-grey-100"
  >
    <preview-accordion>
      <template v-slot:title>
        <span class="text-title-h2 text-grey-800"> Relevant experience </span>
      </template>
      <template v-slot:content>
        <div class="flex justify-between flex-col">
          <preview-skill
            class="my-4"
            v-for="skill in roleApplication.skills"
            :key="skill.id"
            :skill="skill"
          />
        </div>
      </template>
    </preview-accordion>
  </div>
</template>

<script lang="ts">
import PreviewAccordion from "@/components/roleApplication/PreviewAccordion.vue";
import PreviewSkill from "@/components/roleApplication/PreviewSkill.vue";
import useRoleApplication from "@/composables/useRoleApplication";

export default {
  components: {
    PreviewAccordion,
    PreviewSkill,
  },
  setup() {
    const { roleApplication } = useRoleApplication();

    return {
      roleApplication,
    };
  },
  computed: {
    shouldShowIncompleteError() {
      return this.roleApplication.skills.some(
        skill => !skill.response?.relevant_roles_notes
      );
    },
  },
};
</script>
