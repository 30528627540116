<template>
  <role-apply-nudge-modal
    v-if="errorModalVisible"
    :shouldNudgeReasonForApplication="shouldNudgeReasonForApplication"
    :shouldNudgeDesiredSkills="shouldNudgeDesiredSkills"
    :shouldNudgeUserDetails="shouldNudgeUserDetails"
    :missingDesiredSkillIds="missingDesiredSkillIds"
    @close-modal="hideErrorModal"
    @scroll-to-amend="scrollToAmend"
  />
  <manage-cv-modal />
  <div data-cy="role-apply-page" class="flex">
    <role-apply-navigation-mobile class="fixed lg:hidden z-20" />
    <div class="hidden lg:block min-w-80 p-8 bg-sage-50">
      <role-apply-navigation-content
        class="md:fixed w-64 max-h-[calc(100vh_-40px)] overflow-y-auto"
      />
    </div>
    <div class="md:flex-grow mb-24">
      <role-apply-navigation-mobile class="placeholder invisible md:hidden" />
      <role-apply-navigation-header class="sticky top-20 lg:top-0 z-10" />
      <div v-if="isLoading">
        <h1
          class="block text-title-h1 text-grey-800 pb-12 pt-24 px-5 sm:px-12 lg:px-28 my-5 md:my-0 md:border-b md:border-t-0 md:border-l-0 md:border-r-0 md:border-solid md:border-grey-100 bg-white"
        >
          Apply for role
        </h1>
        <div
          class="px-5 sm:px-12 lg:px-28 py-5 md:py-12 my-5 md:my-0 flex flex-col space-y-16 bg-white"
        >
          <preview-skeleton v-for="i in [1, 2, 3, 4, 5]" :key="i" />
        </div>
      </div>
      <div
        v-else-if="roleApplication.submitted"
        class="h-screen flex flex-col gap-6 p-6"
      >
        <h1
          class="text-center block text-title-h1 text-grey-800 md:pb-0 pt-20 px-5 bg-white my-5 sm:px-12 md:my-0 lg:px-28"
        >
          Apply for role
        </h1>
        <p class="text-center">
          You have already submitted an application for this role.
        </p>
        <div class="flex flex-col md:flex-row gap-6 justify-center">
          <router-link
            :to="`/roles/${roleId}/apply/preview`"
            class="button-secondary text-cta-secondary mr-3"
          >
            View application
          </router-link>
          <button
            class="button-primary text-cta-primary"
            @click="createNewApplication"
          >
            Start a new one?
          </button>
        </div>
      </div>
      <div v-else>
        <div class="px-10 max-w-[800px] mx-auto">
          <segment hide-divider title="Personal details" />
          <user-details-card ref="userDetails" />
        </div>

        <div class="border-t mt-8 mb-6" />
        <div class="px-10 max-w-[800px] mx-auto">
          <segment hide-divider title="Professional experience" />
          <role-apply-professional-experience
            @missing-dates="val => (missingExperienceDates = val)"
            ref="experience"
          />
        </div>

        <div class="border-t mt-8 mb-6" />
        <div class="px-10 max-w-[800px] mx-auto">
          <segment
            hide-divider
            variant="h4"
            title="Your CV"
            description="Whilst your application will be evaluated primarily based on the information provided in your application, you can also attach a CV."
          />
          <role-apply-selected-cv v-if="roleApplicationCv" />
          <upload-cv v-else @cv-change="cvUploaded" />
        </div>

        <div class="border-t mt-8 mb-6" v-if="roleApplication.skills?.length" />
        <div
          v-if="roleApplication.skills?.length"
          class="px-10 max-w-[800px] mx-auto"
        >
          <segment hide-divider title="Relevant experience" />
          <role-apply-skills
            ref="skills"
            :shouldNudgeDesiredSkills="shouldNudgeDesiredSkills"
          />
        </div>

        <role-apply-extra-fields class="mt-10" />

        <div
          class="border-t mt-8 mb-6"
          v-if="role?.cover_letter_required !== false"
        />
        <div
          v-if="role?.cover_letter_required !== false"
          class="px-10 max-w-[800px] mx-auto"
        >
          <segment
            hide-divider
            title="Reason for application"
            :description="`${role?.cover_letter_intro || ''} For guidance and advice on what to include
                in your application, please see the <a target='_blank' href='${marketingAppURL}/news-and-guides/good_application_guide'>
                Nurole guide to good applications</a>`"
          />
          <role-apply-reason-for-application ref="reasonForApplication" />
        </div>

        <div class="border-t mt-8 mb-6" />
        <div class="px-10 max-w-[800px] mx-auto pb-8">
          <div class="flex flex-col md:flex-row justify-center mt-2">
            <n-button
              @click="showErrorsOrPreview"
              type="primary"
              label="Preview my application"
              show-right-icon
              right-icon="chevron_right"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import useVuelidate from "@vuelidate/core";
import UserDetailsCard from "@/components/shared/UserDetailsCard.vue";
import RoleApplyProfessionalExperience from "@/components/roleApplication/RoleApplyProfessionalExperience.vue";
import RoleApplySkills from "@/components/roleApplication/RoleApplySkills.vue";
import RoleApplyReasonForApplication from "@/components/roleApplication/RoleApplyReasonForApplication.vue";
import RoleApplyNudgeModal from "@/components/roleApplication/RoleApplyNudgeModal.vue";
import RoleApplyExtraFields from "@/components/roleApplication/RoleApplyExtraFields.vue";
import RoleApplyNavigationMobile from "@/components/roleApplication/RoleApplyNavigationMobile.vue";
import RoleApplyNavigationContent from "@/components/roleApplication/RoleApplyNavigationContent.vue";
import RoleApplySelectedCv from "@/components/roleApplication/RoleApplySelectedCv.vue";
import RoleApplyNavigationHeader from "@/components/roleApplication/RoleApplyNavigationHeader.vue";
import NButton from "@/components-v2/ui-kit/Button.vue";
import Segment from "@/components-v2/ui-kit/Segment.vue";
import UploadCv from "@/components/cv/UploadCv.vue";
import ManageCvModal from "@/components/roleApplication/ManageCvModal.vue";
import PreviewSkeleton from "@/components/roleApplication/PreviewSkeleton.vue";
import useRoleApplication from "@/composables/useRoleApplication";
import useIndividual from "@/composables/useIndividual";
import useRole from "@/composables/useRole";
import useCvs from "@/composables/useCvs";
import { nextTick } from "vue";

export default {
  props: {
    roleId: {
      required: true,
      type: String,
    },
  },
  components: {
    UploadCv,
    ManageCvModal,
    UserDetailsCard,
    RoleApplyNavigationMobile,
    RoleApplyNavigationContent,
    RoleApplyProfessionalExperience,
    RoleApplyReasonForApplication,
    RoleApplyExtraFields,
    RoleApplySkills,
    RoleApplySelectedCv,
    PreviewSkeleton,
    RoleApplyNavigationHeader,
    RoleApplyNudgeModal,
    NButton,
    Segment,
  },
  data() {
    return {
      isLoading: true,
      errorModalVisible: false,
      shouldNudgeReasonForApplication: false,
      shouldNudgeDesiredSkills: false,
      shouldNudgeUserDetails: false,
      missingExperienceDates: false,
      missingDesiredSkillIds: [],
    };
  },
  setup(props) {
    const { getIndividual, isMember, individual } = useIndividual();

    const {
      isManagingCv,
      roleApplicationCv,
      getIndividualCvs,
      getRoleApplicationCv,
      updateRoleApplicationCv,
    } = useCvs();

    const {
      roleApplication,
      setRoleId,
      getLatestRoleApplication,
      createNewApplication,
    } = useRoleApplication();
    const { role, getRoleById } = useRole();

    setRoleId(props.roleId); // TODO: We should not be using composables this way as it's too complex!

    return {
      isMember,
      isManagingCv,
      individual,
      role,
      roleApplication,
      roleApplicationCv,
      getRoleById,
      getIndividual,
      getIndividualCvs,
      getRoleApplicationCv,
      getLatestRoleApplication,
      updateRoleApplicationCv,
      createNewApplication,
      v$: useVuelidate(),
      marketingAppURL: process.env.VITE_MARKETING_BASE_URL,
    };
  },
  async mounted() {
    await this.getIndividual();
    await this.getIndividualCvs();
    await this.getRoleById(this.roleId);
    await this.getLatestRoleApplication();
    await this.getRoleApplicationCv();

    window.analytics.track("Role Application Begin", {
      roleId: this.roleId,
    });

    this.isLoading = false;

    if (this.$route.hash == "#professional-experience") {
      this.$nextTick(() => {
        this.$refs.experience.$el.scrollIntoView({ block: "end" });
      });
    }
  },

  methods: {
    async cvUploaded(cv) {
      await this.updateRoleApplicationCv(cv);
      await this.getIndividualCvs();
    },
    async showErrorsOrPreview() {
      this.v$.$touch();
      this.$refs?.skills?.v$.$touch();
      this.$refs?.experience?.v$?.$touch();
      !this.isMember && this.$refs?.userDetails?.v$?.$touch();
      await nextTick();
      const [firstError] = document.getElementsByClassName("scrollable-error");

      if (firstError) {
        firstError.scrollIntoView({ behavior: "smooth", block: "center" });
      } else if (this.hasNudges()) {
        this.showErrorModal();
      } else {
        await this.goToPreviewAndSaveCvToApplication();
      }
    },
    async goToPreviewAndSaveCvToApplication() {
      await this.updateRoleApplicationCv(this.roleApplicationCv);
      this.$router.push(`/roles/${this.roleId}/apply/preview`);
    },

    getScrollToKey() {
      if (this.shouldNudgeReasonForApplication) return "reasonForApplication";
      if (this.shouldNudgeDesiredSkills) return "skills";
      if (this.shouldNudgeUserDetails) return "userDetails";
      return null;
    },
    scrollToAmend() {
      const refKey = this.getScrollToKey();
      this.$refs?.[refKey]?.$el.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    },
    hasNudges() {
      this.checkReasonForApplicationNudge();
      this.checkDesiredSkillsNudge();
      this.checkUserDetailsNudge();

      return (
        this.shouldNudgeReasonForApplication ||
        this.shouldNudgeDesiredSkills ||
        this.shouldNudgeUserDetails
      );
    },

    checkReasonForApplicationNudge() {
      this.shouldNudgeReasonForApplication =
        this.role.cover_letter_required !== false &&
        !this.roleApplication.cover_letter;
    },
    checkDesiredSkillsNudge() {
      this.shouldNudgeDesiredSkills = false;

      const desiredSkills = this.roleApplication.skills?.filter(
        ({ required }) => required != "true"
      );

      const invalidDesiredSkills = desiredSkills.filter(skill => {
        const hasResponse = skill?.response?.relevant_roles_notes;
        const hasBeenSkipped = skill.response?.has_been_skipped;
        const skillIsValid = hasResponse || hasBeenSkipped;
        return !skillIsValid;
      });

      const invalidDesiredSkillsIds = invalidDesiredSkills.map(
        skill => skill.skill_id
      );
      if (invalidDesiredSkillsIds.length > 0) {
        this.shouldNudgeDesiredSkills = true;
        this.missingDesiredSkillIds = invalidDesiredSkillsIds;
      }
    },
    checkUserDetailsNudge() {
      this.shouldNudgeUserDetails = false;

      if (!this.isMember) {
        this.shouldNudgeUserDetails =
          !this.individual.first_name || !this.individual.last_name;
      }
    },
    hideErrorModal() {
      this.errorModalVisible = false;
    },
    showErrorModal() {
      this.errorModalVisible = true;
    },
    setPageTitle() {
      if (this.role) {
        document.title = `Apply for ${this.role.title} at ${this.role.name} | Nurole`;
      }
    },
  },
  computed: {
    roleApplicationProgress() {
      if (!this.role) {
        return 0;
      }

      // start at 2 because cv and professional experience
      let totalFields = 2;
      totalFields += this.role.skills?.length || 0;
      totalFields += this.roleApplication.extra_fields?.length || 0;
      if (this.roleApplication.cover_letter_required) totalFields++;

      let completedFields = 0;
      completedFields += this.roleApplication.skills?.filter(
        skill => skill.response
      ).length;
      completedFields += this.roleApplication.extra_fields?.filter(
        field => field.response
      ).length;
      if (this.roleApplication.cv_id) completedFields++;
      if (this.roleApplication.cover_letter) completedFields++;
      if (this.roleApplication.selected_roles_held?.length) completedFields++;

      return Math.round((completedFields / totalFields) * 100);
    },

    isiPhone() {
      return /iPhone/i.test(navigator.userAgent);
    },
  },
  watch: {
    role() {
      this.setPageTitle();
    },
    roleApplicationProgress(progress) {
      if (this.role && isNaN(progress) === false) {
        window.analytics.track("Progress made in role application", {
          percentage_complete: progress,
          role_id: this.role.id,
          role_application_id: this.roleApplication.id,
        });
      }
    },
  },
};
</script>
