<template>
  <div class="flex flex-col lg:flex-row mt-4 md:mt-2 mb-4 gap-4 lg:gap-0">
    <div class="flex flex-grow flex-col items-start">
      <span class="text-grey-900 copy-emphasis mb-4">
        {{ name }}
      </span>

      <form
        class="flex flex-col gap-4 mb-4 w-full p-4 md:py-6 md:px-10 bg-blue-50 rounded-lg"
      >
        <fieldset class="flex flex-col gap-3">
          <label
            for="location-select"
            class="text-inputs-title my-0 text-grey-900"
          >
            Location
          </label>
          <country-dropdown
            :initial-selection="country"
            @selection-made="setCountry"
            class="mb-2 bg-white"
          />
        </fieldset>

        <fieldset class="flex flex-col gap-3">
          <label for="telephone" class="text-grey-900 text-inputs-title my-0">
            Phone number
          </label>
          <input
            type="tel"
            id="telephone"
            class="text-input w-full"
            v-model="telephone"
            placeholder="Enter your phone number"
            data-cy="edit-details-telephone-field"
            autocomplete="tel"
          />
          <span
            v-if="v$.$error && v$.telephone.$errors.length"
            class="text-red-500 text-nudging mb-2"
          >
            Phone number is not valid
          </span>
        </fieldset>

        <fieldset class="flex flex-col gap-3">
          <label class="text-grey-900 text-inputs-title my-0" for="linkedin">
            LinkedIn
          </label>
          <linkedin-field
            :initial-value="linkedin"
            @value-changed="setLinkedin"
            class="mb-2 bg-white"
          />
          <span
            v-if="v$.$error && v$.linkedin.$errors.length"
            class="text-red-500 text-nudging mb-2"
          >
            Please use the format linkedin.com/in/username.
          </span>
        </fieldset>
        <button
          class="button-primary text-cta-primary flex gap-2 items-center justify-center w-full sm:w-auto self-start"
          @click.prevent="saveChanges"
          data-cy="edit-details-save-button"
        >
          <span>Save</span>
          <span v-if="saving" class="material-icons-round text-lg animate-spin">
            refresh
          </span>
        </button>
      </form>
      <div
        v-if="error"
        class="bg-red-50 rounded-lg w-full p-4 flex gap-2 items-center mt-4"
      >
        <span class="material-icons-round text-red-600 text-lg"> save </span>
        <span class="text-nudging text-red-700">
          Oops something has gone wrong, try again
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import CountryDropdown from "@/components/roleApplication/CountryDropdown.vue";
import LinkedinField from "@/components/roleApplication/LinkedinField.vue";
import useVuelidate from "@vuelidate/core";
import { numeric, minLength } from "@vuelidate/validators";
import useIndividual from "@/composables/useIndividual";
const { updateIndividual } = useIndividual();

const linkedUrl = value => !value || value.includes("linkedin.com/in/");

export default {
  components: { LinkedinField, CountryDropdown },

  props: {
    individual: { required: true },
  },

  data() {
    return {
      linkedin: "",
      country: "",
      telephone: "",
      saving: false,
      error: false,
    };
  },

  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      linkedin: { linkedUrl },
      telephone: { numeric, minLength: minLength(8) },
    };
  },

  emits: ["close-edit-details", "details-updated"],

  created() {
    const { telephone, country, linkedin } = this.individual;
    this.telephone = telephone || "";
    this.country = country || "";
    this.linkedin = linkedin || "";
  },

  computed: {
    name(): string {
      return `${this.individual.first_name} ${this.individual.last_name}`;
    },

    changesMade(): boolean {
      return (
        this.telephone !== this.individual.telephone ||
        this.country !== this.individual.country ||
        this.linkedin !== this.individual.linkedin
      );
    },
  },

  methods: {
    setLinkedin(linkedin: string) {
      this.linkedin = linkedin;
    },

    setCountry(country: string) {
      this.country = country;
    },

    async saveChanges() {
      if (this.saving) return;
      if (!this.changesMade) return this.$emit("close-edit-details");

      const valid = await this.v$.$validate();
      if (!valid) return;

      this.saving = true;
      this.error = false;

      try {
        await updateIndividual(
          {
            telephone: this.telephone,
            country: this.country,
            linkedin: this.linkedin,
          },
          "personal_details"
        );

        this.saving = false;
        this.$emit("details-updated");
      } catch (error) {
        this.saving = false;
        this.error = true;
      }
    },
  },
};
</script>
