<template>
  <div
    class="absolute w-full left-0 right-0 shadow-md max-h-64 overflow-y-auto rounded-b-lg z-90"
  >
    <button
      @click="$emit('on-select', option)"
      v-for="(option, index) in options"
      :key="index"
      :id="`option-${index}`"
      class="w-full p-4 hover:bg-grey-50 cursor-pointer text-left text-inputs-content"
      :class="{
        'bg-grey-100': index == highlightedIndex,
        'bg-white': index != highlightedIndex,
      }"
    >
      {{ displayKey ? option[displayKey] : option }}
    </button>
  </div>
</template>

<script>
import useOptionsKeyPresses from "@/composables/useOptionsKeyPresses";
import { ref } from "vue-demi";
export default {
  name: "SimpleOptionSelector",
  props: {
    options: {
      type: Array,
      required: true,
    },
    displayKey: {
      type: String,
      required: false,
    },
    defaultIndex: {
      type: Number,
      required: false,
    },
  },
  emits: ["on-select", "on-close"],
  setup(props, context) {
    const highlightedIndex = ref(-1);
    useOptionsKeyPresses({
      highlightedIndex,
      options: props.options,
      defaultIndex: props.defaultIndex,
      onEnter: () => {
        if (props.options[highlightedIndex.value]) {
          context.emit("on-select", props.options[highlightedIndex.value]);
        } else {
          context.emit("on-close");
        }
      },
      onTab: () => context.emit("on-close"),
    });

    return {
      highlightedIndex,
    };
  },
};
</script>
