<template>
  <div class="sm:flex w-full sm:w-auto gap-x-4">
    <button
      @click.prevent.stop="addRolesFromCv"
      type="button"
      class="button-secondary w-full sm:w-auto text-cta-secondary flex items-center justify-between ml-auto whitespace-nowrap mb-4 md:mb-0"
      data-cy="update-roles-button"
    >
      <span class="w-full"
        >{{ anyExistingRoles ? "Replace" : "Add" }} roles from CV</span
      >
    </button>
    <button
      @click.prevent="addARole"
      class="button-primary w-full sm:w-auto text-cta-primary flex items-center justify-between ml-auto whitespace-nowrap"
      data-cy="update-roles-button"
      type="button"
    >
      <span class="w-full">Add a role</span>
    </button>
  </div>
</template>

<script lang="ts" setup>
const emit = defineEmits(["add-a-role", "add-roles-from-cv"]);

defineProps({
  anyExistingRoles: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const addARole = () => {
  emit("add-a-role");
};

const addRolesFromCv = () => {
  emit("add-roles-from-cv");
};
</script>
