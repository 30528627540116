<template>
  <button
    class="cursor-pointer py-2 items-center flex flex-grow justify-center border-solid border outline-none focus:ring focus:z-10 focus:ring-orange-500 disabled:cursor-not-allowed px-4"
    :class="
      [
        borderRadiusClasses,
        otherClasses,
        secondary ? 'text-labelling-inactive' : 'text-labelling-active',
      ].join(' ')
    "
    :data-cy="dataCy"
  >
    <span
      class="material-icons-round text-white mr-2.5"
      v-if="!secondary && !join"
    >
      check
    </span>
    {{ `${label} ${join ? "" : `(${count})`}` }}
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "FilterButton",
  props: {
    label: { type: String },
    count: { default: 0, type: Number },
    secondary: { type: Boolean, default: false },
    join: { type: String },
    dataCy: { type: String },
  },
  computed: {
    borderRadiusClasses(): string {
      switch (this.join) {
        case "left":
          return "rounded-r-full rounded-l-none border-l-0";
        case "right":
          return "rounded-l-full rounded-r-none border-r-0";
        case "both":
          return "rounded-none";
        default:
          return "rounded-full";
      }
    },

    otherClasses(): string {
      if (this.secondary)
        return "bg-white text-grey-700  hover:bg-grey-50 active:bg-grey-100 border-grey-300";

      return "bg-sage-700  hover:bg-sage-600 active:bg-grey-900 text-white border-grey-700  hover:border-grey-600 active:border-grey-900";
    },
  },
});
</script>
