<template>
  <div class="p-8 mb-8 card-outlined">
    <div class="flex items-center">
      <div class="flex-1">
        <p class="text-cta-copy">{{ application.role_name }}</p>
        <p class="copy-emphasis text-sage-700">
          {{ application.role_title }}
        </p>
      </div>
      <img
        v-if="application.logo?.url"
        :src="application.logo.url"
        class="h-7"
      />
    </div>
    <div class="mt-3">
      <p v-if="application.submitted">
        You applied for this role on {{ appliedDate }}
      </p>
      <p v-else class="flex items-center">
        <svg
          width="12"
          height="14"
          viewBox="0 0 12 14"
          class="mr-2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 0H4V1.33333H8V0ZM5.33333 8.66667H6.66667V4.66667H5.33333V8.66667ZM10.6867 4.26L11.6333 3.31333C11.3467 2.97333 11.0333 2.65333 10.6933 2.37333L9.74667 3.32C8.71333 2.49333 7.41333 2 6 2C2.68667 2 0 4.68667 0 8C0 11.3133 2.68 14 6 14C9.32 14 12 11.3133 12 8C12 6.58667 11.5067 5.28667 10.6867 4.26ZM6 12.6667C3.42 12.6667 1.33333 10.58 1.33333 8C1.33333 5.42 3.42 3.33333 6 3.33333C8.58 3.33333 10.6667 5.42 10.6667 8C10.6667 10.58 8.58 12.6667 6 12.6667Z"
            fill="#242726"
          />
        </svg>
        Deadline: {{ deadlineDate }}
      </p>
    </div>
    <div class="mt-6">
      <p
        v-if="application.submitted"
        class="bg-blue-50 text-blue-700 font-semibold px-3 py-1 rounded inline-block mb-4"
      >
        Status: {{ application.candidate_visible_state }}
      </p>

      <div v-if="application.submitted">
        <router-link
          :to="'/applications/' + application.id"
          class="inline-block button-secondary text-cta-secondary cursor-pointer"
        >
          View application
        </router-link>
      </div>

      <div v-else-if="!application.submitted && deadlineInFuture">
        <router-link
          :to="'/roles/' + application.role_id"
          class="inline-block button-primary text-cta-primary text-white cursor-pointer mr-3"
        >
          View details
        </router-link>
        <router-link
          :to="'/roles/' + application.role_id + '/apply'"
          class="inline-block button-secondary text-cta-secondary cursor-pointer"
        >
          Continue application
        </router-link>
      </div>

      <p
        v-else
        class="bg-red-50 text-red-700 font-semibold px-3 py-1 rounded inline-block"
      >
        Role expired, deadline was {{ daysSinceDeadline }} day{{
          daysSinceDeadlinePluralModifier
        }}
        ago
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { formatDeadline } from "../../helpers/roleHelpers";
import { differenceInCalendarDays, format, isFuture } from "date-fns";

export default {
  name: "MyApplication",
  props: ["application"],
  computed: {
    deadlineInFuture() {
      return isFuture(new Date(this.application.deadline));
    },
    daysSinceDeadline() {
      return Math.abs(
        differenceInCalendarDays(
          new Date(),
          new Date(this.application.deadline)
        )
      );
    },
    daysSinceDeadlinePluralModifier() {
      return this.daysSinceDeadline === 1 ? "s" : "";
    },
    appliedDate() {
      if (!this.application.submitted_at) return "";
      return format(new Date(this.application.submitted_at), "do MMMM yyyy");
    },
    deadlineDate() {
      return formatDeadline(this.application.deadline);
    },
  },
};
</script>
