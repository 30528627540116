import { ref, onMounted, onUnmounted, watch, computed } from "vue";

export const useSideNavUtils = (isOnRoot, router, redirectTo) => {
  const screenWidth = ref(window.innerWidth);
  const isDesktop = computed(() => screenWidth.value >= 768);

  const handleResize = () => {
    screenWidth.value = window.innerWidth;
    if (isOnRoot.value && isDesktop.value) {
      router.push(redirectTo);
    }
  };

  handleResize();

  onMounted(() => {
    window.addEventListener("resize", handleResize);
  });

  onUnmounted(() => {
    window.removeEventListener("resize", handleResize);
  });

  watch(isOnRoot, newValue => {
    if (newValue) {
      handleResize();
    }
  });
};
