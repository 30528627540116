<template>
  <label
    class="text-inputs-title font-semibold block mt-6 mb-2"
    for="roleHeldOrganisation"
    ref="organisationName"
    >Organisation name*</label
  >
  <div class="w-full relative">
    <div
      class="w-full"
      :class="{
        'rounded-b-none ring-0 ring-offset-0 rounded-t-lg shadow-md':
          (showResults && searchResults.length) || showLoadingSkeleton,
      }"
    >
      <input
        :value="modelValue.name"
        autocomplete="organization"
        @input="onSearchOrganisation($event.target.value)"
        class="text-input text-inputs-content w-full"
        placeholder="Search for an organisation"
        data-organisation-name
        id="roleHeldOrganisation"
        name="roleHeldOrganisation"
        data-cy="role-held-organisation-name-input"
      />
      <span
        class="material-icons-round text-grey-900 absolute inset-y-0 right-10 xl:right-4 p-4 border border-transparent"
      >
        search
      </span>
    </div>
    <organisation-selector
      v-if="showResults && searchResults.length"
      :organisations="searchResults"
      @on-select="selectOrganisation"
      @on-none-of-these="selectNone"
    />
    <organisation-selector-skeleton v-if="showLoadingSkeleton" />
  </div>
</template>

<script lang="ts">
import OrganisationSelector from "./OrganisationSelector.vue";
import OrganisationSelectorSkeleton from "./OrganisationSelectorSkeleton.vue";
import { searchOrganisations } from "@/helpers/searchOrganisations";
import debounce from "@/utils/debounce";

export default {
  components: {
    OrganisationSelector,
    OrganisationSelectorSkeleton,
  },
  name: "RoleHeldOrganisation",
  props: ["modelValue"],
  data() {
    return {
      isSearchingOrganisations: false,
      searchResults: [],
      showResults: false,
    };
  },
  mounted() {
    this.searchOrganisations("warm");
    const hasOrganisationInfo =
      this.modelValue.sector?.length ||
      this.modelValue.ownership?.length ||
      this.modelValue.size?.label?.length;
    if (
      this.modelValue.name?.length &&
      !this.modelValue.id &&
      !hasOrganisationInfo
    ) {
      this.$refs.organisationName.focus();
      this.onSearchOrganisation(this.modelValue.name);
    }
  },
  methods: {
    onSearchOrganisation(name: any) {
      if (name === this.modelValue.name) return;
      this.$emit("update:modelValue", { name, id: null, size: { value: 0 } });
      this.isSearchingOrganisations = true;
      this.searchOrganisations(name);
      this.showResults = true;
    },
    selectNone() {
      this.showResults = false;
      this.$emit("update:modelValue", {
        name: this.modelValue.name,
        id: null,
        size: { value: 0 },
        sector: "",
        ownership: "",
        website: "",
      });
    },
    selectOrganisation(organisation) {
      this.$emit("update:modelValue", organisation);
      this.showResults = false;
    },
    update(key: string, value: string) {
      this.$emit("update:modelValue", { ...this.modelValue, [key]: value });
    },
    searchOrganisations: debounce(async function (name: string) {
      this.searchResults = await searchOrganisations(name);
      this.isSearchingOrganisations = false;
    }, 500),
  },
  computed: {
    showLoadingSkeleton() {
      return (
        this.isSearchingOrganisations &&
        this.searchResults.length == 0 &&
        this.modelValue.name.length > 0
      );
    },
  },
};
</script>
