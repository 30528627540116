import Toast, { PluginOptions, POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import "@/assets/toasts.css";
import ToastIcon from "@/components/ToastIcon.vue";

export const toastOptions: PluginOptions = {
  position: POSITION.BOTTOM_LEFT,
  containerClassName: "border-8 border-sage-600",
  timeout: 5000, // 5 seconds
  toastClassName: "tailwind",
  icon: ToastIcon,
};

export default Toast;
